import React, { Component } from 'react'
import { connect } from 'react-redux'
import autoBind from 'react-autobind'
import { getOrderStatus, sendEmail } from '../actions'

import { Form, Input, Modal } from 'antd'
import { isEmail } from 'validator'
import '../assets/stylesheets/page-shell.scss'
import '../assets/stylesheets/order-status.scss'

var tu = require('tracking-url')
class OrderStatus extends Component {
  constructor (props) {
    super(props)
    this.state = {
      visibleEmailSentModal: false,
      showExpiredTokenModal: false, 
      message: '', 
      email: props.user.email ? props.user.email : '',
      validEmail: true
    }
    autoBind(this)
  }

  componentDidMount() {

    
   
    const userId = localStorage.getItem("userId")
    if (userId !== null) {
      this.props.dispatch(getOrderStatus(userId))
    }
  }



  searchEmail(email, userId) {
    if(email !== 'invalidEmail') {
      this.props.dispatch(sendEmail(email, userId))
      .then((r) => {
        this.setState({ 
          visibleEmailSentModal: true, 
          showEmailSentModal: true, 
          message: r.message,
          validEmail: true})
      })
    }
  }
  checkEmail(user) {
    const userId = user ? user.id : null
    return (
      <Form key={'email' + userId}>
        <Input.Search
          type='text'
          placeholder="you@email.com"
          defaultValue={user ? user.email : null}
          className='order-status-email-input'
          enterButton={'Send'}
          onSearch={(value, e) => {
            e.preventDefault()
            const new_email = !isEmail(value) ? 'invalidEmail' : value
            if(new_email === 'invalidEmail') {
              this.setState({
                validEmail: false,
                email: new_email
              })
            }
            this.searchEmail(new_email, userId)
          }}
        />
      </Form>
    )
  }
  getNetsuiteTrackingInfo(status) {
    const trakingURL = status.fulfillment && status.fulfillment.tracking_url
    const trakingNumber = status.fulfillment && status.fulfillment.tracking_number
    return (
      (trakingURL)
      ? <a href={trakingURL} target="_blank" rel="noopener noreferrer">
        {trakingNumber} </a>
      : <p>{trakingNumber}</p>
      )
  }
  getSageTrackingInfo(status) {
    const track = tu(status.fulfillment.tracking_number)
    return (
      (track && track.url)
      ? <a href={track.url} target="_blank" rel="noopener noreferrer">
        {status.fulfillment.tracking_number} </a>
      : <p>{status.fulfillment.tracking_number}</p>
      )
  }
  getAFETrackingInfo(status) {
    return (
      status && status.fulfillment && status.fulfillment.boxes && status.fulfillment.boxes.map((box) => {
        const track = tu(box.tracking_number)
        return (
          (track && track.url)
            ? <a href={track.url} target="_blank" rel="noopener noreferrer">
              {box.tracking_number} </a>
            : <p>{box.tracking_number}</p>
        )
      }))
  }
  handleCancel = (e) => {
    this.setState({
      visibleEmailSentModal: false,
    });
  }
  closeDoubleUpBannerModal(){
    this.setState({
      showEmailSentModal: false
    })
  }
  emailSentModal() {
    const { message } = this.state
    return (
      <div className="token-modal">
        <Modal
          visible={this.state.visibleEmailSentModal}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        >
          <div className='token-modal-container'>
            { message === 'Email sent. Please check your email and visit confirmation link to continue.'
                ? <span>
                      <p className='token-modal-header'>Success!</p>
                      <p className='token-modal-text1'>Thank you for submitting your information. Check the email address you just entered and open the email from MyPhoto.</p>
                      <p className='token-modal-text2'>If you can't find it, please double check that you input the correct email address. To send a new link, click the SEND button again and open the most recent email.</p>
                  </span>
                : <span>
                    <p className='token-modal-header'>Oops, something went wrong.</p>
                    <p className='token-modal-text1'>Please try it again.</p>
                  </span>
            }
          </div>
        </Modal>
      </div>
    );
  }
  render() {
    const { orderStatus, user } = this.props
    console.log('order status', orderStatus)
    const errorUrl = window.location.search.split('&')
    return (
      <div className="shell-container main-container order-status-main-container">
        <div className="order-status-container container">
          <div className='order-status-header-container'>
            <h2 className="order-status-header">Order Status</h2>
            <div className='order-status-email-container'>
              <p className='order-status-email-text'>Can't wait for your order to arrive? Us too!</p>
              <p className='order-status-email-text'>Click the <span className='semi-bold'>Track My Package</span> button below for up to the minute shipping details.</p>
              <p className='order-status-email-text'>If your status is <span className='semi-bold'>processing</span>, your order is in it's final stages before shipping. 
              You will receive a confirmation email with tracking details as soon as your order ships.</p>
              <p className='order-status-email-text'>Not seeing your orders below? Enter your email.</p>
              {user.id ? this.checkEmail(user) : this.checkEmail(null)}
              {!this.state.validEmail ? <span style={{'color':'#b00202'}}>Please enter a valid email.</span> : ''}
              {(errorUrl[1] === "error=bad-token" || errorUrl[1] === "error=no-users" || errorUrl[1] === "error=no-update")
                ? <div className="token-error-message">
                    <p className='semi-bold'>No information found!</p>
                    <p>If you are seeing this message, please double check that you input the correct email address.</p>
                    <p>To send a new link, click the SEND button again and open the most recent email.</p>
                  </div>
                : null
                }
            </div>
          </div>
          {orderStatus && orderStatus.length
            ? <div className='order-status-orders-container'>
              {orderStatus.map((status) => {
                var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
                const date = new Date(status.orderDate)
                const formattedDate = date.toLocaleDateString("en-US", options)
                const statusConfirmId = status.confirmId
                return (
                  <div className='order-status-order' key={statusConfirmId}>
                    <div className='order-heading'>
                      <h4 className='order-date'>{formattedDate}</h4>
                      <h5 className='order-status'>{status.status}</h5>
                    </div>
                    <div className='order-content'>
                      <p className='order-status-id'>Order Id: <span className="semi-bold">{status.confirmId}</span></p>
                      <p className='order-status-total'>Total: <span className="semi-bold">${status.totalAmount}</span></p>
                    </div>
                      <div className='order-status-see-more'>
                        {status && status.items.map((item) => {
                          const isMultiPhoto = (item.numphotos > 1)
                          const previewImageUrl = (item.personalized && item.numphotos < 2)
                            ? item.previewurl
                            : item.product_img
                          const renderPreviewUrl = (previewImageUrl === null) ? null : <img src={previewImageUrl} alt='your order'></img>
                          return (
                            <div className='order-status-card'>
                              <div className='order-status-image-cont'>
                                {renderPreviewUrl}
                              </div>
                              <div className='order-status-content-cont'>
                                <span><p className='order-status-card-item-name'>{item.name}</p>
                                {isMultiPhoto && <span><p className='order-status-card-is-miltiphoto'>*image is a product preview (YOUR photos will print)</p></span>}</span>
                                <p className='order-status-card-item-qty'>QTY: {item.qty}</p>
                              </div>
                            </div>)
                        })}
                        {status && status.shipping && status.shipping.map((shipping) => {
                          console.log('shipping', shipping)
                          const zipCode = shipping && shipping.zip && shipping.zip.substring(0, 5)
                          return (
                            <div className='order-status-shipping-cont'>
                              <p className='order-status-shipping-text'><span className='semi-bold'>Shipping to: </span>{shipping.city}, {shipping.state}, {zipCode}</p>
                            </div>)
                        })}
                        <p className='order-status-shipping-text'><span className='semi-bold'>Tracking Number: </span>
                        {status && status.fulfillment && status.fulfillment.hasOwnProperty('sage_order_id')
                          ? this.getSageTrackingInfo(status)
                            : status && status.fulfillment && status.fulfillment.hasOwnProperty('boxes')
                              ? this.getAFETrackingInfo(status)
                                : status && status.fulfillment && status.fulfillment.hasOwnProperty('tracking_number')
                                  ? this.getNetsuiteTrackingInfo(status)
                              : 'No tracking number yet'
                        } </p>
                      </div>
                  </div>)
              })}
            </div>
            : null}
          {user.id && (orderStatus && !orderStatus.length)
            ? <div className='order-not-found-container' >
              <p className='order-not-found-text'>No Orders Found</p>
            </div>
            : null}
        </div>
        {this.state.showEmailSentModal ? this.emailSentModal() : null}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    orderStatus: state.order.orderStatus,
    user: state.user.user,
  }
}
export default connect(mapStateToProps)(OrderStatus)
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import autoBind from 'react-autobind'
import ReactGA from 'react-ga'
import _ from 'lodash'
import { path } from 'ramda'
import { withRouter } from 'react-router-dom'
import { isMobile } from "react-device-detect"
import {
  setProduct, openModal, update_project, is_fetching, updateMultiphotoStatus,
  update_filtered_url, update_filestack_filters, updateZoom, reset_project_edits } from '../../../actions'
import ThumbnailGenerator from '../../common/Thumbnail'
import TabBar from '../../common/tabBar/TabBar'
import Slider from '../../common/Slider'
import BackToProducts from './BackToProducts'
import YoutubeCommercial from '../YoutubeCommercial'
import YotpoStars from "./YotpoStars"
import YotpoWidget from "./YotpoWidget"
import SlickSlider from '../SlickSlider'
import SizeError from './SizeError'
import ProductOptionsWrapper from './ProductOptionsWrapper'
import ProductQuantity from './ProductQuantity'
import RenderButtons from './RenderButtons'
import ProductTitle from './ProductTitle'
import Overlays from './Overlays'
import GuaranteeRibbonWhite from '../../common/guaranteeUpdates/GuaranteeRibbonWhite';
import { CartModal, PreviewModal, DoubleUpModal, WarningModal } from "./ToggleModals"
import MetaDataTags from '../../../helpers/ProcessMetaData'
import addDefaultSrc from '../../../helpers/addDefaultSrc'
import addPhotoFromProject from '../../../helpers/addPhotoFromProject'
import {monetateProductPage,setIntervalHelper} from '../../../helpers/monetate'
import ViewVideoLink from '../../common/ViewVideoLink'

const propTypes = {
  location: PropTypes.object,
  products: PropTypes.any,
  photo: PropTypes.object,
  items: PropTypes.array,
  productSlug: PropTypes.string,
  photos: PropTypes.array,
  dispatch: PropTypes.func,
  product: PropTypes.object,
  seo: PropTypes.object,
  moreViews: PropTypes.array,
  user: PropTypes.object,
  project: PropTypes.object,
  zoomed: PropTypes.bool,
  zoomIndex: PropTypes.number,
  isMultiphoto: PropTypes.bool,
  croppedEdits: PropTypes.object,
  crop: PropTypes.object,
  zoom: PropTypes.number,
  hasFilter: PropTypes.bool,
}
var lowResIndexArr = []

export class ProductForm extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  }
  constructor(props) {
    super(props)
    const qty = []
    for (let i = 1; i <= 50; i++) {
      qty.push({
        value: i,
        label: i,
        updatedPhoto: false
      })
    }
    this.state = {
      qtyValue: (props.project && props.project.project && props.project.project.qty) ? props.project.project.qty : 1,
      product: {},
      item: {},
      qty,
      showModal: false,
      yotpoId: null,
      visiblePromo: false,
      showPromoModal: false,
      visibleWarning: false,
      showWarningModal: false,
      visibleCartModal: false,
      showCartModal: false,
      subtotal: 0,
      isOpen: false,
      inlineEditor: false,
      visiblePreviewModal: false,
      showPreviewModal: false,
    }
    autoBind(this)
  }
  componentDidMount() {
  let productObj =  this.props.products.find(p => p.slug === this.props.productSlug)
  let item =  this.props.items.find(p => p.productId === productObj.id)
  setIntervalHelper(() => {monetateProductPage(productObj,item)} )
 
 
  this.setProductData()
    setTimeout(() => { if (window.yotpo) window.yotpo.refreshWidgets() }, 537)
    // preload all of the overlays
    if (this.props.product && this.props.products) {
      const productItems = this.props.items.filter(items => items.productId === parseInt(this.props.product.id, 10));
      productItems.forEach(i => {
        const landscape = document.createElement('img');
        landscape.src = i.preview.landscape[0].img;
        const portrait = document.createElement('img');
        portrait.src = i.preview.portrait[0].img;
        if (i.preview.numPhotos > 1) { // preload all zoomedOverlays
          i.preview.portrait.forEach(p => {
            const zoom = document.createElement('img')
            zoom.src = p.zoomedOverlay
          })
        }
      })
    };   
  }

  componentWillReceiveProps(nextProps) {
    const { photos, project, dispatch } = this.props
    const hasProject = path(['project', 'photopersonalization', '0', 'url'], project) ? path(['project', 'photopersonalization', '0', 'url'], project) : false;
    const hasUploads =
      (photos && photos.length > 0 &&
        project && photos[0] && photos[0].id) ? true : false
    if (this.props.project === undefined && nextProps.project !== undefined) {
      // Reset qty to 1 when SWITCH product...
      var new_project = JSON.parse(JSON.stringify(nextProps.project))
      new_project.project.qty = 1
      this.setState({
        qtyValue: 1
      })
      this.props.dispatch(update_project(nextProps.project, new_project))
      this.props.dispatch(is_fetching())
    }
    if (hasProject && !hasProject.startsWith('blob') && !hasUploads && !this.state.updatedPhoto && nextProps.user.id !== null) {
      this.setState({ updatedPhoto: true })
      addPhotoFromProject(project, nextProps.user.id, dispatch)
    }
  }

  setProductData() {
    const { products, productSlug, dispatch, config } = this.props
    const subdomain = path(['appConfig', 'subdomain'], config)
    const product = products.find(p => p.slug === productSlug)
    if (product) {
      const item = this.getItem(product)
      const { yotpoId } = product;
      // record product detail view with GA:
      ReactGA.plugin.require('ec') // require ecommerce enhance GA plugin
      ReactGA.plugin.execute('ec', 'addProduct', {
        'id': item.sku,
        'name': item.name,
        'category': product.name,
        'brand': 'MyPhoto'
      });
      ReactGA.plugin.execute('ec', 'setAction', 'detail')
      // record product detail view with Klaviyo
      const prodStyle = (product.attributes && product.attributes[0]) ? product.attributes[0] : product.slug
      const klaviyoItem = {
        ProductName: item.name,
        ProductID: item.sku,
        Categories: product.attributes.concat(product.category), // The list of categories is an array of strings. 
        ImageURL: item.img,
        URL: `https://www.myphoto.com/products/${product.category}/${prodStyle}/${product.slug}`,
        Brand: 'MyPhoto',
        Price: item.msrp,
        subdomain: subdomain,
        SalesChannelId: path(['appConfig', 'id'], config),
      }
      if (subdomain && subdomain.toLowerCase() !== 'frontend') {
        window._learnq.push(['track', 'Viewed Product', klaviyoItem])
        window._learnq.push(['trackViewedItem', {
          Title: klaviyoItem.ProductName,
          ItemId: klaviyoItem.ProductID,
          Categories: klaviyoItem.Categories,
          ImageUrl: klaviyoItem.ImageURL,
          Url: klaviyoItem.URL,
          Metadata: {
            Brand: klaviyoItem.Brand,
            Price: klaviyoItem.Price,
          }
        }])
        window.pintrk('track', 'custom', {
          line_items: [{
            product_name: item.name,
            product_id: item.sku,
            product_price: item.msrp
          }]
        })
      }
      if (!product) {
        this.context.router.history.push('/products')
        return;
      }
      this.setState({
        product,
        item,
        // price: item.price,
        yotpoId
      });
      dispatch(setProduct(product.id))
    }
  }
  handler(subtotal) {
    this.setState({
      subtotal
    })
  }

  getItem(product) {
    const { project, items, dispatch } = this.props
    let item;
    if (!product || !product.optionItems) {
      // if no product found, redirect to 404 page
      window.location.assign('/404')
    }
    const option = product.optionItems.find(({ isDefault }) => isDefault)
    const defaultItemId = option.itemId
    const groupName = (option && option.groupName) || ''
    // if user has a project, update project with new item:
    if (project && project.project && project.project.itemId !== defaultItemId) {
      const itemX = items.find(ix => ix.id === Number(project.project.itemId));
      if (itemX && itemX.productId && itemX.productId === product.id) {
        // correct product, so update the item
        item = itemX;
        window.fbq('track', 'ViewContent', { content_ids: [item.sku], content_type: 'product' });
        window.gtag('event', 'page_view', {
          'send_to': 'AW-668262331',
          'ecomm_prodid': item.sku,
          'ecomm_pagetype': 'product',
          'ecomm_totalvalue': item.price
        });
        // Send to AdRoll
        window.adroll_custom_data = { 'product_id': item.sku }
        return item
      } else {
        // wrong product so update the project
        item = {
          ...items.find(({ id: itemId }) => itemId === defaultItemId),
          groupName,
        };
        var new_project = JSON.parse(JSON.stringify(project));
        const isMultiphoto = (item.preview.numPhotos > 1)
        new_project.project.itemId = item.id;
        new_project.project.name = item.name;
        new_project.project.sku = item.sku;
        new_project.isMultiphoto = isMultiphoto
        new_project.project.bleed = this.state.product.bleed;
        new_project.project.qty = 1
        var new_total = item.price
        new_project.project.amounts = { unitPrice: item.price, total: new_total };
        dispatch(update_project(project, new_project))
        dispatch(updateMultiphotoStatus(isMultiphoto))
        dispatch(is_fetching())
        window.fbq('track', 'ViewContent', { content_ids: [item.sku], content_type: 'product' });
        window.gtag('event', 'page_view', {
          'send_to': 'AW-668262331',
          'ecomm_prodid': item.sku,
          'ecomm_pagetype': 'product',
          'ecomm_totalvalue': item.price
        });
        // Send to AdRoll
        window.adroll_custom_data = { 'product_id': item.sku }
        return item
      }
    } else {
      item = {
        ...items.find(({ id: itemId }) => itemId === defaultItemId),
        groupName,
      };
      window.fbq('track', 'ViewContent', { content_ids: [item.sku], content_type: 'product' });
      window.gtag('event', 'page_view', {
        'send_to': 'AW-668262331',
        'ecomm_prodid': item.sku,
        'ecomm_pagetype': 'product',
        'ecomm_totalvalue': item.price
      });
      // Send to AdRoll
      window.adroll_custom_data = { 'product_id': item.sku }
      return item
    }
  }

  uploadPhotos() {
    this.props.dispatch(openModal('UPLOAD_MODAL'))
  }

  handleAddToCartClick() {
    const { product } = this.state
    const { allowsPersonalization } = product
    const { project, dispatch, cart, items, config } = this.props
    const subdomain = path(['appConfig', 'subdomain'], config)
    const item = (this.state.item && this.state.item.itemX && this.state.item.itemX.id) ? this.state.item.itemX : this.state.item;
    const qty = (project && project.project.qty)
      ? project.project.qty
      : this.state.qtyValue
        ? parseInt(this.state.qtyValue, 10)
        : 1;
    var thisProject = (allowsPersonalization)
      ? JSON.parse(JSON.stringify(project.project))
      : {
        photopersonalization: [{
          edits: {
            brightness: 1,
            rotation: 0
          },
          actualSize: {
            width: 0,
            height: 0,
          },
          thumbSize: {
            width: 0,
            height: 0,
          },
        }]
      };
    thisProject.allowsPersonalization = allowsPersonalization;
    thisProject.qty = parseInt(qty, 10);
    thisProject.itemId = item.id;
    thisProject.name = item.name;
    // first, check if landscape // TODO: Fix this to handle each printData.sku
    // thisProject.sku = thisProject.allowsPersonalization && (thisProject.photopersonalization[0].thumbSize.width > thisProject.photopersonalization[0].thumbSize.height)
    //   ? (item.printData.landscape[0].hasOwnProperty('sku') && item.printData.landscape[0].sku !== '')
    //     ? item.printData.landscape[0].sku
    //     : item.sku
    //   : (item.printData.portrait[0].hasOwnProperty('sku') && item.printData.portrait[0].sku !== '')
    //     ? item.printData.portrait[0].sku
    //     : item.sku;
    thisProject.sku = item.sku
    thisProject.bleed = this.state.product.bleed;
    var new_total = (product.allowsPersonalization)
      ? item.price * (1 + (qty - 1) / 2)
      : item.price * qty
    thisProject.amounts = {
      unitPrice: item.price,
      total: new_total
    }
    dispatch(update_filtered_url(cart, thisProject, items, product))
    dispatch(is_fetching())
    this.handleCartModal(item)
    // record add-to-cart with GA:
    ReactGA.plugin.require('ec') // require ecommerce enhance GA plugin
    ReactGA.plugin.execute('ec', 'addProduct', {
      'id': item.sku,
      'name': item.name,
      'category': product.name,
      'brand': 'MyPhoto',
      'price': item.price,
      'quantity': qty
    });
    ReactGA.plugin.execute('ec', 'setAction', 'add');
    ReactGA.ga('send', 'event', 'UX', 'click', 'add to cart');
    // record in Facebook
    window.fbq('track', 'AddToCart', {
      value: item.price * qty,
      currency: 'USD',
      content_ids: [item.sku],
      content_type: 'product'
    })
    // record in Pinterest
    window.pintrk('track', 'addtocart', {
      value: item.price * qty,
      order_quantity: qty,
      currency: 'USD'
    })
    // Send Add to Cart action to Klaviyo
    const prodStyle = (product.attributes && product.attributes[0]) ? product.attributes[0] : product.slug
    const klaviyoItem = {
      ProductName: item.name,
      ProductID: item.sku,
      Categories: product.attributes.concat(product.category), // The list of categories is an array of strings. 
      ImageURL: item.img,
      URL: `https://www.myphoto.com/products/${product.category}/${prodStyle}/${product.slug}`,
      Brand: 'MyPhoto',
      Price: item.msrp,
      subdomain: subdomain,
      SalesChannelId: path(['appConfig', 'id'], config),
    }
    if (subdomain && subdomain.toLowerCase() !== 'frontend') {
      window._learnq.push(['track', 'Added to Cart', klaviyoItem])
    }
  }

  handleCartModal(item) {
    this.setState({
      item,
      visibleCartModal: true,
      showCartModal: true
    });
  }
  handlePreviewModal() {
    this.setState({
      visiblePreviewModal: true,
      showPreviewModal: true
    })
  }
  handleQtyChange(new_qty) {
    if (this.props.project && this.props.project.project) {
      var new_project = JSON.parse(JSON.stringify(this.props.project));
      new_project.project.qty = parseInt(new_qty, 10);
      this.props.dispatch(update_project(this.props.project, new_project))
      this.props.dispatch(is_fetching())
    }
    if (new_qty > 1) { // show buy-more/save-more modal once
      // check if they've already seen it:
      if (localStorage.getItem('sawBMSM') !== 'yes') {
        // set local storage and show it
        localStorage.setItem('sawBMSM', 'yes')
        this.handlePromoModal()
      }
    }
  }
  handleOverlayChange(overlay, color) {
    var new_project = JSON.parse(JSON.stringify(this.props.project))
    var new_overlay = overlay
    const overlayExists = color === 'white'
      ? overlay.overlayWhite
      : color === 'black'
        ? overlay.overlayBlack
        : null
    if (overlayExists) {
      new_overlay.selectedOverlay = overlayExists
      new_overlay.color = color
      new_project.project.photopersonalization[0].overlay = new_overlay
      this.props.dispatch(update_project(this.props.project, new_project, 'overlayExists', overlay))
      this.props.dispatch(is_fetching())
    }
    else {
      delete new_project.project.photopersonalization[0].overlay
      this.props.dispatch(update_project(new_project, this.props.project))
      this.props.dispatch(is_fetching())
    }

    if( window.innerWidth < 769 && arguments[2] ){
      let el = document.querySelector('.product__img__preview2');
      if( el ){
        setTimeout( function(){ 
          window.scrollTo(0,el.getBoundingClientRect().top);
        }, 500 );
      }      
    }
  }
  handlePromoModal() {
    this.setState({
      visiblePromo: true,
      showPromoModal: true
    })
  }
  setQuantity(qty_value) {
    this.setState({ qtyValue: qty_value })
  }
  handleOptionsQty(item) { // triggered in ProductOptionsWrapper
    this.setState({ item });
  }

  inlineEditorToggle(forceState = null) {
    if (forceState == null) {
      this.setState({ inlineEditor: !this.state.inlineEditor })
    } else {
      this.setState({ inlineEditor: forceState })
    }
  }
  showAddToCartModal() {
    this.setState({ showModal: true })
  }

  openInlineEditor() {
    this.setState({
      inlineEditor: true
    })
  }
  openWarningModal() {
    this.setState({
      visibleWarning: true,
      showWarningModal: true
    })
  }
  pushLowResArr(i) {
    lowResIndexArr.push(i)
  }
  handleCancel(e, index) {
    //e.preventDefault()
    switch (index) {
      case 'visibleWarningModal':
        this.setState({
          visibleWarning: false
        })
        break
      case 'visibleCartModal':
        this.setState({
          visibleCartModal: false
        })
        break
      case 'visiblePreviewModal':
        this.setState({
          visiblePreviewModal: false
        })
        break
      case 'visiblePromoModal':
        this.setState({
          visiblePromo: false
        })
        break
      default: // no additional action needed
        break
    }
  }
  qtycallback(data) {
    this.setState({
      visiblePromo: data.visiblePromo,
      showPromoModal: data.showPromoModal,
    })
  }
  saveEditedValues(zoomIndex, zoomed) {
    const { project, croppedEdits, user, filter, dispatch } = this.props
    const projIndex = zoomed ? zoomIndex : 0;
    const overlayExists = path(['project', 'photopersonalization', 0, 'overlay'], project)
    dispatch(is_fetching());
    dispatch(update_filestack_filters(project, projIndex, croppedEdits, filter, user, overlayExists))
    dispatch(updateZoom(projIndex, false))
  }
  resetValues(zoomIndex, zoomed) {
    const { dispatch, project } = this.props
    const projIndex = zoomed ? zoomIndex : 0;
    var new_project = {
      id: project.id,
      project: {
        actualSize: {
          width: path(['project', 'photopersonalization', projIndex, 'actualSize', 'width'], project),
          height: path(['project', 'photopersonalization', projIndex, 'actualSize', 'height'], project)
        },
        thumbSize: {
          width: path(['project', 'photopersonalization', projIndex, 'thumbSize', 'width'], project),
          height: path(['project', 'photopersonalization', projIndex, 'thumbSize', 'height'], project)
        },
        brightness: path(['project', 'photopersonalization', projIndex, 'brightness'], project),
        rotation: path(['project', 'photopersonalization', projIndex, 'rotation'], project),
        imageWidth: undefined,
        imageHeight: undefined,
        imageX: undefined,
        imageY: undefined,
        imageUrl: path(['project', 'photopersonalization', projIndex, 'imageUrl'], project),
        originalUrl: path(['project', 'photopersonalization', projIndex, 'originalUrl'], project),
        thumbUrl: path(['project', 'photopersonalization', projIndex, 'thumbUrl'], project),
        url: path(['project', 'photopersonalization', projIndex, 'url'], project),
      }
    }

    const overlayExists = path(['project', 'photopersonalization', 0, 'overlay'], project)
    dispatch(reset_project_edits(project, new_project, projIndex, overlayExists))
    dispatch(is_fetching());
  }
  render() {
    const { product: { allowsPersonalization, yotpoId }, product, inlineEditor } = this.state
    const { zoomed, zoomIndex, moreViews, dispatch, project, photos, hasFilter, products, items, cart, isMultiphoto, overlays, selectedOverlay } = this.props
    //console.log('selectedOverlay ***', selectedOverlay)
    const hasProject = (project && project.project && project.project.photopersonalization && project.project.photopersonalization[0] && project.project.photopersonalization[0].actualSize && project.project.photopersonalization[0].actualSize.width) ? true : false;
    const hasUploads =
      (photos && photos.length > 0 &&
        project && photos[0] && photos[0].id)
    // console.log('hasProject', hasProject)
    // console.log('hasUploads', hasUploads)
    const productMoreViews = moreViews && moreViews.sort(({ displayOrder: a }, { displayOrder: b }) => {
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      }
      return 0;
    });
    const qtySuffix = (yotpoId === 'miniblox') ? ' of the same photo' : ''
    var item = path(['project', 'itemId'], project)
      ? // if we already have a selected item...
      _.head(this.props.items.filter(i => i.id === path(['project', 'itemId'], project)))
      : // otherwise choose default item...
      this.state.item;
    // now make sure it is for the right product, otherwise reset it to the default
    if (!item || item.productId !== product.id) { item = this.state.item }
    if (this.state.item !== item) { this.setState({ item: item }) }
    if (product && item.id) {
      const numPhotos = item.preview.numPhotos
      const isMultiPhoto = (numPhotos > 1)
      const isCollageOrMask = item.maskHorizontal || item.maskVertical
      const isSet = isMultiPhoto && !isCollageOrMask
      if (isMultiPhoto !== this.props.isMultiphoto) {
        console.log('misMatched isMultiphoto -- ', isMultiPhoto, this.props.isMultiphoto)
        dispatch(updateMultiphotoStatus(isMultiPhoto))
      }
      const newProductStyle = ((!hasUploads || !allowsPersonalization) ? `new-product-img-2 product-img-2` : `new-product-img-2 thumb-img-2`)
      const prodCatName = (product.category === 'frames-canvas')
        ? 'Frames & Canvas'
        : (product.category === 'table-top')
          ? 'Shelf & Desk'
          : _.startCase(product.category)
      const prodStyle = (product.attributes && product.attributes[0]) ? product.attributes[0] : product.slug
      const prodStyleName = (prodStyle === 'frames-canvas')
        ? 'Frames & Canvas'
        : (prodStyle === 'table-top')
          ? 'Shelf & Desk'
          : _.startCase(prodStyle)
      const overlayIsDisabled = product && product.overlayDisabled
      return (
        <div>
          <div className="main__container">
            {this.props.seo && <MetaDataTags
              pagesMetaData={this.props.seo}
              customData={product}
              key={product.id}
              optionalTitle="Products"
              baseUrl={window.location.origin}
              productCategory={product.category}
              productStyle={prodStyle}
              productSlug={product.slug}
            />}
            <BackToProducts // Back to products links
              product={product}
              prodCatName={prodCatName}
              prodStyle={prodStyle}
              prodStyleName={prodStyleName}
              item={item}
              zoomed={zoomed}
              dispatch={this.props.dispatch}
              zoomIndex={zoomIndex} />
            {(hasUploads && allowsPersonalization && !isMobile && (!isMultiPhoto || zoomed)) ?
              <Slider
                divClass="photos__list__container"
                titleClass="photos__list__title"
                // title="Select a photo to preview."
                autoSlide={true}
                loop={true}
                list={true}
                listItemSize={90}
                ease="ease-in-out"
                fixedHeight="45px"
                itemClick={this.updateProjectPhoto}
                remove={this.removePhotos}
                product={item}
                reverseOrder={true}
                showControls={true}
                showNav={false}
                bg={true}
                zoomIndex={zoomIndex}
                numPhotos={numPhotos}
              /> : null
            }
            {(hasProject && hasUploads && allowsPersonalization && isMobile && (isMultiPhoto || zoomed) && inlineEditor) ?
              <Slider
                divClass="photos__list__container-mobile multiphoto-edit"
                titleClass="photos__list__title"
                // title="Select a photo to preview."
                title='Select photo.'
                isMobile={isMobile}
                autoSlide={true}
                loop={true}
                list={true}
                listItemSize={90}
                ease="ease-in-out"
                fixedHeight="45px"
                itemClick={this.updateProjectPhoto}
                remove={this.removePhotos}
                product={item}
                reverseOrder={true}
                showControls={true}
                showNav={false}
                bg={true}
                zoomIndex={zoomIndex}
              /> : null
            }
            <div className="products__page__container">
              <div className="product-column-image product__img__preview">
                {this.state.item.outOfStock
                  ? <img
                    className="frame out-of-stock" onError={addDefaultSrc}
                    src={'https://storage.googleapis.com/myphoto-img/SOLDOUT_Ribbon.svg'} alt="Out of stock ribbon" />
                  : null}
                <div className="product__img__preview2">
                  {(this.state.product.newproduct && !zoomed)
                    ? <img className={newProductStyle} onError={addDefaultSrc} src="https://storage.googleapis.com/myphoto-img/new-product.svg" alt="NEW" />
                    : null}
                  {(!hasUploads || !allowsPersonalization || !hasProject) ?
                    <React.Fragment>
                      <SlickSlider item={item} product={this.state.product} productMoreViews={productMoreViews} uploadPhotos={this.uploadPhotos} isMobile={isMobile} />
                      <ViewVideoLink videoId={this.state.product.productVideoUrl || null} title="Play Video" />
                    </React.Fragment>
                    :
                    <ThumbnailGenerator
                      photoUrl={this.props.ImageURl}
                      actualSize={{ width: photos[0].width, height: photos[0].height }}
                      edits={this.props.photo.edits}
                      item={item}
                      alt={item.name || this.state.product.name}
                      ref="thumbnailCreator"
                      productMoreViews={productMoreViews}
                      inlineEditorToggle={this.inlineEditorToggle}
                      zoomed={zoomed}
                      zoomIndex={zoomIndex}
                      lowResIndexArr={lowResIndexArr}
                      inlineEditor={inlineEditor}
                      crop={this.props.crop}
                      zoom={this.props.zoom}
                      product={product}
                      hasFilter={hasFilter}
                      saveEditedValues={this.saveEditedValues}
                      resetValues={this.resetValues}
                      selectedOverlay={selectedOverlay}
                    />
                  }
                </div>
                {inlineEditor && <div className='filters-div'></div>}
              </div>
              <div className="product-column-content remove-gap">
                <div className="product__form">
                  <div className="product-form">
                    {(hasProject && hasUploads && allowsPersonalization && isMobile && (!isMultiPhoto || zoomed) && !inlineEditor) ?
                      <Slider
                        divClass="photos__list__container-mobile"
                        titleClass="photos__list__title"
                        // title="Select a photo to preview."
                        title='Select photo.'
                        isMobile={isMobile}
                        autoSlide={true}
                        loop={true}
                        list={true}
                        listItemSize={90}
                        ease="ease-in-out"
                        fixedHeight="45px"
                        itemClick={this.updateProjectPhoto}
                        remove={this.removePhotos}
                        product={item}
                        reverseOrder={true}
                        showControls={true}
                        showNav={false}
                        bg={true}
                        zoomIndex={zoomIndex}
                      /> : null
                    }
                    <ProductTitle name={item.name} price={item.price} />
                    <div className="columns" style={{ paddingBottom: '0.75rem', justifyContent:'space-between' }}>
                      {this.state.yotpoId && <YotpoStars yotpoId={this.state.yotpoId} product={this.state.product} />}
                      <div className="affirm_message">
                        <img className='affirm-logo' src={'/images/icons/affirm_logo.svg'} alt='affirm-logo' />
                        Pay over time
                      </div>
                    </div>
                    <ProductOptionsWrapper
                      items={items}
                      project={project}
                      product={this.state.product}
                      itemDefault={this.state.item}
                      dispatch={dispatch}
                      stateQty={this.state.qty}
                      handleOptionsQty={this.handleOptionsQty}
                      overlays={overlays}
                    />
                    {hasProject && hasUploads && allowsPersonalization && !overlayIsDisabled && <Overlays
                      overlays={overlays}
                      item={item}
                      project={project.project}
                      handleOverlayChange={this.handleOverlayChange}
                    />}
                    {hasUploads && hasProject && allowsPersonalization && <SizeError
                      hasUploads={hasUploads}
                      hasProject={hasProject}
                      project={project}
                      item={item}
                      isMultiPhoto={isMultiPhoto}
                      zoomed={zoomed}
                      numPhotos={numPhotos}
                      allowsPersonalization={allowsPersonalization}
                      zoomIndex={zoomIndex}
                      openWarningModal={this.openWarningModal}
                      lowResIndexArr={lowResIndexArr}
                      pushLowResArr={this.pushLowResArr}
                    />}
                    {(!isSet || !allowsPersonalization) &&
                      <ProductQuantity
                        hasProject={hasProject}
                        project={project}
                        qtyValue={this.state.qtyValue}
                        qty={this.state.qty}
                        qtySuffix={qtySuffix}
                        allowsPersonalization={allowsPersonalization}
                        isMultiPhoto={isMultiPhoto}
                        addDefaultSrc={addDefaultSrc}
                        handleQtyChange={this.handleQtyChange}
                        handlePromoModal={this.handlePromoModal}
                        setQuantity={this.setQuantity}
                      />}
                    <div className="upload-photo-btn is-hidden-mobile">
                      <RenderButtons
                        isTabbar={false}
                        hasUploads={hasUploads}
                        project={project}
                        isMultiPhoto={isMultiPhoto}
                        zoomed={zoomed}
                        inlineEditor={inlineEditor}
                        zoomIndex={zoomIndex}
                        product={product}
                        addDefaultSrc={addDefaultSrc}
                        dispatch={this.props.dispatch}
                        inlineEditorToggle={this.inlineEditorToggle}
                        item={this.state.item}
                        config={this.props.config}
                        handlePreviewModal={this.handlePreviewModal}
                        showAddToCartModal={this.showAddToCartModal}
                        uploadPhotos={this.uploadPhotos}
                        allowsPersonalization={allowsPersonalization}
                        croppedEdits={this.props.croppedEdits}
                        user={this.props.user}
                        filter={this.props.filter}
                        handleAddToCartClick={this.handleAddToCartClick}
                        saveEditedValues={this.saveEditedValues}
                        resetValues={this.resetValues}
                      />
                    </div>
                  </div>
                  <div className='guarantee-top-bottom-border'>
                    <GuaranteeRibbonWhite />
                  </div>
                  <YoutubeCommercial yotpoId={yotpoId} product={this.state.product} />
                </div>
              </div>
            </div>
          </div>
          <TabBar>
            <RenderButtons
              isTabbar
              hasUploads={hasUploads}
              project={project}
              isMultiPhoto={isMultiPhoto}
              zoomed={zoomed}
              inlineEditor={inlineEditor}
              zoomIndex={zoomIndex}
              product={product}
              addDefaultSrc={addDefaultSrc}
              dispatch={this.props.dispatch}
              inlineEditorToggle={this.inlineEditorToggle}
              item={this.state.item}
              config={this.props.config}
              handlePreviewModal={this.handlePreviewModal}
              showAddToCartModal={this.showAddToCartModal}
              uploadPhotos={this.uploadPhotos}
              allowsPersonalization={allowsPersonalization}
              croppedEdits={this.props.croppedEdits}
              user={this.props.user}
              filter={this.props.filter}
              handleAddToCartClick={this.handleAddToCartClick}
              resetValues={this.resetValues}
              saveEditedValues={this.saveEditedValues}
            />
          </TabBar>
          {(this.state.yotpoId) && <YotpoWidget yotpoId={this.state.yotpoId} product={this.state.product} />}
          {this.state.showWarningModal && <WarningModal
            visible={this.state.visibleWarning}
            onCancel={this.handleCancel}
          />}
          {this.state.showPromoModal && <DoubleUpModal
            visible={this.state.visiblePromo}
            onCancel={this.handleCancel} />}
          {this.state.showCartModal && <CartModal
            dispatch={dispatch}
            products={products}
            items={items}
            cart={cart}
            visible={this.state.visibleCartModal}
            onCancel={this.handleCancel}
            product={product}
            handler={this.handler}
            footer={false} />}
          {this.state.showPreviewModal && <PreviewModal
            item={item}
            project={project}
            isMultiphoto={isMultiphoto}
            zoomIndex={zoomIndex}
            dispatch={dispatch}
            visible={this.state.visiblePreviewModal}
            onCancel={this.handleCancel}
            handleClosePreviewModal={this.handleCancel}
            handleAddToCartClick={this.handleAddToCartClick}
            openInlineEditor={this.openInlineEditor}
            overlayIsDisabled={overlayIsDisabled}
            footer={null} />}
        </div>
      )
    }
    return null
  }
}

ProductForm.propTypes = propTypes

function mapStateToProps(state) {
  const productInstance = state.products.list.find(product => product.id === state.product.id)
  return {
    cart: state.cart.cart,
    product: state.product,
    products: state.products.list,
    items: state.products.items,
    seo: state.config.seoData,
    moreViews: productInstance && productInstance.altImages,
    user: state.user.user,
    photos: state.photos.photos,
    project: state.projects.project,
    config: state.config,
    zoomed: state.projects.zoomed,
    zoomIndex: state.projects.zoomIndex,
    isMultiphoto: state.projects.isMultiphoto,
    croppedEdits: state.projects.croppedEdits,
    crop: state.projects.crop,
    zoom: state.projects.zoom,
    filter: state.projects.filter,
    hasFilter: state.projects.hasFilter,
    overlays: state.overlays.overlays,
    selectedOverlay: state.overlays.selectedOverlay
  }
}
export default connect(mapStateToProps)(withRouter(ProductForm));
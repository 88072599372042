import React from 'react'
import { Progress } from 'antd'

const ShippingProgressBar = ({ subtotal, shippingOptions }) => {
  const thisMethod = shippingOptions.find(o => o.id === 1)
  const pctToFree = 100 * subtotal / thisMethod.free_cutoff
  const shippingText = (subtotal < thisMethod.free_cutoff ) 
  ? <p>Only <b>${(thisMethod.free_cutoff - subtotal).toFixed(2)}</b> left untill you've earned <b>Free Shipping!</b></p> 
  : <p>Congrats! You've earned <b>Free Shipping!</b></p>
  return(
  <span className="progress-sub-container">
    <span className="progress-text">{shippingText}</span>
    <Progress
      className="shipping-progress"
      percent={pctToFree}
      showInfo={false}
      >
    </Progress>
  </span>
  )
}
// `Only $${49 - subtotal} left untill you've earned Free Shipping!` 
export default ShippingProgressBar
import * as types from '../actions/actionTypes'

const INITIAL_STATE = {
  id: null,
  product: [],
}

function product(state = INITIAL_STATE, action) {
  switch (action.type) {
    // TODO: The following may all be deprecated...
    case types.PRODUCT_FETCH_SUCCESS:
      return {
        ...state,
        id: action.productId,
      };

    default:
      return state
  }
}

export default product
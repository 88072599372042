import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import '../../assets/stylesheets/category-links.scss'

const allLinks = [
    {name: 'New Arrivals', link: '/products/new'},
    {name: 'Best Sellers', link: '/products/best-sellers'},
    {name: 'Gift Guide', link: '/products/gift-guide'},
    {name: 'Acrylic', link: '/products/acrylic'},
    {name: 'Glass', link: '/products/glass'},
    {name: 'Metal', link: '/products/metal'},
    {name: 'Wood', link: '/products/wood'},
    {name: 'Collage', link: '/products/collage'},
    {name: 'Magnets', link: '/products/magnets'},
    {name: 'Minis', link: '/products/minis'},
    {name: 'Acrylic Blocks', link: '/products/acrylic-block'},
    {name: 'Modern', link: '/products/modern'},
    {name: 'Classic', link: '/products/classic'},
    {name: 'Shelf & Desk', link: '/products/table-top'},
    {name: 'Wall', link: '/products/wall'},
    {name: 'Frames & Canvas', link: '/products/frames-canvas'},
    // {name: 'Ornaments', link: '/products/ornaments'},
    {name: 'Shop All', link: '/products'},
]

const links = [
    {name: 'Shelf & Desk', link: '/products/table-top'},
    {name: 'Wall', link: '/products/wall'},
    {name: 'Modern', link: '/products/modern'},
    {name: 'Classic', link: '/products/classic'},
    {name: 'Gift Guide', link: '/products/gift-guide'},
    // {name: 'Ornaments', link: '/products/ornaments'},
    {name: 'Shop All', link: '/products'},
]

class CategoryLinks extends React.Component {
    render() {
        const linkList = (this.props.showAll) ? allLinks : links
        return (
            <div className='catlinks-container'>
                <h3 className='catlinks-title'>Shop by Style</h3>
                <ul className='catlinks-link-container'>
                    {linkList.map((link,i) => (
                        <li className='catlinks-link' key={i}>
                            <Link to={link.link}>{link.name.toUpperCase()} &gt;</Link>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }
}

export default withRouter(CategoryLinks)
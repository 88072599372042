import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { getInstagramPhotos, } from '../../actions/';
import ImageViewer from './ImageViewer';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';


const propTypes = {
  appId: PropTypes.string.isRequired,
  redirectUri: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  photos: PropTypes.object,
  updateSelectedPhotos: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  multiPhoto: PropTypes.bool,
};

const authenticateInstagram = (instagramClientId, instagramRedirectUri, callback) => {
  // the access token is required to make any endpoint calls, http://instagram.com/developer/endpoints/
  let accessToken = null;
  const appId = instagramClientId;
  const redirectUri = instagramRedirectUri;
  const popupWidth = 700;
  const popupHeight = 500;
  const popupLeft = (window.screen.width - popupWidth) / 2;
  const popupTop = (window.screen.height - popupHeight) / 2;
  // eslint-disable-next-line max-len
  const popup = window.open(window.location.origin, '', `width=${popupWidth}`, `height=${popupHeight}`, `left=${popupLeft}`, `top=${popupTop}`);
  popup.onload = () => {
    if (window.location.hash.length === 0) {
       // eslint-disable-next-line max-len
      popup.open(`https://api.instagram.com/oauth/authorize/?client_id=${appId}&redirect_uri=${redirectUri}&response_type=token`, '_self');
    }
    // an interval runs to get the access token from the pop-up
    const interval = setInterval(() => {
      try {
        // check if hash exists
        if (popup.location.hash.length) {
          // hash found, that includes the access token
          clearInterval(interval);
          accessToken = popup.location.hash.slice(14);
          // slice #access_token= from string
          popup.close();
          if (callback !== undefined && typeof callback === 'function') callback(accessToken);
        }
        // eslint-disable-next-line max-len
        if (popup.location.search === '?error_reason=user_denied&error=access_denied&error_description=The+user+denied+your+request.') {
          clearInterval(interval);
          // alert('Permission denied by the user');
          popup.close();
          if (callback !== undefined && typeof callback === 'function') callback();
        }
      } catch (evt) {
        if (evt.code !== 18) {
          // permission denied
          // alert('Permission denied by the user');
          clearInterval(interval);
          if (callback !== undefined && typeof callback === 'function') callback();
        }
      }
    }, 200);
  };
};

class InstagramImport extends React.Component {

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      auth: localStorage.getItem('authTokenInstagram') === 'undefined' ? {} :localStorage.getItem('authTokenInstagram'),
     
    };
  }
  componentDidMount() {
    if (!_.isEmpty(this.state.auth) && this.props.photos.data.length === 0) {
      this.props.dispatch(getInstagramPhotos(this.state.auth));
    } if (_.isEmpty(this.state.auth)) {

      const appId = this.props.appId;
      const redirectUri = this.props.redirectUri;
      authenticateInstagram(appId, redirectUri, this.responseInstagram);
    }
  }
  responseInstagram(authToken) {
    localStorage.setItem('authTokenInstagram', authToken);
    if (this.props.photos.data.length === 0) {
      this.props.dispatch(getInstagramPhotos(authToken));
    } else {
      this.props.callback('Error');
    }
  }

  loadMore(photos) {
    const authToken = this.state.auth;
    const nextUrl = photos.pagination.next_url;
    this.props.dispatch(getInstagramPhotos(authToken, nextUrl));
  }

  render() {
    const { photos } = this.props;
    if (photos.data.length === 0) {
      return (
        <article className="photo__upload__images">
          <p className="loading__copy center">loading...</p>
        </article>
      );
    }
    return (
      <article className="photo__upload__images has-text-centered">
        <ImageViewer
          images={photos.data}
          source="instagram"
          updateSelectedPhotos={this.props.updateSelectedPhotos}
          multiPhoto={this.props.multiPhoto}
        />
      {!_.isEmpty(photos.pagination) ?
        <span className="has-text-centered">
          <h5
            //type="button"
            className="photos__upload__btns__holder "
            onClick={() => {
              this.loadMore(photos);
            }}
          >
            Show more Of My Instagram Photos
          </h5>
        </span> :
        null
      }
    </article>
    );
  }
}

function mapStateToProps(state) {
  return {
    photos: state.photos.instagram,
    multiPhoto: state.config.multiPhoto,
  };
}

InstagramImport.propTypes = propTypes;

export default withRouter(connect(mapStateToProps)(InstagramImport));

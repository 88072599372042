import React from 'react'
import autoBind from 'react-autobind'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { openModal } from '../../actions'
import '../../assets/stylesheets/cta-container.scss'

class CTAsection extends React.Component {
    constructor(props) {
        super(props)
        autoBind(this)
    }
    render() {
        const { hasPictures, dispatch } = this.props
        return (
            <div className='cta-container is-hidden-mobile'>
                <div className='cta-text-container'><h2>Want to see your photo on all products?</h2></div>
                <div className='cta-button-container'>
                    {hasPictures ?
                        <Link to='/products'>
                            <button style={{ cursor: "pointer" }} className="try-it-btn">
                                Take a look
                            </button>
                        </Link>
                    :
                        <button style={{ cursor: "pointer" }} className="try-it-btn"
                            onClick={(e) => {
                                e.preventDefault()
                                this.setState({ showModal: true })
                                dispatch(openModal('UPLOAD_MODAL'))
                            }}>
                            <span style={{ verticalAlign: 'middle', display: 'inline-block', lineHeight: '100%',
                                        paddingLeft: '4px' }}>Upload Photo</span>
                        </button>
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(connect()(CTAsection))
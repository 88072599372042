import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import MetaDataTags from '../helpers/ProcessMetaData'
import { fetch_artcile } from '../actions'
import '../assets/stylesheets/page-shell.scss'
import '../assets/stylesheets/articles.scss'

const propTypes = {
  dispatch: PropTypes.func,
}

class Article extends Component{
  constructor(props) {
    super(props)
    this.state = {
      article:{
        altText: '',
        contents: '',
        createdAt: '',
        id: 1,
        image: '',
        slug: '',
        summary: '',
        thumbnail: '',
        title: '',
        updatedAt: '',
      }
    }
  }
  componentWillMount(){
    var url = window.location.pathname;
    var urlsplit = url.split("/");
    var articleSlug = urlsplit[urlsplit.length-1];
    console.log('Location.pathname', articleSlug)
    this.props.dispatch(fetch_artcile(articleSlug))
    .then((response)=>{
      console.log('reponse', response)
      this.setState({
        article: response[0]
      })
    })
  }
  render(){
    const { article } = this.state
    var options = { month: 'long', day: 'numeric', year: 'numeric'}
    const date = new Date(article.createdAt)
    const formattedDate = date.toLocaleDateString("en-US", options)
    return(
      <div className="shell-container main-container">
        <MetaDataTags baseUrl={`/articles/${article.slug}`} seoInfo={article.title} />
        <div className='container article-main-container'>
          <div className='article-content'>
            <h1 className='article-title'>{article.title}</h1>
            <p className='article-date italic'>{formattedDate}</p>
            <div className='article-image'>
              <img src={article.image} alt={article.altText}/>
            </div>
            
            <div className='article-text' dangerouslySetInnerHTML={{ __html: article.contents }}></div>
          </div>
          <div className='all-articles-link-cont'>
            <Link to='/articles' className='all-articles-link'>Back to All Articles</Link>
          </div>
        </div>
      </div>
    )
  }
}

Article.propTypes = propTypes

export default connect(null)(Article)
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

class AboutLinks extends Component {
  render(){
    const { pathname } = this.context.router.history.location
    const { title } = this.props
    return(
      <div className='about-links-container'>
        <h1 className='about-us-title'>{title}</h1>
        <div className='about-us-links'>
          <Link to="/our-story" className={`about-us-link ${(pathname==='/our-story') ? 'active' : ''}`}>OUR STORY</Link>
          <Link to="/about-us" className={`about-us-link ${(pathname==='/about-us') ? 'active' : ''}`}>ABOUT US</Link>
          <Link to="/reviews" className={`about-us-link ${(pathname==='/reviews') ? 'active' : ''}`}>REVIEWS</Link>
        </div>
      </div>
    )
  }
}
AboutLinks.contextTypes = {
  router: PropTypes.object.isRequired
}
export default AboutLinks
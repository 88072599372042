import React from "react";
import { withRouter } from "react-router-dom";
import { closeModal, fetch_user as fetchUser } from "../../../actions/";
import "../../../assets/stylesheets/page-shell.scss";
import "../../common/photo-picker/device-upload.scss";

const ContinueButton = ({
  dispatch,
  userId,
  history,
  displayContinue,
  backButton,
}) => (
  <div className="button-upload-phone-container">
    <button
      id="imgKeyBtn"
      disabled={!displayContinue ? true : false}
      style={{ backgroundColor: !displayContinue ? "#f8dec5" : "#ee6f6f" }}
      className="button-upload-phone"
      onClick={() => {
        backButton();
        dispatch(closeModal("UPLOAD_MODAL"));
        dispatch(fetchUser(userId));
        history.push("/products");
      }}
    >
      Continue
    </button>
  </div>
);

export default withRouter(ContinueButton);

import React from 'react'
import { connect } from 'react-redux'
import { openModal } from '../../../actions'
import addDefaultSrc from '../../../helpers/addDefaultSrc'
import TabBar from './TabBar';

class ProductsTabBar extends React.Component {
  render() {
    return (
      <TabBar>
        <div className="tab-bar-level">
          <div className="tab-bar-level-item">
              <button
                onClick={() => {
                  this.props.dispatch(openModal('UPLOAD_MODAL'))
                }}
                type="button"
                className="cta-btn easy-btn"
              >
                Upload your photo. It's Easy.
                <img className='tab-bar-arrow-icon' onError={addDefaultSrc}
                    src={'https://storage.googleapis.com/myphoto-img/icons/arrow-right-icon.png'} alt=''/>
              </button>
            </div>
        </div>
      </TabBar>
    )
  }
}
function mapStateToProps(state) {
  return {
    user: state.user.user,
    photos: state.photos.photos,
    project: state.projects.project,
  }
}
export default connect(mapStateToProps)(ProductsTabBar)
import React from 'react'
import {update_has_filter, updateZoom} from '../../../actions'

export const EditPhotoBtn = ({ inlineEditor, isMultiPhoto, zoomed, dispatch, inlineEditorToggle, zoomIndex, project, resetValues }) => {
  if (!inlineEditor) {
      // edit Photo
      return (
        <span style={{ width: '100%' }}>
          <button
            onClick={() => {
              inlineEditorToggle()
              dispatch(update_has_filter(false))
            }}
            type="button"
            className="edit-photo-btn"
            id="customize-btn"
          >Edit Photo
          </button>
        </span>
      )
  } else {
    //cancel
    return (
      <span style={{ width: '100%' }}>
        <button
          onClick={() => {
            inlineEditorToggle()
            resetValues(zoomIndex, zoomed)
            dispatch(updateZoom(zoomIndex, false))
          }}
          type="button"
          className="cancel-edits-btn"
          id="customize-btn"
        >Cancel
        </button>
      </span>
    )
  }
}

export default EditPhotoBtn

import ModalVideo from 'react-modal-video'

class ModalVideoFix extends ModalVideo {
    closeModal ( e ) {
        e.preventDefault();
        e.stopPropagation();
        super.closeModal(e);
    }
}

export default ModalVideoFix;
import { FETCH_USER } from '../actions/actionTypes'
import * as types from '../actions/actionTypes'

const INITIAL_STATE = {
    order_success: false,       
    user: {
        id: null,
        name: null,
        email: null,
        photos: [],
        carts: [{ items: [] }],
        projects: [],
        orders: [],
        promo_success: '',
        shippingValidation: {},
        userConfirmation: false,
    },
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_USER:
            if (!state.user.id) { // if no previous user.id, let's add to local storage
                localStorage.setItem('userId', action.payload.id);
            }
            return { ...state, user: action.payload, }
        // case FETCH_PROJECT:
        //     return { ...state, user: { projects: action.payload } }
        case types.CONFIRM_ORDER:
            return {
                ...state,
                order_success: true,
                confirm_id: action.payload.confirmId,
                orderItems: action.payload.orderItems,
            }
        case types.RESET_ORDER:
            return { ...state, order_success: false }
        case types.PROMO_SUCCESS:
            return { ...state, promo_success: action.payload }
        case types.ORDER_SUCCESS:
            return { ...state, order_success: true }
        case types.ORDER_FAILURE:
            return { ...state, order_success: false, message: action.payload }
        case types.SMARTYSTREETS_VALIDATION_SUCCESS:
            return { ...state, shippingValidation: action.payload }
        case types.SMARTYSTREETS_VALIDATION_FAILURE:
            return { ...state, error: action.payload }
        case types.USER_CONFIRMATION:
            return { ...state, userConfirmation: true }
        default:
            return state
    }
}
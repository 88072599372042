import * as types from './actionTypes'
import { history } from '../store'
import axios from 'axios'
import _ from 'lodash'
import { path } from 'ramda'
import Notifications from 'react-notification-system-redux' // MAY NOT BE NEEDED AFTER OVERHAUL
import EXIF from 'exif-js'
import uploadPhotosToS3 from '../helpers/uploadPhotosToS3'
import getImageActualSize from '../helpers/getImageActualSize'
import getImageDimensions from '../helpers/getImageDimensions'
// *** set new FOCUS version to bust cache (set in focus-api/hapi/Dockerfile)
const FOCUS = 34
// *** CRITICAL: push api update AFTER frontend is live to avoid reload loop

// const hln = (window.location.href.indexOf("hln.") > -1) ? true : false;
// const domain = window.location.hostname.split('.')
const local = (window.location.hostname.indexOf("local") > -1)
const staging = local || window.location.hostname.indexOf('staging') > -1;
const develop = (window.location.hostname.indexOf('develop.') > -1)
const API_KEY = 'AJPkbXMCpSHWIMU3KY6Ryz'
const API_URL_2 = (local || develop)
  ? 'https://myphoto-api-develop.appspot.com'
  : (staging)
    ? 'https://myphoto-api-staging.appspot.com'
    : 'https://mp-cloud-api.appspot.com'

export const Monetateenv = (local || develop)
? '//se.monetate.net/js/2/a-9b8dca2f/d/admin.focus-admin-staging.com/entry.js'
  : '//se.monetate.net/js/2/a-9b8dca2f/p/myphoto.com/entry.js'


//Set request interceptor to check Focus version
axios.interceptors.request.use(function (config) {
  if (config.url.startsWith(API_URL_2)) config.headers['focus-version'] = FOCUS
  return config
}, function (error) {
  console.log('Axios request Error', error)
  return Promise.reject(error)
})

//Check Focus version and refresh browser to get latest
axios.interceptors.response.use(function (response) {
  // console.log('AXIOS Response ', parseInt(response.config.headers['focus-version'], 10))
  if (response.status === 208) window.location.reload()
  return response;
}, function (error) {
  console.log('AXIOS response Error ', error)
  return Promise.reject(error)
})



/****************************************************************************
 *
 *       LOADING ACTIONS
 *
 ****************************************************************************/

export function is_fetching() {
  return function (dispatch) {
    dispatch({ type: types.IS_FETCHING })
    dispatch(turn_off_initial_load())
  }
}

export function is_not_fetching() {
  return {
    type: types.IS_NOT_FETCHING
  }
}

export function turn_off_initial_load() {
  return {
    type: types.TURN_OFF_INITIAL_LOAD
  }
}


/****************************************************************************
 *
 *       FETCHING ACTIONS
 *
 ****************************************************************************/

export function initialize(cb, ...args) {
  return (dispatch) => {
    return axios.get(`${API_URL_2}/initialize`)
      .then((response) => {
        let { token } = response.data
        dispatch(setInitialize(token))
        if (cb) {
          dispatch(cb(args[0]));
        }
      });
  };
}

export function setInitialize(token) {
  return {
    type: types.SET_INITIALIZE,
    token,
  };
}

export function fetch_user(user_id = null) {
  return (dispatch, getState) => {
    const { token } = getState().config
    return axios.get(`${API_URL_2}/users/${user_id}`, {
      headers: {
        'Authorization': token
      }
    })
      .then((response) => {
        if (response.data.parentId) {
          window.location.replace(window.location.origin + window.location.pathname + '?uid=' + response.data.parentId)
        } else {
          dispatch({
            type: types.FETCH_USER,
            payload: response.data
          })
          dispatch({
            type: types.FETCH_PHOTOS,
            payload: response.data.photos
          })
          dispatch({
            type: types.FETCH_PROJECT,
            payload: response.data.project
          })
          dispatch({
            type: types.FETCH_CART,
            payload: response.data.carts[0]
          })
          dispatch({
            type: types.IS_NOT_FETCHING
          })
        }
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
  }
}

export function fetch_photos(user_id) {
  return (dispatch, getState) => {
    const { token } = getState().config
    return axios.get(`${API_URL_2}/photos/${user_id}`, {
      headers: {
        'Authorization': token
      }
    })
      .then((response) => {
        dispatch({
          type: types.FETCH_PHOTOS,
          payload: response.data
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export function fetch_valid_promo(promotionCode, orderTotal) {
  return (dispatch, getState) => {
    const { token, appConfig } = getState().config;
    const { user } = getState().user;
    const salesChannelId = (appConfig && appConfig.id) ? appConfig.id : null
    const userId = (user && user.id) ? user.id : null;
    return axios.get(`${API_URL_2}/promotions/valid/${promotionCode}?salesChannelId=${salesChannelId}&userId=${userId}&orderTotal=${orderTotal}`, {
      headers: {
        'Authorization': token
      }
    })
      .then((response) => {
        dispatch(getTaxRate(userId))
        switch (response.status) {
          case 200:
            dispatch({
              type: types.FETCH_USER,
              payload: response.data
            })
            dispatch({
              type: types.FETCH_PHOTOS,
              payload: response.data.photos
            })
            dispatch({
              type: types.FETCH_PROJECT,
              payload: response.data.project
            })
            dispatch({
              type: types.FETCH_CART,
              payload: response.data.carts[0]
            })
            dispatch({
              type: types.SAVE_PROMO_CODE,
              payload: promotionCode
            })
            return "OK"
          case 201:
            dispatch({
              type: types.PROMO_SUCCESS,
              payload: response.data
            })
            return response.data
          case 202:
            dispatch({
              type: types.PROMO_SUCCESS,
              payload: response.data
            })
            return response.data
          default:
            dispatch({
              type: types.IS_NOT_FETCHING
            })
            return response.data
        }
      })
      .catch((error) => {
        console.log("GOT BACK ERROR: ", error)
        dispatch({
          type: types.INVALID_PROMOTION,
          error
        })
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
  }
}

export function fetchGiftCardBalance(code, userId) {
  return (dispatch, getState) => {
    const { token } = getState().config;
    return axios.get(`${API_URL_2}/giftcards/getbalance/${code}?userId=${userId}`, {
      headers: {
        'Authorization': token
      }
    })
      .then((response) => {
        switch (response.status) {
          case 200:
            dispatch({
              type: types.FETCH_USER,
              payload: response.data
            })
            dispatch({
              type: types.FETCH_PHOTOS,
              payload: response.data.photos
            })
            dispatch({
              type: types.FETCH_PROJECT,
              payload: response.data.project
            })
            dispatch({
              type: types.FETCH_CART,
              payload: response.data.carts[0]
            })
            dispatch({
              type: types.IS_NOT_FETCHING
            })
            return 'Giftcard Applied'
          case 202:
            dispatch({
              type: types.GIFTCARD_ZERO_BALANCE,
              payload: 'No balance on giftCard'
            })
            dispatch({
              type: types.IS_NOT_FETCHING
            })
            return 'No balance on giftCard'
          default:
            dispatch({
              type: types.GET_GIFTCARD_BALANCE_ERROR,
              error: 'Error validating giftcard',
            })
            dispatch({
              type: types.IS_NOT_FETCHING
            })
            return 'Error validating giftcard'
        }
      })
      .catch((error) => {
        dispatch({
          type: types.GET_GIFTCARD_BALANCE_ERROR,
          error: "Invalid Gift Card", //error.body.message
        })
        dispatch({
          type: types.IS_NOT_FETCHING
        })
        return 'Invalid giftcard code'
      })
  };
}

export function getTaxRate(userId) {
  return (dispatch, getState) => {
    const { token } = getState().config;
    return axios.patch(`${API_URL_2}/orders/taxrate/${userId}`, null, {
      headers: { 'Authorization': token }
    })
      .then((response) => {
        dispatch({
          type: types.FETCH_USER,
          payload: response.data
        })
        dispatch({
          type: types.FETCH_PHOTOS,
          payload: response.data.photos
        })
        dispatch({
          type: types.FETCH_PROJECT,
          payload: response.data.project
        })
        dispatch({
          type: types.FETCH_CART,
          payload: response.data.carts[0]
        })
        dispatch({
          type: types.IS_NOT_FETCHING
        })
        return response.data
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: types.IS_NOT_FETCHING
        })
        return 'taxFetchFail'
      })
  }
}

export function getOrderStatus(userId) {
  return (dispatch, getState) => {
    const { token } = getState().config;
    return axios.get(`${API_URL_2}/orders/status/${userId}`, { headers: { 'Authorization': token } })
      .then((response) => {
        dispatch({
          type: types.FETCH_ORDER_STATUS,
          payload: response.data.results
        })
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
  }
}

export function fetch_artciles() {
  return (dispatch, getState) => {
    const { token } = getState().config;
    return axios.get(`${API_URL_2}/articles`, { headers: { 'Authorization': token } })
      .then((response) => {
        dispatch({
          type: types.FETCH_ARTICLES,
          payload: response.data.results
        })
        dispatch({
          type: types.IS_NOT_FETCHING
        })
        return response.data.results
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
  }
}

export function fetch_artcile(id) {
  return (dispatch, getState) => {
    const { token } = getState().config;
    return axios.get(`${API_URL_2}/articles/${id}`, { headers: { 'Authorization': token } })
      .then((response) => {
        dispatch({
          type: types.FETCH_ARTICLE,
          payload: response.data.results
        })
        dispatch({
          type: types.IS_NOT_FETCHING
        })
        return response.data.results
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
  }
}

export function sendEmail(email, userId) {
  return (dispatch, getState) => {
    const { token } = getState().config
    if (!userId) { userId = '' }
    return axios.post(`${API_URL_2}/orders/status/status`, { email, userId }, { headers: { 'Authorization': token } })
      .then((response) => {
        dispatch({
          type: types.IS_NOT_FETCHING
        })
        return response.data
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
  }
}

/****************************************************************************
 *
 *       UPDATING ACTIONS
 *
 ****************************************************************************/

export function update_user(user_id, payload, userVerified) {
  return (dispatch, getState) => {
    const { token } = getState().config;
    var new_payload = [payload];
    if (!userVerified) { new_payload.verified = false } else { new_payload.verified = undefined }
    return axios.patch(`${API_URL_2}/users/${user_id}`, payload, {
      headers: {
        'Authorization': token
      }
    })
      .then((response) => {
        dispatch({
          type: types.FETCH_USER,
          payload: response.data
        })
        dispatch({
          type: types.FETCH_PHOTOS,
          payload: response.data.photos
        })
        dispatch({
          type: types.FETCH_PROJECT,
          payload: response.data.project
        })
        dispatch({
          type: types.FETCH_CART,
          payload: response.data.carts[0]
        })
        dispatch({
          type: types.IS_NOT_FETCHING
        })
        return 'Ok'
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
  }
}
export function update_project_final(blobUrl, photoParams) {
  return (dispatch, getState) => {
    const { token } = getState().config;
    var old_project = getState().projects.project
    const projectId = old_project.id
    if (projectId) {
      const project_blob = old_project.project.photopersonalization.find((p) => p.url === blobUrl)
      const ppIndex = old_project.project.photopersonalization.findIndex((p) => p.url === blobUrl)
      const new_photopersonalization = {
        actualSize: {
          width: photoParams.actualSize.width,
          height: photoParams.actualSize.height
        },
        thumbSize: {
          width: photoParams.thumbSize.width,
          height: photoParams.thumbSize.height
        },
        url: photoParams.url,
        originalUrl: photoParams.originalUrl,
        imageUrl: photoParams.originalUrl,
        thumbUrl: photoParams.url,
        brightness: 1,
        rotation: 0,
        imageX: undefined,
        imageY: undefined,
        imageHeight: undefined,
        imageWidth: undefined,
      }
      if (project_blob) {
        delete old_project.id
        delete old_project.project.create_date
        delete old_project.project.id
        delete old_project.project.user_id
      }
      var new_projectjson = JSON.parse(JSON.stringify(old_project.project))
      new_projectjson.photopersonalization[ppIndex] = new_photopersonalization
      return axios.patch(`${API_URL_2}/projects/${projectId}`, {
        id: projectId,
        project: new_projectjson
      }, {
        headers: {
          'Authorization': token
        }
      })
        .then((response) => {
          dispatch({
            type: types.FETCH_PROJECT,
            payload: response.data
          })
          dispatch({
            type: types.IS_NOT_FETCHING
          })
        })
        .catch((error) => {
          console.log(error)
          dispatch({
            type: types.IS_NOT_FETCHING
          })
        })
    } else {
      return setTimeout(function () { dispatch(update_project_final(blobUrl, photoParams)); }, 300)
    }
  }
}
export function update_project_initial(photos) {
  return (dispatch, getState) => {
    const { token } = getState().config
    // get from projects if multi-photo, zoomIndex, and if zoomed:
    const { zoomIndex, zoomed, isMultiphoto } = getState().projects
    var old_project = getState().projects.project
    const projectId = old_project.id
    var new_photopersonalization = old_project.project.photopersonalization
    photos.forEach((photo, i) => {
      if (isMultiphoto) { // If Multi-Photo...
        if (!zoomed) { // If Not Zoomed...
          // add to end of photopersonalization
          // console.log('add to end of photopersonalization')
          new_photopersonalization.push(photo)
        } else { // Else Zoomed...
          if (i === 0) { // add FIRST to ZoomIndex...
            // console.log('add FIRST to ZoomIndex')
            new_photopersonalization[zoomIndex] = photo
          } else { // add rest to end...
            // console.log('add rest to end')
            new_photopersonalization.push(photo)
          }
        }
      } else { // Else Not Multi-photo...
        if (i === 0) { // add FIRST to initial index location...
          // console.log('add FIRST to initial index location')
          new_photopersonalization[0] = photo
        } else { // add rest to end...
          // console.log('add rest to end')
          new_photopersonalization.push(photo)
        }
      }
      if ((i + 1) === photos.length) {
        var new_projectjson = JSON.parse(JSON.stringify(old_project.project))
        new_projectjson.photopersonalization = new_photopersonalization
        return axios.patch(`${API_URL_2}/projects/${projectId}`, {
          id: projectId,
          project: new_projectjson
        }, {
          headers: {
            'Authorization': token
          }
        })
          .then((response) => {
            dispatch({
              type: types.FETCH_PROJECT,
              payload: response.data
            })
            dispatch({
              type: types.IS_NOT_FETCHING
            })
          })
          .catch((error) => {
            console.log(error)
            dispatch({
              type: types.IS_NOT_FETCHING
            })
          })
      }
    })
  }
}
export function switch_project_photo(project, projectjson, index = 0) {
  const project_id = project.id
  var new_projectpersonalization = { // photo change...
    imageUrl: projectjson.image_url || projectjson.imageUrl || undefined,
    thumbUrl: projectjson.thumb_url || projectjson.thumbUrl || undefined,
    url: projectjson.thumb_url || projectjson.thumbUrl || undefined,
    originalUrl: projectjson.image_url || projectjson.imageUrl || undefined,
    actualSize: {
      width: projectjson.width || undefined,
      height: projectjson.height || undefined
    },
    thumbSize: {
      width: projectjson.thumb_width || projectjson.thumbWidth || undefined,
      height: projectjson.thumb_height || projectjson.thumbHeight || undefined
    },
    brightness: 1,
    rotation: 0,
    imageX: undefined,
    imageY: undefined,
    imageHeight: undefined,
    imageWidth: undefined,
  }
  var new_photopersonalization = project && project.project && project.project.hasOwnProperty('photopersonalization')
    ? JSON.parse(JSON.stringify(project.project.photopersonalization))
    : []
  if (project.project && project.project.photopersonalization && project.project.photopersonalization[index]) {
    // update project
    //console.log('~ updating existing personalization ~', index)
    new_photopersonalization[index] = new_projectpersonalization
  } else {
    // append
    new_photopersonalization.push(new_projectpersonalization)
  }
  var new_projectjson = project && project.project ? JSON.parse(JSON.stringify(project.project)) : {}
  new_projectjson.qty = 1
  new_projectjson.photopersonalization = new_photopersonalization
  return (dispatch, getState) => {
    const { token } = getState().config;
    return axios.patch(`${API_URL_2}/projects/${project_id}`, {
      id: project_id,
      project: new_projectjson
    }, {
      headers: {
        'Authorization': token
      }
    })
      .then((response) => {
        dispatch({
          type: types.FETCH_PROJECT,
          payload: response.data
        })
        dispatch({
          type: types.UPDATE_CROPPED_VALUES,
          payload: {
            croppedEdits: {},
            crop: {
              x: 0,
              y: 0
            },
            zoom: 1
          }
        })
        dispatch({
          type: types.UPDATE_IMAGE_FILTER,
          payload: { filter: {} }
        })
        dispatch({
          type: types.UPDATE_HAS_FILTER,
          payload: false
        })
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
  }
}
export function update_project_photo(projectId, project, photopersonalization) {
  var new_projectjson = JSON.parse(JSON.stringify(project.project))
  new_projectjson.photopersonalization = photopersonalization
  return (dispatch, getState) => {
    const { token } = getState().config;
    return axios.patch(`${API_URL_2}/projects/${projectId}`, {
      id: projectId,
      project: new_projectjson
    }, {
      headers: {
        'Authorization': token
      }
    })
      .then((response) => {
        dispatch({
          type: types.FETCH_PROJECT,
          payload: response.data
        })
        dispatch({
          type: types.UPDATE_CROPPED_VALUES,
          payload: {
            croppedEdits: {},
            crop: {
              x: 0,
              y: 0
            },
            zoom: 1
          }
        })
        dispatch({
          type: types.UPDATE_IMAGE_FILTER,
          payload: { filter: {} }
        })
        dispatch({
          type: types.UPDATE_HAS_FILTER,
          payload: false
        })
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
      .catch((error) => {
        console.log('ERROR UPDATING PROJECT PHOTO', error)
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
  }
}
export function update_has_filter(hasFilter) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_HAS_FILTER,
      payload: { hasFilter }
    })
  }
}
export function update_filestack_filters(project, projIndex, croppedEdits, filter, user, overlay) {
  return (dispatch) => {
    var new_project = {
      id: project.id,
      create_date: project.create_date,
      user_id: user.id,
      project: {
        actualSize: {
          width: path(['project', 'photopersonalization', projIndex, 'actualSize', 'width'], project),
          height: path(['project', 'photopersonalization', projIndex, 'actualSize', 'height'], project)
        },
        thumbSize: {
          width: path(['project', 'photopersonalization', projIndex, 'thumbSize', 'width'], project),
          height: path(['project', 'photopersonalization', projIndex, 'thumbSize', 'height'], project)
        },
        brightness: path(['project', 'photopersonalization', projIndex, 'brightness'], project),
        rotation: path(['project', 'photopersonalization', projIndex, 'rotation'], project),
        imageWidth: croppedEdits.imageWidth ? Number(croppedEdits.imageWidth) : path(['project', 'photopersonalization', projIndex, 'imageWidth'], project),
        imageHeight: croppedEdits.imageHeight ? Number(croppedEdits.imageHeight) : path(['project', 'photopersonalization', projIndex, 'imageHeight'], project),
        imageX: croppedEdits.imageX ? Number(croppedEdits.imageX) : path(['project', 'photopersonalization', projIndex, 'imageX'], project),
        imageY: croppedEdits.imageY ? Number(croppedEdits.imageY) : path(['project', 'photopersonalization', projIndex, 'imageY'], project),
        fileSize: path(['project', 'photopersonalization', projIndex, 'fileSize'], project),
        imageUrl: path(['project', 'photopersonalization', projIndex, 'imageUrl'], project),
        originalUrl: path(['project', 'photopersonalization', projIndex, 'originalUrl'], project),
        thumbUrl: (filter.imageFilter === 'monochrome' || filter.imageFilter === 'sepia' || filter.imageFilter === 'enhance')
          ? `https://cdn.filestackcontent.com/${filter.transformation}/${filter.imageFilter}/${filter.imageHandle}`
          : (filter.imageFilter === '')
            ? `https://cdn.filestackcontent.com/${filter.transformation}/${filter.imageHandle}`
            : path(['project', 'photopersonalization', projIndex, 'thumbUrl'], project),
        url: (filter.imageFilter === 'monochrome' || filter.imageFilter === 'sepia' || filter.imageFilter === 'enhance')
          ? `https://cdn.filestackcontent.com/${filter.transformation}/${filter.imageFilter}/${filter.imageHandle}`
          : (filter.imageFilter === '')
            ? `https://cdn.filestackcontent.com/${filter.transformation}/${filter.imageHandle}`
            : path(['project', 'photopersonalization', projIndex, 'url'], project),
        itemId: project.project.itemId, //change to path? 
        name: project.project.name,
        sku: project.project.sku
      }
    }
    dispatch(is_fetching());
    dispatch(update_project_edits(project, new_project, projIndex, overlay))
  }
}
export function update_project_edits(project, projectjson, index = 0, overlay) {
  const project_id = project.id
  var new_projectpersonalization = { // photo change...
    imageUrl: projectjson.project.imageUrl || undefined,
    thumbUrl: projectjson.project.thumbUrl || undefined,
    url: projectjson.project.url || undefined,
    originalUrl: projectjson.project.originalUrl,
    actualSize: {
      width: projectjson.project.actualSize.width || undefined,
      height: projectjson.project.actualSize.height || undefined
    },
    thumbSize: {
      width: projectjson.project.thumbSize.width || undefined,
      height: projectjson.project.thumbSize.height || undefined
    },
    brightness: projectjson.project.brightness,
    rotation: projectjson.project.rotation,
    imageX: projectjson.project.imageX,
    imageY: projectjson.project.imageY,
    imageHeight: projectjson.project.imageHeight,
    imageWidth: projectjson.project.imageWidth,
  }
  var new_photopersonalization = JSON.parse(JSON.stringify(project.project.photopersonalization))
  if (project.project && project.project.photopersonalization && project.project.photopersonalization[index]) {
    // update project
    //console.log('~ updating existing personalization ~', index)
    if (index === 0 && overlay) {
      new_projectpersonalization.overlay = overlay
      new_photopersonalization[index] = new_projectpersonalization
    } else {
      new_photopersonalization[index] = new_projectpersonalization
    }
  } else {
    // append
    new_photopersonalization.push(new_projectpersonalization)
  }
  // console.log('new_photopersonalization: ', new_photopersonalization)
  var new_projectjson = JSON.parse(JSON.stringify(project.project))
  new_projectjson.photopersonalization = new_photopersonalization
  //console.log('SENDING new_projectjson: ', new_projectjson)
  return (dispatch, getState) => {
    const { token } = getState().config;
    return axios.patch(`${API_URL_2}/projects/${project_id}`, {
      id: project_id,
      project: new_projectjson
    }, {
      headers: {
        'Authorization': token
      }
    })
      .then((response) => {
        dispatch({
          type: types.FETCH_PROJECT,
          payload: response.data
        })
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
  }
}

export function reset_project_edits(project, projectjson, index = 0, overlay) {
  // console.log('project in reset_project_edits', project )
  // console.log('projectjson in reset_project_edits', projectjson )
  const project_id = project.id
  var new_projectpersonalization = projectjson.project
  var new_photopersonalization = JSON.parse(JSON.stringify(project.project.photopersonalization))
  if (project.project && project.project.photopersonalization && project.project.photopersonalization[index]) {
    // update project
    //console.log('~ updating existing personalization ~', index)
    if (index === 0 && overlay) {
      new_projectpersonalization.overlay = overlay
      new_photopersonalization[index] = new_projectpersonalization
    } else {
      new_photopersonalization[index] = new_projectpersonalization
    }
  } else {
    // append
    //console.log('~ appending new personalization ~', index)
    new_photopersonalization.push(new_projectpersonalization)
  }
  //console.log('new_photopersonalization: ', new_photopersonalization)
  var new_projectjson = JSON.parse(JSON.stringify(project.project))
  new_projectjson.photopersonalization = new_photopersonalization
  //console.log('NewPROJECT JSON', new_projectjson)
  return (dispatch, getState) => {
    const { token } = getState().config;
    return axios.patch(`${API_URL_2}/projects/${project_id}`, {
      id: project_id,
      project: new_projectjson
    }, {
      headers: {
        'Authorization': token
      }
    })
      .then((response) => {
        //console.log('successfully done resetting the edits', response)
        dispatch({
          type: types.FETCH_PROJECT,
          payload: response.data
        })
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
  }
}

export function updateZoom(projectIndex, isZoomed) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_ZOOM,
      payload: { zoomed: isZoomed, zoomIndex: projectIndex }
    })
  }
}

export function updateMultiphotoStatus(isMultiphoto) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_MULTIPHOTO,
      payload: { isMultiphoto }
    })
  }
}

export function croppedParams(edits, crop, zoom) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_CROPPED_VALUES,
      payload: {
        croppedEdits: edits, crop, zoom,
      }
    })
  }
}
export function resetCroppedEdits() {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_CROPPED_VALUES,
      payload: {
        croppedEdits: {},
        crop: {
          x: 0,
          y: 0
        },
        zoom: 1,
      }
    })
  }
}
export function setCroppedEdits(crop) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_CROPPED_VALUES,
      payload: {
        crop
      }
    })
  }
}
export function setCroppedZoom(zoom) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_CROPPED_VALUES,
      payload: {
        zoom
      }
    })
  }
}
export function imageFilterParams(imageFilter, imageHandle, transformation) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_IMAGE_FILTER,
      payload: {
        filter: { imageFilter, imageHandle, transformation }
      }
    })
  }
}

export function update_project(project, projectjson, overlayExists = null, overlay = {}) {
  const project_id = project.id
  var new_projectjson = {
    qty: projectjson.project.qty || project.project.qty,
    itemId: projectjson.project.itemId || project.project.itemId,
    name: projectjson.project.name || project.project.name,
    sku: projectjson.project.sku || project.project.sku,
    //bleed: projectjson.project.bleed || project.project.bleed,
  }
  if (projectjson.amounts) {
    new_projectjson.amounts = {
      total: projectjson.project.amounts.total || project.project.amounts.total,
      unitPrice: projectjson.project.amounts.unitPrice || project.project.amounts.unitPrice,
      discount: projectjson.project.amounts.discount || project.project.amounts.discount
    }
  }
  if (overlayExists === 'overlayExists') {
    new_projectjson.photopersonalization = projectjson && projectjson.project && projectjson.project.hasOwnProperty('photopersonalization')
      ? JSON.parse(JSON.stringify(projectjson.project.photopersonalization))
      : []
  } else {
    new_projectjson.photopersonalization = project && project.project && project.project.hasOwnProperty('photopersonalization')
      ? JSON.parse(JSON.stringify(project.project.photopersonalization))
      : []
  }
  return (dispatch, getState) => {
    const { token } = getState().config;
    return axios.patch(`${API_URL_2}/projects/${project_id}`, {
      id: project_id,
      project: new_projectjson
    }, {
      headers: {
        'Authorization': token
      }
    })
      .then((response) => {
        dispatch({
          type: types.FETCH_PROJECT,
          payload: response.data
        })
        dispatch({
          type: types.IS_NOT_FETCHING
        })
        if (overlayExists === 'overlayExists') {
          dispatch({
            type: types.SAVE_OVERLAY,
            payload: overlay
          })
        }
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
  }
}
export function update_photo(user_id, photo) {
  return (dispatch, getState) => {
    const { token, appConfig } = getState().config;
    const campaignId = (appConfig && appConfig.campaignId) ? appConfig.campaignId : null
    const salesChannelId = (appConfig && appConfig.id) ? appConfig.id : null
    return axios.post(`${API_URL_2}/photos/${user_id}?source=project&campaignId=${campaignId}&salesChannelId=${salesChannelId}`, {
      photos: photo
    }, {
      headers: { 'Authorization': token }
    })
      .then((response) => {
        console.log('updated photo from project ****', response.data)
        dispatch({
          type: types.FETCH_PHOTOS,
          payload: response.data
        })
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
  }
}
// Handle incremental changes to cart item (like qty change)
export function update_cart_item(cart_item_id, cart, index, value, allowsPersonalization) {
  const cart_id = cart.id
  const cartItemId = parseInt(cart_item_id, 10)
  var payload = { index: index, value: value, userId: cart.user_id }
  switch (index) {
    case 'clear':
      payload.cartId = cart_id
      break
    case 'qty': // change amounts.total to reflect new qty
      const cart_item = cart.items.find(i => i.id === cartItemId)
      const unitPrice = cart_item.unit_price
      const new_total = allowsPersonalization ? (unitPrice * (1 + (value - 1) / 2)) : (unitPrice * value)
      payload.total = new_total
      break
    default: // no additional action needed
      break
  }
  return (dispatch, getState) => {
    const { token } = getState().config;
    return axios.patch(`${API_URL_2}/carts/${cartItemId}`, payload, {
      headers: { 'Authorization': token }
    })
      .then((response) => {
        dispatch({
          type: types.FETCH_CART,
          payload: response.data.carts[0]
        })
        if (index === 'delete') {
          dispatch({
            type: types.ITEM_REMOVED,
            payload: false
          })
        }
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
  }
}

export function update_order(order_id, index, value) {
  return (dispatch, getState) => {
    if (index === 'customer') delete value.firstName;
    const { token } = getState().config;
    return axios.patch(`${API_URL_2}/order/${order_id}`, { payload: { index, value } }, {
      headers: { 'Authorization': token }
    })
      .then((response) => {
        if (index === 'shipping') {
          const { user } = getState().user;
          dispatch(getTaxRate(user.id))
        } else {
          dispatch({
            type: types.FETCH_USER,
            payload: response.data
          })
          dispatch({
            type: types.FETCH_PHOTOS,
            payload: response.data.photos
          })
          dispatch({
            type: types.FETCH_PROJECT,
            payload: response.data.project
          })
          dispatch({
            type: types.FETCH_CART,
            payload: response.data.carts[0]
          })
          dispatch({
            type: types.IS_NOT_FETCHING
          })
        }
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
  }
}
export const updateFinalOrder = (orderId, data, notes, shipMethod, shippingId, paymentMethod) => {
  const { email, name, lastName, phone, billingAddress, shipping, giftNote }  = data
  notes.gift = giftNote
  let temp = {
    customer_email: email,
    customer_name: name,
    customer_last_name: lastName,
    customer_phone: phone,
    notes,
    billing_name: data.billToSame ? shipping.name : billingAddress.name,
    billing_last_name: data.billToSame ? shipping.last_name : billingAddress.last_name,
    billing_address1: data.billToSame ? shipping.address1 : billingAddress.address1,
    billing_address2: data.billToSame ? shipping.address2 : billingAddress.address2,
    billing_city: data.billToSame ? shipping.city : billingAddress.city,
    billing_state: data.billToSame ? shipping.state : billingAddress.state,
    billing_zip: data.billToSame ? shipping.zip : billingAddress.zip,
    billing_country: data.billToSame ? shipping.country : 'US',
    shipping: [{
      name: shipping.name,
      last_name: shipping.last_name,
      address1: shipping.address1,
      address2: shipping.address2,
      city: shipping.city,
      state: shipping.state,
      zip: shipping.zip,
      country: 'US',
      business: false,
      method: shipMethod,
      carrier: '',
      id: shippingId
    }]
  }
  if(paymentMethod === 'affirm'){
    temp['transaction_type'] = paymentMethod
  }

  return (dispatch, getState) => {
    const { token } = getState().config
    return axios.patch(`${API_URL_2}/orders/${orderId}`, temp, {
      headers: { 'Authorization': token }
    })
      .then((response) => {
        const { user } = getState().user
        if (paymentMethod === 'stripe' || paymentMethod === 'affirm') dispatch(getTaxRate(user.id))
        return 'updateSuccess'
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
  }
}

export function remove_giftcard(userId) {
  return (dispatch, getState) => {
    const { token } = getState().config;
    axios.patch(`${API_URL_2}/giftcards/remove?userId=${userId}`, null, {
      headers: {
        'Authorization': token
      }
    })
      .then((response) => {
        dispatch({
          type: types.FETCH_USER,
          payload: response.data
        })
        dispatch({
          type: types.FETCH_PHOTOS,
          payload: response.data.photos
        })
        dispatch({
          type: types.FETCH_PROJECT,
          payload: response.data.project
        })
        dispatch({
          type: types.FETCH_CART,
          payload: response.data.carts[0]
        })
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
      .catch(() => {
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
  };
}

export function update_order_pending(order_id) {
  return (dispatch, getState) => {
    const { token } = getState().config
    const { user } = getState().user
    const { cart } = getState().cart
    return axios.patch(`${API_URL_2}/orders/pending/${order_id}`, null, { headers: { 'Authorization': token } })
      .then((response) => {
        dispatch(
          update_cart_item(0, cart, 'clear', null)
        )
        dispatch({
          type: types.CONFIRM_ORDER,
          payload: response.data
        })
        dispatch({
          type: types.SAVE_PROMO_CODE,
          payload: null
        })
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
      .catch((error) => {
        console.log('Failed to update order status to pending', error)
        //alert("Something went wrong. Please contact Customer Service.")
        return axios.get(`${API_URL_2}/users/${user.id}`, {
          headers: {
            'Authorization': token
          }
        })
          .then((response) => {
            if (response && response.data && response.data.orders && response.data.orders[0] && response.data.orders[0].id) {
              dispatch(update_order_pending(response.data.orders[0].id))
            } else {
              alert("Your payment succeeded, but your order failed! Please contact Customer Service immediately at (800) 485-1760.")
              dispatch({
                type: types.IS_NOT_FETCHING
              })
            }
          })
          .catch((error) => {
            alert("Your payment succeeded, but your order failed! Please contact Customer Service immediately at (800) 485-1760.")
            dispatch({
              type: types.IS_NOT_FETCHING
            })
          })
      })
  }
}

export function updatePaypalgiftNote(order_id, data, notes) {
  const makeNotesObj = ({ giftNote }) => {
    notes.gift = giftNote
    return ({
      notes
    })
  }
  return (dispatch, getState) => {
    const { token } = getState().config
    return axios.patch(`${API_URL_2}/orders/${order_id}`, makeNotesObj(data), {
      headers: { 'Authorization': token }
    })
      .then((response) => {
        dispatch({
          type: types.FETCH_USER,
          payload: response.data
        })
        dispatch({
          type: types.FETCH_PHOTOS,
          payload: response.data.photos
        })
        dispatch({
          type: types.FETCH_PROJECT,
          payload: response.data.project
        })
        dispatch({
          type: types.FETCH_CART,
          payload: response.data.carts[0]
        })
        dispatch({
          type: types.IS_NOT_FETCHING
        })
        return 'updateSuccess'
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
  }
}

export function update_paypal_initial_shipping(order_id, index, value, newCustomer) {
  return (dispatch, getState) => {
    const { token } = getState().config;
    return axios.patch(`${API_URL_2}/order/${order_id}`, { payload: { index, value } }, {
      headers: { 'Authorization': token }
    })
      .then((response) => {
        const { user } = getState().user;
        dispatch(updatePaypalCustomer(order_id, newCustomer))
        dispatch(getTaxRate(user.id))
        dispatch({
          type: types.IS_NOT_FETCHING
        })
        return 'addressUpdated'
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
  }
}

export function updatePaypalCustomer(order_id, customer) {
  return (dispatch, getState) => {
    const { token } = getState().config;
    return axios.patch(`${API_URL_2}/orders/${order_id}`, customer, {
      headers: { 'Authorization': token }
    })
      .then(() => { // customer update success
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
      .catch((error) => { // customer update failed
        console.log('updatePaypalCustomer error: ', error)
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
  }
}

export function affirm_checkout (tokenId, orderId) {
  return (dispatch, getState) => {
    const { token } = getState().config;
    return axios.post(`${API_URL_2}/affirm/checkout`, { token: tokenId, orderId}, {
      headers: { 'Authorization': token }
    })
    .then((res) => {
        console.log('Affirm Checkout Sucessfull');
        dispatch(update_order_pending(orderId))
      })
    .catch((error) => {
      console.log('===== Error in Affirm checkout =====');
      console.log(error);
      console.log('====================================');
      dispatch({ type: types.IS_NOT_FETCHING })
    })
  }
}

export function update_order_paypal_shipping(order_id, user, shipping, billing, transactions, reason_code) {
  return (dispatch, getState) => {
    const { token } = getState().config;
    return axios.patch(`${API_URL_2}/order/${order_id}`, { payload: { index: 'shipping', value: shipping } }, {
      headers: { 'Authorization': token }
    })
      .then(() => { // shipping update success
        if (reason_code !== null) {
          dispatch(reasonCode(order_id, reason_code))
        }
        dispatch(update_order_paypal_customer(order_id, user, billing, transactions))
      })
      .catch((error) => { // shipping update failed
        // still continue so no orphan payments
        dispatch(update_order_paypal_customer(order_id, user, billing, transactions))
      })
  }
}

export function update_order_paypal_customer(order_id, user, billing, transactions) {
  return (dispatch, getState) => {
    const { token } = getState().config;
    const payload = {
      customer_email: user.email,
      customer_name: user.name,
      customer_last_name: user.lastName,
      customer_phone: user.phone,
    }
    return axios.patch(`${API_URL_2}/orders/${order_id}`, payload, {
      headers: { 'Authorization': token }
    })
      .then(() => { // shipping update success
        dispatch(update_order_paypal_billing(order_id, billing, transactions))
      })
      .catch((error) => { // shipping update failed
        console.log('update_order_paypal_customer error: ', error)
        // still continue so no orphan payments
        dispatch(update_order_paypal_billing(order_id, billing, transactions))
      })
  }
}

export function update_order_paypal_billing(order_id, billing, transactions) {
  return (dispatch, getState) => {
    const { token } = getState().config;
    return axios.patch(`${API_URL_2}/orders/${order_id}`, billing, {
      headers: { 'Authorization': token }
    })
      .then(() => { // billing update success
        dispatch(update_order_paypal_transactions(order_id, transactions))
      })
      .catch((error) => { // billing update failed
        console.log('update_order_paypal_billing error: ', error)
        // still continue so no orphan payments
        dispatch(update_order_paypal_transactions(order_id, transactions))
      })
  }
}

export function update_order_paypal_transactions(order_id, transactions) {
  return (dispatch, getState) => {
    const { token } = getState().config;
    return axios.patch(`${API_URL_2}/order/${order_id}`, { payload: { index: 'transactions', value: transactions } }, {
      headers: { 'Authorization': token }
    })
      .then((response) => { // add transaction success
        dispatch(update_order_pending(order_id))
      })
      .catch((err) => { // add transaction failed
        console.log('update_order_paypal_transactions error: ', err)
        dispatch(update_order_pending(order_id)) // still continue (so no orphan payments)
      })
  }
}

export function update_order_applePay_shipping(order_id, shipping, customer) {
  return (dispatch, getState) => {
    const { token } = getState().config;
    return axios.patch(`${API_URL_2}/order/${order_id}`, { payload: { index: 'shipping', value: shipping } }, {
      headers: { 'Authorization': token }
    })
      .then(() => { // shipping update success
        dispatch(update_order_applePay_customer(order_id, shipping, customer))
      })
      .catch((error) => { // shipping update failed
        console.log('update_order_paypal_shipping error: ', error)
        // still continue so no orphan payments
        dispatch(update_order_applePay_customer(order_id, shipping, customer))
      })
  }
}

export function update_order_applePay_customer(order_id, shipping, customer) {
  return (dispatch, getState) => {
    const { token } = getState().config;
    return axios.patch(`${API_URL_2}/orders/${order_id}`, customer, {
      headers: { 'Authorization': token }
    })
      .then(() => { // customer update success
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
      .catch((error) => { // customer update failed
        console.log('update_order_applePay_customer error: ', error)
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
  }
}

export function update_shipping_method(user_id, ship_method) {
  const userId = user_id
  const shipMethod = ship_method
  return (dispatch, getState) => {
    const { token } = getState().config;
    return axios.patch(`${API_URL_2}/orders/shipping/${shipMethod}?userId=${userId}`, null,
      { headers: { 'Authorization': token } })
      .then((response) => {
        dispatch({
          type: types.FETCH_USER,
          payload: response.data
        })
        dispatch({
          type: types.FETCH_PHOTOS,
          payload: response.data.photos
        })
        dispatch({
          type: types.FETCH_PROJECT,
          payload: response.data.project
        })
        dispatch({
          type: types.FETCH_CART,
          payload: response.data.carts[0]
        })
        // dispatch({
        //   type: types.IS_NOT_FETCHING
        // })
        dispatch(getTaxRate(userId))
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
  }
}

/****************************************************************************
 *
 *       CREATING ACTIONS
 *
 ****************************************************************************/

export function send_text(user_id = null, phone) {
  return (dispatch, getState) => {
    const { token, appConfig } = getState().config;
    const salesChannelId = (appConfig && appConfig.id) ? appConfig.id : null
    return axios.post(`${API_URL_2}/textlink?user_id=${user_id}&phone=${phone}&salesChannelId=${salesChannelId}`, null, {
      headers: { 'Authorization': token }
    })
      .then((response) => {
        const userId = response.data.id
        dispatch(update_user(userId, { phone: phone, sales_channel_id: salesChannelId }))
        dispatch({
          type: types.FETCH_USER,
          payload: response.data
        })
        dispatch({
          type: types.FETCH_PHOTOS,
          payload: response.data.photos
        })
        dispatch({
          type: types.FETCH_PROJECT,
          payload: response.data.project
        })
        dispatch({
          type: types.FETCH_CART,
          payload: response.data.carts[0]
        })
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      });
  }
}

//For Facebook
export function social_media(files, source = 'facebook', user_id = null) {
  var approvedFiles = [];
  var fileSizes = [];
  var rejections = [];
  var photos = [];

  files.forEach(file => {
    // run validator on each file
    // grab EXIF data
    var img = new Image();
    img.src = file.photoUrl;
    var orientation = 1;
    EXIF.getData(img, function () {
      orientation = EXIF.getTag(this, "Orientation")
    }
    );

    const photo = {
      actualSize: {
        width: img.width,
        height: img.height
      },
      thumbSize: {
        width: (orientation === 6) ? img.height : img.width,
        height: (orientation === 6) ? img.width : img.height
      },
      url: file.photoUrl,
      originalUrl: file.photoUrl,
      fileName: "null",
      fileSize: 0
    };
    photos.push(photo);
    if (rejections.length === 0) {
      approvedFiles.push(file);
      fileSizes.push(file.size);
    }
  });
  return (dispatch, getState) => {
    const { token, appConfig } = getState().config;
    const campaignId = (appConfig && appConfig.campaignId) ? appConfig.campaignId : null
    const salesChannelId = (appConfig && appConfig.id) ? appConfig.id : null
    // now make thumbnails of each photo
    dispatch(closeModal('UPLOAD_MODAL'))
    dispatch(is_fetching())
    return axios.post(`${API_URL_2}/photos?user_id=${user_id}&source=${source}&campaignId=${campaignId}&salesChannelId=${salesChannelId}`, {
      photos: photos
    }, {
      headers: { 'Authorization': token }
    })
      .then((response) => {
        dispatch({
          type: types.FETCH_USER,
          payload: response.data
        })
        dispatch({
          type: types.FETCH_PHOTOS,
          payload: response.data.photos
        })
        dispatch({
          type: types.FETCH_PROJECT,
          payload: response.data.project
        })
        dispatch({
          type: types.FETCH_CART,
          payload: response.data.carts[0]
        })
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      });
  };
}

/**************************************************************
 *                    FILE STACK UPLOADS                      *
 **************************************************************/

export function filestack_initial_uploads(files, user_id = null) {
  var photos = []
  const source = files[0].source
  return (dispatch, getState) => {
    const { token, appConfig } = getState().config;
    const campaignId = (appConfig && appConfig.campaignId) ? appConfig.campaignId : null
    const salesChannelId = (appConfig && appConfig.id) ? appConfig.id : null
    // grab EXIF data
    _.forEach(files, (f) => {
      getImageDimensions(f.url)
        .then((res) => {
          var newFileName = f.name.replace(/[^0-9a-zA-Z.]/g, '') // Ignore special Characters -- $%&*@#!+=\/[]{}’“;:<>,?
          photos.push({
            actualSize: {
              width: res.actualSize.width,
              height: res.actualSize.height
            },
            thumbSize: {
              width: res.thumbSize.width,
              height: res.thumbSize.height
            },
            url: f.url,
            originalUrl: f.url,
            thumbUrl: f.url,
            fileName: newFileName,
            fileSize: f.size
          })
          if (photos.length === files.length) {
            if (user_id !== null) {
              dispatch(update_project_initial(photos))
            }
            const apiPoint = (user_id !== null)
              ? `${API_URL_2}/photos/${user_id}?source=${source}&campaignId=${campaignId}&salesChannelId=${salesChannelId}`
              : `${API_URL_2}/photos?source=${source}&campaignId=${campaignId}&salesChannelId=${salesChannelId}`
            return axios.post(apiPoint, {
              photos: photos
            }, {
              headers: { 'Authorization': token }
            })
              .then((response) => {
                if (user_id === null) {
                  dispatch({
                    type: types.FETCH_USER,
                    payload: response.data
                  })
                  dispatch({
                    type: types.FETCH_PHOTOS,
                    payload: response.data.photos
                  })
                  dispatch({
                    type: types.FETCH_PROJECT,
                    payload: JSON.parse(JSON.stringify(response.data.project))
                  })
                  dispatch({
                    type: types.FETCH_CART,
                    payload: response.data.carts[0]
                  })
                } else {
                  dispatch({
                    type: types.FETCH_PHOTOS,
                    payload: response.data
                  })
                }
                dispatch({
                  type: types.IS_NOT_FETCHING
                })
              })
              .catch((error) => {
                console.log(error)
                dispatch({
                  type: types.IS_NOT_FETCHING
                })
              })
          }
        })
    })
  }
}

export function filestack_final_uploads(file, originalUrl, url, id) {
  // console.log('File, originalUrl', file, originalUrl)
  var userId = id
  var photos = [];
  return (dispatch, getState) => {
    const { token } = getState().config;
    if (!userId) {
      const { user } = getState().user;
      userId = user.id
    }
    // run validator on each file
    // grab EXIF data
    getImageDimensions(file.url)
      .then((res) => {
        let transformation
        if (res.thumbSize.width > res.thumbSize.height * 2) {
          transformation = 'rotate=deg:exif/resize=height:600'
        } else if (res.thumbSize.height > res.thumbSize.width * 2) {
          transformation = 'rotate=deg:exif/resize=width:600'
        } else {
          transformation = 'rotate=deg:exif/resize=width:600,height:600'
        }
        axios.get(`https://cdn.filestackcontent.com/rotate=deg:exif/imagesize/${file.handle}`)
          .then(result => {
            var newFileName = file.filename.replace(/[^0-9a-zA-Z.]/g, '') // Ignore special Characters -- $%&*@#!+=\/[]{}’“;:<>,?
            const needsRotation = (result.data.height > result.data.width && res.actualSize.height < res.actualSize.width)
            const photoParams = {
              actualSize: {
                width: (needsRotation) ? res.actualSize.height : res.actualSize.width,
                height: (needsRotation) ? res.actualSize.width : res.actualSize.height
              },
              thumbSize: {
                width: (needsRotation) ? res.thumbSize.height : res.thumbSize.width,
                height: (needsRotation) ? res.thumbSize.width : res.thumbSize.height
              },
              url: `https://cdn.filestackcontent.com/${API_KEY}/${transformation}/${file.handle}`,
              originalUrl: originalUrl,
              fileName: newFileName,
              fileSize: file.size,
              handle: file.handle
            }
            photos.push(photoParams)
            dispatch(update_project_final(url, photoParams))
            // console.log('FINAL_UPLOADS ====>', photos, url)
            return axios.patch(`${API_URL_2}/photos/${userId}`, { photos: photos, blobUrl: url }, { headers: { 'Authorization': token } })
              .then((response) => {
                dispatch({
                  type: types.FETCH_PHOTOS,
                  payload: response.data
                })
                dispatch({
                  type: types.IS_NOT_FETCHING
                })
              })
              .catch((error) => {
                console.log(error)
                dispatch({
                  type: types.IS_NOT_FETCHING
                })
              })
          })
      })
  }
}
export function filestack_social_uploads(files, user_id = null) {
  var photos = [];
  const source = files[0].source
  return (dispatch, getState) => {
    const { token, appConfig } = getState().config;
    const campaignId = (appConfig && appConfig.campaignId) ? appConfig.campaignId : null
    const salesChannelId = (appConfig && appConfig.id) ? appConfig.id : null
    files.forEach(file => {
      // run validator on each file
      // grab EXIF data
      getImageDimensions(file.url)
        .then((res) => {
          var newFileName = file.filename.replace(/[^0-9a-zA-Z.]/g, '') // Ignore special Characters -- $%&*@#!+=\/[]{}’“;:<>,?
          let transformation
          if (res.thumbSize.width > res.thumbSize.height * 2) {
            transformation = 'resize=height:600'
          } else if (res.thumbSize.height > res.thumbSize.width * 2) {
            transformation = 'resize=width:600'
          } else {
            transformation = 'resize=width:600,height:600'
          }
          photos.push({
            actualSize: {
              width: res.actualSize.width,
              height: res.actualSize.height
            },
            thumbSize: {
              width: res.thumbSize.width,
              height: res.thumbSize.height
            },
            url: `https://cdn.filestackcontent.com/${API_KEY}/${transformation}/${file.handle}`,
            originalUrl: file.url,
            fileName: newFileName,
            fileSize: file.size,
            thumbUrl: file.url,
            handle: file.handle
          })
          if (photos.length === files.length) {
            if (user_id !== null) {
              dispatch(update_project_initial(photos))
            }
            const apiPoint = (user_id !== null)
              ? `${API_URL_2}/photos/${user_id}?source=${source}&campaignId=${campaignId}&salesChannelId=${salesChannelId}`
              : `${API_URL_2}/photos?source=${source}&campaignId=${campaignId}&salesChannelId=${salesChannelId}`
            return axios.post(apiPoint, {
              photos: photos
            }, {
              headers: { 'Authorization': token }
            })
              .then((response) => {
                if (user_id === null) {
                  dispatch({
                    type: types.FETCH_USER,
                    payload: response.data
                  })
                  dispatch({
                    type: types.FETCH_PHOTOS,
                    payload: response.data.photos
                  })
                  dispatch({
                    type: types.FETCH_PROJECT,
                    payload: response.data.project
                  })
                  dispatch({
                    type: types.FETCH_CART,
                    payload: response.data.carts[0]
                  })
                } else {
                  dispatch({
                    type: types.FETCH_PHOTOS,
                    payload: response.data
                  })
                }
                dispatch({
                  type: types.IS_NOT_FETCHING
                })
              })
              .catch((error) => {
                console.log(error)
                dispatch({
                  type: types.IS_NOT_FETCHING
                })
              });
          }
        })
    });
  }
}
// export function setProgressBar(id, percent) {
//   return (dispatch) => {
//     dispatch({
//       type: types.PROGRESS_BAR,
//       payload: { id, percent }
//     })
//   }
// }
//dropZone final uploads
export function new_user_final_upload(file, user_id) {
  const getKey = file => encodeURI(`${Date.now()}-${file.name}`.replace(/([^a-z0-9.]+)/gi, ''));
  // endpoint to get AWS authorization
  const policyEndpoint = `${API_URL_2}/auth/s3policy`;
  return (dispatch, getState) => {
    var thumbWorked = true
    const { token } = getState().config;
    // upload approved files to AWS
    const key = getKey(file);
    // get AWS authorization
    return axios.post(policyEndpoint, { fileName: key }, {
      headers: {
        'Authorization': token
      }
    })
      // now upload to S3
      .then((response) => uploadPhotosToS3({
        bucket: response.data.bucket,
        key,
        awsAccessKeyId: response.data.awsKey,
        policy: response.data.policy,
        signature: response.data.signature,
        file,
      }), error => dispatch(uploadPhotoFailure(file.name, error.body)))
      .then((uploadedFiles) => {
        // const thumbBucket = (staging) ? 'staging.thumb.photoandgo.com' : 'focus-photo-thumbnail';
        const thumbBucket = 'focus-photo-thumbnail';
        if (uploadedFiles.hasOwnProperty('bucket')) {
          // now make thumbnails of each photo
          const data = [{
            source: {
              bucket: uploadedFiles.bucket,
              key: uploadedFiles.key,
            },
            destination: {
              bucket: thumbBucket,
              key: uploadedFiles.key,
            }
          }]
          axios.post(`${API_URL_2}/images/thumbnail`, data, { headers: { 'Authorization': token } })
            .then(thumbsResponse => thumbsResponse.data.thumbs, error => {
              thumbWorked = false
              const failedUrl = `https://${data[0].source.bucket}.s3.amazonaws.com/${data[0].source.key}`;
              console.log(' ••••• THUMBNAIL FAILED TO CREATE •••••', [failedUrl]);
              // dispatch(uploadPhotoFailure(false, error))
              return [failedUrl]
            })
            .then(urls => urls.map(url => (staging) ? url : url.replace('http:', 'https:')))
            .then(photoUrls => Promise.all(photoUrls.map((url) => getImageActualSize(uploadedFiles, url, file.name, thumbWorked))))
            .then(photos => {
              // _.set won't throw errors if obj is not an object. With more complex objects, if a portion of the path doesn't exist, _.set creates it
              _.set(photos, 'fileName', file.name);
              const needsRotation = (photos[0].thumbSize.width < photos[0].thumbSize.height && photos[0].actualSize.width > photos[0].actualSize.height)
              if (needsRotation) { // if (orientations === 5 || orientations === 6 || orientations === 7 || orientations === 8) { // if EXIF orientation = 5-8, swap w/h
                const w = photos[0].actualSize.width;
                const h = photos[0].actualSize.height;
                _.set(photos[0].actualSize, 'width', h);
                _.set(photos[0].actualSize, 'height', w);
              }
              photos[0].fileName = file.name
              // here -- use file.preview (blob url) to update
              //and replace with new thumb and url and size...
              dispatch(update_project_final(file.preview, photos[0]))
              return axios.patch(`${API_URL_2}/photos/${user_id}`, { photos: photos, blobUrl: file.preview }, { headers: { 'Authorization': token } })
                .then((response) => {
                  dispatch({
                    type: types.FETCH_PHOTOS,
                    payload: response.data
                  })
                  dispatch({
                    type: types.IS_NOT_FETCHING
                  })
                })
                .catch((error) => {
                  console.log(error)
                  dispatch({
                    type: types.IS_NOT_FETCHING
                  })
                })
            })
        } else {
          alert("Error uploading. Please try another photo or try later.");
          dispatch(closeModal('UPLOAD_MODAL'));
        }
      });
  };

}
//dropZone preview with blob
export function new_user_initial_upload(imagesData, source = 'upload', user_id = null, zoomed = false, zoomIndex, isMultiphoto = false) {
  //check the files to approve or reject
  const fileRestrictions = [
    // File should be less than 20 megabytes.
    {
      reason: 'exceeds max file size',
      validator: file => file.size / 1024 / 1024 > 20,
    },
    // File's type should be any of image/(png|gif|jpg|jpeg)
    {
      reason: 'is an invalid file type',
      validator: file => !file.type.match(/image\/(png|gif|jpg|jpeg)+$/i),
    },
  ];
  var rejectedFiles = [];
  var approvedFiles = [];
  var fileSizes = [];
  var photos = [];
  return (dispatch, getState) => {
    const { appConfig, token } = getState().config;
    const campaignId = (appConfig && appConfig.campaignId) ? appConfig.campaignId : null
    const salesChannelId = (appConfig && appConfig.id) ? appConfig.id : null
    imagesData.forEach(file => {
      // run validator on each file
      var newFileName = file.name.replace(/[^0-9a-zA-Z.]/g, '') // Ignore special Characters -- $%&*@#!+=\/[]{}’“;:<>,?
      const rejections = fileRestrictions
        .map(({ reason, validator }) => ({
          reason,
          reject: validator(file)
        }))
        .filter(({ reject }) => reject);
      // add each to rejected or approved list
      if (rejections.length === 0) {
        approvedFiles.push(file);
        fileSizes.push(file.size);
        getImageDimensions(file.preview)
          .then((res) => {
            photos.push({
              actualSize: {
                width: res.actualSize.width,
                height: res.actualSize.height
              },
              fileName: newFileName,
              fileSize: file.size || '',
              originalUrl: file.preview,
              thumbSize: {
                width: res.thumbSize.width,
                height: res.thumbSize.height
              },
              url: file.preview || ''
            })
            if (photos.length === approvedFiles.length) {
              if (user_id !== null) {
                (dispatch(update_project_initial(photos)))
              }
              const apiPoint = (user_id !== null)
                ? `${API_URL_2}/photos/${user_id}?source=${source}&campaignId=${campaignId}&salesChannelId=${salesChannelId}`
                : `${API_URL_2}/photos?source=${source}&campaignId=${campaignId}&salesChannelId=${salesChannelId}`
              return axios.post(apiPoint, {
                photos: photos
              }, {
                headers: { 'Authorization': token }
              })
                .then((response) => {
                  //now dispatch new API to update to upload to s3 n create thumbnails
                  //and then update blob url with with thumbUrl
                  //now close the modal and fetch user
                  if (user_id === null) {
                    approvedFiles.forEach(file => {
                      dispatch(new_user_final_upload(file, response.data.id))
                    })
                    dispatch({
                      type: types.FETCH_USER,
                      payload: response.data
                    })
                    dispatch({
                      type: types.FETCH_PHOTOS,
                      payload: response.data.photos
                    })
                    dispatch({
                      type: types.FETCH_PROJECT,
                      payload: response.data.project
                    })
                    dispatch({
                      type: types.FETCH_CART,
                      payload: response.data.carts[0]
                    })
                  }
                  else {
                    approvedFiles.forEach(file => {
                      dispatch(new_user_final_upload(file, user_id))
                    })
                    dispatch({
                      type: types.FETCH_PHOTOS,
                      payload: response.data
                    })
                  }
                })
                .catch((error) => {
                  console.log(error)
                  dispatch({
                    type: types.IS_NOT_FETCHING
                  })
                })
            }
          })
      } else {
        file.rejections = [...rejections]; // eslint-disable-line
        rejectedFiles.push(file);
      }
    })
  }
}

export function new_user_order(user) {
  return (dispatch, getState) => {
    const { appConfig, token } = getState().config;
    return axios.post(`${API_URL_2}/orders`, {
      salesChannelId: appConfig.id,
      userId: user.id,
      name: user.name || undefined,
      lastName: user.lastName || undefined,
      email: user.email || undefined,
      phone: user.phone || undefined,
      token
    }, {
      headers: { 'Authorization': token }
    })
      .then((response) => {
        dispatch({
          type: types.FETCH_USER,
          payload: response.data
        })
        dispatch({
          type: types.FETCH_PHOTOS,
          payload: response.data.photos
        })
        dispatch({
          type: types.FETCH_PROJECT,
          payload: response.data.project
        })
        dispatch({
          type: types.FETCH_CART,
          payload: response.data.carts[0]
        })
        dispatch(closeModal('CART_MODAL'))
        history.push('/checkout')
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
      .catch((error) => {
        console.log(error)
        // alert("There was an error. Please contact customer service.")
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
  }
}

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export function update_filtered_url(cart, project, itemsArray, product) {
  return (dispatch, getState) => {
    const { user } = getState().user;
    const userId = (user && user.id) ? user.id : 'new';
    const preview_cart_item = itemsArray.find((item) => item.id === project.itemId)
    const bucket = 'focus-thumbnail'
    const baseUrl = `https://${bucket}.s3.amazonaws.com/`
    const previewUrl = `${baseUrl}${userId}/${Math.floor(Math.random() * 1001) + Date.now()}.jpg`
    const waitForFilteredUrl = (new_orginal_url, usedFilter, handle, transformation) => new Promise((resolve, reject) => {
      if (usedFilter === 'monochrome' || usedFilter === 'sepia' || usedFilter === 'enhance') { // if filter exists, update with filestack
        return axios.post(`https://cdn.filestackcontent.com/${usedFilter}/${handle}`)
          .then(response => {
            resolve(response.data.url)
          }).catch(e => {
            reject(new_orginal_url)
          })
      } else {
        resolve(new_orginal_url)
      }
    })
    var new_photopersonalization = []
    const createPhotoPersonalization = async () => {
      await asyncForEach(project.photopersonalization, async (p, i) => {
        console.log("update_filtered_url", p)
        const splitImage = p && p.hasOwnProperty('thumbUrl') ? p && p.thumbUrl.split("/") : p && p.url && p.url.split("/")
        var new_orginal_url = p && p.originalUrl
        const handle = splitImage && splitImage[splitImage.length - 1]
        const usedFilter = splitImage && splitImage[splitImage.length - 2]
        let transformation = 'rotate=deg:exif/resize=width:600,height:600'
        if (p.thumbSize.width > p.thumbSize.height * 2) {
          transformation = 'rotate=deg:exif/resize=height:600'
        } else if (p.thumbSize.height > p.thumbSize.width * 2) {
          transformation = 'rotate=deg:exif/resize=width:600'
        }
        const new_url = (usedFilter === 'monochrome' || usedFilter === 'sepia' || usedFilter === 'enhance')
          ? `https://cdn.filestackcontent.com/${usedFilter}/${handle}`
          : p.url
        await waitForFilteredUrl(new_orginal_url, usedFilter, handle, transformation).then((originalUrl) => {
          new_photopersonalization.push({
            bleed: project.bleed || undefined,
            edits: {
              brightness: p.brightness || 1,
              imageX: p.hasOwnProperty('imageX') ? p.imageX : undefined,
              imageY: p.hasOwnProperty('imageY') ? p.imageY : undefined,
              imageHeight: p.hasOwnProperty('imageHeight') ? p.imageHeight : undefined,
              imageWidth: p.hasOwnProperty('imageWidth') ? p.imageWidth : undefined,
              rotation: p.rotation || 0,
            },
            personalized: project.allowsPersonalization,
            images: [{
              actualSize: {
                width: p && p.actualSize && p.actualSize.width ? p.actualSize.width : undefined,
                height: p && p.actualSize && p.actualSize.height ? p.actualSize.height : undefined
              },
              thumbSize: {
                width: p && p.thumbSize && p.thumbSize.width ? p.thumbSize.width : undefined,
                height: p && p.thumbSize && p.thumbSize.height ? p.thumbSize.height : undefined
              },
              url: new_url,
              originalUrl: originalUrl,
            }],
            previewObj: {
              portrait: preview_cart_item.preview.portrait[i],
              landscape: preview_cart_item.preview.landscape[i],
              numPhotos: preview_cart_item.preview.numPhotos,
              productImg: preview_cart_item.img ? preview_cart_item.img : product.img
            },
            previewUrl,
            overlay: p && p.hasOwnProperty('overlay') ? p.overlay : null,
            previewCreated: false,
          })
        })
      });
      // console.log('Done', new_photopersonalization);
      dispatch(new_cart_item(cart, project, new_photopersonalization, itemsArray, product))
      dispatch(is_fetching())
    }
    createPhotoPersonalization()
  }
}
export function new_cart_item(cart, project, new_project, itemsArray, product) {
  return (dispatch, getState) => {
    const { token, appConfig } = getState().config;
    const { user } = getState().user;
    const salesChannelId = appConfig.id
    const userId = (user && user.id) ? user.id : 'new';
    const cartId = (cart && cart.id) ? cart.id : 'new';
    var projectjson = JSON.parse(JSON.stringify(project));
    const preview_cart_item = itemsArray.find((item) => item.id === projectjson.itemId)
    const new_index = (cart && cart.items && cart.items.length && cart.items[0] && cart.items[cart.items.length - 1].line_item_id)
      ? cart.items[cart.items.length - 1].line_item_id + 1
      : 1
    var new_cart_item = {
      cart_id: cartId,
      user_id: userId,
      line_item_id: new_index,
      total: projectjson.amounts.total || undefined,
      unit_price: projectjson.amounts.unitPrice || undefined,
      item_id: projectjson.itemId || undefined,
      personalized: projectjson.allowsPersonalization || undefined,
      name: projectjson.name || undefined,
      sku: projectjson.sku.toString() || undefined,
      qty: projectjson.qty || 1,
      photopersonalization: new_project.slice(0, preview_cart_item.preview.numPhotos)
    }
    const API_URL = (userId === 'new' || cartId === 'new') ? `${API_URL_2}/carts?salesChannelId=${salesChannelId}` : `${API_URL_2}/carts/${cartId}?salesChannelId=${salesChannelId}`
    return axios.post(API_URL, new_cart_item, {
      headers: { 'Authorization': token }
    })
      .then((response) => {
        // console.log(response.data.carts[0].items[0].photopersonalization[0].previewUrl)
        if (userId === 'new' || cartId === 'new') { //new users
          dispatch({
            type: types.FETCH_USER,
            payload: response.data
          })
          dispatch({
            type: types.FETCH_PHOTOS,
            payload: response.data.photos
          })
          dispatch({
            type: types.FETCH_PROJECT,
            payload: response.data.project
          })
          dispatch({
            type: types.FETCH_CART,
            payload: response.data.carts[0]
          })
        } else { //existing users with carts
          dispatch({
            type: types.FETCH_CART,
            payload: response.data.carts[0]
          })
        }
        dispatch({
          type: types.ITEM_REMOVED,
          payload: true
        })
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
  }
}

export function new_upsell_item(upsellItem, qtyValue, userId, orderId, upsellDiscount) {
  return (dispatch, getState) => {
    const { token, appConfig } = getState().config;
    const { cart } = getState().cart;
    const salesChannelId = appConfig.id
    const upsellItemPrice = upsellItem.msrp * upsellDiscount
    const new_index = (cart && cart.items && cart.items.length && cart.items[0]
      && cart.items[cart.items.length - 1].line_item_id)
      && cart.items[cart.items.length - 1].line_item_id + 1
    const new_photopersonalization = [{
      bleed: undefined,
      edits: {
        brightness: 1,
        imageX: undefined,
        imageY: undefined,
        imageHeight: undefined,
        imageWidth: undefined,
        rotation: 0,
      },
      personalized: false,
      images: [{
        actualSize: {
          width: undefined,
          height: undefined
        },
        thumbSize: {
          width: undefined,
          height: undefined
        },
        url: '',
        originalUrl: '',
      }],
      previewObj: {
        portrait: upsellItem.preview.portrait[0],
        landscape: upsellItem.preview.landscape[0],
        numPhotos: upsellItem.preview.numPhotos,
        productImg: upsellItem.img
      },
      previewUrl: upsellItem.img,
      previewCreated: false,
    }]
    var new_cart_item = {
      user_id: userId,
      order_id: orderId,
      cart_id: cart.id,
      line_item_id: new_index,
      total: (upsellItemPrice * qtyValue) || undefined,
      unit_price: upsellItemPrice || undefined,
      item_id: upsellItem.id || undefined,
      personalized: false,
      name: upsellItem.name || undefined,
      sku: upsellItem.sku.toString() || undefined,
      qty: qtyValue || 1,
      photopersonalization: new_photopersonalization
    }
    return axios.patch(`${API_URL_2}/upsell/${userId}?salesChannelId=${salesChannelId}`, new_cart_item, {
      headers: { 'Authorization': token }
    })
      .then((response) => {
        dispatch({
          type: types.FETCH_USER,
          payload: response.data
        })
        dispatch({
          type: types.FETCH_PHOTOS,
          payload: response.data.photos
        })
        dispatch({
          type: types.FETCH_PROJECT,
          payload: response.data.project
        })
        dispatch({
          type: types.FETCH_CART,
          payload: response.data.carts[0]
        })
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
  }
}
export function disableUpsellModal() {
  return (dispatch) => {
    dispatch({
      type: types.SHOW_UPSELL_MODAL,
      payload: false
    })
  }
}
export function fetchUpsellItem(upsell_sku) {
  return (dispatch, getState) => {
    const { token } = getState().config;
    return axios.get(`${API_URL_2}/items?page=1&search=${upsell_sku}`, {
      headers: { 'Authorization': token }
    })
      .then((response) => {
        dispatch({
          type: types.FETCH_UPSELL_ITEM,
          payload: response.data.results[0]
        })
        return response.data.results[0]
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
  }
}







/****************************************************************************
 *
 *       DELETING ACTIONS
 *
 ****************************************************************************/

export function delete_user_photo(photo_id, userId) {
  return (dispatch, getState) => {
    const { token } = getState().config;
    return axios.delete(`${API_URL_2}/photos/${userId}?id=${photo_id}`, {
      headers: {
        'Authorization': token
      }
    })
      .then((response) => {
        dispatch({
          type: types.FETCH_PHOTOS,
          payload: response.data
        })
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
  }
}

export function removeDiscount(userId, code) {
  return (dispatch, getState) => {
    const { token } = getState().config
    const { cart } = getState()
    if (!userId) {
      const { user } = getState().user;
      userId = user.id
    }
    return axios.delete(`${API_URL_2}/promotions/valid/REMOVE?userId=${userId}`, {
      headers: {
        'Authorization': token
      }
    })
      .then((response) => {
        dispatch(getTaxRate(userId))
        dispatch({
          type: types.REMOVE_PROMOTION,
          cart
        })
        dispatch({
          type: types.SAVE_PROMO_CODE,
          payload: code
        })
        dispatch({
          type: types.FETCH_USER,
          payload: response.data
        })
        dispatch({
          type: types.FETCH_PHOTOS,
          payload: response.data.photos
        })
        dispatch({
          type: types.FETCH_PROJECT,
          payload: response.data.project
        })
        dispatch({
          type: types.FETCH_CART,
          payload: response.data.carts[0]
        })
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
  }
}



/****************************************************************************
 *
 *       Other actions
 *
 ****************************************************************************/


// NOTE App config load
export function appConfig(widgetToken = null) {
  return (dispatch, getState) => {
    const { token } = getState().config;
    let widgetProps = {
      config: {
        widgetMode: false,
      },
      layout: {
        showNavbar: true,
        showFooter: true,
        showHero: true,
      },
    };
    if (widgetToken) {
      widgetProps = {
        config: {
          widgetMode: true,
        },
        layout: {
          showNavbar: false,
          showFooter: false,
          showHero: false,
        },
      };
    }
    return axios.get(`${API_URL_2}/appdata`, {
      headers: {
        'Authorization': token
      }
    })
      // Provide a way to either ask for apiAuthToken or use provided apiKey plus current URL to see if they
      // match and data can be provided.
      //.then(response => response.body)
      .then((data) => ({
        ...data,
        config: {
          ...data.config,
          ...widgetProps.config,
          availableFonts: ['Marydale', 'Arial', 'Helvetica', 'Georgia', 'Verdana'],
        },
        layout: {
          ...data.layout,
          ...widgetProps.layout,
        },
        promo: {
          ...data.promo,
        }
      }))
      .then(data => {
        dispatch(setAppData(data.data))
      })
  }
}

export function setAppData({ products, items, config, categories, layout, seoData, promo, shipping, countdownParams, overlays }) {
  return {
    type: types.SET_APP_DATA,
    products,
    items,
    config,
    categories,
    layout,
    shipping,
    overlays,
    countdownParams,
    //TODO: update logic when api sends real data.
    promo: promo && promo.imageUrl ? promo : {
      imageUrl: '/promo-image.png',
      url: '',
      bgColor: '#34c7e0',
    },
    seoData: processSeoData(seoData),
  };
}

function processSeoData(seoData = {}) {
  const { seoName, seoDescription, seoTitle } = seoData;

  if (!seoName) {
    return {
      seoName: null,
      seoMetaData: [{
        type: 'title',
        content: null,
      }],
    };
  }

  return {
    seoName,
    seoMetaData: [{
      type: 'meta',
      content: seoDescription,
      name: 'description',
    },
    {
      type: 'title',
      content: seoTitle,
    }],
  };
}

export function getInstagramPhotos(authToken, nextUrl,) {
  if (nextUrl) {
    return (dispatch, getState) => {
      const current = getState().photos;
      const url = `${nextUrl}`;
      axios.get(url)
        .then(res => {
          const result = {
            data: current.instagram.data.concat(res.data.data),
            pagination: res.data.data.pagination,
          };
          dispatch(fetchInstagramImages(result));
        })
        .catch(error => {
          console.log("error message:", error)
          const myError = error.body.errorMessage || 'Sorry! Something went wrong.';
          console.error(myError); // eslint-disable-line
        });
    };
  }
  return (dispatch) => {
    const url = `https://api.instagram.com/v1/users/self/media/recent/?access_token=${authToken}&count=25`;
    axios.get(url)
      .then(res => {
        dispatch(fetchInstagramImages(res.data));
      })
      .catch(error => {
        const myError = error.errorMessage || 'Sorry! Something went wrong.';
        console.error(myError); // eslint-disable-line
      });
  };
}

export function fetchInstagramImages(photosInstagram) {
  return {
    type: types.INSTAGRAM_FETCH_IMAGES,
    photosInstagram,
  };
}

function uploadPhotoFailure(uploaded, file, device) {
  const { rejections, name } = file;
  const errorMessageComplement = !rejections ? 'couldn\'t be uploaded'
    : rejections.map(({ reason }) => reason).join(' and ');

  return (dispatch) => {
    if (device) {
      dispatch(Notifications.error({
        title: 'File Fetching Error',
        message: 'Files not found',
        position: 'tr',
      }));
    } else {
      dispatch(Notifications.error({
        title: 'File Upload Error',
        message: `File '${name}' ${errorMessageComplement}`,
        position: 'tr',
      }));
    }
    dispatch({
      type: types.USERPHOTO_UPLOAD_ERROR,
      uploaded,
      fileName: name,
      loading: false,
    });
  };
}

// NOTE Projects
export function setSelectedPhoto(photo) {
  return {
    type: types.SELECTED_PHOTO,
    photo,
  };
}

export function clearPhotoServicesSelections() {
  return {
    type: types.CLEAR_PHOTO_SERVICES_SELECTIONS,
  };
}

export function addPhotoToList(item) {
  return {
    type: types.ADD_PHOTOS_TO_LIST,
    item,
  };
}

export function removePhotoFromList(itemId) {
  return {
    type: types.REMOVE_PHOTO_FROM_LIST,
    itemId,
  };
}

// CHECKOUT
export function makePayment(stripeToken, userId, orderId, paymentType) {
  console.log('Sending stripe token to API for transaction', stripeToken)
  return (dispatch, getState) => {
    const { token, appConfig } = getState().config;
    return axios.post(`${API_URL_2}/checkout`, {
      token: stripeToken,
      userId,
      orderId,
      salesChannelId: appConfig.id,
      salesChannelName: appConfig.name,
      paymentType: paymentType
    }, {
      headers: {
        'Authorization': token
      }
    })
      .then(response => {
        switch (response.status) {
          case 206:
            dispatch({
              type: types.ORDER_FAILURE,
              payload: response.data
            })
            dispatch({
              type: types.IS_NOT_FETCHING
            })
            console.log("payment FAILURE")
            return response.data;
          case 200:
          default:
            console.log("approved")
            // dispatch({
            //   type: types.IS_NOT_FETCHING
            // })
            // if(paymentType !== 'apple-pay' || paymentType !== 'basic-card' ) {
            dispatch(closeModal('ADDRESS_CONFIRMATION'))
            dispatch(is_fetching())
            dispatch(update_order_pending(orderId))
            // }
            return "Approved";
        }
      })
      .catch((err) => {
        dispatch(paymentStatus(false, true))
        dispatch({
          type: types.IS_NOT_FETCHING
        })
        console.log(err)
      });
  };
}

export function paymentStatus(approved = null, error = false) {
  return (dispatch) => {
    dispatch({
      type: types.IS_NOT_FETCHING
    })
    return {
      type: approved ? types.ORDER_PLACE_SUCCESS : types.ORDER_PLACE_ERROR,
      approved,
      error,
    }
  };
}
export function clearOrder() {
  return (dispatch) => {
    dispatch({
      type: types.REMOVE_PROMOTION
    })
    dispatch({
      type: types.RESET_ORDER,
    })
  }
}
export function clearGiftCardError() {
  return (dispatch) => {
    dispatch({
      type: types.CLEAR_GIFTCARD_ERROR,
    });
  };
}
export function clearGiftCardBalance() {
  return (dispatch) => {
    dispatch({
      type: types.CLEAR_GIFTCARD_BALANCE,
    })
  };
}
export function setGiftCardError(error) {
  return (dispatch) => {
    dispatch({
      type: types.GET_GIFTCARD_BALANCE_ERROR,
      error,
    });
  };
}

export function createTransaction(nonce, userId, orderId, paymentType) {
  console.log('createTransaction', nonce)
  return (dispatch, getState) => {
    const { token, appConfig } = getState().config;
    return axios.post(`${API_URL_2}/orders/checkout`, {
      nonce: nonce,
      userId,
      orderId,
      salesChannelId: appConfig.id,
      salesChannelName: appConfig.name,
      paymentType
    }, {
      headers: {
        'Authorization': token
      }
    })
      .then(response => {
        console.log('RESPONSE FROM creating transaction', response)
        switch (response.status) {
          case 206:
            dispatch({
              type: types.ORDER_FAILURE,
              payload: response.data
            })
            dispatch({
              type: types.IS_NOT_FETCHING
            })
            console.log("payment FAILURE")
            return response.data;
          case 200:
          default:
            console.log("approved")
            dispatch(closeModal('ADDRESS_CONFIRMATION'))
            dispatch(is_fetching())
            dispatch(update_order_pending(orderId))
            return "Approved";
        }
      })
      .catch((err) => {
        console.log('error FROM creating transaction', err)
        dispatch(paymentStatus(false, true))
        dispatch({
          type: types.IS_NOT_FETCHING
        })
        console.log(err)
      });
  };
}

export function createBraintreeToken() {
  return (dispatch, getState) => {
    const { token } = getState().config;
    return axios.get(`${API_URL_2}/orders/checkout`, {
    }, {
      headers: {
        'Authorization': token
      }
    })
      .then(res => {
        console.log('RESPONSE FROM creating token', res.data.clientToken)
        dispatch({
          type: types.IS_NOT_FETCHING
        })
        return { clientToken: res.data.clientToken }
      })
      .catch(error => {
        console.log('error FROM creating token', error)
      });
  };
}
export function saveBrainTreeInstance(clientInstance, hostedFieldsInstance) {
  return (dispatch, getState) => {
    dispatch({
      type: types.SAVE_BRAINTREE_INSTANCE,
      payload: {
        clientInstance,
        hostedFieldsInstance
      }
    })
  };
}

// NOTE Products
export function fetchProducts() {
  return (dispatch, getState) => {
    const { token } = getState().config;

    axios.get(`${API_URL_2}/products`, {
      headers: {
        'Authorization': token
      }
    })
      .then(res => {
        dispatch(fetchProductsSuccess(res.body.products));
      })
      .catch(error => {
        dispatch({
          type: types.PRODUCTS_FETCH_ERROR,
          error: error.body,
        });
      });
  };
}

export function fetchProductsSuccess(products, items) {
  return {
    type: types.PRODUCTS_FETCH_SUCCESS,
    products,
    items,
  };
}

export function fetchCategories() {
  return (dispatch, getState) => {
    const { token } = getState().config;

    axios.get(`${API_URL_2}/categories`, {
      headers: {
        'Authorization': token
      }
    })
      .then(res => {
        dispatch(fetchCategoriesSuccess(res.body));
      })
      .catch(error => {
        dispatch({
          type: types.CATEGORIES_FETCH_ERROR,
          error: error.body,
        });
      });
  };
}

export function fetchCategoriesSuccess(categories) {
  return {
    type: types.CATEGORIES_FETCH_SUCCESS,
    categories,
  };
}

export function setProduct(productId) {
  return {
    type: types.PRODUCT_FETCH_SUCCESS,
    productId,
  };
}

export function openModal(modal) {
  return {
    type: 'OPEN_MODAL',
    modalName: modal
  }
}

export function closeModal(modal) {
  return {
    type: 'CLOSE_MODAL',
    modalName: modal
  }
}

export const corporateGiftCards = (data) => {
  const LAMBDA_URL = 'https://5thx563eil.execute-api.us-east-1.amazonaws.com/prod/add-lead'
  return (dispatch, getState) => {
    return axios.post(`${LAMBDA_URL}`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        if (res.status === 200) {
          dispatch({
            type: types.CORPORATE_DATA_SUCCESS,
          })
        }
      })
      .catch(error => {
        dispatch({
          type: types.CORPORATE_DATA_ERROR,
          err: error
        })
      });
  };
}
export function smartyStreetsApi(data, index) {
  const street = encodeURIComponent(data.address1)
  const street2 = encodeURIComponent(data.address2)
  const city = encodeURIComponent(data.city)
  const state = encodeURIComponent(data.state)
  const zip = encodeURIComponent(data.zip)
  const API_URL = 'https://us-street.api.smartystreets.com/street-address?auth-id=30203536464370717&auth-token=CgIyfxCU0ayccVIxCmTC';

  return (dispatch) => {
    return axios.get(`${API_URL}&street=${street}&street2=${street2}&city=${city}&state=${state}&zipcode=${zip}`, {
      headers: {
        'Content-Type': 'application/json',
        'Host': 'us-street.api.smartystreets.com',
      }
    })
      .then((response) => {
        if (response.data.length) {
          const smartObj = {
            dpvCode: response.data[0].analysis.dpv_match_code,
            city: response.data[0].components.city_name,
            stateAbbreviated: response.data[0].components.state_abbreviation,
            zip: response.data[0].components.zipcode,
            zipLastFour: response.data[0].components.plus4_code,
            deliveryLine1: response.data[0].delivery_line_1,
            deliveryLine2: response.data[0].delivery_line_2 ? response.data[0].delivery_line_2 : '',
            lastLine: response.data[0].last_line,
            zipType: response.data[0].metadata.zip_type,
            recordType: response.data[0].metadata.record_type,
            dpvFootNotes: response.data[0].analysis.dpv_footnotes,
          }
          dispatch({
            type: types.SMARTYSTREETS_VALIDATION_SUCCESS,
            payload: smartObj
          })
          return index.includes('stripe') ? true : smartObj
        } else {
          return false
        }
      })
      .catch(error => {
        dispatch({
          type: types.SMARTYSTREETS_VALIDATION_FAILURE,
        })
        dispatch({
          type: types.IS_NOT_FETCHING,
        })
        return false
      });
  };
}

export function update_shipping_final(order_id, index, value, reason_code) {
  return (dispatch, getState) => {
    const { token } = getState().config;
    return axios.patch(`${API_URL_2}/order/${order_id}`, { payload: { index, value } }, {
      headers: { 'Authorization': token }
    })
      .then((response) => {
        if (reason_code !== null) {
          dispatch(reasonCode(order_id, reason_code))
        }
        dispatch({
          type: types.USER_CONFIRMATION,
          payload: response
        })
        dispatch({
          type: types.IS_NOT_FETCHING
        })
        // dispatch(update_order_pending(order_id))
        return 'addressUpdated'
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
  }
}
export function reasonCode(order_id, reason_code) {
  return (dispatch, getState) => {
    const { token } = getState().config;
    return axios.patch(`${API_URL_2}/orders/${order_id}`, { reason_code }, {
      headers: { 'Authorization': token }
    })
      .then((response) => {
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: types.IS_NOT_FETCHING
        })
      })
  }
}
export function countdownCompleted() {
  return (dispatch) => {
    dispatch({ type: types.COUNTDOWN_COMPLETED })
  }
}
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../assets/stylesheets/united-way.scss'

const Content = {
  hero:{
    title: "WELCOME TO MYPHOTO",
    image: "/images/united-way/HeroUnited.jpg"
  },
  banner:{
    subtitle1: "Today, we are sharing smiles and gifting you",
    subtitle2: "Shipping is free too! ",
    image: "/images/united-way/MiniBlox_united.png"
  },
  promo:{
    subtitle1: "All we ask of you is to…",
    title2: "assist in the Covid-19 pandemic.",
    subtitle2: "Use promo code:", 
    promo: "UnitedWay",
    subtitle3: "Limit 1 per household. Offer cannot be combined with other promotions. While supplies last. ",
    image: "/images/united-way/UnitedWay_Logo.svg",
    link: "/products/acrylic/table-top/acrylic-miniblox"
  }    
}

class UnitedWay extends Component {
  render(){
    return(
      <div className="shell-container">
        <div className='unitedway-container'>
          <div className='unitedway-hero' style={{backgroundImage: `url(${Content.hero.image})`, height: '256px'}}>
            <div className='unitedway-hero-cont'>
              <h1 className="unitedway-hero-title">{Content.hero.title}</h1>
              <p className="unitedway-hero-sibtitle">The Crazy Easy. Crazy Fast. way to make <br/>your memories into awesome products.</p>
            </div>
          </div>
          <div className='unitedway-banner'>
            <div className='unitedway-banner-cont'>
              <p className="unitedway-banner-sibtitle1">{Content.banner.subtitle1}</p>
              <h1 className="unitedway-banner-title">1 <span className="info-color">Free</span> Acrylic <br/>Miniblox</h1>
              <p className="unitedway-banner-sibtitle2">{Content.banner.subtitle2}</p>
            </div>
            <div className='unitedway-banner-image'>
              <img src={Content.banner.image} alt="hand holding a miniblox" />
            </div>
          </div>
          <div className='unitedway-content'>
            <div className='unitedway-content-cont'>
              <p className="unitedway-promo-sibtitle1">{Content.promo.subtitle1} <img src={Content.promo.image} alt="united way logo" className="unitedway-promo-image image-mobile" /></p>
              <div className="unitedway-promo-title-cont">
                <span className="promo-text-cont">
                  <h1 className="unitedway-promo-title">Donate <span className="info-color">$1</span> to <span className="info-color">UNITED WAY</span> <span className="bold">to</span></h1>
                  <h1 className="unitedway-promo-title bold">{Content.promo.title2}</h1>
                </span>
                <img src={Content.promo.image} alt="united way logo" className="unitedway-promo-image image-desktop" />
              </div>
              <p className="unitedway-promo-sibtitle2">{Content.promo.subtitle2}<span className="unitedway-promo info-color semi-bold"> {Content.promo.promo}</span></p>
              <Link to={Content.promo.link}><div className="unitedway-promo-button">Get Yours <span className="underlined">Today</span></div></Link>
              <p className="unitedway-promo-sibtitle3">{Content.promo.subtitle3}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

}

export default UnitedWay
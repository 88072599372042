export default function getImageActualSize(imageSource, url, fileName, thumbWorked) {
  const actualImage = new Image()
  const thumbImage = new Image()
  let originalUrl
  let thumbUrl
  if (typeof imageSource === 'string') {
    actualImage.src = imageSource
    thumbImage.src = imageSource
  } else {
    const { bucket, key } = imageSource;
    // const thumbhost = (window.location.href.indexOf("staging.") > -1 || window.location.href.indexOf("photoandgo.local") > -1) ? 'staging.thumb.photoandgo.com.s3.amazonaws.com/' : 'focus-photo-thumbnail.s3.amazonaws.com/';
    originalUrl = `${bucket}.s3.amazonaws.com/${key}`
    thumbUrl = (thumbWorked) ? `focus-photo-thumbnail.s3.amazonaws.com/${key}` : originalUrl
    thumbImage.src = `//${thumbUrl}`
    actualImage.src = `//${originalUrl}`
  }

  const actualImageResolver = new Promise((resolve) => {
    actualImage.onload = () => {
      const {
        naturalWidth: width,
        naturalHeight: height
      } = actualImage;
      resolve({
        width, height,
      });
    };
  });

  const thumbImageResolver = new Promise((resolve) => {
    thumbImage.onload = () => {
      const {
        naturalWidth: width,
        naturalHeight: height
      } = thumbImage;
      resolve({
        width, height,
      });
    };
  });

  return Promise
    .all([actualImageResolver, thumbImageResolver])
    .then(([actualImageResult, thumbImageResult]) => {
      const imageUrl = typeof imageSource === 'string' ? imageSource : url;
      return {
        actualSize: actualImageResult,
        thumbSize: thumbImageResult,
        url: imageUrl,
        originalUrl: `https://${originalUrl || imageUrl}`,
        fileName: fileName || null,
      };
    });
}

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'
import { Spin, Icon } from 'antd';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import './slider.scss'

const propTypes = {
    progressBar: PropTypes.array
}

const antIcon = <Icon type="loading" style={{ fontSize: 24, top: '20px', color: 'white' }} spin />;


class SliderProgress extends React.Component {
    constructor(props) {
        super(props)
        autoBind(this)
    }
    render() {
        const { displayImage } = this.props
        // console.log(' ===> displayImage ===> ', displayImage)
        // var displayPercent = 0.01
        // if (displayImage && progressBar && progressBar.length > 0) { // in case Filestack is down, leave displayPercent = 0 (above)
        //     // console.log('cannot match file or not hash...')
        //     const this_progress = progressBar.find(p => p.id === displayImage)
        //     displayPercent = (this_progress) ? this_progress.percent * .8 : 0.01
        // }
        return (
            <Fragment>
                <Spin indicator={antIcon}>
                <img className="slider__hidden__image" src={displayImage} alt="Your Upload" />
                </Spin>
                {/* <div style={{ position: 'absolute', left: 0, bottom: 0, height: '5px', background: '#494949', zIndex: 0, width: '80px', display: 'inline-block' }}></div>
                <div style={{ position: 'absolute', left: 0, bottom: 0, height: '5px', background: '#6fa8ee', zIndex: 1, width: `${displayPercent}px`, display: 'inline-block' }}></div> */}
            </Fragment>
        )
    }
}

SliderProgress.propTypes = propTypes

function mapStateToProps(state) {
    return {
        progressBar: state.photos.progressBar
    }
}

export default withRouter(connect(mapStateToProps)(SliderProgress))
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import autoBind from 'react-autobind'
import { connect } from 'react-redux'
import { isMobile } from "react-device-detect"
import { updateZoom } from '../../actions'
import ThumbnailChild from './ThumbChild'
import ViewVideoLink from './ViewVideoLink'
import Slider from 'react-slick'
import { path } from 'ramda'
import addDefaultSrc from '../../helpers/addDefaultSrc'
import '../../assets/stylesheets/editor.scss'

const GeneratedThumbnail = ({ alt, item, project, useProject, photoUrl, thumbSize, edits,
  isMultiphoto, zoomed, zoomIndex, this_overlay, photopersonalization, hasFilter, dispatch,
  lowResIndexArr, inlineEditor, crop, zoom, inlineEditorToggle, resetValues, saveEditedValues, overlayIsDisabled, selectedOverlay }) => (
    <span className='thumbnail-generator'>
      <div style={style.parentRow}>
        <div className='print-parent'>
          {inlineEditor && <div className='editor-info'><div>Edit mode:</div> <span className='is-hidden-tablet'>&nbsp;Pinch and drag</span><span className='is-hidden-mobile'>&nbsp;Click and drag and use slider below</span>.</div>}
          <div style={{ width: '100%', height: '100%' }}>
            {(!zoomed) ? item.preview.portrait.map((p, i) => {
              const photoUrl = (useProject !== false)
                ? path(['photopersonalization', i, 'thumbUrl'], project.project) ? path(['photopersonalization', i, 'thumbUrl'], project.project) : undefined
                : path([i, 'images', '0', 'url'], photopersonalization)
              return (
                <ThumbnailChild
                  item={item}
                  project={project.project}
                  useProject={useProject}
                  photopersonalization={photopersonalization}
                  inlineEditorToggle={inlineEditorToggle}
                  resetValues={resetValues}
                  saveEditedValues={saveEditedValues}
                  // user={user}
                  key={i}
                  i={i}
                  photoUrl={photoUrl}
                  thumbSize={thumbSize}
                  edits={edits}
                  zoomed={zoomed}
                  isMultiphoto={isMultiphoto}
                  lowResIndexArr={lowResIndexArr}
                  crop={crop}
                  zoom={zoom}
                  zoomIndex={zoomIndex}
                  hasFilter={hasFilter}
                  overlayIsDisabled={overlayIsDisabled}
                  selectedOverlay={selectedOverlay}
                />
              )
            }) :
              <ThumbnailChild
                item={item}
                project={project.project}
                useProject={useProject}
                photopersonalization={photopersonalization}
                // user={user}
                i={zoomIndex}
                photoUrl={photoUrl}
                thumbSize={thumbSize}
                edits={edits}
                zoomed={zoomed}
                isMultiphoto={isMultiphoto}
                lowResIndexArr={lowResIndexArr}
                crop={crop}
                zoom={zoom}
                zoomIndex={zoomIndex}
                hasFilter={hasFilter}
                overlayIsDisabled={overlayIsDisabled}
                selectedOverlay={selectedOverlay}
              />
            }
            <img itemProp="image" style={style.printPreview}
              className='frame frame-2'
              src={this_overlay || 'https://s3.amazonaws.com/focus-misc-assets/image_not_available_829x455.jpg'} alt={alt ? alt : 'Our product overlay'}
              onError={e => { e.target.onerror = null; e.target.src = 'https://s3.amazonaws.com/focus-misc-assets/image_not_available_829x455.jpg' }}
            />
          </div>
          {/* {!inlineEditor && !isMultiphoto && <div
            className='edit-crop-button'
            onClick={() => {
              inlineEditorToggle()
              dispatch(update_has_filter(false))
            }}
            >
            <img className='edit-icon' alt="next slide" onError={addDefaultSrc} src='https://storage.googleapis.com/myphoto-img/icons/EditIcon.svg'/>
            <div>Edit/Crop</div></div>} */}
        </div>
      </div>
    </span> 
  )

const propTypes = {
  user: PropTypes.object,
  isMultiphoto: PropTypes.bool,
}
const defaultProps = {
  corsPolicy: 'anonymous',
}

//Thumbnail Styling
const style = {
  printPreview: {
    verticalAlign: 'middle',
    border: 0,
    position: 'relative',
    zIndex: 99,
    // opacity: 0.64
  },
  parentRow: {
    position: 'relative',
    overflow: 'hidden',
    marginBottom: '40px',
  },
}

function NextArrow(props) {
  const { onClick } = props
  return (
    <img onClick={onClick} className='slick-next' alt="next slide" onError={addDefaultSrc} src='https://storage.googleapis.com/myphoto-img/icons/carousel-arrow-next.png' />
  )
}

function PrevArrow(props) {
  const { onClick } = props
  return (
    <img onClick={onClick} className='slick-prev' alt="previous slide" onError={addDefaultSrc} src='https://storage.googleapis.com/myphoto-img/icons/carousel-arrow-prev.png' />
  )
}

export class ThumbnailGenerator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      isLoading: true,
    };
    autoBind(this);
  }
  componentWillReceiveProps(nextProps) {
    const itemUpdated = nextProps.item !== this.props.item
    const projectUpdated = path(['project'], nextProps) !== path(['project'], this.props)
    if (itemUpdated || projectUpdated) {
      if (this.slider) this.slider.slickGoTo(0)
    }
  }
  render() {
    const { productMoreViews, item, isMultiphoto, dispatch, photopersonalization, overlays,
      lowResIndexArr, productMiniCard, project, product, hasFilter, overlayIsDisabledCart } = this.props
    const urls = productMoreViews && productMoreViews.map(({ url }) => { return url })
    urls && urls.length && urls.unshift(item.img || product.img)
    const sliderSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      lazyLoad: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      dotsClass: 'slick-dots slick-dot-preview slick-thumb '+(product&&product.productVideoUrl != null? 'video-offset' : ''),
      customPaging: i => (
        isMobile ? <div className='carousel-preview-line'></div> : <img className='carousel-preview-img' key={i} src={urls[i]} alt='preview' />
      )
    }
    if (project && project.project && project.project && item && item.preview) {
      // Defining thumbnail calculations..
      const { useProject, thumbSize, edits, zoomed, zoomIndex, inlineEditor, crop, zoom, inlineEditorToggle, resetValues, saveEditedValues, selectedOverlay } = this.props
      const multiCart = useProject === false && photopersonalization && photopersonalization.length > 1
      const isLandscape = (useProject !== false)
        ? path(['photopersonalization', 0, 'thumbSize', 'width'], project.project) > path(['photopersonalization', 0, 'thumbSize', 'height'], project.project)
        : path([0, 'images', 0, 'thumbSize', 'width'], photopersonalization) > path([0, 'images', 0, 'thumbSize', 'height'], photopersonalization)
      var preview = (isMultiphoto)
        ? (zoomed) ? item.preview.portrait[zoomIndex || 0] : item.preview.portrait[0] // for multi-photo, just use portrait
        : (isLandscape) ? item.preview.landscape[0] : item.preview.portrait[0]
      const maxIndex = item.preview.numPhotos - 1
      if (maxIndex < zoomIndex) {
        dispatch(updateZoom(maxIndex, false))
        inlineEditorToggle(false)
        return null
      }
      const projectIndex = (zoomed && useProject !== false && zoomIndex < project.project.photopersonalization.length)
        ? zoomIndex || 0
        : 0
      if (isMultiphoto || multiCart) {
        var thumbWidth = (useProject !== false) ? path(['photopersonalization', projectIndex, 'thumbSize', 'width'], project.project) : path([projectIndex, 'images', 0, 'thumbSize', 'width'], photopersonalization)
        var thumbHeight = (useProject !== false) ? path(['photopersonalization', projectIndex, 'thumbSize', 'height'], project.project) : path([projectIndex, 'images', 0, 'thumbSize', 'height'], photopersonalization)
        preview = (thumbWidth > thumbHeight) ? item && item.preview && item.preview.landscape[(multiCart) ? 0 : zoomIndex || 0] : item && item.preview && item.preview.portrait[(multiCart) ? 0 : zoomIndex || 0]
      }
      const this_overlay = zoomed || (inlineEditor && preview.zoomedOverlay)
        ? preview.zoomedOverlay
        : preview.img

      const printWidth = isLandscape ? item.printData.landscape[0].width : item.printData.portrait[0].width
      const printHeight = isLandscape ? item.printData.landscape[0].height : item.printData.portrait[0].height
      const filteredOverlays = overlays.filter(overlay => overlay.width === printWidth && overlay.height === printHeight)

      const overlayIsDisabled = (product && product.overlayDisabled) ? true : filteredOverlays.length === 0
      return (
        <span className='thumbnail-generator'>
          <div className="print-parent">
            {inlineEditor && <div className='editor-info'><div>Edit mode:</div> <span className='is-hidden-tablet'>&nbsp;Pinch and drag</span><span className='is-hidden-mobile'>&nbsp;Click and drag and use slider below</span>.</div>}
            <div className="zoomed-actions-container" style={{ width: '100%', height: '100%' }}>
              {(productMoreViews && !zoomed && !inlineEditor) ?
                <Fragment>
                  <Slider ref={slider => (this.slider = slider)} {...sliderSettings}>
                    <GeneratedThumbnail {...{
                      item, project, useProject, thumbSize, edits, isMultiphoto,
                      preview, zoomed, zoomIndex, this_overlay, photopersonalization, lowResIndexArr, dispatch,
                      crop, zoom, inlineEditorToggle, resetValues, saveEditedValues, hasFilter, overlayIsDisabled, selectedOverlay
                    }} />
                    {productMoreViews.map && productMoreViews.map(({ url, displayOrder }) => (
                      <div key={displayOrder}>
                        <img src={url} alt='More views' />
                      </div>
                    ))}
                  </Slider>
                  <ViewVideoLink videoId={product.productVideoUrl || null} title="Play Video" />
                </Fragment>
                :
                <Fragment>
                  {(!zoomed) ? item.preview.portrait.map((p, i) => {
                    const photoUrl = (useProject !== false)
                      ? path(['photopersonalization', i, 'thumbUrl'], project.project) ? path(['photopersonalization', i, 'thumbUrl'], project.project) : undefined
                      : path([i, 'images', '0', 'url'], photopersonalization)
                    return (
                      <ThumbnailChild
                        key={i}
                        item={item}
                        project={project.project}
                        useProject={useProject}
                        photopersonalization={photopersonalization}
                        inlineEditorToggle={inlineEditorToggle}
                        // user={user}
                        i={i}
                        photoUrl={photoUrl}
                        thumbSize={thumbSize}
                        edits={(photopersonalization && photopersonalization[i] && photopersonalization[i].edits) ? photopersonalization[i].edits : edits}
                        zoomed={zoomed}
                        isMultiphoto={isMultiphoto}
                        lowResIndexArr={lowResIndexArr}
                        productMiniCard={productMiniCard}
                        inlineEditor={inlineEditor}
                        crop={crop}
                        zoom={zoom}
                        hasFilter={hasFilter}
                        zoomIndex={zoomIndex}
                        saveEditedValues={saveEditedValues}
                        resetValues={resetValues}
                        overlayIsDisabled={overlayIsDisabled}
                        selectedOverlay={selectedOverlay}
                        overlayIsDisabledCart={overlayIsDisabledCart}
                      />
                    )
                  }) : <ThumbnailChild
                      item={item}
                      project={project.project}
                      useProject={useProject}
                      photopersonalization={photopersonalization}
                      inlineEditorToggle={inlineEditorToggle}
                      resetValues={resetValues}
                      saveEditedValues={saveEditedValues}
                      // user={user}
                      i={zoomIndex}
                      thumbSize={thumbSize}
                      edits={edits}
                      zoomed={zoomed}
                      isMultiphoto={isMultiphoto}
                      lowResIndexArr={lowResIndexArr}
                      inlineEditor={inlineEditor}
                      crop={crop}
                      zoom={zoom}
                      hasFilter={hasFilter}
                      zoomIndex={zoomIndex}
                      overlayIsDisabled={overlayIsDisabled}
                      selectedOverlay={selectedOverlay}
                    />}
                  <img itemProp="image"
                    style={style.printPreview}
                    className="frame frame-2"
                    src={this_overlay || 'https://s3.amazonaws.com/focus-misc-assets/image_not_available_829x455.jpg'} alt={(this.props.alt) ? this.props.alt : "Our product overlay"}
                    onError={(e) => { e.target.onerror = null; e.target.src = "https://s3.amazonaws.com/focus-misc-assets/image_not_available_829x455.jpg" }}
                  />
                </Fragment>
              }
            </div>
          </div>
        </span>
      )
    } else {
      return "Loading...";
    }
  }
}
function mapStateToProps(state) {
  return {
    user: state.user.user,
    isMultiphoto: state.projects.isMultiphoto,
    project: state.projects.project,
    overlays: state.overlays.overlays,
  }
}
ThumbnailGenerator.propTypes = propTypes;
ThumbnailGenerator.defaultProps = defaultProps;

export default connect(mapStateToProps)(ThumbnailGenerator);

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { path } from 'ramda'
import { decode } from 'he'
import Helmet from 'react-helmet-async'

const processCustomMeta = (meta, seoName, customData) => {
  switch (meta.type) {
    case 'title': {
      return Object.assign({}, meta, { content: `${customData.name} | ${seoName}` })
    }
    case 'meta': {
      const description = customData.tagline ? `${customData.tagline}, ${seoName}` : seoName
      return Object.assign({}, meta, { content: `${customData.name}, ${description}` })
    }
    default: {
      return meta
    }
  }
}

const sendData = (data, customData, optionalTitle) => {
  return data.seoMetaData.map((meta, index) => {
    if (customData && (meta.name === 'description' || meta.type === 'title')) {
      const customMeta = processCustomMeta(meta, (data.seoName || optionalTitle), customData)

      return (
        customMeta.type === 'title'
          ? <title key={index}>{decode(customMeta.content)}</title>
          : <meta
            key={customMeta.name}
            id={`meta-${customMeta.name}`}
            name={customMeta.name}
            content={customMeta.content}
          />
      )
    }

    return (
      meta.type === 'title'
        ? <title key={`title-${index}`}>{decode(meta.content) || decode(optionalTitle)}</title>
        : <meta
          key={meta.name}
          id={`meta-${meta.name}`}
          name={meta.name}
          content={meta.content}
        />
    )
  })
}

const MetaDataTags = ({
  pagesMetaData,
  customData,
  optionalTitle,
  productCategory,
  productStyle,
  productSlug,
  baseUrl,
  seoInfo,
  config
}) => {
  const { id } = config.appConfig
  const title = path(['title'], seoInfo)
  const metaDescription = path(['metaDescription'], seoInfo)
  const property = path(['property'], seoInfo)
  return (
    <Helmet>
      {id !== 1 && <meta name='robots' context='noindex,nofollow' />}
      {pagesMetaData && pagesMetaData.seoMetaData && sendData(pagesMetaData, customData, optionalTitle)}
      {(productCategory && !productStyle && !productSlug) && <link rel='canonical' href={`${baseUrl}/products/${productCategory}`} />}
      {(productCategory && productStyle && !productSlug) && <link rel='canonical' href={`${baseUrl}/products/${productCategory}/${productStyle}`} />}
      {(productCategory && productStyle && productSlug) && <link rel='canonical' href={`${baseUrl}/products/${productCategory}/${productStyle}/${productSlug}`} />}
      {title && <title>{decode(title)}</title>}
      {metaDescription && <meta property={property} content={metaDescription} />}
    </Helmet>
  )
}

MetaDataTags.propTypes = {
  pagesMetaData: PropTypes.object,
  customData: PropTypes.object,
  optionalTitle: PropTypes.string
}

const mapStateToProps = state => ({
  config: state.config
})

export default connect(mapStateToProps)(MetaDataTags)

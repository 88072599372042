import * as types from '../actions/actionTypes';
import { assign } from 'lodash';

const initialState = {
  photo: {},
  photos: [],
  list: [],
  myAccount: [],
  phone: {},
  instagram: {
    data: [],
    pagination: {},
  },
  uploaded: false,
  progressBar: [{ id: 0, percent: 0 }],
};

function photos(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_PHOTOS:
        return { ...state, photos: action.payload }
    case types.SELECTED_PHOTO:
      return assign({}, state, {
        photo: action.photo,
        failed: false,
      });
    case types.CLEAR_PHOTO_SERVICES_SELECTIONS:
      return {
        ...state,
        failed: false,
        phone: {},
        instagram: {
          data: [],
          pagination: {},
        },
        loading: false,
      };

    case types.USERPHOTO_UPLOAD_ERROR:
      return assign({}, state, {
        uploaded: action.uploaded,
        failed: true,
        loading: action.loading,
      });

    case types.PROGRESS_BAR:
      const oldState = state.progressBar
      const thisIndex = oldState.findIndex(({ id }) => id === action.payload.id)
      if (thisIndex === -1) {
        oldState.push(action.payload)
        // new id assignment
        return assign({}, state, { 
            progressBar: oldState
        })
      } else {
        oldState[thisIndex] = action.payload
        // re-assign existing id
        return assign({}, state, { 
          progressBar: oldState
        })
      }

    case types.INSTAGRAM_FETCH_IMAGES:
      return assign({}, state, {
        instagram: {
          data: action.photosInstagram.data,
          pagination: action.photosInstagram.pagination
        },
        failed: false,
      });

    default:
      return state;
  }
}

export default photos;

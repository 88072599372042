import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { seoInfo } from '../helpers/seoInfo'
import { montetatePageTracker, setIntervalHelper} from '../helpers/monetate'
import HomePageContent from '../components/homepage'
import Hero from '../components/header/Hero'
import { appConfig} from '../actions'
import MetaDataTags from '../helpers/ProcessMetaData'
import { withRouter } from 'react-router-dom'
import GuaranteeRibbon from '../components/common/guaranteeUpdates'
import '../assets/stylesheets/page-shell.scss'



const propTypes = {
  dispatch: PropTypes.func,
  seo: PropTypes.object,
  layout: PropTypes.object,
  promo: PropTypes.object,
  showFeatured: PropTypes.bool,
  photos: PropTypes.object,
}

class HomePage extends React.Component { // eslint-disable-line react/prefer-stateless-function

  static contextTypes = {
    router: PropTypes.object
  }

  componentWillMount() {
    window.gtag('event', 'page_view', {
      'send_to': 'AW-668262331',
      'ecomm_pagetype':'home'
    });

    setIntervalHelper(montetatePageTracker);
   
 
    
  }
  


  updateProducts() {
    // TODO: change this to fetch_products ...
    appConfig()
  }

  render() {
    const { layout } = this.props;
    return (
      <div>
        <MetaDataTags seoInfo={seoInfo.homepage} />
        { (layout.showHero && layout.heroImg.desktop) ?
              <Hero
                dispatch={this.props.dispatch}
                heroImg={layout.heroImg}
                className="hero-section"
              />
        : null
        }
        <GuaranteeRibbon homepage/>
        <section>
          <HomePageContent />
        </section>
      </div>
    );
  }
}



function mapStateToProps(state) {
  return {
    showFeatured: state.showFeatured,
    seo: state.config.seoData,
    layout: state.config.layout,
    promo: state.config.seoData.promo,
    photos: state.photos.photos,
  };
}

HomePage.propTypes = propTypes;
HomePage.contextTypes = {
  router: PropTypes.object.isRequired
}
export default withRouter(connect(mapStateToProps)(HomePage));

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import autoBind from 'react-autobind'
import { fetch_artciles } from '../actions'
import MetaDataTags from '../helpers/ProcessMetaData'
import { seoInfo } from '../helpers/seoInfo'
import '../assets/stylesheets/page-shell.scss'
import '../assets/stylesheets/articles.scss'

const propTypes = {
  dispatch: PropTypes.func,
}

class Articles extends Component{
  static contextTypes = {
    router: PropTypes.object
  }
  constructor(props) {
    super(props)
    this.state = {
      articles:[{
        altText: '',
        contents: '',
        createdAt: '',
        id: 1,
        image: '',
        slug: '',
        summary: '',
        thumbnail: '',
        title: '',
        updatedAt: '',
      }]
    }
    autoBind(this)
  }
  componentWillMount(){
    this.props.dispatch(fetch_artciles())
    .then((response)=>{
      this.setState({
        articles: response
      })
    })
  }
  render(){
    const { articles } = this.state
    return(
      <div className="shell-container main-container">
        <MetaDataTags baseUrl='articles' seoInfo={seoInfo.articles} />
        <div className='container articles-main-container'>
            {articles.map((article)=>{
              var options = { month: 'long', day: 'numeric', year: 'numeric'}
              const date = new Date(article.createdAt)
              const formattedDate = date.toLocaleDateString("en-US", options)
              return(
              <div className='articles-card-container' 
                key={article.id}
                onClick={() => {this.context.router.history.push(`/article/${article.slug}`)}}>
                <div className='articles-card-content'>
                  <h1 className='articles-card-title'>{article.title}</h1>
                  <p className='articles-card-summary' dangerouslySetInnerHTML={{ __html: article.summary }}></p>
                  <p className='articles-card-date italic'>{formattedDate}</p>
                </div>
                <div className='articles-card-image'>
                  <img src={article.thumbnail} alt={article.altText}/>
                </div>
              </div>
              )
            })}
        </div>
      </div>
    )
  }
}

Articles.propTypes = propTypes

export default connect(null)(Articles)
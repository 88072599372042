import React from 'react'
import autoBind from 'react-autobind'
import { Link } from 'react-router-dom'
import addDefaultSrc from '../../helpers/addDefaultSrc'
import { Collapse } from 'antd';
import FaqCollapseConatiner from '../../components/faq/FaqCollapseContainer'
import FaqCategoryWrapper from '../../components/faq/FaqCategoryWrapper'
const { Panel } = Collapse

class FaqPlacingOrder extends React.Component {
  constructor(props) {
      super(props)
      autoBind(this)
  }
  render() {
      return (
        <FaqCategoryWrapper title='Placing an Order'>
          <div className='faq-questions-container'>
            <FaqCollapseConatiner>
              <Panel header="How do I place my first MyPhoto order?" key="1">
                <p>Great news! MyPhoto is the world's easiest and fastest way to turn your photos into one-of-a-kind gifts and décor. </p>
                <p>Simply click the <Link className="faq-link" to="/upload">&nbsp;Upload Photo&nbsp;</Link> button and choose any photo to get started. 
                  Alternatively, you can email a photo directly from your phone to <a className="faq-link" href="mailto:123@myphoto.com">&nbsp;123@myphoto.com&nbsp;</a> we'll email you right back with that photo previewed on our full assortment of handcrafted products.</p> 
                <p>Don't forget…you get <span className="semi-bold">20% OFF your first order</span>! Just enter the promo code at checkout.</p>
              </Panel>
              <Panel header="How do I use my desktop computer to order photos that are on my phone?" key="2">
                <p>We have two easy ways to use your computer when ordering photos on your phone. 
                First, you can email the photo(s) from your phone to<a className="faq-link" href="mailto:123@myphoto.com">&nbsp;123@myphoto.com</a> and open the response email from MyPhoto <span className='underlined'>on your computer</span>.</p>
                <p>Or…on your computer, click the <Link className="faq-link" to="/upload">&nbsp;Upload Photo&nbsp;</Link> button and choose <span className="semi-bold">MY PHONE</span> as the source. 
                There are simple instructions to complete those steps if that option is chosen.</p>
              </Panel>
              <Panel header="I emailed a photo to 123@myphoto.com but nothing came back." key="3">
                <p>First, make sure the photo actually left your phone or computer. 
                If it did and you still can't find our response email, check your spam or promotions folders as sometimes emails can end up there. 
                If it's not there, try again or simply click the<Link className="faq-link" to="/upload">&nbsp;Upload Photo&nbsp;</Link> button to try again directly on our site!</p>
              </Panel>
              <Panel header="How do I rotate or change the orientation of my photo?" key="4">
                <p>We are always adding new features to make your experience faster and easier! 
                Currently, you can not change the orientation of your photo on our site.</p>
                <p>To rotate your photo before you get started, find the photo you wish to send and hit the EDIT button on your phone. </p>
                <p>Then, look for and click the icon similar to this: </p>
                <img style={{height: '80px', width: '80px', margin: '20px 30px'}} src={'https://storage.googleapis.com/myphoto-img/faq/faq-rotate.png'} onError={addDefaultSrc} alt='rotation'/>
                <p>This button will rotate the photo to your desired orientation. </p>
              </Panel>
              <Panel header="Can I get my order gift wrapped or include a gift note?" key="5">
                <p>You're in luck! We recently introduced our <span className='semi-bold'>NEW, beautiful gift boxes</span>. 
                They are so stunning...the box itself is display-worthy. </p>
                <img style={{height: '200px', width: '300px', margin: '20px 30px'}} src={'https://storage.googleapis.com/myphoto-img/faq/faq-under-tree.jpeg'} onError={addDefaultSrc} alt='under tree'/>
                <p>Plus...we provide a <span className='semi-bold'>FREE, custom gift note</span>. 
                Simply select the option to include a gift message during <span className='underlined'>checkout</span> and write a sweet, personal message!</p>
                <img style={{height: '200px', width: '300px', margin: '20px 30px'}} src={'https://storage.googleapis.com/myphoto-img/faq/faq-giftnote.jpg'} onError={addDefaultSrc} alt='gift note'/>
              </Panel>
              <Panel header="How do I get 50% OFF when purchasing a product?" key="6">
                <p>Buy AS MANY duplicates as you want for <span className='semi-bold'>50% OFF</span>. Simply set any item quantity to AS MANY as you want for 50% off. 
                Just remember, the duplicates must be the same photo, same product, and same address for the discount to automatically apply. </p>
              </Panel>
              <Panel header="Why doesn't my NEW CUSTOMER promo code work?" key="7">
                <p>All new customers receive 20% OFF their first order! Please make sure you're entering the code in the correct field. 
                Promo codes should be entered in the field named "Promo Code" in the cart.</p>
                <p>NOTE: Make sure there are no spaces in the code...before, or after. 
                Sometimes spaces can happen when you copy and paste a code.</p>
              </Panel>
              <Panel header="Can I email more than 1 photo at a time?" key="8">
                <p>When email photos to <a className="faq-link" href="mailto:123@myphoto.com">&nbsp;123@myphoto.com</a>, it is best to <span className='semi-bold'>email only 1 photo</span>. </p>
                <p>Once you've received the email back from us and have clicked through to our website, adding more photos is CRAZY EASY! 
                Simply click the<Link className="faq-link" to="/upload">&nbsp;Upload Photo&nbsp;</Link> button to add as many photos as you'd like.</p>
              </Panel>
              <Panel header="Can I turn my physical, hard copy photo into any of your products?" key="9">
                <p>We all have that favorite printed photo we've held on to for ages or have in an album (or shoebox). 
                If you want that special memory printed on any of our awesome products, all you need to do is take a high-resolution scan of the physical photo and save it as a digital file on your computer. 
                Get our most recommended free scanning app, Google Scan, <a className="faq-link" target="_blank" rel="noopener noreferrer" href="https://www.google.com/photos/scan/">here.</a></p>
              </Panel>
            </FaqCollapseConatiner>
          </div>
        </FaqCategoryWrapper>
      )
  }
}

export default FaqPlacingOrder;
import React from 'react'
import { Spin, Icon } from 'antd';
import { updateZoom, openModal } from '../../../actions'



const antIcon = <Icon type="loading" style={{ fontSize: 24, top: '20px', color: 'white' }} spin />

const handleShowPreviewModal = (e, item, handleAddToCartClick, handlePreviewModal ) => {
  const noPreviewShape = item.previewShape === null || item.previewShape === ""
  const isCollageOrMask = (item.maskHorizontal || item.maskVertical)
  if (isCollageOrMask && noPreviewShape) {
    e.preventDefault()
    handleAddToCartClick()
  } else {
    e.preventDefault()
    handlePreviewModal()
  }
}


export const AddToCartBtn = ({ hasUploads, zoomed, dispatch, project, item, product, inlineEditor, appConfig, isMultiPhoto, 
  handlePreviewModal, inlineEditorToggle,handleAddToCartClick, showAddToCartModal,
  zoomIndex, saveEditedValues}) =>{
    if (item && item.outOfStock && appConfig.id !== 4) { // out of stock
    return (
      <span style={{ width: '100%' }}>
        <button disabled type="button" className="cta-btn add-disabled">
          Add to Cart
        </button>
      </span>
    )
  } else { // items in stock
    if (product && product.allowsPersonalization) { // --------- PERSONALIZED ITEMS ---------
      // PERSONALIZED
      if (hasUploads) {
        // HASUPLOADS
        if (isMultiPhoto) {
          // ISMULTIPHOTO
          if (zoomed) { // When zoomed in, show DONE button to zoom out
            // ZOOMED
            
            if (inlineEditor) {
              // EDITOR -- SHOW SAVE
              return (
                <span style={{ width: '100%' }}>
                  <button onClick={(e) => {
                    inlineEditor && saveEditedValues(zoomIndex, zoomed)
                    inlineEditorToggle()
                  }}
                    type="button" className="cta-btn" id="add-to-cart-btn">
                    Save Edits
                  </button>
                </span>
              )
            } else {
              // NOT EDITOR -- SHOW DONE
              return (
                <span style={{ width: '100%' }}>
                  <button onClick={(e) => { dispatch(updateZoom(0, false)) }}
                    type="button" className="cta-btn" id="add-to-cart-btn">
                    Done
                  </button>
                </span>
              )
            }
          } else { // now check if all photo slots full...
            // NOT ZOOMED
            const numPhotos = (item && item.preview && item.preview.numPhotos) ? item.preview.numPhotos : 9999
            const photopersonalizationLength = (project && project.project && project.project.photopersonalization && project.project.photopersonalization.length)
              ? project.project.photopersonalization.length
              : 0
            if (photopersonalizationLength >= numPhotos) { // allow add-to-cart
              // FULL
              const blobIndex = project.project.photopersonalization.findIndex((p, i) => p && p.url && p.url.startsWith('blob'))
              if (blobIndex > -1 && numPhotos > blobIndex) { // spin add-to-cart if blob
                // HASBLOB -- SHOW SPINNER
                return (
                  <span style={{ width: '100%' }}>
                    <Spin indicator={antIcon}>
                      <button type="button" className="cta-btn">
                        Add to Cart
                      </button>
                    </Spin>
                  </span>
                )
              } else { // show add-to-cart for FULL multiphoto
                // NOT HASBLOB -- SHOW ADD-TO-CART
                return (
                  <span style={{ width: '100%' }}>
                    <button onClick={(e) => {
                      // handleAddToCartClick()
                      handleShowPreviewModal(e, item, handleAddToCartClick, handlePreviewModal)
                    }}
                      type="button" className="cta-btn" id="add-to-cart-btn">
                      Add to Cart
                    </button>
                  </span>
                )
              }
            } else { // upload
              // NOT FULL
              return (
                <span style={{ width: '100%' }}>
                  <button onClick={(e) => {
                    showAddToCartModal() 
                    dispatch(openModal('UPLOAD_MODAL'))
                  }}
                    type="button" className="cta-btn" id="add-to-cart-btn">
                    Add Photos
                    </button>
                </span>
              )
            }
          }
        } else { // single-photo
          // NOT ISMULTIPHOTO
          const isBlob = project && project.project && project.project.photopersonalization && project.project.photopersonalization[0] && project.project.photopersonalization[0].url && project.project.photopersonalization[0].url.startsWith('blob')
          if (isBlob) { // spin add-to-cart if blob
            // ISBLOB -- SHOW SPINNING
            return (
              <span style={{ width: '100%' }}>
                <Spin indicator={antIcon}>
                  <button
                    type="button" className="cta-btn" id="add-to-cart-btn">
                    Add to Cart
                  </button>
                </Spin>
              </span>
            )
          } else { // add-to-cart for single-photo
            // NOT ISBLOB
            if (inlineEditor) {
              // EDITOR -- SHOW SAVE
              return (
                <span style={{ width: '100%' }}>
                  <button onClick={(e) => {
                    inlineEditor && saveEditedValues(zoomIndex, zoomed)
                    inlineEditorToggle()
                  }}
                    type="button" className='cta-btn' id="add-to-cart-btn">
                    Save Edits
                    </button>
                </span>
              )
            } else {
              // NOT EDITOR -- SHOW ADD-TO-CART
              return (
                <span style={{ width: '100%' }}>
                  <button onClick={(e) => {
                    // handleAddToCartClick()
                    handleShowPreviewModal(e, item, handleAddToCartClick, handlePreviewModal)
                  }}
                    type="button" className="cta-btn" id="add-to-cart-btn">
                    Add to Cart
                  </button>
                </span>
              )
            }
          }
        }
      } else { // no uploads, show upload button
        // NOT HASUPLOADS -- SHOW UPLOAD
        return (
          <span style={{ width: '100%' }}>
            <button onClick={(e) => {
              showAddToCartModal()
              dispatch(openModal('UPLOAD_MODAL'))
            }}
              type="button" className="cta-btn" id="add-to-cart-btn">
              Add Photo
            </button>
          </span>
        )
      }
    } else { // ------------------- NON-PERSONALIZED ITEMS -------------------
      // NOT PERSONALIZED -- SHOW ADD-TO-CART
      return (
        <span style={{ width: '100%' }}>
          <button onClick={(e) => {
            handleAddToCartClick()
          }}
            type="button" className="cta-btn" id="add-to-cart-btn">
            Add to Cart
          </button>
        </span>
      )
    }
  }
}

export default AddToCartBtn
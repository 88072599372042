import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import autoBind from 'react-autobind'
import Checkout from '../components/checkout'

const propTypes = {
  salesChannel: PropTypes.number
}
class MyCheckout extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      stripeKey: null,
      stripe: null
    }
    autoBind(this)
  }

  render () {
      return (
            <Checkout />
      )
    } 
}

function mapStateToProps (state) {
  return {
    salesChannel: state.config.appConfig.id
  }
}

MyCheckout.propTypes = propTypes

export default connect(mapStateToProps)(MyCheckout)

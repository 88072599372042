import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { is_fetching, new_user_order } from '../../actions/'
import { connect } from 'react-redux'
import autoBind from 'react-autobind'
import {monetateAddToCart,  setIntervalHelper} from '../../helpers/monetate.js'
import CartList from './index.js'
import addDefaultSrc from '../../helpers/addDefaultSrc'
import ShippingProgressBar from '../common/ShippingProgressBar'
import '../../assets/stylesheets/added-to-cart.scss'

class AddedToCart extends React.Component {

  static contextTypes = {
    router: PropTypes.object
  }



  constructor(props) {
    super(props);
    const qty = []
    for (let i = 1; i <= 50; i++) {
      qty.push({
        value: i,
        label: i,
      })
     
    }
   
   
    this.state = {
      qty,
      subtotal: 0,
    }
    autoBind(this);
  }
componentWillMount(){
  setIntervalHelper(() =>{monetateAddToCart( this.props.cart.items)});
 

}

  handleCheckoutClick() {
    const { dispatch, user } = this.props
    dispatch(new_user_order(user))
    dispatch(is_fetching())
  }
  handler(subtotal) {
    this.setState({
      subtotal
    })
  }
  render() {
    const { cart, products, items, handleCancelCartModal, productPage, hideLink } = this.props;
    const cartItems = cart.items
    const cartItemsIsArray = (cartItems.length && cartItems[0] && cartItems[0].item_id)
    const cartItem = cartItemsIsArray ? cartItems[cartItems.length - 1] : cartItems[0]
    var subtotal
    if (cartItemsIsArray) {
      subtotal = cartItems.reduce((accumulator, item) => {
        const itemTotal = (Number(item.total) > 0) ? Number(item.total) : 0;
        return accumulator + itemTotal
      }, 0);
    } else {
      subtotal = 0
    }
    if (cartItem && cartItem.id > -1) {
      const item = items.find(itemElement => itemElement.id === cartItem.item_id);
    
      if (!item) {
        return null
      } else {
        const product = products.find(productItem => productItem.id === item.productId);
       
        if (!product) {
          return null
        } else {
          return (
            <span style={{ display: 'flex', flexDirection: 'column' }}>
              <span className="added-main-container">
                <div className="shipping-progress-container">
                  <ShippingProgressBar subtotal={subtotal} shippingOptions={this.props.shippingOptions} />
                  <div className="added-continue-order"
                    onClick={() => {
                      (productPage === 'miniblox')
                        ? handleCancelCartModal()
                        : this.context.router.history.push('/products')
                    }}>
                    <h5 className="continue-shopping-link">Continue Shopping</h5>
                    <img className='continue-shopping-arrow' alt='right chevron' onError={addDefaultSrc}
                      src='https://storage.googleapis.com/myphoto-img/icons/continue-shopping-arrow.svg' />
                  </div>
                </div>
                <CartList
                  handler={this.handler}
                  addedToCart={true}
                  handleCancelCartModal={handleCancelCartModal}
                  hideLink={hideLink}
                />
              </span>
            </span>
          );
        }
      }
    } else {
      return (
        <span className="added-main-container">
          <div className="added-continue-order"
            style={{ marginBottom: '20px' }}
            onClick={() => {
              this.context.router.history.push('/products')
            }}>
            <h5 className="continue-shopping-link">Continue Shopping</h5>
            <img className='continue-shopping-arrow' alt='right chevron' onError={addDefaultSrc}
              src='https://storage.googleapis.com/myphoto-img/icons/continue-shopping-arrow.svg' />
          </div>
          <div className="deleted-from-cart-container">
            <p className="deleted-item-text">Your Cart is empty!</p>
          </div>
          <div className="added-total-list">
            <p className="added-subtotal-text">Subtotal (0 items)</p>
            <p className="added-subtotal">${(subtotal > 0) ? subtotal.toFixed(2) : '0'}</p>
          </div>
        </span>
      );
    }
  }

}

export default withRouter(connect(state => {
  return {
    products: state.products.list,
    items: state.products.items,
    cart: state.cart.cart,
    itemRemoved: state.cart.itemRemoved,
    shippingOptions: state.config.appConfig.shippingOptions,
  };
})(AddedToCart))
import React from 'react'
import { connect } from 'react-redux'
import { closeModal } from '../../actions'
import ReactModal from 'react-modal'
import addDefaultSrc from '../../helpers/addDefaultSrc'
import AddressConfirmationModal from './AddressConfirmationModal'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: '-45%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0
  }
}

export const ShowConfirm = ({
  dispatch,
  isOpen,
  order_success: orderSuccess,
  order,
  user,
  paymentDone,
  shippingValidation,
  stripeTokenId,
  zeroDollarOrder,
  executeStripePayment,
  toggleShowModal,
  shipMethod,
  reason_code,
  handlePlaceOrder,
  braintreeNonce,
  executeBrainTreePayment
}) => (
  <div>
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => { dispatch(closeModal('ADDRESS_CONFIRMATION')) }}
      ariaHideApp={false}
      style={customStyles}
    >
      <button
        className='modal-close-btn'
        style={{ marginRight: '20px' }}
        onClick={() => {
          dispatch(closeModal('ADDRESS_CONFIRMATION'))
          toggleShowModal(false)
        }} >
        <img alt='close'
            onError={addDefaultSrc}
            src='https://storage.googleapis.com/myphoto-img/icons/close-btn.svg' style={{ width: '20px' }} />
      </button>
      <div className='promo-bar-modal'>
        <AddressConfirmationModal
          shippingValidation={shippingValidation}
          paymentDone={paymentDone}
          order={order}
          user={user}
          shipMethod={shipMethod}
          order_success={orderSuccess}
          stripeTokenId={stripeTokenId}
          zeroDollarOrder={zeroDollarOrder}
          executeStripePayment={executeStripePayment}
          reason_code={reason_code}
          handlePlaceOrder={handlePlaceOrder}
          braintreeNonce={braintreeNonce}
          executeBrainTreePayment={executeBrainTreePayment}
        />
      </div>
    </ReactModal>
  </div>
)

export default connect()(ShowConfirm)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import MetaDataTags from '../helpers/ProcessMetaData'
import { seoInfo } from '../helpers/seoInfo'
import AboutLinks from '../components/common/AboutLinks'
import CTAsection from '../components/homepage/CTAsection'
import '../assets/stylesheets/page-shell.scss'
import '../assets/stylesheets/our-story.scss'
import '../assets/stylesheets/framework.scss'

const propTypes = {
    user: PropTypes.object,
}
class OurStory extends Component {

    static contextTypes = {
        router: PropTypes.object,
    }

    componentWillMount() {
        window.gtag('event', 'page_view', {
            'send_to': 'AW-668262331',
            'ecomm_pagetype': 'other'
        });
    }
    render() {
    const { photos } = this.props
    const hasPictures = photos.length > 0 && photos[0] && photos[0].width
        return (
            <div className="shell-container main-container">
                <MetaDataTags baseUrl='our-story' seoInfo={seoInfo.aboutUs} />
                <div className='about-us-container'>
                    <AboutLinks title={'Our Story'}/>
                    <div className='waren-container'>
                        <div className='image-warren'>
                            <img src='./images/Warren.png' alt=''/>
                        </div>
                        <div className='our-story-quote-container'>
                            <img src='./images/icons/quotes.svg' alt=''/>
                            <p>I founded MyPhoto with a single mission: To create the easiest and fastest way possible to allow <span className='italic'>anyone</span> to get photos off their phone and into their home.</p>
                        </div>
                    </div>
                    <div className='our-story-text-container'>
                        <p>There’s <span className='italic'>magic</span> in your phone. It’s in the photo of your baby’s laugh, or the photo you took while on a family vacation, or of your grandchild’s first step, or your son’s smile when he sat in his first car. It’s even in the selfie you and your best friend just took!</p>
                        <p>But there’s the problem…</p>
                        <p>All these amazing photos, just sitting on your phone, never to see the light of day. That’s why I created MyPhoto a few years ago: to give you the easiest and fastest way to bring those moments to life. We’re so excited to have already transformed <span className='italic'>millions of memories</span> into incredible handcrafted, high-quality photo gifts and décor</p>
                        <p>Imagine coming home to see your favorite memories made into true artwork for your walls, desks and shelves with the push of a button! Or being able to create a <span className='italic'>personalized, one-of-a-kind gift</span> for your friends and family from the comfort of your couch. </p>
                        <p>We’re not talking about downloading an app or subscribing to a service…we’re talking about your photos, from your phone to your home within 5 days and ordered in less than 5 minutes! MyPhoto is created on the principle of being Crazy Easy. Crazy Fast. ANYONE CAN DO IT!</p>
                        <p>These are not just photos to us…these are your memories and we take that very seriously. We’re <span className='italic'>thankful to all our customers</span> for trusting us in taking care of your most precious photo moments.</p>
                        <p style={{marginBottom: '10px'}}>Warren Struhl</p>
                        <p>Founder, MyPhoto</p>
                    </div>
                    <CTAsection hasPictures={hasPictures} />
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        photos: state.photos.photos,
    }
}

OurStory.propTypes = propTypes;
OurStory.contextTypes = {
    router: PropTypes.object.isRequired
}
export default withRouter(connect(mapStateToProps)(OurStory));

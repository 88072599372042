import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import autoBind from 'react-autobind'
import { Link, withRouter } from 'react-router-dom'
import ThumbnailGenerator from '../common/Thumbnail'
import addDefaultSrc from '../../helpers/addDefaultSrc'
import '../../assets/stylesheets/product-page/product-card.scss'


const propTypes = {
    dispatch: PropTypes.func.isRequired,
    products: PropTypes.array,
    items: PropTypes.array,
    user: PropTypes.object,
    project: PropTypes.object,
}

class ProductMiniCard extends React.Component {

    static contextTypes = {
        router: PropTypes.object,
    }
    constructor(props) {
        super(props);
        autoBind(this);
    }
    componentDidMount(){
        setTimeout(() => { if (window.yotpo) window.yotpo.refreshWidgets() }, 537)
        
    }
    getItem(product, single) {
        const { projectItems, items } = this.props
        let item
        const option = product.optionItems.find(({ isDefault }) => isDefault)
        const groupName = (option && option.groupName) || '';
        // If there aren't any project items yet, load the default item.
        if (projectItems.length === 0 || single) {
            if (option) {
                item = {
                    ...items.find(({ id: itemId }) => itemId === product.defaultItemId),
                    groupName,
                };
            } else {
                item = {
                    ...items.find(({ productId: id }) => id === product.id),
                    groupName,
                };
            }
        } else {
            // const projectItem = projectItems.find(({ productId }) => productId === product.id)
            const projectItem = items.find(({ productId }) => productId === product.id)
            item = {
                ...items.find(({ id: itemId }) => itemId === projectItem.id),
                groupName,
            };
        }
        return item
    }
    render() {
        const { photos, hasProject, product } = this.props
        const getProduct = (product) => {
            const items = this.props.items.filter((itm) => itm.productId === product.id);
            const item = items.reduce((prev, current) => ((prev.price < current.price) ? prev : current));
            const samePrice = (items.length > 1) ? !!!items.find(itm => itm.price !== item.price) : true;
            if (_.isEmpty(item) || product.disabled) {
                return null;
            }
            const itemInfo = this.getItem(product, true)
            const prodStyle = (product.attributes && product.attributes[0]) ? product.attributes[0] : product.slug
            const prodLink = `/products/${product.category}/${prodStyle}/${product.slug}`
            if (product.allowsPersonalization && hasProject && photos && photos[0] && (photos[0].thumb_url || photos[0].thumbUrl)) {
                return (
                    <div className='product-card-main-container-a' key={product.id}>
                        <Link to={prodLink}>
                            <div className="product-card-image-cont">
                                {product.newproduct ?
                                    <img className="new-product-img thumb-img" onError={addDefaultSrc} src="https://storage.googleapis.com/myphoto-img/new-product.svg" alt="NEW" />
                                    : null}
                                <ThumbnailGenerator
                                    photoUrl={photos[0].thumb_url || photos[0].thumbUrl}
                                    actualSize={{ width: photos[0].width, height: photos[0].height }}
                                    edits={photos[0].edits}
                                    item={itemInfo}
                                    alt={product.tagline}
                                    ref="thumbnailCreator"
                                    productMiniCard
                                    />
                            </div>
                            <div className="product-card-desc-container">
                                <h3 itemProp="name" className="product-card-title" dangerouslySetInnerHTML={{ __html: product.name }} />
                                {product && product.yotpoId && <div className="yotpo bottomLine"
                                    data-appkey="QJL7i7XyPLnGHtG8sLX56GdlEOsWYnUcWMaxuoYe"
                                    data-domain="http%3A%2F%2Fwww.myphoto.com"
                                    data-product-id={product.yotpoId}>
                                    </div>}
                                <div className="product-card-footer-container">
                                    <div className="product-card-footer">
                                        <div className="product-price-container">
                                            <p className="price-holder">{samePrice ? 'Price' : 'From'}</p>
                                            <p itemProp="price" className="price">${item.price ? item.price : '1'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                );
            } else {

                return (
                    <div className='product-card-main-container-a' key={product.id}>
                        <Link to={prodLink}>
                            <div className="product-card-image-cont">
                                {product.newproduct ?
                                    <img className="new-product-img product-img" onError={addDefaultSrc} src="https://storage.googleapis.com/myphoto-img/new-product.svg" alt="NEW" />
                                    : null}
                                <img className="home-product-image"
                                    src={(hasProject && photos && photos[0] && (photos[0].thumb_url || photos[0].thumbUrl)) ? product.preview.portrait[0].img : product.img}
                                    alt={product.tagline}
                                    onError={(e) => { e.target.onerror = null; e.target.src = "https://s3.amazonaws.com/focus-misc-assets/image_not_available_829x455.jpg" }}
                                    />
                            </div>
                            <div className="product-card-desc-container">
                                <h3 itemProp="name" className="product-card-title" dangerouslySetInnerHTML={{ __html: product.name }} />
                                {product && product.yotpoId && <div className="yotpo bottomLine"
                                    data-appkey="QJL7i7XyPLnGHtG8sLX56GdlEOsWYnUcWMaxuoYe"
                                    data-domain="http%3A%2F%2Fwww.myphoto.com"
                                    data-product-id={product.yotpoId}>
                                    </div>}
                                <div className="product-card-footer-container">
                                    <div className="product-card-footer">
                                        <div className="product-price-container">
                                            <p className="price-holder">{samePrice ? 'Price' : 'From'}</p>
                                            <p itemProp="price" className="price">${item.price ? item.price : '1'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                )
            }
        }
        return getProduct(product)
    }
}

ProductMiniCard.propTypes = propTypes

function mapStateToProps(state) {
    return {
        products: state.products.list,
        items: state.products.items,
        photos: state.photos.photos,
        projectItems: state.projects.items,
        project: state.projects.project,
    }
}
export default withRouter(connect(mapStateToProps)(ProductMiniCard))

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'
import store, { history } from './store'
// import registerServiceWorker from './registerServiceWorker'
import { unregister } from './registerServiceWorker'
import App from './scenes/App'
import './App.css'

// set render target
const target = document.querySelector('#root')


console.log(process.env.REACT_APP_OTHER_OTHER_THING, 'DOTENV')
// registerServiceWorker()
unregister()

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  target
)


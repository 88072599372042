import React from 'react'
import { Route, Switch } from 'react-router'

// Module root components
import Home from './scenes/Home'
import Upload from './scenes/Upload'
import Category from './scenes/Category'
import Products from './scenes/Products'
import Product from './scenes/Product'
import Cart from './scenes/Cart'
import MyCheckout from './scenes/MyCheckout' // MyCheckout is Stripe wrapper for Checkout
import OrderSuccess from './scenes/OrderSuccess'
import LoadingPage from './scenes/LoadingPage'
import PageNotFound from './scenes/PageNotFound'
import OurStory from './scenes/OurStory'
import Services from './scenes/Services'
import AtWork from './scenes/AtWork'
import Reviews from './scenes/Reviews'
import TermsAndConditions from './scenes/TermsAndConditions'
import PrivacyPolicy from './scenes/PrivacyPolicy'
import FAQ from './scenes/FAQ'
import Redirects from './scenes/Redirects'
import GiftingProgram from './scenes/GiftingProgram'
import BlackFriday from './scenes/BlackFriday'
import CyberMonday from './scenes/CyberMonday'
import CorporateGiftingForm from './scenes/CorporateGiftingForm'
import Partners from './scenes/Partners'
import HowTo from './scenes/HowTo'
import Promotions from './scenes/Promotions'
import OrderStatus from './scenes/OrderStatus'
import Articles from './scenes/Articles'
import Article from './scenes/Article'
import AboutUs from './scenes/AboutUs'
import FaqPlacingOrder from './scenes/faq/FaqPlacingOrder'
import FaqShippingTracking from './scenes/faq/FaqShippingTracking'
import FaqSecurePersonalInfo from './scenes/faq/FaqSecurePersonalInfo'
import FaqPhotoProductQuality from './scenes/faq/FaqPhotoProductQuality'
import FaqOtherQuestions from './scenes/faq/FaqOtherQuestions'
import FaqLargeVolume from './scenes/faq/FaqLargeVolume'
import FaqCorporateGifting from './scenes/faq/FaqCorporateGifting'
import FaqGifting from './scenes/faq/FaqGifting'
import UnitedWay from './scenes/UnitedWay'

const unsubRedirect = () => {
  window.location.href = 'https://manage.kmail-lists.com/subscriptions/unsubscribe?cy=JXs6Yw';
  return;
}

export default (
  <Switch>
    <Route exact path="/upload-photo" component={Upload}/>
    <Route exact path="/upload" component={Upload}/>
    <Route exact path="/customize" component={Upload}/>
    <Route exact path="/category/:id/:categoryName" component={Category}/>
    <Route path="/p/:activaProductId/:activaCampaignId" component={Redirects} status={301} />
    <Route path="/p/:activaProductId" component={Product} status={301} />
    <Route path="/s/:activaCampaignId" component={Redirects} status={301} />
    <Route path="/products/:categorySlug/:styleSlug/:productSlug/steps" component={Product} />
    <Route path="/products/:categorySlug/:styleSlug/:productSlug" component={Product} />
    <Route path="/products/:categorySlug/:styleSlug" component={Category} />
    <Route path="/products/:categorySlug" component={Category} />
    <Route exact path="/products" component={Products} />

    <Route exact path="/product/:id/:productName" component={Redirects} status={301} />
    <Route exact path="/product/:id/:productName/steps" component={Redirects} status={301} />

    <Route path="/articles/" component={Articles} />
    <Route path="/article/:articleSlug" component={Article} />

    <Route exact path="/cart" component={Cart}/>
    <Route exact path="/checkout" component={MyCheckout}/>
    <Route exact path="/order-success" component={OrderSuccess}/>
    <Route exact path="/response-instagram" component={LoadingPage}/>
    <Route exact path="/" component={Home}/>
    <Route exact path="/our-story" component={OurStory}/>
    <Route exact path="/services" component={Services}/>
    <Route exact path="/at-work" component={AtWork}/>
    <Route exact path="/reviews" component={Reviews}/>
    <Route exact path="/terms" component={TermsAndConditions}/>
    <Route exact path="/privacy" component={PrivacyPolicy}/>
    <Route exact path="/unsubscribe" component={unsubRedirect} />
    <Route exact path="/faq" component={FAQ}/>
    <Route exact path="/faq-shipping" component={FaqShippingTracking}/>
    <Route exact path="/faq-placing-order" component={FaqPlacingOrder}/>
    <Route exact path="/faq-secure" component={FaqSecurePersonalInfo}/>
    <Route exact path="/faq-quality" component={FaqPhotoProductQuality}/>
    <Route exact path="/faq-questions" component={FaqOtherQuestions}/>
    <Route exact path="/faq-large" component={FaqLargeVolume}/>
    <Route exact path="/faq-gifting" component={FaqGifting}/>
    <Route exact path="/faq-corporate" component={FaqCorporateGifting}/>
    <Route exact path="/bigorders" component={GiftingProgram}/>
    <Route exact path="/blackfriday" component={BlackFriday}/>
    <Route exact path="/cybermonday" component={CyberMonday}/>
    <Route exact path="/CorporateGiftingForm" component={CorporateGiftingForm}/>
    <Route exact path="/blackfriday2019" component={BlackFriday}/>
    <Route exact path="/cybermonday2019" component={CyberMonday}/>
    <Route exact path="/partners" component={Partners}/>
    <Route exact path="/how-to-myphoto" component={HowTo}/>
    <Route exact path="/promotions" component={Promotions}/>
    <Route exact path="/order-status" component={OrderStatus}/>
    <Route exact path="/about-us" component={AboutUs}/>
    <Route exact path="/free-mini-unitedway" component={UnitedWay}/>
    <Route exact path="/404" component={PageNotFound} status={404} />
    <Route exact path="/:categorySlug" component={Redirects} status={301} />
    <Route path="/express/home/:session" component={Redirects} status={301} />
    <Route path="/express/about/:sessionAbout" component={Redirects} status={301} />
    <Route path="/express/reviews/:sessionReviews" component={Redirects} status={301} />
    <Route path="/express/faq/:sessionFAQ" component={Redirects} status={301} />
    <Route path="/pages/1801140/:emaBS" component={Redirects} status={301} />
    <Route path="/express/:sessionUpload" component={Redirects} status={301} />
    <Route path="*" component={PageNotFound} status={404} />
  </Switch>
)
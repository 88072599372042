import React from 'react'
import autoBind from 'react-autobind'
import { path } from 'ramda'
import _ from 'lodash'
import { update_project, is_fetching } from '../../../actions'
import ProductOptions from './ProductOptions'

export class ProductOptionsWrapper extends React.Component {
  constructor(props) {
    super(props)
    autoBind(this)
  }
  handleOptionChange(id) { /// Put in ProductOptionsWrapper ???
    const { product, stateQty, dispatch, handleOptionsQty} = this.props
    const new_id = parseInt(id, 10)
    const item = _.head(this.props.items.filter(i => {
      return i.id === new_id;
    }));
    if (item) {
      handleOptionsQty(item)
      // // Dispatch here update_project with new item...
      if (this.props.project && this.props.project.project) {
        var new_project = JSON.parse(JSON.stringify(this.props.project));
        new_project.project.itemId = item.id;
        new_project.project.name = item.name;
        new_project.project.sku = item.sku;
        new_project.project.bleed = product.bleed;
        const new_qty = parseInt(new_project.qty || stateQty, 10);
        var new_total = (product.allowsPersonalization)
          ? item.price * (1 + (new_qty - 1) / 2)
          : item.price * new_qty
        // BUT HLN...
        // if(this.props.config.appConfig.subdomain === 'hln') new_total = item.price * new_qty * .5;
        new_project.project.amounts = { unitPrice: item.price, total: new_total }
        dispatch(update_project(this.props.project, new_project))
        dispatch(is_fetching())
      }
    }
  }
  render(){
    const { items, project, product, itemDefault } = this.props
    const overlayIsDisabled = product && product.overlayDisabled
    if (product && product.optionGroups) {
      return product.optionGroups.map((productOpt, index) => {
        const { name, position } = productOpt;
        const optionItems = product && product.optionItems
          // Show items only for the current option group
          .filter(({ groupName }) =>{
            return groupName === name})
          // Filter out items that have been disabled
          .filter(({ itemId }) => !!items.find(({ id }) => id === itemId))
          .map((item) => ({
            label: item.name,
            value: item.itemId,
            id: item.itemId,
            options: item.options,
            groupNo: position,
            swatch: item.swatch,
            position: item.position,
            isDefault: item.isDefault,
            groupName: item.groupName,
          }))
        const projectItemIdPath = path(['project', 'itemId'], project)
        const defaultValue = (projectItemIdPath &&
          optionItems.find(({ id }) => id === path(['project', 'itemId'], project))) // makes sure item is one of THESE products
          ? projectItemIdPath : itemDefault.id;
        const showAsSelector = (product.optionGroups[0].name === 'Size & Color')
        return (
            <div className="product-size" key={index}>
              <div key={index}>
                <ProductOptions
                  productOptionItems={product && product.optionItems}
                  value={defaultValue}
                  defaultOption={optionItems.find(({ isDefault }) => isDefault)}
                  name={name}
                  showAsSelector={showAsSelector}
                  options={optionItems}
                  callback={(option) => {
                    this.handleOptionChange(option)
                  }}
                  overlayIsDisabled={overlayIsDisabled}
                />
            </div>
          </div>
        );
      });
    } else {
      return "Loading..."
    }
  }
}
export default ProductOptionsWrapper
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import autoBind from 'react-autobind'
import { Collapse } from 'antd';
import FaqCollapseConatiner from '../../components/faq/FaqCollapseContainer'
import FaqCategoryWrapper from '../../components/faq/FaqCategoryWrapper'
import '../../assets/stylesheets/faq.scss'

const { Panel } = Collapse

class FaqOtherQuestions extends React.Component {
  constructor(props) {
      super(props)
      autoBind(this)
  }
  render() {
    const { shippingOptions } = this.props
    const fastShippingMethod = shippingOptions.find(o => o.id === 1)
      return (
        <FaqCategoryWrapper title='Other Questions'>
          <div className='faq-questions-container'>
            <FaqCollapseConatiner>
              <Panel header="What is the Money-Back Guarantee?" key="1">
                <p>With over 10,000 5-star reviews, we still understand the rare chance that an issue may result. If that's the case and you're not 100% satisfied with your order, DON'T WORRY! Contact our amazing support team with your issue and we will make it right or refund 100% of your order.</p>
              </Panel>
              <Panel header="How do I contact MyPhoto's Customer Experience team?" key="2">
                <p>The best way to reach our dedicated support staff is by emailing <a className="faq-link" href="mailto:hello@myphoto.com">&nbsp;hello@myphoto.com</a>. Include your order number and contact info and we'll get back to you as quickly as possible!&nbsp;
                {/** uncoment for phone info ** Our call center is available Monday-Friday from 9am-5pm EST by calling 800-485-176 **/}
                </p>
              </Panel>
              <Panel header="How do MyPhoto prices compare to other photo printing services?" key="3">
                <p>We are passionate about delivering top-quality products at everyday fair prices.&nbsp; 
                MyPhoto is about making memories, not playing "price games"&hellip;which is why we'll continue to bring you extremely fair priced product and ALWAYS free shipping on orders over ${fastShippingMethod && fastShippingMethod.free_cutoff}.
                </p>
              </Panel>
              <Panel header="How do I unsubscribe from your mailing list?" key="4">
                <p>Sorry to see you miss out on special offers and new product announcements! If you would like to unsubscribe, please click on the following <a className="faq-link" href="https://manage.kmail-lists.com/subscriptions/unsubscribe?cy=JXs6Yw">link</a>.
                </p>
              </Panel>
            </FaqCollapseConatiner>
          </div>
        </FaqCategoryWrapper>
      )
  }
}
function mapStateToProps(state) {
  return {
      photos: state.photos.photos,
      shippingOptions: state.config.appConfig.shippingOptions,
  };
}

export default withRouter(connect(mapStateToProps)(FaqOtherQuestions));
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { seoInfo } from '../helpers/seoInfo'
import { Link, withRouter } from 'react-router-dom'
import Slider from '../components/common/Slider'
import ProductsTabBar from '../components/common/tabBar/ProductsTabBar'
import MetaDataTags from '../helpers/ProcessMetaData'
import ProductList from '../components/homepage/ProductList'
import {montetateProductsTracker,setIntervalHelper} from '../helpers/monetate'
import '../assets/stylesheets/page-shell.scss'



const propTypes = {
  dispatch: PropTypes.func.isRequired,
  products: PropTypes.array,
  items: PropTypes.array,
  user: PropTypes.object,
  project: PropTypes.object,
}

class Products extends React.Component {

  shouldComponentUpdate() {
    return true
  }



  componentDidMount(){
    setIntervalHelper(montetateProductsTracker);
  }
  render() {
    const { photos, project } = this.props

    const hasPictures = photos.length && photos[0] && photos[0].id
    const hasProject = project && project.project && project.project.photopersonalization && project.project.photopersonalization[0] && project.project.photopersonalization[0].actualSize && project.project.photopersonalization[0].actualSize.width
    const firstPhoto = (hasProject)
      ? project.project.photopersonalization[0]
      : (hasPictures)
          ? photos[0]
          : null
    // console.log('firstPhoto: ', firstPhoto)
    return (
      <section className="shell-container main-container products-container">
        <MetaDataTags baseUrl='products' seoInfo={seoInfo.shopAll} />
        <div className="container">
          <div className="back-to-products-container">
            <Link to="/" className="back-to-products-link">
              <span className="back-to-products-text">Home</span>
            </Link> &gt;&nbsp;
              <span className="back-to-products-text">All Products</span>
          </div>
          {hasPictures 
          ? <Slider
              divClass="products__slider__holder"
              titleClass="photos__list__title"
              // title="Select a photo to preview on all products."
              className="column"
              autoSlide={true}
              loop={true}
              list={true}
              listItemSize={90}
              ease="ease-in-out"
              fixedHeight="45px"
              loadError={this.handleLoadError}
              itemClick={this.selectImage}
              remove={this.removePhotos}
              reverseOrder={true}
              showControls={false}
              showNav={false}
              bg={true}
            />
            : <ProductsTabBar />}
          <div id="products__list" className="products__list" itemScope itemType="http://schema.org/Product">
              <ProductList
                products={this.props.products}
                items={this.props.items}
                projectItems={this.props.projectItems}
                photo={firstPhoto}
                photos={photos}
                hasProject={hasProject}
              />
          </div>
          </div>
      </section>
    );
  }
}

Products.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    products: state.products.list,
    items: state.products.items,
    user: state.user.user,
    photo: state.photos.photos[0],
    photos: state.photos.photos,
    projectItems: state.projects.items,
    project: state.projects.project,
  }
}

export default withRouter(connect(mapStateToProps)(Products))

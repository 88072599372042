import React from 'react';
import PropTypes from 'prop-types'
import ContactText from './ContactText'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import './footer.scss'

const propTypes = {
  config: PropTypes.object,
}

class FooterSection extends React.Component {
  renderContactLinks() {
    const { contactLinks } = this.props;

    return contactLinks.map((link, index) => {
      const text = link.text || '';
      const href = link.link || '#';
      const newWindow = link.newWindow || false
      return (<span key={index}>
                {newWindow 
                  ? <a href={href} style={{fontWeight: '500'}} target="_blank" rel="noopener noreferrer">{text}</a>
                  : <a href={href} style={{fontWeight: '500'}}>{text}</a>}
                <br />
              </span>);
    });
  }

  render() {
    const { colwidth, title, text, contactText, readmore, readmoreLink,
      contactLinks, id } = this.props
    return (
      <div key={id} className={`column ${colwidth}`}>
          <h3>{title}</h3>
          <div>
            {text}
            {(contactText === 'true') ?
              <ContactText />
            : null}
            {(readmore === 'true') ?
              <span>
                  &nbsp;
                <a href={readmoreLink}>Read More</a>
                <br />
              </span>
            : null}
            </div>
            {(contactLinks) ?
              <div className='footer-contact-container'>
                {this.renderContactLinks()}
              </div>
            : null}
        </div>
    );
  }
}

FooterSection.propTypes = propTypes;

export default withRouter(connect((state) => {
  return {
    config: state.config.appConfig,
  };
})(FooterSection))

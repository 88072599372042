import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import FeaturedOn from './FeaturedOn'
import FooterSection from './FooterSection'
import Copyright from './Copyright'
import ConnectSection from './ConnectSection';
import './footer.scss'

export class Footer extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  }
  constructor(props, context) {
    super(props, context)
    this.state = {
      showFeatured: true,
    }
  }
  componentDidMount() {
    this.setState({
      showFeatured: true
    })
  }

  componentWillUnmount() {
    this.setState({ showFeatured: false })
  }

  render() {

    const { showFeatured, photos } = this.props;
    const hasPictures = photos && photos.length && photos[0] && photos[0].id
    const links = [
      { text: 'FAQ', link: '/faq' },
      { text: 'Best Sellers', link: '/products/best-sellers' },
      { text: 'Reviews', link: '/reviews' },
      { text: 'Affiliates', link: 'https://ascend.pepperjam.com/affiliate/registration.php?refid=197670', newWindow: true },
    ];

    const smileGuarantee = 'If you’re not happy and smiling after getting your order, tell us and we’ll take care of it. We promise.';

    const footerSectionProps = [
      {
        id: 'FS1',
        colwidth: 'is-4',
        title: '100% Money Back Guarantee',
        text: smileGuarantee
      },
      { id: 'FS3', colwidth: 'is-4', title: 'Contact Us', contactText: 'true' },
      { id: 'FS4', colwidth: 'is-2', title: 'More', contactLinks: links },
    ]
    const footerContainer = hasPictures ? 'footer-cont-hasimages' : 'footer-cont-noimages'
    return (
      <footer className={`${footerContainer}`}>
        {(!showFeatured) ?
          <FeaturedOn />
          : null}

        <div className="container footer-container">
          <div className="row content footer-columns">
            <ConnectSection />
            {footerSectionProps.map((item, index) => { return <FooterSection key={index} {...item} />; })}
          </div>
          <br />
        </div>
        <div className="bottom_line"></div>
        <Copyright />
      </footer>
    );
  }
}

function mapStateToProps(state) {
  return {
    showFeatured: state.showFeatured,
    photos: state.photos.photos,
  }
}

export default connect(mapStateToProps)(Footer)
import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { openModal } from '../actions'
import MetaDataTags from '../helpers/ProcessMetaData'
import addDefaultSrc from '../helpers/addDefaultSrc'
import '../assets/stylesheets/page-shell.scss'
import '../assets/stylesheets/black-friday.scss'

const propTypes = {
    user: PropTypes.object,
    seo: PropTypes.object,
}

class BlackFriday extends React.Component {
    static contextTypes = {
        router: PropTypes.object,
    }
    componentWillMount() {
        window.gtag('event', 'page_view', {
            'send_to': 'AW-668262331',
            'ecomm_pagetype': 'other'
        });
    }

    render() {
        const { seo } = this.props;
        return (
            <div className="shell-container main-container bf-main-container">
                <div className="columns container">
                    <div className="column is-10">
                        {seo && <MetaDataTags
                            baseUrl='blackfriday2018'
                            pagesMetaData={seo}
                            customData={{ name: "Black Friday Specials on Holiday Photo Gifts 2018", tagLine: "Black Friday 2018 Deals - Find special savings on personalized photo gifts for everyone on your holiday list. HD photos printed on wood, glass, acrylic, metal and more! Turn photos on your phone into memories that’ll be treasured for years to come & get free 5-day shipping!" }} />}
                        <div
                            style={{ cursor: "pointer" }}
                            className="reviews-banner"
                            onClick={(e) => {
                                this.setState({ showModal: true })
                                this.props.dispatch(openModal('UPLOAD_MODAL'))
                                e.preventDefault();
                            }}>
                            <img className="is-hidden-mobile" style={{ width: '100%' }} src="https://storage.googleapis.com/myphoto-img/blackfriday-hero-desktop.jpg" alt="black friday special offer" onError={addDefaultSrc} />
                            <img className="is-hidden-desktop is-hidden-tablet" style={{ width: '100%' }} src="https://storage.googleapis.com/myphoto-img/blackfriday-hero-mobile.jpg" alt="black friday special offer" onError={addDefaultSrc} />
                        </div>
                    </div>
                </div>
                <div className="bf-contaier-2">
                    <h3 className="galore-heading">PHOTO GIFTS GALORE</h3>
                    <p className="bf-text">Shop a variety of personalized photo products perfect for everyone on your holiday gift list. Our memorable photo gifts are handcrafted from high-quality metal, glass, acrylic, wood and more! Smiles guaranteed.</p>
                    <p className="bf-text">Best of all, you can preview your photo FREE on all our gifts and ordering is Crazy Easy. Crazy Fast.®</p>
                    <p className="bf-text">FREE 5-Day Shipping Anywhere in the U.S.</p>
                    <div className="all-products-btn-container">
                        <Link to="/products" className="all-products-btn">
                            Try It Now. It’s Easy.
                        </Link>
                    </div>
                    <img className="bf-ornament is-hidden-touch" src="https://storage.googleapis.com/myphoto-img/blackfriday-ornament.png" alt="black friday special offer" onError={addDefaultSrc} />
                </div>
                <div className="columns bf-contaier-3 container">
                    <div className="column is-10">
                        <img className="christmas-img" style={{ width: '100%', height: 'auto' }} src="https://storage.googleapis.com/myphoto-img/blackfriday-banner.jpg" alt="black friday special offer" onError={addDefaultSrc} />
                        <div className="all-products-btn-container">
                            <Link to="/products" className="all-products-btn">
                                See All Products
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user.user,
        seo: state.config.seoData,
    };
}

BlackFriday.propTypes = propTypes;
BlackFriday.contextTypes = {
    router: PropTypes.object.isRequired
}
export default withRouter(connect(mapStateToProps)(BlackFriday))

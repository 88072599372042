import React, { Suspense} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import _ from 'lodash'
import MetaDataTags from '../helpers/ProcessMetaData'
import Slider from '../components/common/Slider'
import { openModal} from '../actions'
import GenericTabBar from '../components/common/tabBar/GenericTabBar'
import CTAsection from '../components/homepage/CTAsection'
import CategoryLinks from '../components/homepage/CategoryLinks'
import addDefaultSrc from '../helpers/addDefaultSrc'
import {monetateCategoryTraacker,setIntervalHelper} from '../helpers/monetate'
import '../assets/stylesheets/page-shell.scss'
import '../assets/stylesheets/best-sellers.scss'

const propTypes = {
  user: PropTypes.object,
}
const ProductList = React.lazy(() => import('../components/homepage/ProductList'))
class Category extends React.Component {
  static contextTypes = {
    router: PropTypes.object,
  }

  componentDidMount(){
    setIntervalHelper(monetateCategoryTraacker);
      }
    
  
  componentWillMount() {
    window.gtag('event', 'page_view', {
      'send_to': 'AW-668262331',
      'ecomm_pagetype': 'other'
    }
  
   
    )
  
  
    const { params: { categorySlug, styleSlug } } = this.props.match
    this.setState({ categorySlug, styleSlug })
  }
   

  componentWillReceiveProps(nextProps) {
    const oldParams = this.props && this.props.match && this.props.match.url
    const newParams = nextProps && nextProps.match && nextProps.match.url
    if (newParams !== oldParams) {
      const { params: { categorySlug, styleSlug } } = nextProps.match
      this.setState({ categorySlug, styleSlug })
    }
  }
  render() {
    const { products, photos, dispatch, project } = this.props
    const { categorySlug, styleSlug } = this.state
    const productsWithCats = products.filter(p => p.category !== null)
    var categoryName = _.startCase(categorySlug)
    var productIds = []
    var BannerContent = {}
    var metaDescription = 'Shop our most popular, bestselling photo gifts and decor to find the best fit for your personal style. From classic wood frames to modern acrylic and metal prints, MyPhoto gives you the fastest and easiest solution for custom decor and gifts.'
    switch (categorySlug) {
      case 'minis':
        BannerContent = {
          title: "Mini Collection",
          text1: "For all of life's little moments...make a mini photo gift! They easily go anywhere in the home or office and every photo prints with perfect quality!",
          btnIcon: "https://storage.googleapis.com/myphoto-img/icons/arrow-right-icon.png",
          imageDesktop: "https://storage.googleapis.com/myphoto-img/category-banners/MINIS_CATEGORY_D.jpg", 
          imageMobile: "https://storage.googleapis.com/myphoto-img/category-banners/MINIS_CATEGORY_M.jpg", 
          showButton: true,
          mobileBottom: true
        }
        metaDescription = 'Mini photo gifts and decor print any photo perfectly clear. Both affordable and adorable, you can display memories anywhere in your home or office. Create the perfect gifts effortlessly for friends and family!'
        break
      case 'frames-canvas':
        categoryName = 'Frames & Canvas'
        BannerContent = {
          title: "Frames & Canvas Collection",
          btnIcon: "https://storage.googleapis.com/myphoto-img/icons/arrow-right-icon.png",
          imageDesktop: "https://storage.googleapis.com/myphoto-img/category-banners/FRAMES&CANVAS_D.jpg",
          imageMobile: "https://storage.googleapis.com/myphoto-img/category-banners/FRAMES&CANVAS_M.jpg",
          showButton: true
        }
        metaDescription = 'Print any photo on classic, beautiful frames and canvas. Your favorite photos can go straight from your phone to the wall in a variety of styles to fit with any decor.'
        break
      case 'new':
        categoryName = 'New Arrivals'
        BannerContent = {
          title: "New Arrivals",
          text1: "Looking for the latest and greatest photo gifts? You've come to the right place! Check out our newest assortment of handcrafted gifts and decor you can personalize instantly.",
          btnIcon: "https://storage.googleapis.com/myphoto-img/icons/arrow-right-icon.png",
          imageDesktop: "https://storage.googleapis.com/myphoto-img/category-banners/NEW_ARRIVALS_D.jpg",
          imageMobile: "https://storage.googleapis.com/myphoto-img/category-banners/NEW_ARRIVALS_M.jpg",
          showButton: true
        }
        metaDescription = 'Browse our newly released photo gifts and decor. Shop a variety of unique photo gifts made of wood, metal, glass, acrylic and more! Create personalized photo products effortlessly and instantly.'
        break
      case 'best-sellers':
        BannerContent = {
          title: "Best Sellers",
          text1: "Our customers have spoken! Here are the top rated products to turn your favorite photos into awesome gifts or decor for your home...",
          btnIcon: "https://storage.googleapis.com/myphoto-img/icons/arrow-right-icon.png",
          imageDesktop: "https://storage.googleapis.com/myphoto-img/category-banners/BESTSELLERS_CATEGORY_D.jpg", 
          imageMobile: "https://storage.googleapis.com/myphoto-img/category-banners/BESTSELLERS_CATEGORY_M.jpg",
          showButton: true,
          mobileBottom: true
        }
        metaDescription = 'Shop our most popular, bestselling photo gifts and decor to find the best fit for your personal style. From classic wood frames to modern acrylic and metal prints, MyPhoto gives you the fastest and easiest solution for custom decor and gifts.'
        break
      case 'metal':
        BannerContent = {
          title: "Metal Collection",
          btnIcon: "https://storage.googleapis.com/myphoto-img/icons/arrow-right-icon.png",
          imageDesktop: "https://storage.googleapis.com/myphoto-img/category-banners/METAL_D.jpg",
          imageMobile: "https://storage.googleapis.com/myphoto-img/category-banners/METAL_M.jpg",
          showButton: true
        }
        metaDescription = 'Print any photo on high quality metal prints. Our metal products amplify the sharpness and brightness of your favorite photos. Perfect for a modern, urban feel.'
        break
      case 'acrylic':
        BannerContent = {
          title: "Acrylic Collection",
          btnIcon: "https://storage.googleapis.com/myphoto-img/icons/arrow-right-icon.png",
          // imageDesktop: "https://storage.googleapis.com/myphoto-img/category-banners/ACRYLIC_D.jpg",
          // imageMobile: "https://storage.googleapis.com/myphoto-img/category-banners/ACRYLIC_M.jpg",
          imageDesktop: '/images/category-banners/AcrylicCollecion_PageHero_D.jpg',
          imageMobile: '/images/category-banners/AcrylicCollecion_PageHero_M.jpg',
          showButton: true
        }
        metaDescription = 'Print any photo on brilliant acrylic prints. Our acrylic products capture the light in a way that radiates through the photo, amplifying the image. Acrylic blocks are ultra-thick with a unique dimensional quality.'
        break
      case 'acrylic-block':
        BannerContent = {
          title: "Acrylic Block Collection",
          btnIcon: "https://storage.googleapis.com/myphoto-img/icons/arrow-right-icon.png",
          imageDesktop: "https://storage.googleapis.com/myphoto-img/category-banners/AcrylicCollecion_CategoryHero_D.jpg",
          imageMobile: "https://storage.googleapis.com/myphoto-img/category-banners/AcrylicCollecion_CategoryHero_M.jpg",
          showButton: true,
          mobileBottom: true
        }
        metaDescription = 'Print any photo on brilliant acrylic prints. Our acrylic products capture the light in a way that radiates through the photo, amplifying the image. Acrylic blocks are ultra-thick with a unique dimensional quality.'
        break
      case 'glass':
        BannerContent = {
          title: "Glass Collection",
          btnIcon: "https://storage.googleapis.com/myphoto-img/icons/arrow-right-icon.png",
          imageDesktop: "https://storage.googleapis.com/myphoto-img/category-banners/GLASS_D.jpg",
          imageMobile: "https://storage.googleapis.com/myphoto-img/category-banners/GLASS_M.jpg",
          showButton: true
        }
        metaDescription = 'Print any photo on thick, high-quality glass. Elegant, classy and modern, light shines through your photo enhancing the vibrancy and quality.'
        break
      case 'expressions':
        BannerContent = {
          title: "Expressions Collection",
          btnIcon: "https://storage.googleapis.com/myphoto-img/icons/arrow-right-icon.png",
          imageDesktop: "https://storage.googleapis.com/myphoto-img/category-banners/Glass_Collection_D.jpg",
          imageMobile: "https://storage.googleapis.com/myphoto-img/category-banners/Glass_Collection_M.jpg",
          showButton: true,
          mobileBottom: true
        }
        metaDescription = 'Print any photo on thick, high-quality glass. Elegant, classy and modern, light shines through your photo enhancing the vibrancy and quality.'
        break
      case 'wood':
        BannerContent = {
          title: "Wood Collection",
          btnIcon: "https://storage.googleapis.com/myphoto-img/icons/arrow-right-icon.png",
          imageDesktop: "https://storage.googleapis.com/myphoto-img/category-banners/WOOD_CAT_D.jpg",
          imageMobile: "https://storage.googleapis.com/myphoto-img/category-banners/WOOD_CAT_M.jpg",
          showButton: true
        }
        metaDescription = 'Print any photo on rustic, vibrant wood. From classic frames to unique bases and stands, wood photo products give you the versatility to make any photo in your favorite style.'
        break
      case 'wall':
        BannerContent = {
          title: "Wall Collection",
          btnIcon: "https://storage.googleapis.com/myphoto-img/icons/arrow-right-icon.png",
          imageDesktop: "https://storage.googleapis.com/myphoto-img/category-banners/Wall_Collection_D.jpg",
          imageMobile: "https://storage.googleapis.com/myphoto-img/category-banners/Wall_Collection_M.jpg",
          showButton: true,
          mobileBottom: true
        }
        metaDescription = 'Shop photo decor for your walls. From classic wood frames to modern acrylic and metal prints, MyPhoto gives you the fastest and easiest solution for custom decor for your home or office.'
        break
      case 'table-top':
        categoryName = 'Shelf & Desk'
        BannerContent = {
          title: "Shelf & Desk Collection",
          btnIcon: "https://storage.googleapis.com/myphoto-img/icons/arrow-right-icon.png",
          imageDesktop: "https://storage.googleapis.com/myphoto-img/category-banners/ShelfCollection_Hero_D.jpg",
          imageMobile: "https://storage.googleapis.com/myphoto-img/category-banners/ShelfCollection_Hero_M.jpg",
          showButton: true,
          mobileBottom: true
        }
        metaDescription = 'Shop photo decor for your desks, tables and shelves. Made from wood, acrylic, bamboo, metal, and more, MyPhoto gives you the fastest and easiest solution for custom gifts and decor.'
        break
      case 'modern':
        BannerContent = {
          title: "Modern Collection",
          btnIcon: "https://storage.googleapis.com/myphoto-img/icons/arrow-right-icon.png",
          imageDesktop: "https://storage.googleapis.com/myphoto-img/category-banners/MODERN_D.jpg",
          imageMobile: "https://storage.googleapis.com/myphoto-img/category-banners/MODERN_M.jpg",
          showButton: true,
          mobileBottom: true
        }
        metaDescription = 'Shop our collection of modern photo gifts and decor. Keep your style current with your favorite photos on unique decor for your home or gift for a loved one.'
        break
      case 'classic':
        BannerContent = {
          title: "Classic Collection",
          btnIcon: "https://storage.googleapis.com/myphoto-img/icons/arrow-right-icon.png",
          imageDesktop: "https://storage.googleapis.com/myphoto-img/category-banners/CLASSIC_D.jpg",
          imageMobile: "https://storage.googleapis.com/myphoto-img/category-banners/CLASSIC_M.jpg",
          showButton: true,
          mobileBottom: true
        }
        metaDescription = 'Shop our collection of classic photo gifts and decor. Any photo can be transformed into a classic piece for your home or gift for a loved one.'
        break
      case 'ornaments':
        BannerContent = {
          title: "Ornaments Collection",
          btnIcon: "https://storage.googleapis.com/myphoto-img/icons/arrow-right-icon.png",
          imageDesktop: "https://storage.googleapis.com/myphoto-img/category-banners/Ornaments_CategoryHero_D.jpg",
          imageMobile: "https://storage.googleapis.com/myphoto-img/category-banners/Ornaments_CategoryHero_M.jpg",
          showButton: true,
          mobileBottom: true
        }
        metaDescription = 'The perfect holiday gift is already on your phone! Transform photo memories into personalized wood, metal or glass ornaments. Perfect for your Christmas tree or to display year round.'
        break
      case 'collage':
        BannerContent = {
          title: "Collage Collection",
          btnIcon: "https://storage.googleapis.com/myphoto-img/icons/arrow-right-icon.png",
          imageDesktop: "https://storage.googleapis.com/myphoto-img/category-banners/CollageCollection/D_CollageCollection_HERO.jpg",
          imageMobile: "https://storage.googleapis.com/myphoto-img/category-banners/CollageCollection/M_CollageCollection_HERO.jpg",
          showButton: true,
          mobileBottom: true
        }
        metaDescription = 'Turn all of your favorite photos into a personalized collage. With dozens of designs and expressions to choose from, you can instantly and effortlessly make brilliant gifts and décor from your most special memories.'
        break
      case 'magnets':
        BannerContent = {
          title: "Magnet Collection",
          btnIcon: "https://storage.googleapis.com/myphoto-img/icons/arrow-right-icon.png",
          imageDesktop: "https://storage.googleapis.com/myphoto-img/category-banners/MagnetCollection/D_MagnetCollection_PageHERO.jpg",
          imageMobile: "https://storage.googleapis.com/myphoto-img/category-banners/MagnetCollection/M_MagnetCollection_PageHERO.jpg",
          showButton: true,
          mobileBottom: true
        }
        metaDescription = 'Collect, connect and display our new, brilliant mini magnetic photos. With accessories including chalkboards and wall hangers, you can place these magnets anywhere in your home!'
        break
      case 'office':
        BannerContent = {
          title: "Office Collection",
          btnIcon: "https://storage.googleapis.com/myphoto-img/icons/arrow-right-icon.png",
          imageDesktop: "https://storage.googleapis.com/myphoto-img/category-banners/OfficeCategoryD.jpg",
          imageMobile: "https://storage.googleapis.com/myphoto-img/category-banners/OfficeCategoryM.jpg",
          showButton: true,
          mobileBottom: true
        }
        metaDescription = 'Turn all of your favorite photos into personalized office products.'
        break
      case 'gift-guide':
        BannerContent = {
          title: "Gift Guide",
          btnIcon: "https://storage.googleapis.com/myphoto-img/icons/arrow-right-icon.png",
          imageDesktop: "/images/category-banners/GiftGuide_Page_Hero_D.jpg",
          imageMobile: "/images/category-banners/GiftGuide_Page_Hero_M.jpg",
          showButton: true,
          mobileBottom: true
        }
        metaDescription = 'The perfect gift is already on your phone! Shop our favorite photo gifts for any time of year. Instantly and effortlessly turn photos from your phone into memorable, affordable gifts for everyone on your shopping list.'
        break
      case 'mothers-day':
        categoryName = "Mother's Day Gift Guide"
        productIds = [220, 211, 3, 218, 219, 173]
        BannerContent = {
          imageDesktop: "https://storage.googleapis.com/myphoto-img/GiftGuide_MothersDay_Desktop.jpg",
          imageMobile: "https://storage.googleapis.com/myphoto-img/GiftGuide_MothersDay_Mobile.jpg"
        }
        break
      case 'holiday-gift-guide':
        categoryName = "Holiday Gift Guide"
        productIds = [220, 211, 3, 218, 219, 173]
        BannerContent = {
          imageDesktop: "https://storage.googleapis.com/myphoto-img/giftguide-desktop.jpg",
          imageMobile: "https://storage.googleapis.com/myphoto-img/giftguide-mobile.jpg"
        }
        break
      case 'fathers-day':
        categoryName = "Father's Day Gift Guide"
        productIds = [220, 211, 3, 218, 219, 173]
        break
      default:
        break
    }
    var displayProducts = []
    if (productIds.length) {
      displayProducts = products.filter(p => productIds.includes(p.id))
    } else {
      displayProducts = productsWithCats.filter(p => (p.category.toLowerCase() === categorySlug.toLowerCase() || p.attributes.includes(categorySlug.toLowerCase())))
    }
    var styleName = ''
    if (styleSlug) {
      displayProducts = displayProducts.filter(p => (p.attributes.includes(styleSlug.toLowerCase()) || p.category.toLowerCase() === styleSlug.toLowerCase()))
      styleName = (styleSlug === 'frames-canvas') 
        ? 'Frames & Canvas' 
        : (styleSlug === 'table-top') 
          ? 'Shelf & Desk' 
          : _.startCase(styleSlug)
    }
    if (displayProducts.length === 0) {
      // if no product found, redirect to 404 page
      window.location.assign('/404')
    }
    const mobileText = BannerContent.mobileBottom ? 'text-container-bottom' : ''
    const hasPictures = photos.length && photos[0] && photos[0].width
    const hasProject = project && project.project && project.project.photopersonalization && project.project.photopersonalization[0] && project.project.photopersonalization[0].actualSize && project.project.photopersonalization[0].actualSize.width
    return (
      <div className="shell-container products-container best-main-container">
        <MetaDataTags baseUrl={window.location.origin}
          productCategory={categorySlug}
          productStyle={styleSlug}
          seoInfo={{
            title: `${categoryName} – Your Photos on High-Quality Glass, Metal, Wood, and Metal | MyPhoto`,
            property: categoryName,
            metaDescription
          }} />
        {(BannerContent.imageDesktop && !styleSlug) &&
          <div className="best-banner-container" onClick={(e) => { this.setState({ showModal: true }); dispatch(openModal('UPLOAD_MODAL')) }}>
            <span className={`best-text-container ${mobileText}`}>
              {BannerContent.icon && <img className="best-icon" alt="quiestion mark" onError={addDefaultSrc} src={BannerContent.icon} />}
              {BannerContent.additionalTitle && <h1 className="best-banner-additional-title">{BannerContent.additionalTitle}</h1>}
              {BannerContent.title && <h1 className="best-banner-title">{BannerContent.title}</h1>}
              {BannerContent.text1 && <p className="best-banner-text-1">{BannerContent.text1}</p>}
              {BannerContent.showButton && <button
                style={{ cursor: "pointer" }}
                className="try-it-btn is-hidden-mobile"> Upload your photo. It's Easy. {BannerContent.btnIcon &&
                  <img className="free-preview-icon" alt="" onError={addDefaultSrc} src={BannerContent.btnIcon} />}
              </button>}
            </span>
            {BannerContent.imageMobile && <img className="best-img-mobile is-hidden-desktop is-hidden-tablet" alt="banner-img" onError={addDefaultSrc} src={BannerContent.imageMobile} />}
            {BannerContent.imageDesktop && <img className="best-img-desktop is-hidden-mobile" alt="banner-img" onError={addDefaultSrc} src={BannerContent.imageDesktop} />}
          </div>
        }
        <div className="container category-container">
          <div className="back-to-products-container">
            <Link to="/" className="back-to-products-link">
              <span className="back-to-products-text">Home</span>
            </Link> &gt;&nbsp;
                        <Link to="/products" className="back-to-products-link">
              <span className="back-to-products-text">All Products</span>
            </Link> &gt;&nbsp;
                        <Link to={`/products/${categorySlug}`} className="back-to-products-link">
              <span className="back-to-products-text">{categoryName}</span>
            </Link>
            {styleSlug &&
              <span>
                &nbsp;&gt; <Link to={`/products/${categorySlug}/${styleSlug}`} className="back-to-products-link">
                  <span className="back-to-products-text">{styleName}</span>
                </Link>
              </span>}
          </div>
          {hasPictures
            ? <Slider
              divClass="products__slider__holder"
              titleClass="photos__list__title"
              // title="Select a photo to preview on all products."
              className="column"
              autoSlide={true}
              loop={true}
              list={true}
              listItemSize={90}
              ease="ease-in-out"
              fixedHeight="45px"
              loadError={this.handleLoadError}
              itemClick={this.selectImage}
              remove={this.removePhotos}
              reverseOrder={true}
              showControls={false}
              showNav={false}
              bg={true}
            />
            : <GenericTabBar />}
          <Suspense fallback={<div>Loading...</div>}>
            {(displayProducts.length) && <div id="products__list" className="products__list" itemScope itemType="http://schema.org/Product">
              <ProductList
                products={displayProducts}
                items={this.props.items}
                projectItems={this.props.projectItems}
                photo={photos[0]}
                photos={photos}
                hasProject={hasProject}
              /></div>
            }
          </Suspense>
        </div>
        <CTAsection hasPictures={hasPictures} />
        <div style={{marginBottom: '10px', width: '100%'}}></div>
        <CategoryLinks showAll={true} />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    products: state.products.list,
    items: state.products.items,
    photos: state.photos.photos,
    projectItems: state.projects.items,
    project: state.projects.project,
  };
}

Category.propTypes = propTypes;
Category.contextTypes = {
  router: PropTypes.object.isRequired
}
export default withRouter(connect(mapStateToProps)(Category))
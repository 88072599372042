import React from 'react'
import EditPhotoBtn from './EditPhotoBtn'
import AddToCartBtn from './AddToCartBtn'

export default ({ hasUploads, project, isMultiPhoto, zoomed, inlineEditor,
  zoomIndex, product, addDefaultSrc, dispatch, inlineEditorToggle, handleAddToCartClick,
  item, config, handlePreviewModal, showAddToCartModal, uploadPhotos, allowsPersonalization,
  croppedEdits, user, filter, saveEditedValues, resetValues, isTabbar}) => {
  const btnFlexDirection = (inlineEditor && !isTabbar) ? 'column' : 'row'
  const saveEditsBtn = inlineEditor ? 'save-edits-btn' : ''
  return (
    <div className="tab-bar-level" style={{flexDirection: btnFlexDirection}}>
      {(!hasUploads && allowsPersonalization) &&
        <div className="tab-bar-level-item">
          <button
            onClick={uploadPhotos}
            type="button"
            className="cta-btn easy-btn"
            id="customize-btn"
          >
            {isMultiPhoto ? 'Upload your photos. It\'s Easy.' : 'Upload your photo. It\'s Easy.'}
            <img className='tab-bar-arrow-icon-product' onError={addDefaultSrc} src={'https://storage.googleapis.com/myphoto-img/icons/arrow-right-icon.png'} alt='' />
          </button>
        </div>
      }
      {(hasUploads && allowsPersonalization && (!isMultiPhoto || inlineEditor))
        ? <div className="tab-bar-level-item">
          <EditPhotoBtn
            inlineEditor={inlineEditor}
            isMultiPhoto={isMultiPhoto}
            zoomed={zoomed}
            dispatch={dispatch}
            zoomIndex={zoomIndex}
            project={project}
            inlineEditorToggle={inlineEditorToggle}
            resetValues={resetValues}
            saveEditedValues={saveEditedValues}
          /></div>
        : null}
      {(!allowsPersonalization || hasUploads) // show add to cart button
        ? <div className={`tab-bar-level-item ${saveEditsBtn}`}>
          <AddToCartBtn
            hasUploads
            isMultiPhoto={isMultiPhoto}
            zoomed={zoomed}
            dispatch={dispatch}
            project={project}
            item={item}
            product={product}
            inlineEditor={inlineEditor}
            appConfig={config && config.appConfig}
            handlePreviewModal={handlePreviewModal}
            inlineEditorToggle={inlineEditorToggle}
            showAddToCartModal={showAddToCartModal}
            handleAddToCartClick={handleAddToCartClick}
            zoomIndex={zoomIndex}
            croppedEdits={croppedEdits} 
            user={user} 
            filter={filter}
            saveEditedValues={saveEditedValues}
          /></div>
        : null}
    </div>
  )
}
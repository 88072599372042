import React from 'react'
import addDefaultSrc from '../../../helpers/addDefaultSrc'

export const SizeError = ({hasUploads, hasProject, project, item, isMultiPhoto, zoomed, numPhotos, 
  allowsPersonalization, zoomIndex, openWarningModal, lowResIndexArr, pushLowResArr }) => {
    var hasLowResImg = false
    const projectPhotoPersonalization = project && project.project && project.project.photopersonalization.slice(0, numPhotos)
    const hasImage = project && project.project && project.project.photopersonalization && project.project.photopersonalization[zoomIndex] && project.project.photopersonalization[zoomIndex].url ? true : false
    projectPhotoPersonalization.forEach((p, i) => {
      if (p && p.actualSize && p.actualSize.width && p.actualSize.height && p.thumbSize && p.thumbSize.width && p.thumbSize.height) {
        const maxWidth = (hasProject)
          ? (p.imageWidth)
            ? (p.imageWidth / 100) * p.actualSize.width / item.printData.printMinPpi
            : p.actualSize.width / item.printData.printMinPpi
          : 999;
        const maxHeight = (hasProject)
          ? (p.imageHeight)
            ? (p.imageHeight / 100) * p.actualSize.height / item.printData.printMinPpi
            : p.actualSize.height / item.printData.printMinPpi
          : 999;
        const productWidth = (hasProject)
          ? (p.actualSize.width > p.actualSize.height)
            ? item.printData.landscape[i].width
            : item.printData.portrait[i].width
          : item.printData.portrait[i].width;
        const productHeight = (hasProject)
          ? (p.actualSize.width > p.actualSize.height)
            ? item.printData.landscape[i].height
            : item.printData.portrait[i].height
          : item.printData.landscape[i].height;
        if (productWidth > maxWidth || productHeight > maxHeight) {
          hasLowResImg = true
          pushLowResArr(i)
        }
      }
    })
    //   SINGLE PRODUCT IMAGE RESOLUTION: 
    if (hasProject && hasUploads && !isMultiPhoto && allowsPersonalization) {
      if (hasLowResImg) {
        return (
          <p className="image-warning-message">
            <img className="warning-img" onError={addDefaultSrc} src="https://storage.googleapis.com/myphoto-img/icons/size_error_warning_icon.svg" alt="warning" />
            <span
              onClick={(e) => {
                e.preventDefault()
                openWarningModal()
              }}
            >&nbsp;<span>Your photo may not print well at this size.</span>
              &nbsp;
                <span className="link-learn-more">Learn More</span>
            </span>
          </p>
        )
      } else {
        return (
          <p className="image-good-message">
            <span><span><img className="thumb-img" onError={addDefaultSrc} src="https://storage.googleapis.com/myphoto-img/icons/size_error_thumb_icon.svg" alt="thumbs up" /></span>
              &nbsp;{"Your photo's resolution will work at this size. Adjust the position or crop using the EDIT PHOTO button below."}</span>
          </p>
        )
      }
    }
    //   MULTIPRODUCT IMAGE RESOLUTION (NOT ZOOMED): 
    if (hasProject && hasUploads && isMultiPhoto && !zoomed && allowsPersonalization) {
      if (hasLowResImg) {
        return (
          <p className="image-warning-message">
            <img className="warning-img" onError={addDefaultSrc} src="https://storage.googleapis.com/myphoto-img/icons/size_error_warning_icon.svg" alt="warning" />
            <span
              onClick={(e) => {
                e.preventDefault()
                openWarningModal()
              }}
            >&nbsp;<span>Selected photo(s) may not print well at this size.</span>
              &nbsp;
            <span className="link-learn-more">Learn More</span>
            </span>
          </p>
        )
      } else {
        return (
          <p className="image-good-message">
            <span><span><img className="thumb-img" onError={addDefaultSrc} src="https://storage.googleapis.com/myphoto-img/icons/size_error_thumb_icon.svg" alt="thumbs up" /></span>
              &nbsp;{"All photo's resolution will work at this size."}</span>
          </p>)
      }
    }
    //   ZOOMED!!! MULTIPRODUCT IMAGE RESOLUTION: 
    if (hasProject && hasUploads && isMultiPhoto && zoomed && allowsPersonalization) {
      if (hasLowResImg && lowResIndexArr.includes(zoomIndex)) {
        return (
          <p className="image-warning-message">
            <img className="warning-img" onError={addDefaultSrc} src="https://storage.googleapis.com/myphoto-img/icons/size_error_warning_icon.svg" alt="warning" />
            <span
              onClick={(e) => {
                e.preventDefault()
                openWarningModal()
              }}
            >&nbsp;<span>Your photo may not print well at this size.</span>
              &nbsp;
            <span className="link-learn-more">Learn More</span>
            </span>
          </p>
        )
      } else if (!hasImage) {
        return (null)
      } else {
        return (
          <p className="image-good-message">
            <span><span><img className="thumb-img" onError={addDefaultSrc} src="https://storage.googleapis.com/myphoto-img/icons/size_error_thumb_icon.svg" alt="thumbs up" /></span>
              &nbsp;{"Your photo's resolution will work at this size. Adjust the position or crop using the EDIT PHOTO button below."}</span>
          </p>)
      }
    }
}

export default SizeError
import React from 'react'
import autoBind from 'react-autobind'
import { updateZoom } from '../../actions/'
import '../../assets/stylesheets/print-preview.scss'
import ThumbChild from './ThumbChild'


class PrintPreviewModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLandscape: true
    }
    autoBind(this);
  }

  handler(isLandscape) {
    this.setState({
      isLandscape: isLandscape
    })
  }
  handleEditBtn(e){
    const { handleClosePreviewModal, isMultiphoto, openInlineEditor, dispatch } = this.props
    e.preventDefault() 
    handleClosePreviewModal()
      if (isMultiphoto) {
        dispatch(updateZoom(0, true))
      }else{
        openInlineEditor()
      } 
  }
  render(){
    const { item, project, handleClosePreviewModal, handleAddToCartClick, isMultiphoto, zoomIndex, overlayIsDisabled } = this.props
    const { isLandscape } = this.state
    const printHeight = isLandscape ? item.printData.landscape[zoomIndex].height : item.printData.portrait[zoomIndex].height
    const printWidth = isLandscape ? item.printData.landscape[zoomIndex].width : item.printData.portrait[zoomIndex].width
    const divPreviewHeight = ((printHeight * 220)/ printWidth) 
    const printX = isLandscape ? item.printData.landscape[zoomIndex].x : item.printData.portrait[zoomIndex].x
    const printY = isLandscape ? item.printData.landscape[zoomIndex].y : item.printData.portrait[zoomIndex].y
    const divPreviewX = (printX/item.printWidth) * 220
    const divPreviewY = (printY/item.printHeight) * 220
    return(
      <div className='preview-main-cont'>
        <div className='preview-header-container'>
          <h1 className='preview-title'>Print Preview -</h1>
          <h2 className='preview-product-name'>{item.name}</h2>
          {!isMultiphoto 
            ? <p className='preview-text'>Your photo will print exactly as it appears below. <span className='second-paragraph'>Click the button below to approve or edit your photo.</span></p>
            : <p className='preview-text'>Your photos will print exactly as they appear below. <span className='second-paragraph'>Scroll down and click the button below to approve or edit your photos.</span></p> }
        </div>
        <div className='preview-content'>
          <div className='preview-images-container'>
          { item.previewShape 
              ? isMultiphoto // has PrintPriview image and is Multiphoto
                ? <div className='preview-shape-image-cont' style={{height: '220px'}}>
                    <img className='preview-shape-image' src={item.previewShape} alt='print preview'/>
                    {item.printData.landscape.map((p, i) =>{
                      const this_divPreviewX = (p.x/item.printWidth) * 220
                      const this_divPreviewY = (p.y/item.printHeight) * 220 
                      return <div style={{position: 'absolute', top: this_divPreviewY, left: this_divPreviewX}}>
                                <ThumbChild 
                                  item={item}
                                  project={project.project}
                                  isMultiphoto={isMultiphoto}
                                  i={i}
                                  printPreview
                                  handler={this.handler} // getting isLandscape from ThumbChild
                                /> </div>}
                            )}
                  </div>
                : <div className='preview-shape-image-cont' style={{height: divPreviewHeight,}}>
                    <img className='preview-shape-image' src={item.previewShape} alt='print preview'/>
                    <div style={{position: 'absolute', top: divPreviewY, left: divPreviewX}}>
                      <ThumbChild 
                        item={item}
                        project={project.project}
                        isMultiphoto={isMultiphoto}
                        i={zoomIndex}
                        printPreview
                        hasPreviewShape
                        handler={this.handler} // getting isLandscape from ThumbChild
                        overlayIsDisabled={overlayIsDisabled}
                      />
                    </div>
                  </div>
              : isMultiphoto // NO PrintPreview image and is Multiphoto
                ? item.printData.landscape.map((p, i) =>{
                  const this_printHeight = isLandscape ? item.printData.landscape[i].height : item.printData.portrait[i].height
                  const this_printWidth = isLandscape ? item.printData.landscape[i].width : item.printData.portrait[i].width    
                  const this_divPreviewHeight = ((this_printHeight * 220)/ this_printWidth)  
                  return <div className='preview-image' style={{height: this_divPreviewHeight}}>
                            <ThumbChild 
                              item={item}
                              project={project.project}
                              isMultiphoto={isMultiphoto}
                              i={i}
                              printPreview
                              handler={this.handler} // getting isLandscape from ThumbChild
                            />
                          </div>}
                        )
              : <div className='preview-image' style={{height: divPreviewHeight}}>
                  <ThumbChild 
                    item={item}
                    project={project.project}
                    isMultiphoto={isMultiphoto}
                    i={zoomIndex}
                    printPreview
                    handler={this.handler} // getting isLandscape from ThumbChild
                    overlayIsDisabled={overlayIsDisabled}
                  />
                </div>
            }
          </div>
          <div className='preview-ctas'>
            <div className='preview-back-btn' 
              onClick={(e => this.handleEditBtn(e) )}>GO BACK / EDIT</div>
            <div className='preview-approve-btn' 
              onClick={(e =>{ e.preventDefault(); handleAddToCartClick(); handleClosePreviewModal()})}>APPROVE</div>
          </div>
        </div>
      </div>
    )
  }
}

export default PrintPreviewModal
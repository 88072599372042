import React from 'react'
import addDefaultSrc from '../../../helpers/addDefaultSrc'
import '../../../assets/stylesheets/guarantee-shipping-modal.scss'

class GuaranteeShippingModal extends React.Component{
  constructor(props) {
    super(props);
    const { shippingOptions } = props
    const fastShippingMethod = shippingOptions.find(o => o.id === 1)
    //const crazyFastShippingMethod = shippingOptions.find(o => o.id === 2)
    this.state = {
      content :{
        imageTop: 'https://storage.googleapis.com/myphoto-img/icons/Shipping_Plane-01.svg',
        imageBottom: 'https://storage.googleapis.com/myphoto-img/icons/FedEx_Icon-01-01.svg',
        title: "Fast free shipping",
        subtitle: `on any order $${fastShippingMethod.free_cutoff}+`,
        columns: [{
          icon: "https://storage.googleapis.com/myphoto-img/icons/guarantee-shipping.svg",
          title: "5-Day Delivery",
          type: `$${fastShippingMethod.free_cutoff} and up`,
          price: "Free",
          color: "#ee6f6f",
          disabled: fastShippingMethod.disabled,
        },{
          icon: "https://storage.googleapis.com/myphoto-img/icons/guarantee-shipping.svg",
          title: "5-Day Delivery",
          type: `Under $${fastShippingMethod.free_cutoff}`,
          price: `$${fastShippingMethod.price}`,
          color: "#272727",
          disabled: fastShippingMethod.disabled,
        // },{
        //   icon: "https://storage.googleapis.com/myphoto-img/icons/guarantee-extra-fast-shipping.svg",
        //   title: "CRAZY Fast 3-Day",
        //   type: "No Minimum",
        //   price: `$${crazyFastShippingMethod && crazyFastShippingMethod.price}`,
        //   color: "#272727",
        //   disabled: crazyFastShippingMethod && crazyFastShippingMethod.disabled,
        }],
        footer: "FedEx automatically applied at checkout except to PO Boxes and Puerto Rico. We do not yet ship to international addresses."
      }
    }
  }
  render(){
    const { content } = this.state
    return(
      <div className="shipping-modal-container">
        <div className="shipping-main-container">
          <img className='shipping-image' onError={addDefaultSrc} src={content.imageTop} alt=''/>
          <h1 className="shipping-title">{content.title}</h1>
          <p className="shipping-subtitle">{content.subtitle}</p>
        </div>
        <div className="shipping-guarantee-container">
        {content.columns.map((column, index)=>{
          if(column.disabled !== false){
            return null
          } else {
            return(
              <div className="shipping-column" key={index}>
                <span className="shipping-column-title-cont">
                  <h1 className="shipping-column-title">{column.title}</h1>
                </span>
                <p className="shipping-column-type">{column.type}</p>
                <p 
                className="shipping-column-price" 
                style={{color: column.color}}
                >{column.price}</p>
              </div>
            )}
        })}
        </div>
        <div className="shipping-footer-container">
          <img className='shipping-bottom-image' onError={addDefaultSrc} src={content.imageBottom} alt=''/>
          <spam>
            <p className="shipping-footer-text bold">Automatically applied at checkout.</p>
            <p className="shipping-footer-text">{content.footer}</p>
          </spam>
        </div>
      </div>
    )
  }

}

export default GuaranteeShippingModal
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { openModal, closeModal } from '../actions'
import { default as ReactModal } from 'react-modal'
import CorporateGiftingForm from './CorporateGiftingForm'
import MetaDataTags from '../helpers/ProcessMetaData'
import { seoInfo } from '../helpers/seoInfo'
import addDefaultSrc from '../helpers/addDefaultSrc'
import TabBar from '../components/common/tabBar/TabBar'
import '../assets/stylesheets/page-shell.scss'
import '../assets/stylesheets/gifting-program.scss'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: '-45%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
}

class GiftingProgram extends Component {

    static contextTypes = {
        router: PropTypes.object,
    }
    handleChange() {
        this.props.dispatch(openModal('CORPORATE_GIFTING_MODAL'));
    }
    getStartedBtn(){
        return(
        <button
            type="button"
            className="get-started-btn"
            onClick={() => { this.handleChange() }}
        >
            <span>GET STARTED</span>
            <img className="arrow-icon" alt="" src='https://storage.googleapis.com/myphoto-img/icons/arrow-right-icon.png' onError={addDefaultSrc}/>
        </button>   
        )
    }
    render() {
        return (
            <div className="shell-container main-container">
                <MetaDataTags baseUrl='bigorders' seoInfo={seoInfo.corporateGifting} />
                <div className="columns">
                    <section className="column is-10">
                        <div className="gifting-banner-container">
                            <img className="gifting-banner is-hidden-mobile" src="https://storage.googleapis.com/myphoto-img/corporate-gifting-banner.png" alt="Banner" onError={addDefaultSrc}/>
                            <img className="gifting-banner is-hidden-desktop is-hidden-tablet" src="https://storage.googleapis.com/myphoto-img/corporate-gifting-banner-mobile.jpg" alt="Banner" onError={addDefaultSrc}/>
                            <div className="gifting-banner-text">
                                <h2 className="gifting-banner-title" >Corporate Gifting Services</h2>
                                <p className="gifting-banner-subheader">This holiday, your employees and clients won't forget what you gave them. They all took at least one meaningful photo - let them make it into a gift from you.</p>
                            </div>
                        </div>
                        <div className="gifting-text-container">
                            <h1 className="gifting-heading">Corporate Gifting Made Easy</h1>
                            <p className="gifting-text">We're providing volume discounts on MyPhoto gift cards so you can get the most out of your budget—and your clients and employees can purchase personalized, handcrafted photo gifts and decor they'll treasure for years to come.</p>
                            <p className="gifting-text">We've made turning photos into memories Crazy Easy. Crazy Fast.&trade; Anyone can do it!</p>
                        </div>
                        <img className="gifting-banner" src="https://storage.googleapis.com/myphoto-img/corporate-gifting-banner2.png" alt="Banner" onError={addDefaultSrc}/>
                        <div className="columns is-gapless gifting-nextstep-container ">
                            <div className="column is-7">
                                <div className="gifting-text-container align-left next-step-container">
                                    <h1 className="gifting-heading">Next Steps</h1>
                                    <p className="gifting-text">1. Click GET STARTED below and fill out a quick form.</p>
                                    <p className="gifting-text">2. Or, place your order now by&nbsp;
																			<a href="/downloads/Corporate_Gift_Card_Development-Order_Form.pdf" download className="form-download">downloading our easy order form</a>. A corporate gifting concierge will contact you to verify and finalize your order.</p>
                                    <p className="gifting-text ">3. Watch your recipients smile with genuine happiness!</p>
                                    <span className="is-hidden-mobile">
                                        {this.getStartedBtn()}
                                    </span>
                                </div>
                            </div>
                            <div className="column is-5">
                                <img className="gifting-banner" src="https://storage.googleapis.com/myphoto-img/corporate-gifting-box.png" alt="Banner" onError={addDefaultSrc}/>
                            </div>
                        </div>
                        <div className="columns is-gapless gifting-about-container">
                            <div className="column is-7">
                                <div className="gifting-text-container align-left">
                                    <h1 className="gifting-heading">About MyPhoto</h1>
                                    <p className="gifting-text">Our mission is to be the world's easiest and fastest way to turn photos on your phone into personalized, high-quality gifts and decor.</p>
                                    <p className="gifting-text">At MyPhoto, we're all about preserving memories and moments that matter. We've made it Crazy Easy. Crazy Fast.&trade; to order a variety of handcrafted photo products printed on glass, acrylic, metal, wood and more.</p>
                                </div>
                            </div>
                            <div className="column is-5">
                                <div className="gifting-text-container">
                                    <p className="gifting-text-small bold">With our gift card, your recipients can order as easy as 1,2,3!</p>
                                    <div className="circle-container"><span className="circled-number">1</span></div>
                                    <p className="gifting-text-small">They Select Their<br /> Favorite Photo</p>
                                    <div className="circle-container"><span className="circled-number">2</span></div>
                                    <p className="gifting-text-small">Email It To <br /><span className="bold">123@myphoto.com</span></p>
                                    <div className="circle-container"><span className="circled-number">3</span></div>
                                    <p className="gifting-text-small">Open Email Reply, <br /> Browse Product, Insert Gift Card Code <br /> at Checkout & Order</p>
                                </div>

                            </div>
                        </div>
                        <div className="gifting-more-container" >
                            <div className="gifting-text-container align-left ">
                                <h1 className="gifting-heading">Buy More, Save More</h1>
                                <p className="gifting-text">Gift cards are available in denominations of $50 and $100. <br />The more cards you purchase, the less each card costs.</p>
                                <p className="gifting-text">Our MyPhoto gift card program includes:</p>
                                <p className="gifting-text">- Free shipping to one or individual locations.</p>
                                <p className="gifting-text">- Volume discounts above 25 cards.</p>
                                <table className="table table is-striped table is-fullwidth">
                                    <thead className="table-head">
                                        <tr>
                                            <th>Quantity of Gift Cards</th>
                                            <th>A $50 Gift Card Costs Just:</th>
                                            <th>A $100 Gift Card Costs Just:</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-body">
                                        <tr>
                                            <td>25-99</td>
                                            <td>$45.00 per card</td>
                                            <td>$90.00 per card</td>
                                        </tr>
                                        <tr>
                                            <td>100-499</td>
                                            <td>$40.00 per card</td>
                                            <td>$80.00 per card</td>
                                        </tr>
                                        <tr>
                                            <td>500-999</td>
                                            <td>$37.50 per card</td>
                                            <td>$75.00 per card</td>
                                        </tr>
                                        <tr>
                                            <td>1000+</td>
                                            <td>$35.00 per card</td>
                                            <td>$70.00 per card</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <img className="gifting-ribbon" src="https://storage.googleapis.com/myphoto-img/corporate-gifting-ribbon.png" alt="Ribbon" onError={addDefaultSrc}/>
                        </div>
                        <div className="gifting-banner-container ">
                            <img className="gifting-banner is-hidden-mobile is-hidden-touch" src="https://storage.googleapis.com/myphoto-img/corporate-gifting-banner3.jpg" alt="Banner" onError={addDefaultSrc}/>
                            <img className="gifting-banner is-hidden-mobile is-hidden-desktop" src="https://storage.googleapis.com/myphoto-img/corporate-gifting-banner3-tablet.jpg" alt="Banner" onError={addDefaultSrc}/>
                            <img className="gifting-banner is-hidden-desktop is-hidden-tablet" src="https://storage.googleapis.com/myphoto-img/corporate-gifting-banner3-mobile.jpg" alt="Banner" onError={addDefaultSrc}/>
                            <div className="gifting-banner-text">
                                <h2 className="gifting-banner-title">Reviews</h2>
                                <p className="gifting-banner-subheader2">"Our employees LOVED the Gift Cards and ordered immediately. Their office spaces are now filled with happy memories of their family photos."</p>
                                <p className="quote-name">Ted, VP of Sales<br />
                                    Sierra Brown, LLC</p>
                                <p className="gifting-banner-subheader2">"MyPhoto is at the top of the list for next year's gifts. Crazy easy crazy fast and super meaningful."</p>
                                <p className="quote-name">Mike, CEO/Founder<br />
                                    ADC Consolidated</p>
                                <p className="gifting-banner-subheader2">"The ordering process was super easy for me - such a thoughtful gift!"</p>
                                <p className="quote-name">Susan, VP of Human Resources<br />
                                    United Technical Inc.</p>
                                <span className="is-hidden-mobile corp-reviews-btn">
                                    {this.getStartedBtn()}
                                </span>
                            </div>
                        </div>
                    </section>
                    <ReactModal isOpen={this.props.isOpen} onRequestClose={() => { this.props.dispatch(closeModal('CORPORATE_GIFTING_MODAL')) }}
                        ariaHideApp={false}
                        style={customStyles} >
                        <button className="modal-close-btn" onClick={() => { this.props.dispatch(closeModal('CORPORATE_GIFTING_MODAL')) }}>
                            <img alt="close" src="https://storage.googleapis.com/myphoto-img/icons/close-btn.svg" onError={addDefaultSrc}/>
                        </button>
                        <div className="">
                            <CorporateGiftingForm />
                        </div>
                    </ReactModal>
                </div>
                <TabBar>
                    <div className="tab-bar-level-item">
                        {this.getStartedBtn()}
                    </div>
                </TabBar>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user.user,
        isOpen: state.modal['CORPORATE_GIFTING_MODAL'],
    };
}

export default withRouter(connect(mapStateToProps)(GiftingProgram));

import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Modal } from 'antd'
import GuaranteeQualityModal from './GuaranteeQualityModal'
import GuaranteeShippingModal from './GuaranteeShippingModal'
import addDefaultSrc from '../../../helpers/addDefaultSrc'
import '../../../assets/stylesheets/guarantee-ribbon.scss'

const GuaranteeRibbon = ({ shippingOptions, tightVW, fixWidth, homepage }) => {
  const [state, setState] = useState({ visibleQuality: false, showQualityModal: false, visibleShipping: false, showShippingModal: false });
  const thisMethod = shippingOptions.find(o => o.id === 1)
  const tightViewWidth = tightVW ? 'tight-ribbon' : 'shipping-text'
  const fixViewWidth = fixWidth ? 'fix-ribbon' : 'shipping-text'
  const isHomePage = homepage ? '14px' : 'inherit'
  return (
    <div className='guarantee-container'>
      <div
        onClick={(e) => { e.preventDefault(); setState({ ...state, visibleQuality: true, showQualityModal: true })}}
        className={`${tightViewWidth} ${fixViewWidth} ribbon-item`} 
        style={{ fontSize: isHomePage}}>
        <div className="image-container">
          <img className="guarantee-icon" onError={addDefaultSrc} style={{width: '40px'}}
            src={'https://storage.googleapis.com/myphoto-img/icons/Guarantee_Icon.svg'} alt='seal' />
        </div>
        <p>100% money <span className='next-line'>back <span className="underline">guarantee</span></span></p>
      </div>
      <div
        onClick={(e) => { e.preventDefault(); setState({ ...state, visibleShipping: true, showShippingModal: true })}}
        className={`${tightViewWidth} ${fixViewWidth} ribbon-item` }
        style={{fontSize: isHomePage }}>
        <div className="image-container">
          <img className="guarantee-icon" onError={addDefaultSrc} style={{width: '60px'}}
            src={'https://storage.googleapis.com/myphoto-img/icons/FedEx_Icon.svg'} alt='seal' />
        </div>
        <p><span className="underline">Arrives in 5 Days</span><span className='next-line'> (Free ${thisMethod.free_cutoff}+)</span></p>
      </div>
      <div
        className={`${tightViewWidth} ${fixViewWidth} ribbon-item affirm`}
        style={{fontSize: isHomePage, cursor:'auto'}}>
        <div className="image-container">
          <img className='affirm-logo guarantee-icon' onError={addDefaultSrc} src={'/images/icons/affirm_logo.svg'} alt='affirm-logo' />
        </div>
        <p>pay over time</p>
      </div>
      {state.showShippingModal ? <div>
        <Modal
          visible={state.visibleShipping}
          onCancel={(e) => { e.preventDefault(); setState({ ...state, visibleQuality: false, visibleShipping: false }) }}
          footer={false}
        >
          <GuaranteeShippingModal shippingOptions={shippingOptions} />
        </Modal>
      </div> : null}
      {state.showQualityModal ? <div>
        <Modal
          visible={state.visibleQuality}
          onCancel={(e) => { e.preventDefault(); setState({ ...state, visibleQuality: false, visibleShipping: false }) }}
          footer={false}
        >
          <GuaranteeQualityModal />
        </Modal>
      </div> : null}
    </div>
  )
}

export default withRouter(connect(state => {
  return {
    shippingOptions: state.config.appConfig.shippingOptions,
  };
})(GuaranteeRibbon))
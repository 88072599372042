import React, { Fragment } from 'react'
import { BrowserView, TabletView, MobileOnlyView } from "react-device-detect"

export const FilestackMyComputer = ({ addDefaultSrc }) => {
  return (
    <Fragment>
      <BrowserView>
        <div className="dropzone-area" id="filestack-div">
          <button className='button-upload button-computer inside-dropzone'>
            <span className="icon-container">
              <img className="folder-icon icon" alt="" onError={addDefaultSrc}
                src={'https://storage.googleapis.com/myphoto-img/icons/photopicker/Icon_COMPUTER-02.svg'} />
            </span>
            <span className="upload-btn-text"> My Computer </span>
          </button>
        </div>
      </BrowserView>
      <TabletView>
        <div className="dropzone-area" id="filestack-div">
          <button className='button-upload button-computer full-width-upload-btn'>
            <span className="icon-container">
              <img className="folder-icon icon" alt="" onError={addDefaultSrc}
                src={'https://storage.googleapis.com/myphoto-img/icons/photopicker/Icon_TABLET-2.svg'} />
            </span>
            <span className="upload-btn-text"> My Tablet </span>
          </button>
        </div>
      </TabletView>
      <MobileOnlyView>
        <div className="dropzone-area" id="filestack-div">
          <button className='button-upload button-computer full-width-upload-btn'>
            <span className="icon-container">
              <img className="folder-icon icon" alt="" onError={addDefaultSrc}
                src={'https://storage.googleapis.com/myphoto-img/icons/photopicker/Icon_PHONE-02.svg'} />
            </span>
            <span className="upload-btn-text">My Phone/Camera Roll</span>
          </button>
        </div>
      </MobileOnlyView>
    </Fragment>
  )
}

export const FilestackUploader = ({ client, filestackSocialUpload, source, customAuthText, icon, btnText, addDefaultSrc, iconClassName, selectorType }) => {
  return (
    <button
      className="button-upload"
      onClick={(e) => {
        e.preventDefault();
        return client.picker({
          uploadInBackground: true,
          exposeOriginalFile: true,
          disableTransformer: true,
          fromSources: [source],
          maxFiles: 20,
          viewType: 'grid',
          accept: ['.jpeg', '.jpg', '.png', '.gif'],
          onUploadDone: ((res) => {
            if (res.filesUploaded.length) {
              filestackSocialUpload(res)
            } else {
              if (source === 'instagram') selectorType('instagram')
            }
          })
        })
          .open()
          // .then((r) => {
          //   console.log('Filestack success', r)
          // })
          .catch((e) => {
            console.log('Filestack Failed to load!')
            if (source === 'instagram') selectorType('instagram')
          })
      }}
    >
      <span className="icon-container"><img className={`${iconClassName} icon`} alt=""
        onError={addDefaultSrc}
        src={icon} /></span>
      <span className="upload-btn-text">{btnText}</span>
    </button>
  )
}

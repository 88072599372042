import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import autoBind from 'react-autobind'
import { withRouter } from 'react-router-dom'
import { Form, Input, message } from 'antd';
import { corporateGiftCards } from '../actions'
import addDefaultSrc from '../helpers/addDefaultSrc'
import _ from 'lodash';

import '../assets/stylesheets/page-shell.scss'

const propTypes = {
    user: PropTypes.object,
}
const FormItem = Form.Item;

class CorporateGiftingForm extends React.Component {
    static contextTypes = {
        router: PropTypes.object,
    }
    constructor(props) {
        super(props);
        this.state = {
            corporateData: {
                companyName: '',
                noOfEmployees: '1-25',
                firstName: '',
                lastName: '',
                title: '',
                phone: '',
                email: ''
            },
            modalContent: false
        }
        autoBind(this);
    }

    handleChange(e) {
        const updateData = this.state.corporateData;
        if (e.target.type === 'checkbox') {
            _.set(updateData, e.target.name, e.target.checked);
        } else {
            _.set(updateData, e.target.name, e.target.value);
        }
        this.setState({
            corporateData: updateData,
        });
    }
    handleSubmit() {
        const { corporateData } = this.state;
        if (corporateData.companyName.trim() === '') {
            return message.error("Please enter company name!", 5) && false
        }
        if (corporateData.firstName.trim() === '') {
            return message.error("Please enter a first name!", 5) && false
        }
        if (corporateData.lastName.trim() === '') {
            return message.error("Please enter a last name!", 5) && false
        }
        if (corporateData.title.trim() === '') {
            return message.error("Please enter a title!", 5) && false
        }
        if (corporateData.phone === '') {
            return message.error("Please enter a valid phone!", 5) && false
        }
        if (corporateData.email.trim() === '') {
            return message.error("Please enter email!", 5) && false
        }
        this.props.dispatch(corporateGiftCards(this.state.corporateData))
        this.setState({
            modalContent: true
        })
    }
    render() {
        const { corporateData } = this.state
        if (!this.state.modalContent) {
            return (
                <div className="shell-container main-container main-container-gifting-form">
                    <div className="columns">
                        <div className="column is-10">
                            <div className="gifting-form-container">
                                <div className='gifting-form-heading'>
                                    <h1 className="corporate-gifting-heading">Corporate Gifting Program</h1>
                                    <p className="corporate-form-heading">Fill out the form below and our Corporate Gifting Concierge will contact you within 24 hours to discuss your business needs.</p>
                                </div>
                                <Form className="gifting-form">
                                    <FormItem
                                        label="Company"
                                        labelCol={{ span: 5 }}
                                        wrapperCol={{ span: 15 }}
                                    >
                                        <Input
                                            type="text"
                                            name="companyName"
                                            value={corporateData.companyName}
                                            onChange={this.handleChange}
                                        />
                                    </FormItem>
                                    <FormItem
                                        label="# of Employees"
                                        labelCol={{ span: 5 }}
                                        wrapperCol={{ span: 15 }}
                                    >
                                        <select
                                            className="employees-select"
                                            type="text"
                                            name="noOfEmployees"
                                            value={corporateData.noOfEmployees}
                                            onChange={this.handleChange}
                                            >
                                            <option value="1-25">1-25</option>
                                            <option value="26-50">26-50</option>
                                            <option value="51-100">51-100</option>
                                            <option value="101-250">101-250</option>
                                            <option value="250+">250+</option>
                                        </select>
                                    </FormItem>
                                    <FormItem
                                        label="First Name"
                                        labelCol={{ span: 5 }}
                                        wrapperCol={{ span: 15 }}
                                    >
                                        <Input
                                            type="text"
                                            name="firstName"
                                            value={corporateData.firstName}
                                            onChange={this.handleChange}
                                        />
                                    </FormItem>
                                    <FormItem
                                        label="Last Name"
                                        labelCol={{ span: 5 }}
                                        wrapperCol={{ span: 15 }}
                                    >
                                        <Input
                                            type="text"
                                            name="lastName"
                                            value={corporateData.lastName}
                                            onChange={this.handleChange}
                                        />
                                    </FormItem>
                                    <FormItem
                                        label="Title"
                                        labelCol={{ span: 5 }}
                                        wrapperCol={{ span: 15 }}
                                    >
                                        <Input
                                            type="text"
                                            name="title"
                                            value={corporateData.title}
                                            onChange={this.handleChange}
                                        />
                                    </FormItem>
                                    <FormItem
                                        label="Phone"
                                        labelCol={{ span: 5 }}
                                        wrapperCol={{ span: 15 }}
                                    >
                                        <Input
                                            type="tel"
                                            name="phone"
                                            value={corporateData.phone}
                                            onChange={this.handleChange}
                                        />
                                    </FormItem>
                                    <FormItem
                                        label="Email"
                                        labelCol={{ span: 5 }}
                                        wrapperCol={{ span: 15 }}
                                    >
                                        <Input
                                            type="email"
                                            name="email"
                                            value={corporateData.email}
                                            onChange={this.handleChange}
                                        />
                                    </FormItem>
                                    <button
                                        onClick={() => { this.handleSubmit() }}
                                        type="button"
                                        className="submit-btn"
                                    >
                                        <span>SUBMIT</span>
                                    </button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="shell-container main-container">
                    <div className="corporate-success-container">
                        <h2 className="corporate-success-page-header">Thank you for contacting us!</h2>
                        <h2 className="corporate-success-confirmation">
                            We have received your submission and will respond shortly.<br />For urgent inquiries please call us at &nbsp;
                   			 <strong>1-844-420-9720.</strong>
                        </h2>
                        <img className="gifting-banner" src="https://storage.googleapis.com/myphoto-img/corporate-gifting-confirmation.png" alt="Banner" onError={addDefaultSrc}/>
                    </div>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        user: state.user.user,
        corporateData: state.corporateData
    };
}

CorporateGiftingForm.propTypes = propTypes;
CorporateGiftingForm.contextTypes = {
    router: PropTypes.object.isRequired
}
export default withRouter(connect(mapStateToProps)(CorporateGiftingForm))

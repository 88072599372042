import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import autoBind from 'react-autobind'
import { withRouter, Link } from 'react-router-dom'
import { openModal } from '../actions'
import MetaDataTags from '../helpers/ProcessMetaData'
import addDefaultSrc from '../helpers/addDefaultSrc'
import '../assets/stylesheets/page-shell.scss'
import '../assets/stylesheets/cyber-monday.scss'

const propTypes = {
    seo: PropTypes.object,
}

class CyberMonday extends React.Component {
    static contextTypes = {
        router: PropTypes.object,
    }
    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentWillMount() {
        window.gtag('event', 'page_view', {
            'send_to': 'AW-668262331',
            'ecomm_pagetype':'other'
          });
    }

    render() {
        const { seo } = this.props;
        return (
            <div className="shell-container main-container cm-main-container">
                <div className="columns container">
                    <div className="column is-10">
                    {seo && <MetaDataTags baseUrl='cybermonday2018' pagesMetaData={seo} customData={{ name: "Cyber Monday Specials on Holiday Photo Gifts 2018", tagLine: "Cyber Monday 2018 Deals - Find special online savings on personalized photo gifts for everyone on your holiday list. HD photos printed on wood, glass, acrylic, metal and more! Turn photos on your phone into memories that’ll be treasured for years to come & get free 5-day shipping!" }} />}
                    <div
                            style={{ cursor: "pointer" }}
                            className="reviews-banner"
                            onClick={(e) => {
                                this.setState({ showModal: true })
                                this.props.dispatch(openModal('UPLOAD_MODAL'))
                                e.preventDefault();
                            }}>
                            <img className="is-hidden-mobile" style={{width: '100%'}} src="https://storage.googleapis.com/myphoto-img/cybermonday-hero-desktop.jpg" alt="cyber monday special offer" onError={addDefaultSrc}/>
                            <img className="is-hidden-desktop is-hidden-tablet" style={{width: '100%'}} src="https://storage.googleapis.com/myphoto-img/cybermonday_hero.jpg" alt="cyber monday special offer" onError={addDefaultSrc}/>
                    </div>
                 </div>
                </div>
                <div className="cm-contaier-2">
                    <h3 className="cm-love-heading">Photo Gifts Everyone Will Love</h3>
                    <p className="cm-text">Shop our huge variety of heartfelt holiday gifts! Everything at MyPhoto is handcrafted from high-quality metal, glass, acrylic, wood and more!</p>
                    <p className="cm-text">Best of all, you can preview your photo FREE on all our gifts and ordering is Crazy Easy. Crazy Fast.®</p>
                    <p className="cm-text">FREE 5-Day Shipping Anywhere in the U.S.</p>
                    <div className="all-products-btn-container">
                        <Link to="/products" className="all-products-btn">
                            Try It Now. It’s Easy.
                        </Link>
                    </div>
                    <img className="cm-ornament1 is-hidden-touch" src="https://storage.googleapis.com/myphoto-img/cybermonday-ornament1.png" alt="cyber monday special offer" onError={addDefaultSrc}/>
                    <img className="cm-ornament2 is-hidden-touch" src="https://storage.googleapis.com/myphoto-img/cybermonday-ornament2.png" alt="cyber monday special offer" onError={addDefaultSrc}/>
                </div>
                <div className="columns cm-contaier-3 container">
                    <div className="column is-10">
                        <img className="product-variety-img" style={{width: '100%'}} src="https://storage.googleapis.com/myphoto-img/cybermonday-banner.png" alt="cyber monday friday special offer" onError={addDefaultSrc}/>
                        <div className="all-products-btn-container">
                            <Link to="/products" className="all-products-btn">
                                See All Products
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
          );
    }
}

function mapStateToProps(state) {
    return {
        seo: state.config.seoData,
    };
}

CyberMonday.propTypes = propTypes;
CyberMonday.contextTypes = {
    router: PropTypes.object.isRequired
}
export default withRouter(connect(mapStateToProps)(CyberMonday))

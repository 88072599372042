import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import autoBind from 'react-autobind'
import { withRouter } from 'react-router-dom'
import { openModal } from '../actions'
import '../assets/stylesheets/page-shell.scss'

const propTypes = {
  onStart: PropTypes.func,
  dispatch: PropTypes.func,
  location: PropTypes.object,
  photo: PropTypes.object,
};

class Upload extends React.Component {
  static contextTypes = {
    router: PropTypes.object,
  }
  constructor(props) {
    super(props);
    this.state = {};

    autoBind(this);
  }

  componentDidMount() {
      this.context.router.history.push('/products');
      this.props.dispatch(openModal('UPLOAD_MODAL'))
      window.gtag('event', 'page_view', {
        'send_to': 'AW-668262331',
        'ecomm_pagetype':'other'
      });
  }

  render() {
    return (
      <div className="shell-container main-container">
        <div className="container">
          {/* <PhotoPicker
            dispatch={this.props.dispatch}
            changeProductPhoto={this.props.location.search.indexOf('change') !== -1}
            wsConnection={wsConnection}
          /> */}
        </div>
      </div>

      );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
  };
}

Upload.propTypes = propTypes;

export default withRouter(connect(mapStateToProps)(Upload));

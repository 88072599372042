import React from 'react'
import addDefaultSrc from '../../helpers/addDefaultSrc'

function MoneyBackGuarantee() {
  return (
     <div className="faq-money-back-container">
        <img src="https://storage.googleapis.com/myphoto-img/icons/Guarantee_Badge-01.svg" onError={addDefaultSrc} alt='' style={{ width: '100px' }}/>
        <h3>100% Money-Back Guarantee</h3>
        <p>Be prepared for a big smile when you open your order. We stand behind both the photos printed as well as the products themselves. We're certain you'll love it, and that's why we can provide you a 60-Day Money Back Guarantee. If you're not happy and smiling, tell us and we'll take care of it. We promise.</p>
    </div>
  )
}

export default MoneyBackGuarantee
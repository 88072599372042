import React from 'react'
import MetaDataTags from '../helpers/ProcessMetaData'

function AtWork() {
    return (
      <section>
        <MetaDataTags baseUrl='at-work' />
        <article>
          <img alt="Galleri Frame" src="https://d31hzlhk6di2h5.cloudfront.net/20180419/c1/f6/2d/3d/5f74296625b7465c8cacb3f1_1600x796.jpg" />
          <h2>Photo Products for Businesses</h2>
          <div>
            <p>
                MyPhoto's selection of customizable photo products are perfect for offices, businesses, 
                or any workspace. From photo frames, to glass and acrylic, wood and more, MyPhoto's 
                high-quality event giveaways, recognition and thank you gifts, and corporate awards are 
                available in desk and wall sizes to suit any professional environment. We're here to help. 
                Email us at 
                <a data-name="hello@myphoto.com" data-type="email" 
                    href="mailto:hello@myphoto.com?subject=MyPhoto-at-Work">hello@myphoto.com</a>
                 and we'll be right back to you. 
            </p>
            <h3>Put a component with the cards for Atrium Acrylic, Moderna Metal, Tudor Wood, and 
                Wood Coasters here.</h3>
        </div>
        </article>
      </section>
    )
  }
  
  export default AtWork
import React, { Component } from 'react'
import MetaDataTags from '../helpers/ProcessMetaData'
import PropTypes from 'prop-types'
import { seoInfo } from '../helpers/seoInfo'
import { connect } from 'react-redux'
import { withRouter ,Link } from 'react-router-dom'
import autoBind from 'react-autobind'
import { openModal } from '../actions'
import addDefaultSrc from '../helpers/addDefaultSrc'
import TabBar from '../components/common/tabBar/TabBar'
import '../assets/stylesheets/page-shell.scss'
import '../assets/stylesheets/how-to.scss'

const Content = {
  headerDesktop: 'https://storage.googleapis.com/myphoto-img/howto-header-desktop.jpg',
  headerTablet: 'https://storage.googleapis.com/myphoto-img/howto-header-tablet.jpg',
  subTitle: "MyPhoto is the fastest and easiest photo website to turn meaningful moments into timeless photo art! Prices start at just $12 and shipping is free for all orders over $59!",
  card1: {
    image: "https://storage.googleapis.com/myphoto-img/howto-email.jpg",
    title: "Email a photo",
    text1: "1. Choose a photo or photos from your phone or computer",
    text2: "2. Email photo(s) to 123@myphoto.com",
    subtext1: "No subject needed",
    subtext2: "Choose actual size for highest quality results",
    text3: "3. Open our email response (If you can't find the email, check your spam or promo folders)",
    text4: "4. Browse our products with your photo previewed",
    text5: "5. Edit (if needed), Add to Cart, and Checkout!",
  },
  card2: {
    image: "https://storage.googleapis.com/myphoto-img/howto-addphoto.jpg",
    title: "Add a photo",
    text1: "1. Click the UPLOAD PHOTO button",
    text2: "2. Select the source of your photo",
    text3: "3. Select the photos you want to add",
    subtext3: "Hang tight as we process your photos",
    text4: "4. Browse our products with your photo previewed",
    text5: "5. Edit (if needed), Add to Cart, and Checkout!",
  },
  card3: {
    icon: "https://storage.googleapis.com/myphoto-img/icons/crazy-easy-icon-gray.svg",
    title: "Crazy easy",
    text: "No apps to download, no accounts to register. Simply upload your photos to preview them on a variety of quality products that will arrive ready to hang or stand...the hardest part is choosing your favorite photos!",
  },
  card4: {
    icon: "https://storage.googleapis.com/myphoto-img/icons/crazy-fast-icon.svg",
    title: "Crazy Fast",
    text: "Ordering custom gifts and decor only takes 1 minute. Email a photo and you'll instantly receive an email back from us previewing your photo on all our product options. Choose a product you love and it's shipped within 5 business days!",
  },
  card5: {
    icon: "https://storage.googleapis.com/myphoto-img/icons/gift-solution-icon.svg",
    title: "#1 Gifting Solution",
    text: "MyPhoto is your one stop shop for the perfect gifts. Our products come in gift-ready packaging and include a free custom gift note. Want to get one for you and one for them? Get a second of the same photo/product for 50% off when you increase the quantity of any item to 2!",
  },
  image1: "https://storage.googleapis.com/myphoto-img/howto-moreinfo-link1.jpg",
  image2: "https://storage.googleapis.com/myphoto-img/howto-moreinfo-link2.jpg",
  image3: "https://storage.googleapis.com/myphoto-img/howto-moreinfo-link3.jpg",
  image4: "https://storage.googleapis.com/myphoto-img/howto-moreinfo-link4.jpg",
}

const propTypes = {
  dispatch: PropTypes.func,
  user: PropTypes.object,
}

class HowTo extends Component {
  static contextTypes = {
    router: PropTypes.object,
  }
  constructor() {
    super();
    this.state = {
        showModal: false,
    };
    autoBind(this);
  }
  componentWillMount() {
    window.gtag('event', 'page_view', {
      'send_to': 'AW-668262331',
      'ecomm_pagetype': 'other'
    });
  }
  renderBtn(){
    return(
      <div className="how-cta-btn-container is-hidden-mobile">
        <button 
          className="how-cta-btn easy-btn"
          onClick={(e) => {
              this.setState({ showModal: true })
              this.props.dispatch(openModal('UPLOAD_MODAL'))
          }}> Upload your photo. It's Easy.
            <img className='tab-bar-arrow-icon' src={'https://storage.googleapis.com/myphoto-img/icons/arrow-right-icon.png'} alt='' onError={addDefaultSrc}/>
        </button>
      </div>
    )
  }
  render() {
    return (
      <div className="shell-container main-container">
        <MetaDataTags baseUrl='how-to-myphoto' seoInfo={seoInfo.howTo} />
        <div className='how-main-container'>
          <div className='how-header-container'>
            <img className="how-header-img is-hidden-touch" src={Content.headerDesktop} alt='' onError={addDefaultSrc}/>
            <img className="how-header-img is-hidden-desktop is-hidden-mobile" src={Content.headerTablet} alt='' onError={addDefaultSrc}/>
            <div className="how-header-div is-hidden-desktop is-hidden-tablet"></div>
            <div className="how-title-content">
              <h1 className="how-title1">How To <span className="italic">myPhoto</span></h1>
              <h1 className="how-title2">Your Photo</h1>
              <p className="how-header-text">{Content.subTitle}</p>
            </div>
          </div>
          <div className="how-subtitle-container">
            <p className="how-subtittle-text1">Two Ways To</p>
            <p className="how-subtittle-text2"><span className="italic">MyPhoto</span> Your Photo</p>
          </div>
          <div className="how-cards-container">
            <div className="how-card">
              <img className="how-card-img" src={Content.card1.image} alt="email" onError={addDefaultSrc}/>
              <div className="how-card-text-container">
                <p className="how-card-title">{Content.card1.title}</p>
                <p className="how-card-text">{Content.card1.text1}
                </p>
                <p className="how-card-text">{Content.card1.text2}
                  <span className="italic">&emsp;No subject needed</span>
                  <span className="italic">&emsp;Choose actual size for highest quality results</span>
                </p>
                <p className="how-card-text">{Content.card1.text3}</p>
                <p className="how-card-text">{Content.card1.text4}</p>
                <p className="how-card-text" style={{marginBottom: '20px'}}>{Content.card1.text5}</p>
                {this.renderBtn()}
              </div>
            </div>
            <div className="how-card">
              <img className="how-card-img" src={Content.card2.image} alt="email" onError={addDefaultSrc}/>
              <div className="how-card-text-container">
                <p className="how-card-title">{Content.card2.title}</p>
                <p className="how-card-text">{Content.card2.text1}</p>
                <p className="how-card-text">{Content.card2.text2}
                  <span className="italic italic-small">&emsp;Your Computer</span>
                  <span className="italic italic-small">&emsp;Your Phone/Tablet</span>
                  <span className="italic italic-small">&emsp;Facebook</span>
                  <span className="italic italic-small">&emsp;Instagram</span>
                  <span className="italic italic-small">&emsp;Google Photos (coming soon)</span>
                </p>
                <p className="how-card-text">{Content.card2.text3}
                  <span className="italic">&emsp;Hang tight as we process your photos</span> 
                </p>
                <p className="how-card-text">{Content.card2.text4}</p>
                <p className="how-card-text" style={{marginBottom: '20px'}}>{Content.card2.text5}</p>
                {this.renderBtn()}
              </div>
            </div>
          </div>
          <div className="how-crazy-physical-container">
            <div className="how-crazy-container">
              <p className="how-crazy-title1">It’s as simple as 1, 2, 3...</p>
              <p className="how-crazy-title2">MyPhoto Your Photo</p>
              <div className="how-crazy-cards-container">
                <div className="how-crazy-card">
                  <img className="how-crazy-card-icon is-hidden-mobile" src={Content.card3.icon} alt='' onError={addDefaultSrc}/>
                  <p className="how-crazy-card-title">{Content.card3.title}</p>
                  <p className="how-crazy-card-text">{Content.card3.text}</p>
                </div>
                <div className="how-crazy-card">
                  <img className="how-crazy-card-icon is-hidden-mobile" src={Content.card4.icon} alt='' onError={addDefaultSrc}/>
                  <p className="how-crazy-card-title">{Content.card4.title}</p>
                  <p className="how-crazy-card-text">{Content.card4.text}</p>
                </div>
                <div className="how-crazy-card">
                  <img className="how-crazy-card-icon is-hidden-mobile" src={Content.card5.icon} alt='' onError={addDefaultSrc}/>
                  <p className="how-crazy-card-title">{Content.card5.title}</p>
                  <p className="how-crazy-card-text">{Content.card5.text}</p>
                </div>
              </div>
              {this.renderBtn()}
            </div>
          </div>
          <div className="how-more-container">
            <p className="how-more-title">More info</p>
            <div className="how-more-cards-container">
              <Link to="/faq" className="how-more-card">
              <span className='how-image-container'><img className="how-more-image" src={Content.image1} alt='Hands carefully reviewing colorful family portrait' onError={addDefaultSrc}/></span>
                <p className="how-more-text">Faq’s</p>
              </Link>
              <Link to="/reviews" className="how-more-card">
              <span className='how-image-container'><img className="how-more-image" src={Content.image2} alt='Opening gift box with beautifully framed family portrait' onError={addDefaultSrc}/></span>
                <p className="how-more-text">Reviews</p>
              </Link>
              <Link to="/our-story" className="how-more-card">
                <span className='how-image-container'><img className="how-more-image" src={Content.image3} alt='Woman holding baby are among the exquisitely printed glass, acrylic, and custom framed products displayed in warm home' onError={addDefaultSrc}/></span>
                <p className="how-more-text">About us</p>
              </Link>
              <a href="mailto:hello@myphoto.com" className="how-more-card">
              <span className='how-image-container'><img className="how-more-image" src={Content.image4} alt='Woman sits on couch and opens MyPhoto gift box' onError={addDefaultSrc}/></span>
                <p className="how-more-text">Support</p>
              </a>
            </div>
          </div>
        </div>
        <TabBar>
          <div className="tab-bar-level-item">
            <div className="how-cta-btn-container">
              <button 
                className="how-cta-btn how-cta-btn-tabbar easy-btn"
                onClick={(e) => {
                    this.setState({ showModal: true })
                    this.props.dispatch(openModal('UPLOAD_MODAL'))
                }}> Upload your photo. It's Easy. 
                  <img className='tab-bar-arrow-icon' src={'https://storage.googleapis.com/myphoto-img/icons/arrow-right-icon.png'} alt=''/>
              </button>
            </div>
          </div>
        </TabBar>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    photos: state.photos.photos,
  }
}
HowTo.propTypes = propTypes

export default withRouter(connect(mapStateToProps)(HowTo))
import React from 'react'
import addDefaultSrc from '../../../helpers/addDefaultSrc'
import '../../../assets/stylesheets/guarantee-quality-modal.scss'

const content = {
  mainTitle: "100% Quality Guarantee",
  subTitle: "or your Money Back",
  text: {
    text1: "Be prepared for a big smile when you open your order. We stand behind both the photos printed as well as the products themselves. We're certain you'll love it, and that's why we can provide you a",
    text2: "60-Day Money Back Guarantee.",
    text3: "If you're not happy and smiling, tell us and we'll take care of it. We promise."
  },
  secondTitle: "Our guarantee covers the following:",
  footerText1: "In the rare instances you feel the need to return your order, we will even cover the shipping cost.",
  footerText2: "Our first priority is always a happy customer!"
}
const columnsContent = [{
  icon: "https://storage.googleapis.com/myphoto-img/icons/guarantee-color-issues.svg",
  title: "Color Issues:",
  text: "If your photo is too dark, too light, or washed out."
}, {
  icon: "https://storage.googleapis.com/myphoto-img/icons/guarantee-photo-quality.svg",
  title: "Photo Quality:",
  text: "Includes photos that come out blurry, pixelated, or grainy."
}, {
  icon: "https://storage.googleapis.com/myphoto-img/icons/guarantee-cropping.svg",
  title: "Cropping Mishaps:",
  text: "An important part of your photo is incorrectly cropped."
}, {
  icon: "https://storage.googleapis.com/myphoto-img/icons/guarantee-damages.svg",
  title: "Damages:",
  text: "If your photo arrives chipped, broken, or dented. Don't fret, we'll send you a replacement."
}
]

class GuaranteeQualityModal extends React.Component {
  render() {
    return (
      <div className="quality-modal-container">
        <div className="quality-main-container">
          <h1 className="quality-title">{content.mainTitle}</h1>
          <p className="quality-subtitle">{content.subTitle}</p>
          <p className="quality-text">{content.text.text1}<span className="bold">&nbsp;{content.text.text2}</span>&nbsp;{content.text.text3}</p>
          <div className="quality-line"></div>
          <p className="quality-second-title">{content.secondTitle}</p>
          <div className="quality-guarantee-container">
            {columnsContent.map((column, index) => {
              return (
                <div className="quality-column" key={index}>
                  <img onError={addDefaultSrc} src={column.icon} className="quality-column-img" alt='' />
                  <h3 className="quality-column-title">{column.title}</h3>
                  <p className="quality-column-text">{column.text}</p>
                </div>
              )
            })}
          </div>
        </div>
        <div className="quality-footer-container">
          <p className="quality-footer-text1">{content.footerText1}</p>
          <p className="quality-footer-text2">{content.footerText2}</p>
        </div>
      </div>
    )
  }
}

export default GuaranteeQualityModal 

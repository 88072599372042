import * as types from '../actions/actionTypes';
import { assign } from 'lodash';

const INITIAL_STATE = {
  discount: {},
  reduxPromoCode: null,
  upsellModal: true,
  upsellItem: null
}

function promotions(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.FETCH_VALID_PROMO:
      return { ...state, discount: action.payload }
    case types.SHOW_UPSELL_MODAL:
        return { ...state, upsellModal: action.payload }
    case types.FETCH_UPSELL_ITEM:
        return { ...state, upsellItem: action.payload }
    // TODO: The following may all be deprecated...
    case types.REMOVE_PROMOTION:
      return assign({}, state, {
        discount: {},
      });
    case types.INVALID_PROMOTION:
      return assign({}, state, {
        discount: { promoCodeError: true }
      });
    case types.SAVE_PROMO_CODE:
        return { ...state, reduxPromoCode: action.payload }

    default:
      return state
  }
}

export default promotions
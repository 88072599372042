import React from 'react'
import autoBind from 'react-autobind';
import { path } from 'ramda'
import '../../../assets/stylesheets/product-overlays.scss'

import ScrollerArea from './ScrollerArea';


const distinct = (values, index, self) => { // to filter distinct values in an array
  return self.indexOf(values) === index
}

export class Overlays extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      switchCategory: false,
    };
    autoBind(this);
  }

  componentDidMount() {
    if( !('ontouchstart' in window || 'onmsgesturechange' in window) ){
      let scrollers = [];
      Array.from( document.querySelectorAll('.overlays-container .categories-select-container, .overlay-carousel') ).forEach( function( el ){
        scrollers.push( new ScrollerArea( el ) );
      } );
      this.scrollers = scrollers;
    }    
  }

  componentDidUpdate(){
    if( this.scrollers ){
      for( let i in this.scrollers ){
        this.scrollers[i].destroy();
      }
    }
    this.scrollers = null;
    if( !('ontouchstart' in window || 'onmsgesturechange' in window) ){
      this.scrollers = [];
      let ref = this;
      Array.from( document.querySelectorAll('.overlays-container .categories-select-container, .overlay-carousel') ).forEach( function( el ){
        ref.scrollers.push( new ScrollerArea( el ) );
      } );
    }
  }

  componentWillUnmount(){
    if( this.scrollers ){
      for( let i in this.scrollers ){
        this.scrollers[i].destroy();
      }
    }
    this.scrollers = [];
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.item !== this.props.item) {
      this.setState({ switchCategory: false })
    }
  }
  render() {
    const { overlays, item, project, handleOverlayChange } = this.props
    const { value, switchCategory } = this.state
    var thumbWidth = path(['photopersonalization', 0, 'thumbSize', 'width'], project)
    var thumbHeight = path(['photopersonalization', 0, 'thumbSize', 'height'], project)
    const isLandscape = thumbWidth > thumbHeight
    const printWidth = isLandscape ? item.printData.landscape[0].width : item.printData.portrait[0].width;
    const printHeight = isLandscape ? item.printData.landscape[0].height : item.printData.portrait[0].height;
    const filteredOverlays = overlays.filter(overlay => overlay.width === printWidth && overlay.height === printHeight)
    const categoryArray = filteredOverlays.map((x) => {
      return x.category
    })
    const categories = categoryArray.filter(distinct) // list of all distinct overlay categories for this size
    var oldOverlay = path(['photopersonalization', 0, 'overlay'], project) // last overlay selected by project
    var oldCategory = path(['category'], oldOverlay) // previous selected category
    var oldColor = path(['color'], oldOverlay) // previous selected color
    var selectedCategory = (oldCategory && categories.includes(oldCategory)) ? oldCategory : categories[0]
    var selectedColor = (oldCategory) ? oldColor : 'white'
    const categorySelected = switchCategory ? value : selectedCategory
    console.log(oldCategory, selectedCategory, categorySelected)
    const displaySelectedOverlays = categorySelected && filteredOverlays.filter((x) => {
      return x.category === categorySelected
    })
    const useOldOverlay = displaySelectedOverlays && oldOverlay && displaySelectedOverlays.some(overlay => overlay.id === oldOverlay.id)
    const useSimilarOverlay = displaySelectedOverlays && oldOverlay && !useOldOverlay && displaySelectedOverlays.some(overlay => overlay.altTxt === oldOverlay.altTxt)
    const selectedOverlay = useOldOverlay
      ? displaySelectedOverlays.find(o => o.id === oldOverlay.id)
      : useSimilarOverlay
        ? displaySelectedOverlays.find(o => o.altTxt === oldOverlay.altTxt)
        : displaySelectedOverlays && displaySelectedOverlays[0]
          ? displaySelectedOverlays[0]
          : {}
    // console.log(useOldOverlay, useSimilarOverlay, selectedOverlay, displaySelectedOverlays) // DELETE
    if (!useOldOverlay && oldOverlay && selectedOverlay.hasOwnProperty('id')) {
      console.log('SWITCH: ', selectedOverlay, oldOverlay) // DELETE
      handleOverlayChange(selectedOverlay, selectedColor)
    }
    if (categories.length === 0) { return null }
    return (
      <div className="overlays-container">
        <div className="categories-container">
          <p className="categorie-label">Design</p>
          <div className="categories-select-container">
            <div className="scroll_container">
              {categories.map( (categorie,idx) => {
                const isSelected = categorySelected === categorie ? 'selected-category' : ''
                return <button
                  key={idx}
                  className={`categorie-button ${isSelected}`}
                  value={categorie}
                  onMouseUp={e => {
                    this.setState({ value: e.target.value, switchCategory: true });
                    
                  }}
                >
                  {categorie}</button>
              }
              )}
            </div>
          </div>
        </div>
        <div className="overlay-carousel">
          <div className="scroll_container">
            {displaySelectedOverlays && displaySelectedOverlays.map( (overlay, idx) => {
              const overlayClassname = selectedOverlay === overlay ? '' : 'not-selected-overlay'
              return (
                <div
                  onMouseUp={() => {
                    handleOverlayChange(overlay, selectedColor, true );
                  }} key={idx}>
                  <img className={`overlay-image ${overlayClassname}`} src={overlay.swatch} alt={overlay.altTxt} />
                </div>
              )
            })}
          </div>
        </div>
        <div className="overlay-colors">
          <span className="colors-cont">
            <p className="categorie-label">Color</p>
            <div
              className="color-select"
              onClick={() => {
                handleOverlayChange(selectedOverlay, 'white', true)
              }}>
            </div>
            <div
              className="color-select color-black"
              onClick={() => {
                handleOverlayChange(selectedOverlay, 'black', true)
              }}>
            </div>
          </span>
          <button
            className="remove-overlay"
            onClick={() => handleOverlayChange({ overlay: {} }, 'removeOverlay', true)}>
            Remove Design
          </button>
        </div>
      </div>
    )
  }

}

export default Overlays
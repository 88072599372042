import React from 'react'
import { connect } from 'react-redux'
import { openModal } from '../../../actions'
import { Link } from 'react-router-dom'
// import addDefaultSrc from '../../../helpers/addDefaultSrc'
import TabBar from './TabBar';

class HomePageTabBar extends React.Component {
  render() {
    return (
      <TabBar>
        <h3 className='hp-tap-bar-title'>Want to see your photo on all products?</h3>
        <div className="tab-bar-level">
          <div className="tab-bar-level-item">
            <button
              onClick={() => {
                this.props.dispatch(openModal('UPLOAD_MODAL'))
              }}
              type="button"
              className="cta-btn easy-btn"
            >
              Upload Photo
              {/* <img className='tab-bar-arrow-icon' onError={addDefaultSrc}
                  src={'https://storage.googleapis.com/myphoto-img/icons/arrow-right-icon.png'} alt=''/> */}
            </button>
          </div>
          <div className="tab-bar-level-item">
            <Link to='/products'>
              <button
                style={{padding: '0 15px'}}
                className='cta-secondary-btn'
                >See All Products
              </button>
            </Link>
          </div>
        </div>
      </TabBar>
    )
  }
}
function mapStateToProps(state) {
  return {
    user: state.user.user,
    photos: state.photos.photos,
    project: state.projects.project,
  }
}
export default connect(mapStateToProps)(HomePageTabBar)
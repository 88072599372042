export default function getImageActualSize(imageSource) {
  const actualImage = new Image();
  const thumbImage = new Image();

  actualImage.src = imageSource;
  thumbImage.src = imageSource;
  
  const actualImageResolver = new Promise((resolve) => {
    actualImage.onload = () => {
      const {
        naturalWidth: width,
        naturalHeight: height
      } = actualImage;
      resolve({
        width, height,
      });
    };
  });

  const thumbImageResolver = new Promise((resolve) => {
    thumbImage.onload = () => {
      const {
        naturalWidth: width,
        naturalHeight: height
      } = thumbImage;
      resolve({
        width, height,
      });
    };
  });

  return Promise
    .all([actualImageResolver, thumbImageResolver])
    .then(([actualImageResult, thumbImageResult]) => {
      return {
        actualSize: actualImageResult,
        thumbSize: thumbImageResult,
      };
    });
}

import * as types from '../actions/actionTypes'
import { assign } from 'lodash'

// TODO: Initial State may not be needed...
const initialState = {
  appConfig: {},
  multiPhoto: false,
  express: false,
  cartItemEditingMode: false,
  location: {},
  seoData: {},
  token: null,
  layout: {
    layout: 'default',
    theme: 'default',
    clientLogo: null,
    heroImg: {
      desktop: null,
      mobile: null,
    },
    showNavbar: true,
    showFooter: true,
    showFeatured: true,
    showHero: true,
  },
  promo: {},
  promoText: '',
  promoText2: '',
  promoText3: '',
  promoSubtext: '',
  promoTextMobile: '',
  promoText2Mobile: '',
  promoText3Mobile: '',
  promoSubtextMobile: '',
  promoColor: '',
  promoHighlightColor: '',
  promoBackgroundColor: '',
  promoLink: '',
  countdownBackgroundColor: '',
  countdownEndDate: '',
  countdownText1: '',
  countdownText1Mobile: '',
  countdownText2: '',
  countdownText2Mobile: '',
  countdownText3: '',
  countdownText3Mobile: '',
  countdownTextColor: '',
  steps: false,
  sessId: null,
  event: {},
  paymentApproved: null,
  paymentError: false,
  checkoutToken: null,
  giftCardBalance: 0,
  giftCardError: null,
  shipping: {
    flatShippingRate: 12.95,
    setFreeShipping: false,
  },
  timerCompleted: false,
};

function config(state = initialState, action) {
  switch (action.type) {
    case types.SET_APP_DATA:
      return assign({}, state, {
        appConfig: action.config,
        seoData: action.seoData,
        layout: action.layout,
        promo: action.promo,
        shipping: action.shipping,
      });
    case types.LOCATION_DATA:
      return {
        ...state,
        location: {
          ...action.location,
        },
      };
    case types.SET_INITIALIZE:
      return assign({}, state, {
        token: action.token,
      });
    case types.ORDER_PLACE_SUCCESS:
      return assign({}, state, {
        paymentApproved: action.approved,
        paymentError: action.error,
      });
    case types.COUNTDOWN_COMPLETED:
      return assign({}, state, {
        ...state, timerCompleted: true
      })
    default:
      return state
  }
}

export default config
import React from 'react'
import autoBind from 'react-autobind'
import addDefaultSrc from '../../helpers/addDefaultSrc'
import { Collapse } from 'antd';
import FaqCollapseConatiner from '../../components/faq/FaqCollapseContainer'
import FaqCategoryWrapper from '../../components/faq/FaqCategoryWrapper'

const { Panel } = Collapse

class FaqGifting extends React.Component {
  constructor(props) {
      super(props)
      autoBind(this)
  }
  render() {
      return (
        <FaqCategoryWrapper title='Gifting'>
          <div className='faq-questions-container'>
            <FaqCollapseConatiner>
              <Panel header="What kind of gift packaging do you use?" key="1">
                <p>WOW! Wait until you see our NEW gift boxes! They showcase all of our products in stunning Tiffany-like boxes that display each item beautifully! </p>
                <img style={{height: '200px', width: '280px', margin: '20px 30px'}} src={'https://storage.googleapis.com/myphoto-img/faq/faq-under-tree.jpeg'} onError={addDefaultSrc} alt='gifting boxes under tree'/>
                <p>You or your loved ones will be so amazed you will not want to take the photos out of the box! </p>
                <img style={{height: '200px', width: '280px', margin: '20px 30px'}} src={'https://storage.googleapis.com/myphoto-img/faq/faq-box.jpeg'} onError={addDefaultSrc} alt='gifting boxes'/>
              </Panel>
              <Panel header="Can I include a gift note?" key="2">
                <p>Of course, and it's FREE! At checkout, simply select the option to include a custom gift note and write any personal message you'd like. 
                Just be sure to make it sweet!</p>
                <img style={{height: '200px', width: '310px', margin: '20px 30px'}} src={'https://storage.googleapis.com/myphoto-img/faq/faq-giftnote.jpg'} onError={addDefaultSrc} alt='gift note'/>
              </Panel>
              <Panel header="Do you show any costs/prices in the gift boxes?" key="3">
                <p>No, costs are not disclosed on the receipts in our packages.</p>
              </Panel>
              <Panel header="Do you offer corporate gifting programs or large quantity discounts?" key="4">
                <p>While we always have amazing prices, if you want to order over $2,000 for family, friends, or organizations, please contact us at&nbsp;
                <a className="faq-link" href="mailto:bigorders@myphoto.com">bigorders@myphoto.com</a> to learn more.</p>
              </Panel>
            {/**
              <Panel header="Do you do gift cards?" key="4">
                <p>Absolutely! In $25, $50 or $100 denominations…each beautifully gift boxed. Click 
                <Link className="faq-link" to="/products/gift-guide/gift-guide/gift-card">&nbsp;here</Link> to see how special our gift cards are.</p>
              </Panel> **/}
            </FaqCollapseConatiner>
          </div>
        </FaqCategoryWrapper>
      )
  }
}

export default FaqGifting;
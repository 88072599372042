import request from 'superagent';

export default function uploadPhotosToS3(uploadFile) {
  if(window.location.href.indexOf("staging.") > -1 || window.location.href.indexOf("photoandgo.local") > -1) console.log("TRYING TO UPLOAD FILE: ",uploadFile);
  const { bucket, key, awsAccessKeyId, policy, signature, file } = uploadFile;
  const baseUrl = `//${bucket}.s3.amazonaws.com/`;
  const formData = new FormData();
  formData.append('key', key);
  formData.append('AWSAccessKeyId', awsAccessKeyId);
  formData.append('policy', policy);
  formData.append('signature', signature);
  formData.append('acl', 'public-read');
  if(file.type){
    formData.append('Content-Type', file.type);
    formData.append('file', file);
  }
  return request
    .post(baseUrl)
    .send(formData)
    .then(() => ({
      bucket,
      key,
    }))
    .catch(err => {
      console.log("UPLOAD ERROR: ",err);
      return null;
    });
}

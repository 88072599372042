import React from 'react'
import addDefaultSrc from '../../helpers/addDefaultSrc'
import { Collapse } from 'antd';

const customPanelStyle = {
  marginTop: 15,
  marginBottom: 15,
}

function FaqCollapseConatiner(props) {
  return (
    <div className='faq-collapse-container'>
      <Collapse 
      accordion
      style={customPanelStyle}
      expandIcon={({ isActive }) => <img className="colapse-icon" 
        onError={addDefaultSrc} 
        src={isActive 
          ? "https://storage.googleapis.com/myphoto-img/faq/icons/Inset.svg" 
          : "https://storage.googleapis.com/myphoto-img/faq/icons/Inset-white.svg" } alt=''/>}
      >
        {props.children}
      </Collapse>
    </div>
  )
}

export default FaqCollapseConatiner;
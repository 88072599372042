export function setIntervalHelper(funcToRun){
  let monetateInterval= setInterval(()=>  {
  if(window.monetateQ){
    funcToRun();
  clearInterval(monetateInterval)
  }}, 500);
}

export  function montetatePageTracker (){ 
  window.monetateQ = window.monetateQ || [];
window.monetateQ.push(["setPageType", "home"]);
window.monetateQ.push(["trackData"]); 
  }



  export  function montetateProductsTracker (){ 
    window.monetateQ = window.monetateQ || [];
    window.monetateQ.push(["setPageType", "allProducts"]);

    window.monetateQ.push(["trackData"]); 
      }

      

  
      
export function monetateCategoryTraacker(){
  window.monetateQ = window.monetateQ || [];
  window.monetateQ.push(["setPageType", "category"]);
  window.monetateQ.push(["trackData"]);
}      

export function monetateAddToCart(theCart){
  window.monetateQ = window.monetateQ || [];
  window.monetateQ.push(["setPageType", "product"]);
  theCart.forEach(item => {
    let unitPrice = item.unit_price
    let value = item.qty
    let allowsPersonalization = item.personalized
    let totalPrice = (allowsPersonalization ? (unitPrice * (1 + (value - 1) / 2))/value : (unitPrice * value)).toFixed(2)

    window.monetateQ.push([
    "addCartRows",
    [{
      "productId": item.item_id, 
      "quantity": item.qty, 
      "unitPrice": totalPrice, 
      "sku": item.sku, 
    }]
  ]);
    })
  window.monetateQ.push(["trackData"]);
}  

export function monetateCheckoutPageTracker(orders){
  window.monetateQ = window.monetateQ || [];
window.monetateQ.push(["setPageType", "checkout"]);
orders.forEach(item => {
let unitPrice = item.unit_price
let value = item.qty
let allowsPersonalization = item.personalized
let totalPrice = (allowsPersonalization ? (unitPrice * (1 + (value - 1) / 2))/value : (unitPrice * value)).toFixed(2)
window.monetateQ.push([
"addCartRows",
  [{
      "productId": item.item_id, 
      "quantity": item.qty, 
      "unitPrice": totalPrice, 
      "sku": item.sku, 
    }]
  ]);
    })
window.monetateQ.push(["trackData"]);
}
 
export function monetateCartTracker(theCart){
  window.monetateQ = window.monetateQ || [];
window.monetateQ.push(["setPageType", "cart"]);
theCart.forEach(item => {
      let unitPrice = item.unit_price
      let value = item.qty
      let allowsPersonalization = item.personalized
      let totalPrice = (allowsPersonalization ? (unitPrice * (1 + (value - 1) / 2))/value : (unitPrice * value)).toFixed(2)
  
      window.monetateQ.push([
      "addCartRows",
      [{
        "productId": item.item_id, 
        "quantity": item.qty, 
        "unitPrice": totalPrice, 
        "sku": item.sku, 
          }]
          ]);
            })
  window.monetateQ.push(["trackData"]);
    } 



export function monetatePurchase(giftCard,totalItemNumber,shippingNumber,discountNumber,giftCardNumber,items,confirmId){  
  window.monetateQ = window.monetateQ || [];
  window.monetateQ.push(["setPageType", "purchased"])
  items.forEach(item => {
    let unitPrice = item.unit_price
    let value = item.qty
    let allowsPersonalization = item.personalized
    let totalPricewithBuiltInDiscount = allowsPersonalization ? (unitPrice * (1 + (value - 1) / 2)) / value : (unitPrice * value)
    let productGift
    if (giftCard >= (totalItemNumber)) {
      productGift = 0
    } else {
      productGift = ((totalPricewithBuiltInDiscount) + (shippingNumber || 0) - (discountNumber || 0) - (giftCardNumber || 0)).toFixed(2);
    }
    window.monetateQ.push(["addPurchaseRows", [{
      "purchaseId": confirmId,
      "productId": item.item_id,
      "quantity": item.qty,
      "unitPrice": productGift
    }]
    ]);
  })
  window.monetateQ.push(["trackData"]);
}



export function monetateProductPage(productObj,item){ 
  window.monetateQ = window.monetateQ || [];
  window.monetateQ.push(["setPageType", "product"]);  
  window.monetateQ.push(["addProductDetails", [{
      "productId": productObj.defaultItemId,
      "sku": item.sku
      }]
    ]);
    window.monetateQ.push(["trackData"]);
  }

  export function monetateEmptyCartTracker(){ 
    window.monetateQ = window.monetateQ || [];
    window.monetateQ.push(["setPageType", "cart"]);  
      window.monetateQ.push(["trackData"]);
    }
  
  
export function  handleQuanityChangeMonetate(cartItem) {
   
  if(window.location.pathname === "/cart"){
  setIntervalHelper(() =>{monetateCartTracker( cartItem)});
    
  }else{
    setIntervalHelper(() =>{monetateAddToCart(cartItem)});
      }}
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { closeModal, update_shipping_final, is_fetching, update_order_pending } from '../../actions'
import { path, pathOr } from 'ramda'
import { Button, Radio } from 'antd'
import addDefaultSrc from '../../helpers/addDefaultSrc'
import '../../assets/stylesheets/address-confirmation.scss'

const RadioGroup = Radio.Group;

class AddressConfirmationModal extends React.Component {
    static contextTypes = {
        router: PropTypes.object
    }
    constructor(props) {
        super(props);
        this.state = {
            value: 1,
        }
    }
    onChange = (e) => {
        this.setState({
            value: e.target.value,
        })
    }
    saveAddress() {
        const { shippingValidation: { city, stateAbbreviated, zip, deliveryLine1, deliveryLine2, zipLastFour }, dispatch, order,  user, zeroDollarOrder, paymentDone, reason_code, executeBrainTreePayment, braintreeNonce } = this.props;
        if (this.state.value === 1) {
            //customer choose address suggested by smarty streets
            const new_address = [{
                id: pathOr(0, ['shipping', '0', 'id'], order),
                method: this.props.shipMethod,
                carrier: order.shipping[0].carrier,
                name: order.shipping[0].name,
                last_name: order.shipping[0].last_name,
                address1: deliveryLine1,
                address2: deliveryLine2,
                city: city,
                state: stateAbbreviated,
                zip: `${zip}-${zipLastFour}`,
                country: 'US',
                business: false
            }]
            dispatch(is_fetching())
            dispatch(update_shipping_final(order.id, 'shipping', new_address, reason_code))
                .then((response) => {
                    // execute payment with stripe
                    // if (!zeroDollarOrder) {
                    //     // if balance due, process Stripe payment
                    //     executeStripePayment(stripeTokenId, user.id, order.id)
                    //     dispatch(is_fetching())
                    // } else {
                        // zero dollar order
                        // dispatch(update_order_pending(order.id))
                        // dispatch(is_fetching())
                        // paymentDone()
                    // }
                    if(!zeroDollarOrder) {
                        executeBrainTreePayment(braintreeNonce, user.id, order.id)
                        dispatch(is_fetching())
                    } else {
                        dispatch(update_order_pending(order.id))
                        dispatch(is_fetching())
                        paymentDone()
                    }
                })
                .catch((err) => console.log(err))
        } else {
            //customer choose to stick with their address
            // if (!zeroDollarOrder) {
            //     // execute payment with stripe
            //     executeStripePayment(stripeTokenId, user.id, order.id)
            //     dispatch(is_fetching())
            // } else {
            //     // zero dollar order
            //     dispatch(update_order_pending(order.id))
            //     dispatch(is_fetching())
            //     paymentDone()
            // }
            if(!zeroDollarOrder) {
                executeBrainTreePayment(braintreeNonce, user.id, order.id)
                dispatch(is_fetching())
            } else {
                dispatch(update_order_pending(order.id))
                dispatch(is_fetching())
                paymentDone()
            }
        }
    }

    render() {
        const { shippingValidation: { deliveryLine1, deliveryLine2, lastLine }, order, handlePlaceOrder } = this.props;
        const defaultAddress1 = path(['shipping', '0', 'address1'], order)
        const defaultAddress2 = path(['shipping', '0', 'address2'], order)
        const shippingName = path(['shipping', '0', 'name'], order)
        const shippingLastname = path(['shipping', '0', 'last_name'], order)
        const defaultCity = path(['shipping', '0', 'city'], order)
        const defaultState = path(['shipping', '0', 'state'], order)
        const defaultZipCode = path(['shipping', '0', 'zip'], order)
        return (<div className="shell-container main-container main-container-gifting-form">
            <div className="address-verification-container">
                <div className='address-verification-heading'>
                    <div className="address-verification-heading">
                        <img className="danger-sign" alt="ALERT" onError={addDefaultSrc}
                            src={'https://storage.googleapis.com/myphoto-img/icons/danger-sign.svg'} />
                        Please Verify Your Address To Complete Your Order</div>
                </div>
                <div className="address-suggestion-container">
                    <RadioGroup
                        onChange={this.onChange}
                        value={this.state.value}
                    >
                        <span className="columns">
                            <div className="column">
                                <h3 className="address-heading">We Suggest:</h3>
                            </div>
                            <div className="column">
                                <Radio value={1}>
                                    <span className="address-content">
                                        <p>{shippingName} {shippingLastname}</p>
                                        <p>{deliveryLine1} {deliveryLine2}</p>
                                        <p>{lastLine}</p>
                                    </span>
                                </Radio>
                            </div>
                        </span>
                        <br />
                        <br />
                        <span className="columns">
                            <div className="column">
                                <h3 className="address-heading">You entered:</h3>
                            </div>
                            <div className="column">
                                <Radio value={2}>
                                    <span className="address-content">
                                        <p>{shippingName} {shippingLastname}</p>
                                        <p>{defaultAddress1} {defaultAddress2}</p>
                                        <p>{defaultCity} {defaultState} {defaultZipCode}</p>
                                    </span>
                                </Radio>
                            </div>
                        </span>
                    </RadioGroup>
                </div>
                <div className="address-buttons-container columns">
                    <div className="column " style={{textAlign: 'center'}}>
                        <button
                            style={{fontSize: '14px', height: '52px'}}
                            className="button back-button"
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.dispatch(closeModal('ADDRESS_CONFIRMATION'))
                                handlePlaceOrder(false)
                            }}
                        >
                            <span> Go Back and Edit </span>
                        </button>
                    </div>
                    <div className="column place-order">
                        <Button
                            type="primary"
                            className="button place-order-btn"
                            onClick={(e) => {
                                e.preventDefault();
                                //paymentDone()
                                this.saveAddress()
                            }}
                        >
                            <span>Place order with selected Address</span>
                        </Button>
                    </div>
                </div>
            </div>

        </div>
        )
    }
}
export default connect()(AddressConfirmationModal)

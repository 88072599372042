import React from 'react'

export default ({yotpoId, product}) => {
  return (
    <div>
      <div className="yotpo yotpo-main-widget"
        data-product-id={yotpoId}
        data-name={product.name}
        data-url="http%3A%2F%2Fwww.myphoto.com%2Fproducts%2Fmoderna-metal" // TODO: Change this and below
        data-image-url="http%3A%2F%2Fonepic.photoandgo.com.s3.amazonaws.com%2Fproductgroup%2F2%2FWebsiteMainImages_Metal.jpg"
        data-description={product.description}
      >
      </div>
    </div>
  )
} 
import React from 'react'
import autoBind from 'react-autobind'
import { new_upsell_item } from '../../actions'
import '../../assets/stylesheets/upsell-modal.scss'

class UpsellModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      qty: [...Array(51).keys()].slice(1,51), // to remove the '0' from first index
      qtyValue: 1
    }
    autoBind(this);
  }

  render(){
    const { handleCancelUpsellModal, upsellItem, userId, dispatch, orderId, upsellDescription, upsellDiscount } = this.props
    const { qtyValue } = this.state
    return(
      <div className="upsell-modal-container">
        <h2 className='upsell-title'>Today's Offer!</h2>
        <p className='upsell-subtitle'>Don't miss your chance...</p>
        <div className="upsell-product-container">
          <div className="upsell-image-container">
            <img src={upsellItem.img} alt=''/>
          </div>
          <div className="upsell-description-container">
            <div className="upsell-title-cont">
              <h3 className="upsell-item-title">{upsellItem.name}</h3>
              <div className="upsel-price-cont">
                <p className="upsell-price-title">Today's price</p>
                <p className="upsell-price"><span className="upsell-price-no-more">${upsellItem.msrp.toFixed(2)}</span><span className="upsell-price-offer">${(upsellItem.msrp * upsellDiscount).toFixed(2)}</span></p>
              </div>
            </div>
            <p className='upsell-description' dangerouslySetInnerHTML={{ __html: upsellDescription }}></p>
            <div className='upsell-quantity-cont'>
              <p className='upsell-qty-text'>qty:</p>
              <select 
              className='upsell-select-qty'
              value={this.state.qtyValue}
              onChange={e=>{
                this.setState({
                  qtyValue: e.target.value
                })
              }}
              >
              {this.state.qty.map((option, id) => {
                return <option key={id} value={option}>{option}</option>
              })}
              </select>
            </div>
          </div>
        </div>
        <div className="upsell-cta-cont">
          <div 
            className="upsell-decline"
            onClick={(e=> {e.preventDefault(); handleCancelUpsellModal()})}
          >No, thanks</div>
          <button 
            className="upsell-accept"
            onClick={(e => {
              e.preventDefault()
              dispatch(new_upsell_item(upsellItem, qtyValue, userId, orderId, upsellDiscount))
              handleCancelUpsellModal()
            })}
          >Add to cart</button>
        </div>
      </div>
    )
  }
}

export default UpsellModal
import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { pathOr, path, isEmpty, isNil } from 'ramda'
import _ from 'lodash'
import autoBind from 'react-autobind'
import ReactGA from 'react-ga'
import { message, Form, Input, Button, Modal } from 'antd'
import { Formik, Form as FormikForm, Field } from 'formik'
import {
  clearGiftCardBalance, setGiftCardError, fetch_valid_promo, fetchGiftCardBalance,
  removeDiscount, is_fetching, update_user, createTransaction, getTaxRate, remove_giftcard,
  clearGiftCardError, update_order_pending, smartyStreetsApi, openModal, affirm_checkout,
  update_shipping_final, updateFinalOrder, update_shipping_method,
  disableUpsellModal, fetchUpsellItem
} from '../../actions'
import { validatePanel } from './validation'
import Address from './Address.js'
import MetaDataTags from '../../helpers/ProcessMetaData'
import {setIntervalHelper, monetateCheckoutPageTracker} from '../../helpers/monetate'
import GiftNote from './GiftNote.js'
import PaymentForm from './PaymentForm.js'
import PaypalButton from './PaypalButton'
import FormItemInput from './FormItemInput'
import ShowConfirm from './ShowConfirm'
import GuaranteeRibbonWhite from '../common/guaranteeUpdates/GuaranteeRibbonWhite'
import UpsellModal from './UpsellModal'
import BrainTreePayments from './BrainTreePayments'
import '../../assets/stylesheets/page-shell.scss'
import '../../assets/stylesheets/checkout-page.scss'
import '../../components/checkout/PayForm.css'


const FormItem = Form.Item
const staging = (window.location.href.indexOf("staging.") > -1 || window.location.href.indexOf("develop.") > -1 || window.location.href.indexOf(".local") > -1) ? true : false

const linkStyle = {
  outline: 'none',
  border: 'none',
  textDecoration: 'underline',
  color: '#999999',
  background: 'none'
}

const propTypes = {
  shippingAddress: PropTypes.object,
  billingAddress: PropTypes.object,
  dispatch: PropTypes.func,
  order: PropTypes.object,
  seo: PropTypes.object,
  subdomain: PropTypes.string,
  paymentApproved: PropTypes.bool,
  paymentError: PropTypes.bool,
  products: PropTypes.array,
  items: PropTypes.array,
  discount: PropTypes.object,
  order_success: PropTypes.bool,
  shippingOptions: PropTypes.array,
}
const CLIENT = {
  sandbox: 'AaTh2-NEFJ0o6AdfB0Hdu0Zi4wVT-sHfB4DfSi6w8RMgPrxTJ5hUrOGQG8MQevqBdGX6upXEmPw8necX',
  production: 'AcQ9D6x3bPEdrwiRbwEnysu1U-k6zCSrwRxhPt3EuOaYeglULWfx1aQbOpdYYTbgQ30r0hfjMH8oUujT'
} //Paypal Keys

const ENV = (staging) ? 'sandbox' : 'production' //Paypal Keys

const PaypalButtonStyle = {
  color: 'black',
  shape: 'rect',
  label: 'pay',
  tagline: 'false',
  size: 'responsive'
}
const autoShipStates = ['AL', 'GA', 'KY', 'NC', 'SC', 'TN', 'VA', 'WV', 'AR', 'CT', 'DC', 'DE', 'IL', 'IN', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'NH', 'NJ', 'NY', 'OH', 'PA', 'RI', 'VT', 'IA', 'KS', 'ND', 'NE', 'OK', 'SD', 'TX', 'WI', 'AZ', 'CA', 'CO', 'ID', 'MT', 'NM', 'NV', 'OR', 'UT', 'WA', 'WY']
const apoStates = ['AA', 'AE', 'AP']
var reason_code;
class Checkout extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  }
  constructor(props) {
    super(props)
    const paidShipping = ((path(['shipping_amount'], props.order) || 0) > 10)
    const thisShippingOption = props.shippingOptions.find(s => (paidShipping && s.price > 10) || s.price < 10)
    this.state = {
      billing_address1: '',
      billing_address2: '',
      billing_city: '',
      billing_country: '',
      billing_last_name: path(['lastName'], props.user) || '',
      billing_name: path(['name'], props.user) || '',
      billing_state: '',
      billing_zip: '',
      shippingAddress: {
        name: path(['name'], props.user) || '',
        last_name: path(['lastName'], props.user) || '',
        address1: path(['shipping', '0', 'address1'], props.order) || '',
        address2: path(['shipping', '0', 'address2'], props.order) || '',
        city: path(['shipping', '0', 'city'], props.order) || '',
        state: path(['shipping', '0', 'state'], props.order) || '',
        zip: path(['shipping', '0', 'zip'], props.order) || '',
        id: path(['shipping', '0', 'id'], props.order) || 0,
        carrier: ''
      },
      giftNote: '',
      giftCard: {
        balance: 0,
        error: null,
        drawdown: 0,
      },
      email: path(['email'], props.user) || '',
      name: path(['name'], props.user) || '',
      lastName: path(['lastName'], props.user) || '',
      phone: (props.user.phone)
        ? (props.user.phone.indexOf('+1') === 0) ? props.user.phone.substring(2) : props.user.phone
        : '',
      personalFieldsValid: false,
      giftCode: '',
      billToSame: true,
      shippingDescription: `${thisShippingOption.name} (${thisShippingOption.description})` || 'Fast Shipping (Delivered in 5 business days)',
      shipMethod: thisShippingOption.label || 'FREE SHIPPING',
      showGiftbox: false,
      hasGiftCode: false,
      paymentProcessing: false,
      payWithCC: false,
      showPaymentOptions: true,
      showShipping: false,
      showCC: true,
      showModal: false,
      stripeTokenId: null,
      zeroDollarOrder: false,
      visible: false,
      showConfirmation: false,
      cancelConfirm: false,
      loadingButton: false,
      zeroDollarOrderInvalidApt: false,
      PromoAppliedSuccess: false,
      PromoFailure: false,
      giftCardFailure: false,
      enablePlaceOrderButton: false,
      invalidAddressPopUp: false,
      smartySuccess: '',
      zeroDollarOrderInvalidAddress: false,
      addressValidationFailure: false,
      testCodeApplied: false,
      visibleUpsellModal: false,
      showUpsellModal: false,
      upsell_item: {},
      showUpsellLink: false,
      placeOrder: false,
      formikShipping: {},
      showPlaceOrder: false,
      payWithAffirm: false,
    };
    this.getTax = _.debounce(this.getTax, 387)
    autoBind(this);
  }
    componentDidMount(){
      if(this.props.order && this.props.order.items){
        setIntervalHelper(()=> {monetateCheckoutPageTracker (this.props.order.items)});
          }    
    document.body.scrollTop = 0
    const upsell_sku = path(['appConfig', 'upsellSku'], this.props.config)
    this.props.dispatch(fetchUpsellItem(upsell_sku))
      .then((res) => {
        const hasUpsellItem = path(['items'], this.props.order) && path(['items'], this.props.order).find(item => item && item.sku === upsell_sku)
        if (upsell_sku && !hasUpsellItem) {
          this.setState({
            showUpsellLink: true
          })
        }
        if (this.props.upsellModal && upsell_sku && !hasUpsellItem) { // display upsell modal only once and if upsellSku exists
          setTimeout(() => {
            this.setState({
              visibleUpsellModal: true,
              showUpsellModal: true,
              upsell_item: res
            })
          }, 2000);
        }
      })
      .catch((e) => {
        console.log('Error fetching upsellItem', e)
      })

    if (this.props.user) {
      const { name, email, lastName, phone } = this.props.user
      if (!isNil(email) && !isNil(name) && !isNil(lastName) && !isNil(phone)) {
        this.setState({
          personalFieldsValid: true
        })
      }
    }
    if (this.props.reduxPromoCode && this.state.testCodeApplied === false) {
      this.validatePromoCode(this.props.reduxPromoCode)
    }
    // track checkout view in GA
    ReactGA.plugin.require('ec') // require ecommerce enhance GA plugin
    ReactGA.plugin.execute('ec', 'setAction', 'checkout', {
      'step': 1
    });
    // facebook tracking
    window.fbq('track', 'InitiateCheckout');
    window.gtag('event', 'page_view', {
      'send_to': 'AW-668262331',
      'ecomm_pagetype': 'other'
    });
    // klaviyo
    const subdomain = path(['appConfig', 'subdomain'], this.props.config)
    if (this.props.salesChannelId !== 4 && this.props.order && this.props.products && this.props.items && subdomain.toLowerCase() !== 'frontend') {
      const { order, products, items } = this.props
      const KlaviyoItems = order.items.map(order_item => {
        const this_item = items.find(item => item.id === order_item && order_item.item_id)
        const this_product = (this_item) ? products.find(product => product.id === this_item.productId) : null
        if (this_product) {
          const prodStyle = (this_product.attributes && this_product.attributes[0]) ? this_product.attributes[0] : this_product.slug
          return {
            "SKU": order_item.sku,
            "ProductName": order_item.name,
            "Quantity": order_item.qty,
            "ItemPrice": order_item.unit_price,
            "RowTotal": order_item.total,
            "ProductURL": `https://www.myphoto.com/products/${this_product.category}/${prodStyle}/${this_product.slug}`,
            "ImageURL": order_item.photopersonalization[0].previewUrl,
            "ProductCategories": this_product.attributes.concat(this_product.category)
          }
        } else {
          return null
        }
      })
      window._learnq.push(['track', 'Started Checkout', {
        "$event_id": order.confirm_id, // The cart ID if you have it. Otherwise remove this line.
        "$value": order.merchandise_amount,
        "ItemNames": order.items.map(order_item => order_item && order_item.name),
        "CheckoutURL": "https://www.myphoto.com/checkout",
        "Items": KlaviyoItems,
        "subdomain": subdomain,
        "SalesChannelId": this.props.salesChannelId
      }])
    }
  }
  componentWillReceiveProps(nextProps) {
    // check if upsell item is not in cart
    const upsell_sku = path(['appConfig', 'upsellSku'], this.props.config)
    const propsUpsellItem = path(['items'], this.props.order) && path(['items'], this.props.order).find(item => item.sku === upsell_sku)
    const nextPropsUpsellItem = path(['items'], nextProps.order) && path(['items'], nextProps.order).find(item => item.sku === upsell_sku)
    if (upsell_sku && (propsUpsellItem !== nextPropsUpsellItem)) {
      this.setState({
        showUpsellLink: false
      })
    }
    // check if order_success
    if (this.props.order_success === false && nextProps.order_success) {
      this.paymentDone()
    }
    const { payWithCC } = this.state
    const propTotal = pathOr(0, ['total_amount'], this.props.order);
    const propGiftcard = pathOr(0, ['giftcard_redeemed'], this.props.order);
    const newPropTotal = pathOr(0, ['total_amount'], nextProps.order);
    const newGiftcard = pathOr(0, ['giftcard_redeemed'], nextProps.order);
    if (propTotal !== newPropTotal || propGiftcard !== newGiftcard) {
      // something changed
      if (newPropTotal - newGiftcard <= 0) {
        // ZERO DOLLAR ORDER
        this.setState({ showPaymentOptions: false, showShipping: true, showCC: false, zeroDollarOrder: true, showPlaceOrder: true, enablePlaceOrderButton: true })
      } else {
        // YOU OWE MONEY
        if (payWithCC) {
          this.setState({ showPaymentOptions: false, showShipping: true, showCC: true, zeroDollarOrder: false })
        } else {
          this.setState({ showPaymentOptions: true, showShipping: false, showCC: true, zeroDollarOrder: false })
        }
      }
    }
    if (this.props.salesChannelId !== 4 && ((!this.props.order && nextProps.order) || this.props.products !== nextProps.products || path(['merchandise_amount'], this.props.order) !== path(['merchandise_amount'], nextProps.order))) {
      const { order, products, items } = nextProps
      const KlaviyoItems = order.items.map(order_item => {
        const this_item = items.find(item => item.id === order_item && order_item.item_id)
        const this_product = (this_item) ? products.find(product => product.id === this_item.productId) : null
        if (this_product) {
          const prodStyle = (this_product.attributes && this_product.attributes[0]) ? this_product.attributes[0] : this_product.slug
          return {
            "SKU": order_item.sku,
            "ProductName": order_item.name,
            "Quantity": order_item.qty,
            "ItemPrice": order_item.unit_price,
            "RowTotal": order_item.total,
            "ProductURL": `https://www.myphoto.com/products/${this_product.category}/${prodStyle}/${this_product.slug}`,
            "ImageURL": order_item.photopersonalization[0].previewUrl,
            "ProductCategories": this_product.attributes.concat(this_product.category)
          }
        } else {
          return null
        }
      })
      window._learnq.push(['track', 'Started Checkout', {
        "$event_id": order.confirm_id, // The cart ID if you have it. Otherwise remove this line.
        "$value": order.merchandise_amount,
        "ItemNames": order.items.map(order_item => order_item && order_item.name),
        "CheckoutURL": "https://www.myphoto.com/checkout",
        "Items": KlaviyoItems,
        "SalesChannelId": this.props.salesChannelId
      }])
    }
  }
  paymentDone() {
    this.setState({
      processing: false,
      giftCode: null,
      hasGiftCode: false,
      giftCard: {
        balance: 0,
        error: null,
        drawdown: 0,
      },
      discount: {},
      promotions: {}
    })
    if (this.props.salesChannelId !== 4) {
      const { order, items, products, user, config, salesChannelId } = this.props
      const subdomain = path(['appConfig', 'subdomain'], config)
      const revenue = order.merchandise_amount
      ReactGA.plugin.require('ec') // require ecommerce enhance GA plugin
      var fbItems = [];
      var totalQty = 0;
      order.items.forEach(i => {
        // get parent product
        const { config, newUpsellItem } = this.props
        const { upsellSku } = config && config.appConfig && config.appConfig
        const new_item = (i.sku === upsellSku) ? newUpsellItem : items.find(ix => ix.id === i && i.item_id)
        const thisProduct = (i.sku === upsellSku) ? newUpsellItem.name : products.find(p => p.id === new_item && new_item.productId)
        // add product to GA
      
        ReactGA.plugin.execute('ec', 'addProduct', {
          'id': i.sku,
          'name': i.name,
          'category': thisProduct && thisProduct.name,
          'brand': 'MyPhoto',
          'price': i.unit_price,
          'quantity': i.qty
        });
        fbItems.push({ id: i.sku, quantity: i.qty, item_price: i.unit_price });
        totalQty = totalQty + i.qty
      })
      console.log('TRIGGERING GTAGS @@@@', order.total_amount, order.confirm_id)
      // GoogleTag:
      window.gtag('event', 'conversion', {
        'send_to': 'AW-668262331/dmbtCObB-MIBELu_074C',
        'value': revenue,
        'currency': 'USD',
        'transaction_id': order.confirm_id,
      })
      // Bing:
      window.uetq.push('event', 'purchase', {
        'event_category': 'purchase',
        'revenue_value': revenue.toString(),
        'currency': 'USD'
      
      })
      // Snap Chat
      window.snaptr('track', 'PAGE_VIEW', {
        'user_email': user.email
      });
     console.log("yeet",this.props)
      // Push order to GA:
      ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
       
        id: order.confirm_id,
        revenue : (order.merchandise_amount) - (order.discount_amount) + (order.shipping_amount),
        coupon: (order.promocode) !== null ? (order.promocode):
        "",
        affiliation: 'MyPhoto',
        tax: order.tax_amount,
        shipping: order.shipping_amount,
      });
      window.fbq('track', 'Purchase', {
        contents: fbItems,
        content_type: 'product',
        value: revenue,
        currency: 'USD',
      });
      window.pintrk('track', 'checkout', {
        value: revenue,
        order_quantity: totalQty,
        currency: 'USD',
      });

          //custom dimension GA staging for giftcard//
          ReactGA.set({ dimension3: this.props.order.giftcard_redeemed });
      // Pepperjam
      const discountFix = order.discount_amount / order.merchandise_amount
      var iframe = document.createElement('iframe')
      iframe.height = 1
      iframe.width = 1 // will this 1 px change our CSS??
      iframe.frameborder = 0
      iframe.src = `https://t.pepperjamnetwork.com/track?INT=DYNAMIC&PROGRAM_ID=9480&ORDER_ID=${order.confirm_id}`
      if (order.promocode) {
        iframe.src += `&COUPON=${order.promocode.toUpperCase()}`
      }
      order.items.forEach((pitem, index) => {
        iframe.src += `&ITEM_ID${index + 1}=${pitem.sku}&ITEM_PRICE${index + 1}=${(1.0 - discountFix) * pitem.total / pitem.qty}&QUANTITY${index + 1}=${pitem.qty}`
      })
      document.body.appendChild(iframe) // will this mount before above forEach finishes??
      // TV-Squared:
      window._tvq.push([function () {
        this.setCustomVariable(5, 'session', JSON.stringify({ 'user': `${user.id}` }), 'visit')
      }])
      window._tvq.push([function () {
        this.setCustomVariable(5, 'purchase', JSON.stringify({ 'rev': `${revenue}`, 'prod': '', 'id': `${order.confirm_id}`, 'promo': `${order.promocode}` }), 'page')
      }])
      window._tvq.push(['trackPageView'])
      //Klaviyo push to Api
      if (subdomain && subdomain.toLowerCase() !== 'frontend') {
        window._learnq.push(['track', 'Placed Order', {
          "$event_id": order.confirm_id,
          "$value": Number(order.merchandise_amount) - Number(order.discount_amount),
          "ItemNames": order.items.map(order_item => order_item.name),
          "Discount Code": order.promocode,
          "Discount Value": order.discount_amount,
          "subdomain": subdomain,
          "SalesChannelId": salesChannelId,
          "Items": order.items.map(order_item => {
            const { config } = this.props
            const { upsellSku } = config && config.appConfig && config.appConfig
            const upsell_item = (upsellSku === order_item.sku) ? true : false
            const this_item = upsell_item ? null : items.find(item => item.id === order_item && order_item.item_id)
            const this_product = (this_item) ? products.find(product => product.id === this_item.productId) : null
            if (this_product) {
              const prodStyle = (this_product.attributes && this_product.attributes[0]) ? this_product.attributes[0] : this_product.slug
              const prodUrl = `https://www.myphoto.com/products/${this_product.category}/${prodStyle}/${this_product.slug}`
              // send each order item
              window._learnq.push(['track', 'Ordered Product', {
                "$event_id": `${order.confirm_id}-${order_item.id}`,
                "$value": order_item.total,
                "ProductID": this_product.id,
                "SKU": order_item.sku,
                "ProductName": order_item.name,
                "Quantity": order_item.qty,
                "ProductURL": prodUrl,
                "ImageURL": order_item.photopersonalization[0].previewUrl,
                "ProductCategories": this_product.attributes.concat(this_product.category),
                "ProductBrand": "MyPhoto",
                "subdomain": subdomain,
                "SalesChannelId": salesChannelId,
              }])
              return {
                "ProductID": this_product.id,
                "SKU": order_item.sku,
                "ProductName": order_item.name,
                "Quantity": order_item.qty,
                "ItemPrice": order_item.unit_price,
                "RowTotal": order_item.total,
                "ProductURL": prodUrl,
                "ImageURL": order_item.photopersonalization[0].previewUrl,
                "ProductCategories": this_product.attributes.concat(this_product.category),
                "Brand": "MyPhoto",
              }
            } else {
              return null
            }
          })
        }])
      }
      // now push to obApi (OutBrain)
      window.obApi('track', 'Revenue', { orderValue: revenue, currency: 'USD', orderId: order.confirm_id });
    }
    this.context.router.history.push('/order-success')
  }
  handleProceedAnyway = (e) => { // Proceed anyway button from INVALID APT / INVALID ADDRESS pop up
    const { order } = this.props
    const { smartySuccess, braintreeNonce } = this.state
    const ReduxShipping = path(['shipping', '0'], order)
    this.setState({ loadingButton: true });
    if (smartySuccess) { //if it's an Invalid Apt model
      setTimeout(() => {
        this.setState({ loadingButton: false, visible: false, cancelConfirm: true, paymentProcessing: true });
        if (!this.state.zeroDollarOrderInvalidApt) {
          this.handleStripeCheckout(braintreeNonce, ReduxShipping)
        } else {
          this.handleChangePlaceOrder(braintreeNonce, ReduxShipping)
        }
      }, 2000);
    } else { //if it's an Invalid Address model
      setTimeout(() => {
        this.setState({ loadingButton: false, visible: false, cancelConfirm: true, invalidAddressPopUp: false, paymentProcessing: true });
        if (!this.state.zeroDollarOrderInvalidAddress) { // if balance due, process Stripe payment --- GIFTCARD + STRIPE
          this.executeBrainTreePayment(this.state.braintreeNonce, this.props.user.id, this.props.order.id)
        } else { // zero dollar order
          this.props.dispatch(update_order_pending(this.props.order.id))
        }
      }, 2000);
    }
  }

  executeBrainTreePayment(braintreeNonce, userId, orderId) {
    const { dispatch } = this.props
    dispatch(is_fetching())
    dispatch(createTransaction(braintreeNonce, userId, orderId, 'stripe')) // create stripe charge via our API
      .then((response => {
        // console.log("MAKING PAYMENT")
        if (response !== "Approved") {
          // console.log("DECLINED PAYMENT")
          this.setState({ paymentProcessing: false })
          message.error(response || "CreditCard Payment Failed.", 10)
        }
      }));
  }

  handleCancel = (e) => { // Go back and edit button from INVALID APT / INVALID ADDRESS pop up
    console.log(e);
    this.setState({
      visible: false,
      cancelConfirm: false,
      paymentProcessing: false
    });
  }
  handlePlaceOrder(value) {
    this.setState({
      visible: false,
      cancelConfirm: false,
      paymentProcessing: false
    });
  }
  invalidAddressModal() {
    const { order } = this.props;
    const shippingAddressFirstName = path(['shipping', '0', 'name'], order)
    const shippingAddressLastName = path(['shipping', '0', 'last_name'], order)
    const shippingAddressOne = path(['shipping', '0', 'address1'], order)
    const shippingAddressTwo = path(['shipping', '0', 'address2'], order) ? path(['shipping', '0', 'address2'], order) : ''
    const shippingAddressCity = path(['shipping', '0', 'city'], order)
    const shippingAddressState = path(['shipping', '0', 'state'], order)
    const shippingAddressZipCode = path(['shipping', '0', 'zip'], order)
    return (
      <div>
        <Modal
          title="Hold On!"
          visible={this.state.visible}
          onOk={this.handleProceedAnyway}
          onCancel={this.handleCancel}
          footer={[
            <Button className="checkout-go-back-and-edit-button" key="back" onClick={this.handleCancel}>Go Back and Edit</Button>,
            <Button className="checkout-proceed-anyway-button" key="submit" type="primary" loading={this.state.loadingButton} onClick={this.handleProceedAnyway}>
              Proceed Anyway
            </Button>,
          ]}
        >
          <p className="address-not-valid">Shipping Address appears to be undeliverable</p>
          <p className="address-not-valid-deliveryLine">{shippingAddressFirstName} {shippingAddressLastName}</p>
          <p className="address-not-valid-deliveryLine">{shippingAddressOne} {shippingAddressTwo}</p>
          <p className="address-not-valid-deliveryLine">{shippingAddressCity} {shippingAddressState} {shippingAddressZipCode}</p>
        </Modal>
      </div>
    );
  }
  invalidAptPopUp() {
    return (
      <div>
        <Modal
          title="Hold On!"
          visible={this.state.visible}
          onOk={this.handleProceedAnyway}
          onCancel={this.handleCancel}
          footer={[
            <Button className="checkout-go-back-and-edit-button" key="back" onClick={this.handleCancel}>Go Back and Edit</Button>,
            <Button className="checkout-proceed-anyway-button" key="submit" type="primary" loading={this.state.loadingButton} onClick={this.handleProceedAnyway}>
              Proceed Anyway
            </Button>,
          ]}
        >
          <p className="apt-not-valid">Apt/Suite Number Not Found Or Invalid.</p>
        </Modal>
      </div>
    );
  }
  handleSmartyValidation(braintreeNonce, shipping, index) {
    const { user, order, dispatch } = this.props
    const { smartySuccess } = this.state
    if (smartySuccess) { // smarty streets api returns true
      const { shippingValidation: { dpvCode, city, stateAbbreviated, zip, deliveryLine1, zipLastFour, deliveryLine2, zipType, recordType, dpvFootNotes } } = this.props
      const POBoxCheck = (recordType === 'P' || zipType === 'POBox' || (['PB', 'P1', 'P3'].includes(dpvFootNotes)))
      const MilitaryCheck = (zipType === 'Military' || dpvFootNotes === 'F1' || apoStates.includes(stateAbbreviated))
      const stateCheck = autoShipStates.includes(stateAbbreviated)
      reason_code = MilitaryCheck
        ? 'DA-ML'
        : POBoxCheck
          ? 'DA-PO'
          : !stateCheck
            ? 'DA-ST'
            : null
      console.log('reason_code', reason_code)
      if ((dpvCode === 'S' || dpvCode === 'D') && !this.state.cancelConfirm) {
        if (index === 'processStripeOrder') {
          // Invalid apt Dialog Box + stripe + giftcard
          this.setState({ showConfirmation: true, visible: true, showModal: true, })
          return false
        } else if (index === 'processZeroDollarOrder') {
          // Invalid apt Dialog Box + zeroDollar Order
          this.setState({ showConfirmation: true, visible: true, showModal: true, zeroDollarOrderInvalidApt: true })
          return false
        }
      } // end of Invalid apt Dialog Box
      // here .... verify with smarty THEN makePayment
      if ((dpvCode === 'S' || dpvCode === 'Y' || dpvCode === 'D') && (shipping.city.toLowerCase() !== city.toLowerCase() || shipping.state.toLowerCase() !== stateAbbreviated.toLowerCase() || shipping.zip !== zip)) {
        // Suggested address Modal + zeroDollar Order
        this.setState({ paymentProcessing: false })
        return this.props.dispatch(openModal('ADDRESS_CONFIRMATION'))
      } else {
        // Update shipping address with smarty streets address
        const new_address = [{
          id: pathOr(0, ['shipping', '0', 'id'], order),
          carrier: pathOr('', ['shipping', '0', 'carrier'], order),
          method: this.state.shipMethod,
          name: shipping.name,
          last_name: shipping.last_name,
          address1: deliveryLine1,
          address2: deliveryLine2,
          city: city,
          state: stateAbbreviated,
          zip: `${zip}-${zipLastFour}`,
          country: 'US',
          business: false
        }]
        dispatch(update_shipping_final(order.id, 'shipping', new_address, reason_code))
          .then((res) => {
            if (index === 'processStripeOrder') { // process stripe + giftcard transaction
              this.executeBrainTreePayment(braintreeNonce, user.id, order.id)
            }
            else if (index === 'processZeroDollarOrder') { // process zeroDollar Order
              dispatch(update_order_pending(order.id))
            }
          })
          .catch((err) => console.log("ERROR:", err))
      }
    } else { // smarty streets API returned false
      reason_code = 'DA-UN'
      if (index === 'processStripeOrder' && !this.state.cancelConfirm) { // process stripe + giftcard transaction
        this.setState({
          invalidAddressPopUp: true,
          visible: true,
          zeroDollarOrderInvalidAddress: false
        })
      }
      if (index === 'processZeroDollarOrder' && !this.state.cancelConfirm) { // process zero dollar order
        this.setState({
          invalidAddressPopUp: true,
          visible: true,
          zeroDollarOrderInvalidAddress: true
        })
      }
    } // smarty streets end
  }
  handleStripeCheckout(braintreeNonce, shipping) {
    const { order } = this.props
    var balance = (order.total_amount) - (order.giftcard_redeemed);
    if (balance > 0 && balance < 0.5) {
      // CORRECT FOR MINIMUM STRIPE CHARGE OF $0.50
      balance = 0.5
    }
    // get the nonce from Braintree component
    if (braintreeNonce) {
      // this.setState({
      //   stripeTokenId: braintreeNonce
      // })
      // call smarty function and make Payment
      this.handleSmartyValidation(braintreeNonce, shipping, 'processStripeOrder')
    } else {
      //console.log("NO TOKEN FROM STRIPE...")
      message.error("Incorrect Card details.", 10)
      this.setState({ paymentProcessing: false })
    }
  }
  handleBraintreeCheckout(payloadNonce) {
    console.log('handleBraintreeCheckout', payloadNonce)
    this.setState({
      braintreeNonce: payloadNonce,
      placeOrder: false
    })
    this.smartyStreets(payloadNonce, this.state.formikShipping)
  }
  handleChangePlaceOrder(braintreeNonce, shipping) {
    const { order } = this.props
    // change PLACE ORDER button to spinner...
    if ((order.total_amount) > (order.giftcard_redeemed)) {
      console.log('if balance due, process Stripe payment --- GIFTCARD + STRIPE')
      // if balance due, process Stripe payment --- GIFTCARD + STRIPE
      this.handleStripeCheckout(braintreeNonce, shipping)
    } else {
      // zero dollar order...
      // ******** call smarty function THEN makePayment ********
      this.handleSmartyValidation(braintreeNonce, shipping, 'processZeroDollarOrder')
    }
    this.setState({ paymentProcessing: true })
  }
  validateGiftCard() {
    const { giftCode, hasGiftCode } = this.state
    const { dispatch, user, order } = this.props
    // dispatch(clearGiftCardError());
    dispatch(clearGiftCardBalance());
    if (hasGiftCode || !_.isEmpty((order.giftcard))) {
      this.setState({
        giftCode: '',
        hasGiftCode: false,
        giftCardAppliedSuccess: false,
      });
      dispatch(remove_giftcard(user.id))
      dispatch(is_fetching())
      return;
    }
    if (giftCode.length < 4) {
      const error = 'Please enter a valid gift card code';
      dispatch(setGiftCardError(error));
    } else {
      dispatch(fetchGiftCardBalance(giftCode, user.id))
        .then((res) => {
          switch (res) {
            case 'No balance on giftCard': message.info(res, 10)
              break;
            case 'Giftcard Applied': message.success(res, 10)
              break;
            case 'Invalid giftcard code': message.error(res, 10)
              break;
            default: message.error(res, 10)
              break;
          }
        })
        .catch((err) => message.error('Sorry something went wrong! Try again.'))
      dispatch(is_fetching())
      this.setState({
        hasGiftCode: true,
      });
    }
  }
  validatePromoCode(promoCode) {
    const { dispatch, order } = this.props
    const total = order.total_amount
    if (this.state.testCodeApplied === false) { this.setState({ testCodeApplied: true }) }
    dispatch(fetch_valid_promo(promoCode, total))
      .then((response) => {
        if (response === 'OK') {
          this.setState({ PromoAppliedSuccess: true })
          return message.success("Promo code discount applied.", 5)
        }
        else if(response.startsWith('Add')) {
          this.setState({ PromoFailure: false }) // if wrong promo code was added before
          return message.error(response, 10)
        }
        else {
          this.setState({ PromoFailure: true })
          return message.error(response, 5)
        }
      })
    dispatch(is_fetching())
  }
  renderPromoCodeErrors() {
    if (this.state.PromoAppliedSuccess) {
      return <div className="success_alert__message">* Promo code discount applied.</div>;
    }
    return this.state.PromoFailure ? <div className="alert__message">* Invalid Promo Code.</div> : null;
  }
  renderGiftCardErrors() {
    if (this.state.giftCardAppliedSuccess) {
      return <div className="success_alert__message">* Gift card applied successfully.</div>;
    }
    return this.state.giftCardFailure ? <div className="alert__message">* Invalid giftcard code.</div> : null;
  }
  getTax() { // DEBOUNCED
    const { dispatch, user } = this.props
    dispatch(getTaxRate(user.id))
    dispatch(is_fetching())
  }
  renderLinks(giftCardFromRedux) {
    const { user, dispatch, order, reduxPromoCode } = this.props
    // const testCode = 'fall18'
    if (giftCardFromRedux.error) {
      // message.error("Invalid GiftCard Code", 5)
      this.setState({
        giftCardFailure: true,
      })
      dispatch(clearGiftCardError())
    }
    const promoButtons = !_.isEmpty(order.promocode) ? 'REMOVE' : 'APPLY';
    const hasGift = this.state.hasGiftCode
    return (
      <div>
        <MetaDataTags baseUrl='checkout' />
        <Form className="payment__list">
          <FormItem label="Gift Card" labelCol={{ span: 9 }} wrapperCol={{ span: 15 }} validateStatus="error" help={this.renderGiftCardErrors()}>
            <Input.Search
              id='giftCode'
              defaultValue={order.giftcard}
              onSearch={(value, event) => {
                event.preventDefault()
                if (hasGift) {
                  document.getElementById('giftCode').value = '';
                  this.setState({ giftCode: '', hasGiftCard: false, giftCardAppliedSuccess: false, giftCardFailure: false }, () => { this.validateGiftCard() })
                }
                else {
                  this.setState({ giftCode: value, hasGiftCard: true, giftCardFailure: false }, () => { this.validateGiftCard() })
                }
              }}
              type="text"
              placeholder="88XX8XXXXXXXXX"
              className="column"
              enterButton={hasGift || !_.isEmpty(order.giftcard) ? 'REMOVE' : 'APPLY'}
            />
          </FormItem>
        </Form>
        <Form className="payment__list">
          <FormItem label="Promo Code" labelCol={{ span: 9 }} wrapperCol={{ span: 15 }} validateStatus="error" help={this.renderPromoCodeErrors()}>
            <Input.Search
              ref="promoCode"
              defaultValue={order.promocode || reduxPromoCode}
              type="text"
              placeholder="Promo Code"
              required
              enterButton={promoButtons}
              onSearch={(value, event) => {
                event.preventDefault()
                console.log('Default promo value', promoButtons, value)
                if (promoButtons === 'APPLY') {
                  if (value) {
                    this.setState({ promoCode: value, })
                    this.validatePromoCode(value)
                  } else {
                    this.setState({ promoCodeError: true, PromoFailure: true, })
                  }
                } else {
                  dispatch(is_fetching())
                  dispatch(removeDiscount(user.id))
                  this.setState({ promoCode: '', promoCodeError: null, PromoFailure: false, PromoAppliedSuccess: false })
                }
              }}
            />
          </FormItem>
        </Form>
      </div>
    );
  }
  renderPaymentButton() {
    const { showPlaceOrder, enablePlaceOrderButton, paymentProcessing, payWithAffirm } = this.state
    if(payWithAffirm){
      if(this.props.order.total_amount - this.props.order.giftcard_redeemed >= 50){
        return (
          <div className="checkout__actions is-fullwidth">
            <button
              disabled={!enablePlaceOrderButton}
              onClick={(e) => {
                e.preventDefault();
                this.affirmCheckout()
              }}
              className="button is-medium is-fullwidth affirm-checkout">
              Checkout with 
              <img className='affirm-logo' src={'/images/icons/affirm_logo.svg'} alt='lock' />
            </button>
          </div>
        )
      }
      else{
        this.setState({ payWithCC: false, showPaymentOptions: true, showShipping: false, showCC: false, payWithAffirm: false })
      }
      
    }
    else if (enablePlaceOrderButton) {
      return (
          <div className="checkout__actions is-fullwidth">
            <Button
              htmlType="submit"
              className="button is-medium is-fullwidth place-order-btn"
              value="Place Order"
              id="submit"
              loading={paymentProcessing}
              onClick={(e) => {
                e.preventDefault();
                if (showPlaceOrder) {
                  console.log('RENDER BUTTONS ***', showPlaceOrder, enablePlaceOrderButton)
                  this.smartyStreets(this.state.payloadNonce, this.state.formikShipping)
                } else {
                  this.braintreePlaceOrder()
                }
              }}
            >Place Order
            </Button>
          </div>
        ) 
    } else {
      return (<div className="checkout__actions is-fullwidth">
        <Button
          disabled
          className="button is-medium is-fullwidth place-order-btn"
          size="large"
          loading={paymentProcessing}
        >
          Place Order
        </Button>
      </div>)
    }
  }
  smartyStreets(braintreeNonce, shipping) {
    console.log('smartyStreets')
    const { formikShipping, shippingAddress } = this.state
    const new_shipping = formikShipping && !_.isEmpty(formikShipping.address1)
      ? formikShipping : shippingAddress
    console.log('sending Address to smartyStreets =>', new_shipping)
    if (shipping) {
      this.props.dispatch(smartyStreetsApi(new_shipping, 'stripe'))
        .then(
          (res) => {
            this.setState({
              smartySuccess: res
            })
            if (this.validateCheckout(new_shipping)) {
              // console.log('PLACING ORDER WITH SHIPPING NONCE!!', braintreeNonce )
              this.handleChangePlaceOrder(braintreeNonce, new_shipping)
            }
          }
        )
        .catch(err => {
          message.error("Please enter a valid shipping address!"); console.log("err", err)
          this.setState({
            paymentProcessing: false
          })
        })
    }
    else {
      console.log('No shipping to dispatch SmartyStreets', new_shipping)
      message.error('Please fill all the shipping address fields', 10)
    }
  }
  validateCheckout(shipping) {
    const { smartySuccess } = this.state;
    if (smartySuccess) {
      const { shippingValidation: { dpvCode, city, stateAbbreviated, zip } } = this.props
      if (dpvCode === 'Y') {
        if (shipping.city !== city || shipping.state !== stateAbbreviated || shipping.zip !== zip) {
          this.setState({ paymentProcessing: false, showModal: true, })
          return true
        }
      }
      if (dpvCode === 'N' && !this.state.addressValidationFailure) {
        this.setState({ paymentProcessing: false, addressValidationFailure: true })
        return message.error("Entered Address could not be validated, Please try again ") && false
      }
    }
    return true
  }
  toggleShowModal(showModalState) {
    this.setState({
      showModal: showModalState
    })
  }
  handleShippingMethod(method) {
    const { dispatch, user, shippingOptions } = this.props
    const thisOption = shippingOptions.find(s => s.label === method)
    this.setState({
      shippingDescription: `${thisOption.name} (${thisOption.description})`,
      shipMethod: method
    })
    dispatch(update_shipping_method(user.id, method))
    dispatch(is_fetching())
  }
  handleGiftNote(e) {
    console.log('Handling GiftNote', e)
    e.preventDefault();
    this.setState({ showGiftbox: !this.state.showGiftbox })
  }
  handleCancelUpsellModal = (e) => {
    this.setState({
      visibleUpsellModal: false,
    })
    this.props.dispatch(disableUpsellModal())
  }
  renderUpsellModal() {
    const { user, dispatch, order, config } = this.props
    const { upsellDescription, upsellDiscount } = config && config.appConfig
    const upsellItem = this.state.upsell_item
    if (upsellItem) {
      return (
        <Modal
          visible={this.state.visibleUpsellModal}
          onOk={this.handleOk}
          onCancel={this.handleCancelUpsellModal}
          footer={null}
        >
          <UpsellModal
            upsellItem={upsellItem}
            handleCancelUpsellModal={this.handleCancelUpsellModal}
            userId={user.id}
            dispatch={dispatch}
            orderId={order.id}
            upsellDescription={upsellDescription}
            upsellDiscount={upsellDiscount}
          />
        </Modal>
      )
    }
  }
  openUpsellModal() {
    const upsell_sku = this.props.config && this.props.config.appConfig && this.props.config.appConfig.upsellSku
    this.props.dispatch(fetchUpsellItem(upsell_sku))
      .then((res) => {
        const hasUpsellItem = this.props.order && this.props.order.items && this.props.order.items.find(item => item.sku === upsell_sku)
        if (upsell_sku && !hasUpsellItem) {
          this.setState({
            visibleUpsellModal: true,
            showUpsellModal: true,
            upsell_item: res
          })
        }
      })
      .catch((e) => {
        console.log('Error fetching upsellItem', e)
      })
  }
  braintreePlaceOrder = () => {
    this.setState({
      placeOrder: true,
    })
  }

  toggleMobilePlaceOrder() {
    this.setState({ placeOrder: false })
  }

  affirmCheckout = () => {
    const {dispatch, order, user} = this.props
    let items =  order.items.map((item)=>{
      return {
        sku: item.sku,
        display_name: item.name,
        unit_price: parseInt(item.unit_price * 100),
        qty: item.qty
      }
    })
    let shipping = {
      name: {
        first: order.shipping[0].name || user.name ,
        last: order.shipping[0].last_name || user.lastName 
      },
      address: {
        line1: order.shipping[0].address1 || '',
        line2: order.shipping[0].address2 || '',
        city: order.shipping[0].city || '',
        state: order.shipping[0].state || '',
        country: "US",
        zipcode: order.shipping[0].zip || '',
      },
      email: user.email,
      phone_number: user.phone
    }
    let billing = {
      name: {
        first: order.billing_name,
        last: order.billing_last_name
      },
      address: {
        line1: order.billing_address1,
        line2: order.billing_address2,
        city: order.billing_city,
        state: order.billing_state,
        country: "US",
        zipcode: order.billing_zip
      },
      email: user.email,
      phone_number: user.phone
    }
    let total = order.total_amount - order.giftcard_redeemed
    total = total > 0 ? parseInt(total * 100) : 0 
    const local = (window.location.hostname.indexOf("local") > -1)
    const staging = window.location.hostname.indexOf('staging') > -1;
    const develop = (window.location.hostname.indexOf('develop.') > -1)
    const redirectUrl = (local || develop)
    ? 'https://frontend.focus-web-develop.com/'
    : (staging)
      ? 'https://frontend.focus-web-staging.com/'
      : 'https://www.myphoto.com/'
    window.affirm.checkout({
      merchant: {
        user_confirmation_url: `${redirectUrl}checkout`,
        user_cancel_url: `${redirectUrl}checkout`,
        user_confirmation_url_action: "POST",
        name: "MyPhoto"
      },
      metadata: {
        mode: "modal"
      },
      shipping,
      billing,
      items,
      total,
      order_id: order.confirm_id,
      currency: order.currency,
      shipping_amount: parseInt(order.shipping_amount * 100),
      tax_amount: parseInt(order.tax_amount * 100)
    });

    window.affirm.checkout.open({
      onFail: (e) => {
        console.log(e);
      },
      onSuccess: (a) => {
        dispatch(is_fetching())
        dispatch(affirm_checkout(a.checkout_token, order.id))
      }
    })
  }

  render() {
    const { user, order, dispatch, giftCard, order_success, isOpen, seo,
      shippingValidation, shippingOptions } = this.props;
    const { showGiftbox, showPaymentOptions, showShipping, showCC, stripeTokenId, zeroDollarOrder,
      showModal, shipMethod, shippingAddress } = this.state
    const upsellDiscount = 100 - (path(['appConfig', 'upsellDiscount'], this.props.config) * 100)
    // MAKE SURE THERE IS AN ORDER FIRST...
    if (!order || !order.id) {
      return <div className="shell-container main-container " style={{ textAlign: 'center', fontSize: '30px', paddingBottom: '35px' }}><h2>Loading....</h2></div>
    } else {
      const initialValues = {
        email: user.email || '',
        name: user.name || '',
        lastName: user.lastName || '',
        phone: user.phone || '',
        billToSame: true,
        giftNote: pathOr('', ['notes', 'gift'], order),
        billingAddress: {
          name: '',
          last_name: '',
          address1: '',
          address2: '',
          city: '',
          state: '',
          zip: '',
        },
        shipping: {
          name: user.name || shippingAddress.name,
          last_name: user.lastName || shippingAddress.last_name,
          address1: pathOr('', ['shipping', '0', 'address1'], order),
          address2: pathOr('', ['shipping', '0', 'address2'], order),
          city: pathOr('', ['shipping', '0', 'city'], order),
          state: pathOr('', ['shipping', '0', 'state'], order),
          zip: pathOr('', ['shipping', '0', 'zip'], order),
          country: 'US',
        }
      }
      const onSuccess = (payment) =>
        dispatch(is_fetching())
      const onError = (error) =>
        console.log('Erroneous payment OR failed to load script!', error);
      const onCancel = (data) =>
        console.log('Cancelled payment!', data);
        const finalAmount = (order && order.giftcard_redeemed)
      ? ((order.total_amount) - (order.giftcard_redeemed)).toFixed(2)
        : (order.total_amount);
      const finalSubtotal = (order.discount_amount)
        ? (order.merchandise_amount) - (order.discount_amount)
        : (order.merchandise_amount);
      // if (Number(finalAmount) === 0 && !this.state.enablePlaceOrderButton && isEmpty(validatePanel(initialValues))) {
      //   this.setState({
      //     enablePlaceOrderButton: true, showPaymentOptions: false,
      //     showShipping: true, showCC: false, zeroDollarOrder: true
      //   })
      // } // Repeated in componentWillReceiveProps
      // THEN RENDER....

    
      return (
        <div className="shell-container main-container ">
          <div className="main__container checkout__page container">
            {seo && <MetaDataTags pagesMetaData={seo} optionalTitle="Checkout" />}
            <div className="checkout-columns">
              <div className="checkout-info">
                <div className="containers cart__total__holder">
                  <h3 className='checkout-title'>Checkout</h3>
                  {showModal &&
                    <ShowConfirm
                      shippingValidation={shippingValidation}
                      isOpen={isOpen}
                      order_success={order_success}
                      order={order}
                      user={user}
                      shipMethod={shipMethod}
                      paymentDone={this.paymentDone}
                      stripeTokenId={stripeTokenId}
                      zeroDollarOrder={zeroDollarOrder}
                      executeStripePayment={this.executeBrainTreePayment}
                      toggleShowModal={this.toggleShowModal}
                      reason_code={reason_code}
                      handlePlaceOrder={this.handlePlaceOrder}
                      braintreeNonce={this.state.braintreeNonce}
                      executeBrainTreePayment={this.executeBrainTreePayment}
                    />}
                  {this.state.showConfirmation
                    ? this.invalidAptPopUp()
                    : null}
                  {this.state.invalidAddressPopUp
                    ? this.invalidAddressModal()
                    : null}
                  <Formik
                    // enableReinitialize
                    initialValues={initialValues}
                    validate={(values) => {
                      const personalFields = ['email', 'lastName', 'name', 'phone']
                      const errors = validatePanel(values)
                      const isPersonalValid = !personalFields.map(field => isNil(errors[field])).includes(false)
                      if (this.state.personalFieldsValid !== isPersonalValid) this.setState({ personalFieldsValid: isPersonalValid })
                      if (isPersonalValid && (user.name !== values.name || user.lastName !== values.lastName || user.email !== values.email || user.phone !== values.phone)) {
                        const new_customer = {
                          email: values.email,
                          name: values.name,
                          lastName: values.lastName,
                          phone: values.phone
                        }
                        const userVerified = user.verified && user.email.toLowerCase() !== values.email.toLowerCase() ? false : user.verified
                        dispatch(update_user(user.id, new_customer, userVerified))
                          .catch((err) => { message.error("Sorry! Something went wrong. Try again in a moment.") })
                      }
                      if (isEmpty(errors)) { // There are no errors...
                        const shippingId = pathOr(0, ['shipping', '0', 'id'], order)
                        this.setState({ formikShipping: values.shipping })
                        // dispatch(is_fetching())
                        dispatch(updateFinalOrder(order.id, values, order.notes, shipMethod, shippingId, this.state.payWithAffirm  ? 'affirm' :'stripe'))
                          .then((res) => { if (!this.state.enablePlaceOrderButton) this.setState({ enablePlaceOrderButton: true }) })
                          .catch((err) => { message.error("Sorry! Something went wrong. Try again") })

                        // else { // No need to update order, just enable PLACE ORDER button
                        //   if (!this.state.enablePlaceOrderButton) this.setState({ enablePlaceOrderButton: true })
                        // }
                      } else { // There ARE errors...
                        if (this.state.enablePlaceOrderButton) this.setState({ enablePlaceOrderButton: false })
                      }
                      return validatePanel(values)
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      this.setState({ paymentProcessing: true, formikShipping: values.shipping })
                      setSubmitting(true)
                      // this.smartyStreets(values.shipping);
                    }}
                    render={({ values, errors }) => (
                      <FormikForm>
                        <FormItemInput />
                        <br />
                        <h4>Shipping Options</h4>
                        {shippingOptions && shippingOptions.map(s => (
                          <div key={s.id}>
                            <Field name={`${s.label.replace(' ', '')}`} component='checkbox'>
                              <input type='checkbox' name='shippingMethod' checked={s.label === shipMethod}
                                onChange={e => { e.preventDefault(); this.handleShippingMethod(`${s.label}`) }} />
                              <label>
                                <span className="label-text">{s.name} <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;({s.description})
                                        <span className="shipping-label-price">{(s.free_cutoff && finalSubtotal >= s.free_cutoff) ? 'FREE' : `$${s.price.toFixed(2)}`}</span>
                                </span>
                              </label>
                            </Field>
                            <br />&nbsp;<br />
                          </div>
                        ))}
                        <GiftNote linkStyle={linkStyle} handleGiftNote={this.handleGiftNote} showGiftbox={showGiftbox} errors={errors} />
                        {this.state.showUpsellLink &&
                          <div className="upsell-link-container">
                            <img className='deal-icon' src={'/images/icons/deal-icon-01.svg'} alt='deal' />
                            GREAT DEAL!&nbsp;&nbsp;<span
                              className="upsell-link"
                              onClick={e => { e.preventDefault(); this.openUpsellModal() }}
                            >Add a Gift Card at {upsellDiscount}% Off.</span>
                          </div>}
                        <div className="is-hidden-tablet">
                          {this.renderLinks(giftCard)}
                          <p>&nbsp;</p>
                        </div>
                        <div className='terms-privacy-cont'>
                          <p className='terms-privacy-text'>By selecting a payment method, you agree to our&nbsp;<Link className="underlined" to="/terms">Terms of Service</Link>&nbsp;&#x0002B;&nbsp;<Link className="underlined" to="/privacy">Privacy Policy</Link>, including receipt of emails and promotions.</p>
                        </div>
                        {showPaymentOptions ?
                          <div style={{ textAlign: 'center' }}>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({ payWithCC: true, showPaymentOptions: false, showShipping: true, payWithAffirm: false  });
                                // no errors
                                if (isEmpty(validatePanel(initialValues))) this.setState({ enablePlaceOrderButton: true })
                                // setTimeout(() => { document.body.scrollTop = document.getElementById('ccform2').offsetTop; }, 197); 
                              }}
                              className="button is-medium is-fullwidth cc-checkout">
                              <img className='lock-img' src={'/images/icons/lock-icon.svg'} alt='lock' />
                                Checkout with Credit Card<span>
                              </span>
                            </button>
                            {/* New Paypal Component */}
                            <div>
                              <PaypalButton
                                client={CLIENT}
                                env={ENV}
                                commit={true}
                                currency={'USD'}
                                total={finalAmount}
                                subtotal={finalSubtotal}
                                onSuccess={onSuccess}
                                onError={onError}
                                onCancel={onCancel}
                                order={order}
                                user={user}
                                shipMethod={shipMethod}
                                areFieldsValid={this.state.personalFieldsValid}
                                style={PaypalButtonStyle}
                                values={values}
                                token={this.props.config.token}
                                autoShipStates={autoShipStates}
                                apoStates={apoStates}
                              />
                            </div>
                            {/* checkout with Affirm */}
                            { (order.total_amount - order.giftcard_redeemed) >= 50 ?
                                <div style={{marginTop: "16px"}}>
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault()
                                      this.setState({ payWithCC: true, showPaymentOptions: false, showShipping: true, showCC: false, payWithAffirm: true });
                                      if (isEmpty(validatePanel(initialValues))) {
                                        this.setState({ enablePlaceOrderButton: true })
                                      }
                                    }}
                                    className="button is-medium is-fullwidth affirm-checkout">
                                      Checkout with 
                                      <img className='affirm-logo' src={'/images/icons/affirm_logo.svg'} alt='lock' />
                                  </button>
                                </div> : null
                            }
                            {/* <PaymentRequestForm
                                order={order}
                                shipMethod={shipMethod}
                                shippingAmount={order.shipping_amount}
                                areFieldsValid={this.state.personalFieldsValid}
                                total={finalAmount}
                                user={user}
                                dispatch={dispatch}
                                history={this.context.router.history}
                                order_success={order_success}
                                shippingDescription={this.state.shippingDescription}
                                values={values}
                              /> */}
                          </div>
                          : null}
                        {showShipping ?
                          <div id="ccform2">
                            <div id="shipform">
                              <br />
                              <h4>Shipping Address</h4>
                              <Address
                                addressType="shipping"
                                formikName='shipping'
                              />
                              <br />
                              <h4>Billing Address</h4>
                              <Field name='billToSame' component='checkbox' type='checkbox'>
                                <input type='checkbox' name='billToSame' checked={values.billToSame} />
                                <label>&nbsp;Same as Shipping Address (above)</label>
                              </Field>
                              {(values.billToSame) ? null :
                                <span className={`block billing__container ${values.billToSame ? 'billing__container-close' : ''}`}>
                                  <Address
                                    addressType="billing"
                                    formikName='billingAddress'
                                  />
                                </span>
                              }
                              <p>&nbsp;</p>
                              {showCC && !this.state.payWithAffirm ?
                                <div>
                                  <h4>Credit Card</h4>
                                  <BrainTreePayments
                                    dispatch={this.props.dispatch}
                                    handleBraintreeCheckout={this.handleBraintreeCheckout}
                                    placeOrder={this.state.placeOrder}
                                    toggleMobilePlaceOrder={this.toggleMobilePlaceOrder}
                                    brainTreeInstance={this.props.brainTreeInstance}
                                    paymentProcessing={this.state.paymentProcessing}
                                    enablePlaceOrderButton={this.state.enablePlaceOrderButton} />
                                </div>
                                : null}
                              {((this.state.showPlaceOrder && zeroDollarOrder) || this.state.payWithAffirm )&&
                                <div className="is-hidden-mobile" style={{ width: '100%' }}>
                                  {this.renderPaymentButton()}
                                </div>}
                              {/* <div className="is-hidden-mobile">{this.renderPaymentButton()}</div> */}
                            </div>
                          </div>
                          : null}
                        {/* Render pay button on mobile */}
                        <div className="bottom-nav-bar-container is-hidden-desktop is-hidden-tablet">
                          <nav className="tab-bar-container" style={{ background: 'white' }}>
                            <div className="tab-bar-level checkout-tab-bar">
                              <div className="tab-bar-level-item">
                                <p className="total-ammount">Total: </p>
                                <p className="total-ammount">&nbsp;&nbsp;${(finalAmount)}</p>
                              </div>
                              <div className="tab-bar-level-item">
                                <div className="is-hidden-desktop" style={{ width: '100%' }}>
                                  {this.renderPaymentButton()}
                                </div>
                              </div>
                            </div>
                            {/* <div className='guarantee-top-border'>
                                  <GuaranteeRibbonWhite />
                              </div> */}
                          </nav>
                        </div>
                      </FormikForm>
                    )}
                  />
                </div>
              </div>
              <div className="order-summary-container">
                <h3 className='order-summary-title'>Order Summary</h3>
                {order &&
                  <PaymentForm
                    cart={order.items}
                    taxTotal={order.tax_amount}
                    shipping={order.shipping_amount}
                    shippingDescription={this.state.shippingDescription}
                    total={order.total_amount}
                    giftcard={order.giftcard_redeemed.toFixed(2)}
                    giftCardBalance={order.giftcard_balance}
                    discount={order.discount_amount.toFixed(2)}
                    discountName={order.discount_name}
                    promoCode={order.promocode}
                  />
                }
                <div className="checkout__actions in-summary is-fullwidth">
                  <div className="is-hidden-mobile">
                    {this.renderLinks(giftCard)}
                  </div>
                </div>
                <div className="guarantee-top-border is-hidden-mobile">
                  <GuaranteeRibbonWhite />
                </div>
              </div>
            </div>
          </div>
          {this.state.showUpsellModal ? this.renderUpsellModal() : null}
        </div>
      );
    }
  }
}

Checkout.propTypes = propTypes;

export default connect(state => { // eslint-disable-line arrow-body-style
  return {
    order: state.user.user.orders[0],
    user: state.user.user,
    giftCard: state.giftcard,
    products: state.products.list,
    items: state.products.items,
    discount: state.promotions.discount ? state.promotions.discount : {},
    order_success: state.user.order_success || false,
    shippingValidation: state.user.shippingValidation,
    isOpen: state.modal['ADDRESS_CONFIRMATION'],
    shippingOptions: state.config.appConfig.shippingOptions,
    salesChannelId: state.config.appConfig.id,
    reduxPromoCode: state.promotions.reduxPromoCode,
    config: state.config,
    upsellModal: state.promotions.upsellModal,
    newUpsellItem: state.promotions.upsellItem,
    braintreeNonce: state.user.braintreeNonce,
    brainTreeInstance: state.order.brainTreeInstance
  };

})(Checkout)

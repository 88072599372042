import {
    IS_FETCHING,
    IS_NOT_FETCHING,
    TURN_OFF_INITIAL_LOAD,
  } from '../actions/actionTypes'
  
  const INITIAL_STATE = {
    loading: null,
    initialLoad: true,
  }
  
  export default function(state = INITIAL_STATE, action){
    switch(action.type){
      case IS_FETCHING:
        return { ...state, loading: true, }
      case IS_NOT_FETCHING:
        return { ...state, loading: false, }
      case TURN_OFF_INITIAL_LOAD:
        return { ...state, initialLoad: false }
      default:
          return state
    }
  }
  
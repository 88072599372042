import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import MetaDataTags from '../helpers/ProcessMetaData'
import { seoInfo } from '../helpers/seoInfo'
import GenericTabBar from '../components/common/tabBar/GenericTabBar'
import addDefaultSrc from '../helpers/addDefaultSrc'
import '../assets/stylesheets/page-shell.scss'
import '../assets/stylesheets/partners.scss'

const propTypes = {
  user: PropTypes.object,
}
class Partners extends Component {
  static contextTypes = {
    router: PropTypes.object,
  }
  componentDidMount() {
    window.gtag('event', 'page_view', {
      'send_to': 'AW-668262331',
      'ecomm_pagetype': 'other'
    });
  }
  render() {
    return (
      <div className="shell-container partners-main-container">
        <MetaDataTags baseUrl='partners' seoInfo={seoInfo.partners} />
        <div className="columns">
          <div className="column is-10">
            <p className="partners-heading">MyPhoto Partners and Advisors</p>
            <div className="columns cards">
              <div className="column is-one-fourth">
                <div className="card">
                  <div className="image">
                    <figure className="image is-4by3">
                      <img src="https://storage.googleapis.com/myphoto-img/partners-Pitbull.jpg" alt="Pitbull" onError={addDefaultSrc}/>
                    </figure>
                  </div>
                  <div className="card-content">
                    <p className="titles">Pitbull</p>
                    <p className="content">Armando Christian Perez, a.k.a. Pitbull, GRAMMY winning global superstar, education advocate, entrepreneur, motivational speaker and actor.</p>
                  </div>
                </div>
              </div>
              <div className="column is-one-fourth">
                <div className="card">
                  <div className="image">
                    <figure className="image is-4by3">
                      <img src="https://storage.googleapis.com/myphoto-img/partners-Sable.jpg" alt="David Sable" onError={addDefaultSrc}/>
                    </figure>
                  </div>
                  <div className="card-content">
                    <p className="titles">David Sable</p>
                    <p className="content">One of the most respected, followed and experienced marketing icons who has spent the last 7 years as Global CEO of Young & Rubicam Advertising.</p>
                  </div>
                </div>
              </div>
              <div className="column is-one-fourth">
                <div className="card">
                  <div className="image">
                    <figure className="image is-4by3">
                      <img src="https://storage.googleapis.com/myphoto-img/partners-Goldman.jpg" alt="Laurie Ann Goldman" onError={addDefaultSrc}/>
                    </figure>
                  </div>
                  <div className="card-content">
                    <p className="titles">Laurie Ann Goldman</p>
                    <p className="content">Laurie Ann spent 10 years at Coca Cola building the company’s global licensing platform before serving as CEO of Spanx for 12 years creating a household consumer brand.</p>
                  </div>
                </div>
              </div>
              <div className="column is-one-fourth">
                <div className="card">
                  <div className="image">
                    <figure className="image is-4by3">
                      <img src="https://storage.googleapis.com/myphoto-img/partners-Rubin.jpg" alt="Jeff Rubin" onError={addDefaultSrc}/>
                    </figure>
                  </div>
                  <div className="card-content">
                    <p className="titles">Jeff Rubin</p>
                    <p className="content">Recently inducted into the Candy Hall of Fame, Jeff co-founded Dylan’s Candy Bar before starting IT’SUGAR — the largest retail confectionary concept with over 100+ locations worldwide and growing.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="columns cards">
              <div className="column is-one-fourth">
                <div className="card">
                  <div className="image">
                    <figure className="image is-4by3">
                      <img src="https://storage.googleapis.com/myphoto-img/partners-Kipust.jpg" alt="Alan Kipust" onError={addDefaultSrc}/>
                    </figure>
                  </div>
                  <div className="card-content">
                    <p className="titles">Alan Kipust</p>
                    <p className="content">Has held executives positions at Microsoft, Amazon and now Uber. Alan has built a reputation as one of the smartest and strategic thinkers and do’ers.</p>
                  </div>
                </div>
              </div>
              <div className="column is-one-fourth">
                <div className="card">
                  <div className="image">
                    <figure className="image is-4by3">
                      <img src="https://storage.googleapis.com/myphoto-img/partners-Hammond.jpg" alt="Robert Hammond" onError={addDefaultSrc}/>
                    </figure>
                  </div>
                  <div className="card-content">
                    <p className="titles">Robert Hammond</p>
                    <p className="content">His vision to create the Highline in Manhattan in 1990 has added over $1 trillion of value to New York City. The Highline is now the second most visited place in New York — after Central Park.</p>
                  </div>
                </div>
              </div>
              <div className="column is-one-fourth">
                <div className="card">
                  <div className="image">
                    <figure className="image is-4by3">
                      <img src="https://storage.googleapis.com/myphoto-img/partners-Alfieri.jpg" alt="Mark Alfieri" onError={addDefaultSrc}/>
                    </figure>
                  </div>
                  <div className="card-content">
                    <p className="titles">Mark Alfieri</p>
                    <p className="content">Self made entrepreneur, Mark created Brandstar - one of the largest TV production studios in the country who has produced 1,000+ award winning nationally syndicated original TV episodes. Clients include P&G, AT&T, Ford, Verizon, Home Depot and Walmart.</p>
                  </div>
                </div>
              </div>
              <div className="column is-one-fourth">

              </div>
            </div>
          </div>
        </div>
        <GenericTabBar />
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    user: state.user.user,
  };
}

Partners.propTypes = propTypes;
Partners.contextTypes = {
  router: PropTypes.object.isRequired
}
export default withRouter(connect(mapStateToProps)(Partners));

import React, { Component } from 'react'
import MetaDataTags from '../helpers/ProcessMetaData'
import '../assets/stylesheets/page-shell.scss'
import '../assets/stylesheets/reviews.scss'

class PrivacyPolicy extends Component {

    componentDidMount() {
        window.gtag('event', 'page_view', {
            'send_to': 'AW-668262331',
            'ecomm_pagetype': 'other'
        });
    }

    render() {
        return (
            <div className="shell-container main-container">
                <MetaDataTags baseUrl='privacy' />
                <div className="columns container" style={{justifyContent: 'center'}}>
                    <div className="column is-8">
                        <div className="terms-text-container">
                            <h3 className="p1">
                                <span className="s1">
                                    <strong>MyPhoto Privacy Policy </strong>
                                </span>
                            </h3>
                            <p className="p2">
                                <span className="s1">Last updated on August 28</span>
                                <span className="s2">
                                    <sup>th</sup>
                                </span>
                                <span className="s1">, 2018 </span>
                            </p>
                            <p className="p3">
                                <span className="s1">This Privacy Policy describes the privacy practices of the MyPhoto brand of companies (collectively, "MyPhoto", "we" or "us") for any websites, mobile apps and emails that display this privacy policy notice or a link to this privacy policy notice, including
                                        <a href="https://www.myphoto.com">
                                        <span className="s3"> www.myphoto.com</span>
                                    </a>, operated by Photo &amp; Go Web, LLC (such sites are referred to collectively as the "MyPhoto Site").
                                 </span>
                            </p>
                            <p className="p3">
                                <span className="s1">MyPhoto's mission is to create the easiest and fastest way possible for photos on your phone to become amazing products and gifts. You can enjoy MyPhoto with the confidence that it strives to comply with all applicable privacy laws and is committed to protecting the confidentiality of customer’s and visitor’s personal information, as explained in this Privacy Policy. </span>
                            </p>
                            <p className="p3">
                                <span className="s1">This Privacy Policy covers:</span>
                            </p>
                            <ol className="ol1" style={{ paddingLeft: '30px' }}>
                                <li className="li3">
                                    <span className="s1">Personal customer information that MyPhoto collects </span>
                                </li>
                                <li className="li3">
                                    <span className="s1">Privacy of customer billing and shipping information</span>
                                </li>
                                <li className="li3">
                                    <span className="s1">Non-personal or aggregate information collected</span>
                                </li>
                                <li className="li3">
                                    <span className="s1">What MyPhoto does with the personal customer information it collects</span>
                                </li>
                                <li className="li3">
                                    <span className="s1">Information sharing</span>
                                </li>
                                <li className="li3">
                                    <span className="s1">Opting-out from receiving marketing e-mails</span>
                                </li>
                                <li className="li3">
                                    <span className="s1">Cookies</span>
                                </li>
                                <li className="li3">
                                    <span className="s1">The MyPhoto Site is not intended for children under thirteen</span>
                                </li>
                                <li className="li3">
                                    <span className="s1">Your California Privacy Rights</span>
                                </li>
                                <li className="li3">
                                    <span className="s1">Changes to this Privacy Policy</span>
                                </li>
                            </ol>
                            <p className="p3">
                                <span className="s1">1.
                                <span className="Apple-converted-space">&nbsp; </span>
                                </span>
                                <span className="s4">Personal customer information that MyPhoto collects</span>
                            </p>
                            <p className="p3">
                                <span className="s1">Like every business that offers and sells products or services online through a website, mobile app or email, we collect personal information from customers and other users and visitors in order to perform and administer our normal business operations. Personal customer information is information that can be used to contact, identify or locate you, such as, for example, an e-mail address, telephone number, shipping or billing address, as well as billing and credit card information. Your personal customer information allows us to do things like communicate with you about our products and offers, provide you with a superior user or customer experience and ship and bill your orders in accordance with your instructions and requests to the correct address and using the right credit card.</span>
                            </p>
                            <p className="p3">
                                <span className="s1">We maintain all e-mail addresses that we receive online or in an e-mail, including the e-mail addresses from which photos are sent to us at 123@myphoto.com, the MyPhoto Site, or at any other e-mail address designated or advertised by us as an e-mail address for the receipt of photos. We require online customers to provide an email address at checkout and we maintain those e-mail addresses. We also receive and maintain all other e-mail addresses that you give us in any other way, such as by volunteering it to us over the telephone. </span>
                            </p>
                            <p className="p3">
                                <span className="s1">We also receive and maintain the requested customer information that you provide if you participate in or sign up for a MyPhoto promotion, special offer, sweepstakes or contest.&nbsp;</span>
                            </p>
                            <p className="p3">
                                <span className="s1">2.
                                <span className="Apple-converted-space">&nbsp; </span>
                                </span>
                                <span className="s4">Privacy of customer billing and shipping information</span>
                            </p>
                            <p className="p3">
                                <span className="s1">In order to fulfill and process customer orders, in addition to an e-mail address, we require customers to provide necessary billing and shipment information, including shipping address, billing name and address, credit card number and expiration date. This shipping and billing information will be shared only with third parties who help to complete your order, such as a shipping or delivery company and credit card payment processor, and only for the purpose of completing and fulfilling your order.
                                <span className="Apple-converted-space">&nbsp; &nbsp;</span>
                                </span>
                            </p>
                            <p className="p3">
                                <span className="s1">We do not store your credit card information in our system. Credit card information you enter on the MyPhoto Site is securely processed by a payment processor which is certified to PCI Service Provider Level 1, the most stringent level of certification available in the payments industry. </span>
                            </p>
                            <p className="p4">
                                <span className="s1">Online customer orders are encrypted with SSL (secure socket layers) technology. The billing and shipping information you send via our online ordering process can be trusted to arrive privately and unaltered. In order for SSL security to work though, you must use an SSL-enabled browser. Supported browsers include Google Chrome, Microsoft Edge, Mozilla Firefox and Safari.</span>
                            </p>
                            <p className="p3">
                                <span className="s1">3.
                                <span className="Apple-converted-space">&nbsp; </span>
                                </span>
                                <span className="s4">Non-personal or aggregate information collected using technology</span>
                                <span className="s1">&nbsp;</span>
                            </p>
                            <p className="p3">
                                <span className="s1">We use various internet technologies that collect non-personal or aggregate information to manage, analyze and track the usage of the MyPhoto Site, including to obtain broad demographic information about aggregate usage, identify and analyze trends, and improve your user or customer experience. </span>
                            </p>
                            <p className="p3">
                                <span className="s1">We do not specifically identify any customers or users with this information. </span>
                            </p>
                            <p className="p3">
                                <span className="s1">For example, to analyze traffic to the MyPhoto Site, we use Google Analytics, or a similar program or service, which collects aggregate data about the visitors to the site but does not create an individual profile of each visitor. </span>
                            </p>
                            <p className="p3">
                                <span className="s1">We may also collect or aggregate non-personal information about the computer, mobile device or other device you use to access the MyPhoto Site, such as its IP (internet protocol) address, browser type, operating system, browser language, as well as the pages on the site that you view. </span>
                            </p>
                            <p className="p3">
                                <span className="s1">4.
                                <span className="Apple-converted-space">&nbsp; </span>
                                </span>
                                <span className="s4">What MyPhoto does with the personal customer information it collects</span>
                            </p>
                            <p className="p3">
                                <span className="s1">We use your personal customer information to process and fulfill your requests and orders for our products and services, for internal business and administrative purposes, to provide customer service and to communicate with you about your orders and requests. </span>
                            </p>
                            <p className="p3">
                                <span className="s1">We also use your e-mail address and name to communicate with you about and market our products, services, offers and promotions. </span>
                            </p>
                            <p className="p5">
                                <span className="s1">Your Photos: When you decide to email or upload a photo to MyPhoto.com, it will be retained on our servers for your use in future projects.</span>
                            </p>
                            <p className="p5">
                                <span className="s1">&nbsp;</span>
                            </p>
                            <p className="p5">
                                <span className="s1">We may also use your photos in emails or social ads, targeted to you and you alone. We will not post your photo where anyone other than yourself can see it. This is to inspire you rediscover past memories and or explore new creative ideas.
                                <span className="Apple-converted-space">&nbsp; </span>You can opt-out to receiving these emails at any time through the unsubscribe link within each email.
                                </span>
                            </p>
                            <p className="p6">&nbsp;</p>
                            <p className="p3">
                                <span className="s1">We also may send you e-mails on behalf of our strategic or business partners to offer you products or services that we and/or our strategic or business partners think may be of interest to you.</span>
                            </p>
                            <p className="p3">
                                <span className="s1">5.
                                <span className="Apple-converted-space">&nbsp; </span>
                                </span>
                                <span className="s4">Information sharing</span>
                            </p>
                            <p className="p3">
                                <span className="s1">
                                    <em>Personal customer information that we may have to share in the normal course of business and not for marketing purposes</em>
                                </span>
                            </p>
                            <p className="p3">
                                <span className="s1">We may disclose personal customer information to third-parties as is usual or appropriate to help us administer, run or manage our business, including to fulfill customer orders and to enhance the experience of customers and other visitors to the MyPhoto Site. As one example, we have to disclose a customer's name and address and other contact information to a freight or delivery company in order to ship an order to a customer. </span>
                            </p>
                            <p className="p3">
                                <span className="s1">If we are required by subpoena, court order or other legal process to release customer information, then we will have to do so.</span>
                            </p>
                            <p className="p3">
                                <span className="s1">If MyPhoto merges with or is acquired by another company, then customer information may be transferred to or shared with the new or acquiring company. All customer information transmitted, submitted or provided to MyPhoto becomes the property of MyPhoto. However, if MyPhoto is sold to or acquired by another company, all customer information would then belong to the acquiring company. Advisors on any such transaction, such as lawyers, accountants and business consultants, may also have access to such customer information but will be subject to a duty of confidentiality or a confidentiality agreement or both.</span>
                            </p>
                            <p className="p3">
                                <span className="s1">
                                    <em>Customer information that we may share for marketing purposes</em>
                                </span>
                            </p>
                            <p className="p3">
                                <span className="s1">The </span>
                                <span className="s4">only</span>
                                <span className="s1"> customer information that MyPhoto may share with other parties for marketing purposes are your name and e-mail address. We may share your name and e-mail address with other companies or organizations that may contact you regarding products or services that we and/or they think may be of interest to you. We do not share your customer information with outside companies or organizations that we do not consider one of our business or strategic partners.&nbsp;</span>
                            </p>
                            <p className="p3">
                                <span className="s1">While MyPhoto is not responsible for the privacy practices of any other parties, including those that we consider our business or strategic partners, we nevertheless try to make sure that any parties with which we share customer information for marketing purposes comply with all applicable privacy laws. We are also not responsible for customer information that you may provide to other companies or websites, even if this site contains links to any other websites.</span>
                            </p>
                            <p className="p3">
                                <span className="s1">6. </span>
                                <span className="s4">Opting-out from receiving marketing e-mails</span>
                            </p>
                            <p className="p3">
                                <span className="s1">You can notify MyPhoto at any time that you do not want to receive marketing e-mails from MyPhoto by either (1) sending an e-mail with your instructions to MyPhoto at
                                        <a href="mailto:hello@myphoto.com">
                                        <span className="s3"> hello@myphoto.com</span>
                                    </a>, and referencing "Opt-Out Request" in the subject line or (2) clicking on the "Unsubscribe" link in a MyPhoto e-mail and then following the instructions to unsubscribe or manage the frequency of your marketing communications from MyPhoto.
                                    </span>
                            </p>
                            <p className="p3">
                                <span className="s1">You can notify MyPhoto at any time that you do not want MyPhoto to share (or continue to share) your personal customer information for marketing purposes with any outside parties by sending an e-mail with your instructions to MyPhoto at
                                        <a href="mailto:hello@myphoto.com">
                                        <span className="s3"> hello@myphoto.com</span>
                                    </a> and referencing "Opt-Out Request" in the subject line.
                                </span>
                            </p>
                            <p className="p3">
                                <span className="s1">We will always honor your opt-out request. However, it may take up to 30 days to process your opt-out request and you may receive additional communications from us or an outside company during the processing period. Also, please note that you may continue to receive e-mails from MyPhoto that are not marketing e-mails, such as service announcements and administrative messages. For example, if this Privacy Policy changes following your opt-out request, you may receive an e-mail from MyPhoto notifying you of the changes. </span>
                            </p>
                            <p className="p3">
                                <span className="s1">7.
                                <span className="Apple-converted-space">&nbsp; </span>
                                </span>
                                <span className="s4">Cookies</span>
                            </p>
                            <p className="p3">
                                <span className="s1">Like most companies that offer products or services online, the MyPhoto Site uses "cookie" technology. Cookies are small pieces of information that our server places on a visitor's device or computer hard drive to enable the user to more easily communicate and interact with the MyPhoto Site and to provide us with important information on how visitors use the MyPhoto Site. For example, a cookie is automatically placed on a visitor's computer or other device when he/she visits the MyPhoto Site. The next time that person visits the MyPhoto Site using the same computer or device, our server will recognize the cookie and the visitor. Cookies are simply the best way for us to be able to offer you the most enjoyable and hassle-free online shopping environment. Cookies are also used in email messages we send to help us measure the effectiveness of our advertising and how visitors use our site. </span>
                            </p>
                            <p className="p3">
                                <span className="s1">Many internet web browsers are automatically set to accept cookies. You may change your browser settings to either reject cookies or notify you when a cookie is about to be placed on your computer. Please note, however, that rejecting cookies while visiting the MyPhoto Site may result in certain parts of the MyPhoto Site not operating correctly or as efficiently as if they were allowed.</span>
                            </p>
                            <p className="p3">
                                <span className="s1">8.
                                <span className="Apple-converted-space">&nbsp; </span>
                                </span>
                                <span className="s4">The MyPhoto Site is not intended for children under thirteen</span>
                            </p>
                            <p className="p3">
                                <span className="s1">The MyPhoto Site is not intended for children and we do not knowingly collect personal customer information from children under thirteen years of age. If you have reason to believe that a child under the age of thirteen has provided personal customer information to us, please contact MyPhoto and to the extent possible, we will delete that child’s personal customer information from our databases.</span>
                            </p>
                            <p className="p3">
                                <span className="s1">9.
                                <span className="Apple-converted-space">&nbsp; </span>
                                </span>
                                <span className="s4">Your California privacy rights</span>
                            </p>
                            <p className="p3">
                                <span className="s1">Under California Civil Code section 1798.83A, certain businesses are required to disclose to its California customers, upon request, the identity of any third parties to whom the business has disclosed personal information within the previous calendar year, along with the type of personal information disclosed, for the third parties' direct marketing purposes.
                                        <br />
                                    <br />If you are a California resident and would like to make such a request, please submit your request in writing, by letter or e-mail, to:
                                        </span>
                            </p>
                            <p className="p5">
                                <span className="s1">MyPhoto</span>
                            </p>
                            <p className="p5">
                                <span className="s1">2915 S Congress Avenue </span>
                            </p>
                            <p className="p5">
                                <span className="s1">Delray Beach, FL 33445 </span>
                            </p>
                            <p className="p3">
                                <span className="s1">Attn: Legal - Privacy Issues
                                            <br />Email:
                                            <a href="mailto:hello@myphoto.com">
                                        <span className="s3"> hello@myphoto.com</span>
                                    </a>
                                </span>
                            </p>
                            <p className="p3">
                                <span className="s1">Under California law, businesses are required to respond to a customer request for this information only once during any calendar year.</span>
                            </p>
                            <p className="p3">
                                <span className="s1">10.
                                                <span className="Apple-converted-space">&nbsp; </span>
                                </span>
                                <span className="s4">Changes to this Privacy Policy</span>
                            </p>
                            <p className="p3">
                                <span className="s1">This Privacy Policy is effective as of August 28</span>
                                <span className="s6">
                                    <sup>th</sup>
                                </span>
                                <span className="s1">, 2018 but may be changed or modified at any time. If there is a change to MyPhoto's Privacy Policy, it will be posted on this page and will be effective when posted. </span>
                            </p>
                            <p className="p7">
                                <span className="s1">
                                    <strong>Contact MyPhoto</strong>
                                </span>
                            </p>
                            <p className="p3">
                                <span className="s1">If you have any questions, comments or concerns about this Privacy Policy or our products or services, please contact us by e-mail at
                                                <a href="mailto:hello@myphoto.com">
                                        <span className="s3"> hello@myphoto.com</span>
                                    </a>, or by physical mail to MyPhoto, 2915 S Congress Avenue Delray Beach, FL 33445, Attn: Legal – Privacy Issues.
                                             </span>
                            </p>
                            <p className="p3">
                                <span className="s1">If you have any questions, comments or concerns about MyPhoto’s products or services, please contact us by e-mail at hello@myphoto.com, or by physical mail to MyPhoto, 2915 S Congress Avenue Delray Beach, FL 33445, Attn: Customer Service. </span>
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default (PrivacyPolicy);

import { assign } from 'lodash'
import * as types from '../actions/actionTypes';

const initialState = {
  cart: { items: [] },
  itemRemoved: false
}

function cart(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_CART:
      return assign({}, state, {
        cart: action.payload,
      })
    case types.ITEM_REMOVED:
      return{...state,
        itemRemoved: action.payload
      }
    default:
      return state;
  }
}

export default cart;

import React, {Suspense} from 'react';

const AppLoader = ({ isLoading, error }) => {
  return <Suspense fallback = {<div className="AppLoader">Still Loading...</div>}>
  <div className="AppLoader" style={{ paddingTop: '100px' }}>
    {isLoading ?
      <div className="AppLoader">
        <p>Loading...</p>
      </div>
      : error ?
        <div className="AppLoader">Sorry, there was a problem loading the page.</div>
        : <div className="AppLoader">Loading...</div>
        }
  </div>
  </Suspense>
}

export default AppLoader
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import autoBind from 'react-autobind'
import _ from 'lodash'
import { path } from 'ramda'
import { Modal, message } from 'antd'
import {monetateCartTracker,  setIntervalHelper} from '../../helpers/monetate.js'

import { is_fetching, new_user_order, clearOrder, removeDiscount, fetchUpsellItem} from '../../actions/'
import CartItem from './CartItem'
import PromoBarInfoModal from '../header/PromoBarInfoModal'
import MetaDataTags from '../../helpers/ProcessMetaData'
import '../../assets/stylesheets/page-shell.scss'
import '../../assets/stylesheets/cart-page.scss'

const propTypes = {
  dispatch: PropTypes.func,
  products: PropTypes.array,
  items: PropTypes.array,
  seo: PropTypes.object,
  user: PropTypes.object,
  upsellItem: PropTypes.object,
}
class CartList extends React.Component {

  static contextTypes = {
    router: PropTypes.object
  }

  constructor(props) {
    super(props);
    const qty = []
    for (let i = 1; i <= 50; i++) {
      qty.push({
        value: i,
        label: i,
      })
    }
    this.state = {
      qty,
      visible: false,
      showModal: false,
    }
    autoBind(this);
  }
  componentDidMount(){  
    const { dispatch, user, reduxPromoCode, order, config, upsellItem } = this.props
    const {upsellSku} = config && config.appConfig && config.appConfig
   
    dispatch(clearOrder())
    if (order) {
      dispatch(removeDiscount(user.id, reduxPromoCode))
    }
    if(upsellSku && !upsellItem) {
      this.props.dispatch(fetchUpsellItem(upsellSku))
    }}
componentWillMount(){
  if(this.props.location.pathname === "/cart"){
  setIntervalHelper(() =>{monetateCartTracker( this.props.cart.items)});
}}


  
  handleCheckoutClick(totalImagesCart) {
    const { dispatch, user, cart } = this.props
    const lineItems = cart.items.length
    if (totalImagesCart <= 150) {
      if (lineItems <= 50) {
        dispatch(new_user_order(user))
        dispatch(is_fetching())
      } else {
        message.error("Sorry, you have exceeded the limit of 50 products on an order. Please remove some products and try again.", 10)
      }
    } else {
      message.error("Sorry, you have exceeded the limit of 150 photos on an order. Please remove some photos and try again.", 10)
    }

  }

  renderCartItems() {
    const { cart, dispatch, itemRemoved, items, products, addedToCart, hideLink, config, upsellItem } = this.props;
    const { upsellSku, upsellDiscount } = config && config.appConfig
    return _.orderBy(cart.items, ['id'], ['desc']).map((cartItem, index) => {
      const lastAddedToCart = (index === 0) ? itemRemoved : ''
      if (cartItem.id > -1) {
        const item = upsellItem && (cartItem.sku === upsellItem.sku )
            ? upsellItem
            : items.find(itemElement => itemElement.id === cartItem.item_id);
        if (!item) {
          return <CartItem
            key={index}
            cart={cart}
            dispatch={dispatch}
            item={'itemNotFound'}
            cartItem={cartItem}
            product={null}
            qty={this.state.qty}
            addedToCart={addedToCart}
            lastAddedToCart={lastAddedToCart}
            hideLink={hideLink}
            upsellItemSku={upsellSku}
            upsellDiscount={upsellDiscount}
            upsellItem={upsellItem}
          />
        } else {
          const product = products.find(productItem => { return productItem.id === item.productId });
          if (!product && !upsellItem) {
            return null
          } else {
            return (
              <CartItem
                key={index}
                cart={cart}
                dispatch={dispatch}
                item={item}
                cartItem={cartItem}
                product={product}
                qty={this.state.qty}
                addedToCart={addedToCart}
                lastAddedToCart={lastAddedToCart}
                hideLink={hideLink}
                upsellItemSku={upsellSku}
                upsellItem={upsellItem}
                upsellDiscount={upsellDiscount}
              />
            )
          }
        }
      } else {
        return null;
      }
    })
  }
  handleCancel = (e) => {
    this.setState({
      visible: false,
    })
  }
  doubleUpBannerModal() {
    return (
      <div>
        <Modal
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        >
          <PromoBarInfoModal />
        </Modal>
      </div>
    )
  }
  render() {
    const { cart, seo, handler, items } = this.props;
    const cartItems = cart.items
    const cartItemsIsArray = (cartItems.length && cartItems[0] && cartItems[0].item_id)
    var subtotal = (cartItemsIsArray)
      ? cartItems.reduce((accumulator, item) => { 
          return accumulator + item.total }, 0)
      : 0
    handler(subtotal) // passing subtotal to the parent component
    const productList = (cartItemsIsArray)
      ? cartItems.filter(products => items.includes(products.id)).map(cartItem => this.props.items.find(itemElement => itemElement.id === cartItem.itemId).productId)
      : []
    window.gtag('event', 'page_view', {
      'send_to': 'AW-668262331',
      'ecomm_prodid': productList,
      'ecomm_pagetype': 'cart',
      'ecomm_totalvalue': subtotal
    });
    const quantity = (cartItemsIsArray)
      ? cartItems.reduce((accumulator, item) => { return (item.qty && item.item_id) ? accumulator + parseInt(item.qty, 10) : accumulator; }, 0)
      : 0
    if (quantity !== this.state.quantity) {
      this.setState({ quantity })
    }
    const tabBarCTA = cartItems.length <= 3 ? 'tab-bar-ctas' : ''
    const inlineCTA = cartItems.length >= 4 ? 'inline-ctas' : ''
    const totalImagesCart = cartItems.reduce((accumulator, item) => { return accumulator + path(['photopersonalization', '0', 'previewObj', 'numPhotos'], item) * path(['qty'], item) }, 0)
    if (cartItemsIsArray) { // CART CONTAINS ITEMS
      return (
        <span>
          {seo && <MetaDataTags pagesMetaData={seo} optionalTitle="Cart" />}
          {/* {this.state.showModal ? this.doubleUpBannerModal() : null} */}
          {/* {this.props.config.appConfig.subdomain!== 'hln' ? */}
          {/* : null } */}
          {this.renderCartItems()}
          <div className={`cart-action-container ${inlineCTA}`}>
            <div className="cart-total-list">
              <p className="cart-subtotal-text">Subtotal ({this.state.quantity} items):&nbsp;</p>
              <p className="cart-subtotal">${(subtotal > 0) ? subtotal.toFixed(2) : '0'}</p>
            </div>
            <div style={{ textAlign: "center", flex: 4 }} className="column checkout-btn">
              <button
                className={cart.disabledProducts ? 'cart-btn disabled btn cart__checkout' : ' cart-btn enabled btn cart__checkout'}
                onClick={(e) => {
                  e.preventDefault();
                  this.handleCheckoutClick(totalImagesCart);
                }}
              >
                Checkout
                    </button>
            </div>
          </div>
          {/* ** TabBar on mobile: ** */}
          <div className={`bottom-nav-bar-container is-hidden-desktop is-hidden-tablet ${tabBarCTA}`}>
            <nav className="tab-bar-container cart-tab-bar">
              <div className="tab-bar-level">
                <div className="tab-bar-level-item" style={{ marginBottom: '2px' }}>
                  <p className="total-ammount">Subtotal ({this.state.quantity} items):</p>
                  <p className="total-ammount">&nbsp;&nbsp;${(subtotal > 0) ? subtotal.toFixed(2) : '0'}</p>
                </div>
                <div className="tab-bar-level-item">
                  <button
                    style={{ borderRadius: 0 }}
                    className={cart.disabledProducts ? 'cart-btn disabled btn cart__checkout' : ' cart-btn enabled btn cart__checkout'}
                    onClick={(e) => {
                      e.preventDefault();
                      this.handleCheckoutClick(totalImagesCart);
                    }}
                  >
                    Checkout
                      </button>
                </div>
              </div>
            </nav>
          </div>
        </span>
      )
    } else { // EMPTY CART
    
      return (
        <span>
          {seo && <MetaDataTags pagesMetaData={seo} optionalTitle="Cart" />}
          {/* {this.state.showModal ? this.doubleUpBannerModal() : null} */}
          {/* {this.props.config.appConfig.subdomain!== 'hln' ? */}
          {/* {this.doubleUpBanner()} */}
          {/* : null } */}
          <div className={`cart-action-container ${inlineCTA}`}>
            <div className="cart-total-list">
              <p className="cart-subtotal-text">Subtotal ({this.state.quantity} items):&nbsp;</p>
              <p className="cart-subtotal">${(subtotal > 0) ? subtotal.toFixed(2) : '0'}</p>
            </div>
            <div style={{ textAlign: "center", flex: 4 }} className="column checkout-btn">
              <button
                style={{ borderRadius: 0 }}
                className={cart.disabledProducts ? 'cart-btn disabled btn cart__checkout' : ' cart-btn enabled btn cart__checkout'}
                onClick={(e) => {
                  e.preventDefault();
                  this.handleCheckoutClick();
                }}
              >
                Checkout
                    </button>
            </div>
          </div>
          {/* ** TabBar on mobile: ** */}
          <div className={`bottom-nav-bar-container is-hidden-desktop is-hidden-tablet ${tabBarCTA}`}>
            <nav className="tab-bar-container cart-tab-bar">
              <div className="tab-bar-level">
                <div className="tab-bar-level-item" style={{ marginBottom: '2px' }}>
                  <p className="total-ammount">Subtotal ({this.state.quantity} items):</p>
                  <p className="total-ammount">&nbsp;&nbsp;${(subtotal > 0) ? subtotal.toFixed(2) : '0'}</p>
                </div>
                <div className="tab-bar-level-item">
                  <button
                    style={{ borderRadius: 0 }}
                    className={cart.disabledProducts ? 'cart-btn disabled btn cart__checkout' : ' cart-btn enabled btn cart__checkout'}
                    onClick={(e) => {
                      e.preventDefault();
                      this.handleCheckoutClick(totalImagesCart);
                    }}
                  >
                    Checkout
                      </button>
                </div>
              </div>
            </nav>
          </div>
        </span>
      )
    }
  }
}

CartList.propTypes = propTypes;

export default withRouter(connect(state => {
  return {
    products: state.products.list,
    items: state.products.items,
    config: state.config,
    seo: state.config.seoData,
    user: state.user.user,
    cart: state.cart.cart,
    itemRemoved: state.cart.itemRemoved,
    reduxPromoCode: state.promotions.reduxPromoCode,
    order: state.user.user.orders[0],
    upsellItem: state.promotions.upsellItem
  }
})(CartList))

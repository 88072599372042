import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import autoBind from 'react-autobind'
import { withRouter } from 'react-router-dom'
import Slider from '../common/Slider'
import CTAsection from './CTAsection'
import Banner from './Banner'
import ReviewsCarousel from './ReviewsCarousel'
import CategoryLinks from './CategoryLinks'
import TryItNow from './TryItNow'
import ProductCarousel from './ProductCarousel'
import HomePageTabBar from '../common/tabBar/HomePageTabBar'
import '../../assets/stylesheets/page-shell.scss'

const propTypes = {
    dispatch: PropTypes.func.isRequired,
    products: PropTypes.array,
    photos: PropTypes.array,
    items: PropTypes.array,
    user: PropTypes.object,
    project: PropTypes.object,
}

class HomePageContent extends React.Component {

    static contextTypes = {
        router: PropTypes.object,
    }
    constructor(props) {
        super(props);
        autoBind(this);
    }
componentDidMount(){
   

}

    shouldComponentUpdate() {
        return true
    }
    render() {
        const { photos, project, products } = this.props
        const hasPictures = photos.length > 0 && photos[0] && photos[0].width

        const carouselPoruducts =[ 211, 297, 175, 3, 282 ]
        return (
            <section className="shell-container products-container">
                <div className="home-slider is-hidden-mobile">
                    {hasPictures &&
                        <Slider
                            divClass="products__slider__holder"
                            titleClass="photos__list__title"
                            // title="Select a photo to preview on all products."
                            className="column"
                            autoSlide={true}
                            loop={true}
                            list={true}
                            listItemSize={90}
                            ease="ease-in-out"
                            fixedHeight="45px"
                            loadError={this.handleLoadError}
                            itemClick={this.selectImage}
                            remove={this.removePhotos}
                            reverseOrder={true}
                            showControls={false}
                            showNav={false}
                            bg={true}
                        />}
                </div>
                <CTAsection hasPictures={hasPictures} />
                <ProductCarousel 
                    title={'Featured Products'}
                    productIds={carouselPoruducts}
                    products={products}
                    project={project}
                />
                {/* <div className='banners-columns'>
                    <Banner
                        link={'/products/ornaments'}
                        desktop={'https://storage.googleapis.com/myphoto-img/banners/HP_Ornaments_D.jpg'}
                        mobile={'https://storage.googleapis.com/myphoto-img/banners/HP_Ornaments_M.jpg'}
                        alt={'Family photos Christmas memories on different ornaments.'}
                        title={`Ornaments`}
                        color={'#222222'}
                        full={true}
                        width={12}
                        textAlignment={'right'}
                        verticalAlignment={'center'}
                        textAlignmentMobile={'center'}
                        verticalAlignmentMobile={'flex-end'}
                        linkClass={'ctaButton'}
                        linkText={'See More >'} />
                </div> */}
                <div className='banners-columns'>
                    <Banner
                        link={'/products/acrylic-block'}
                        desktop={'/images/banners/HP0713_AcrylicBannerD.jpg'}
                        mobile={'/images/banners/HP0713_AcrylicBannerM.jpg'}
                        // desktop={'https://storage.googleapis.com/myphoto-img/banners/HP_Acrylic_D.jpg'}
                        // mobile={'https://storage.googleapis.com/myphoto-img/banners/HP_Acrylic_M.jpg'}
                        alt={'Family photos Christmas memories on holiday gifts.'}
                        title={`Acrylic Blocks`}
                        color={'#222222'}
                        full={true}
                        width={12}
                        textAlignment={'left'}
                        verticalAlignment={'center'}
                        textAlignmentMobile={'center'}
                        verticalAlignmentMobile={'flex-end'}
                        linkClass={'ctaButton'}
                        linkText={'See More >'} />
                </div>
                <div className="banners-columns is-full-width-mobile">
                    <Banner
                        link={'/products/wall'}
                        desktop={'https://storage.googleapis.com/myphoto-img/banners/Wall_HomePage.jpg'}
                        mobile={'https://storage.googleapis.com/myphoto-img/banners/Wall_HomePage.jpg'} 
                        alt={'Top rated products to turn your favorite photos into awesome gifts or decor for your walls.'}
                        title={'Wall Collection'}
                        color={'#222222'}
                        width={1}
                        full={true}
                        textAlignment={'center'}
                        verticalAlignment={'flex-end'}
                        linkClass={'ctaButton'}
                        linkText={'Shop Now'}
                        hideLinkDesktop={true} />
                    <Banner
                        link={'/products/table-top'}
                        desktop={'https://storage.googleapis.com/myphoto-img/banners/HP_ShelfCollection.jpg'}
                        mobile={'https://storage.googleapis.com/myphoto-img/banners/HP_ShelfCollection.jpg'}
                        alt={'Top rated products to turn your favorite photos into awesome gifts or decor for your shelfs.'}
                        title={'Shelf Collection'}
                        color={'white'}
                        width={1}
                        full={true}
                        textAlignment={'center'}
                        verticalAlignment={'flex-end'}
                        linkClass={'ctaButton'}
                        linkText={'Shop Now'}
                        hideLinkDesktop={true} />
                </div>
                <h2 className='homepage-title'>Shop By Category</h2>
                <div className="banners-columns banners-columns-wrap is-full-width-mobile">
                    <Banner
                        smallBanner
                        link={'/products/glass'}
                        desktop={'https://storage.googleapis.com/myphoto-img/banners/D_GLASS.jpg'}
                        mobile={'https://storage.googleapis.com/myphoto-img/banners/M_GLASS.jpg'}
                        alt={'Young, girl smiling on a park bench shown in glass photo frame with transparent boarder.'}
                        title={'Glass'}
                        color={'#222222'}
                        width={1}
                        mobilePadding={true}
                        full={true}
                        fontSize={'20px'}
                        textAlignment={'center'}
                        verticalAlignment={'flex-end'} 
                        verticalAlignmentMobile={'flex-start'}
                        linkClass={'ctaLink'}
                        linkText={'See More >'} />
                    <Banner
                        smallBanner
                        link={'/products/metal'}
                        desktop={'https://storage.googleapis.com/myphoto-img/banners/D_METAL.jpg'}
                        mobile={'https://storage.googleapis.com/myphoto-img/banners/M_METAL.jpg'}
                        alt={'Father, mother, and son in an outdoor winter setting on a metal print with black stand.'}
                        title={'Metal'}
                        color={'#222222'}
                        width={1}
                        mobilePadding={true}
                        full={true}
                        fontSize={'20px'}
                        textAlignment={'center'}
                        verticalAlignment={'flex-end'}
                        verticalAlignmentMobile={'flex-start'}
                        linkClass={'ctaLink'}
                        linkText={'See More >'} />
                    <Banner 
                        smallBanner
                        link={'/products/wood'}
                        desktop={'https://storage.googleapis.com/myphoto-img/banners/D_WOOD.jpg'}
                        mobile={'https://storage.googleapis.com/myphoto-img/banners/M_WOOD.jpg'}
                        alt={'Collage of family memories on wood photo block.'}
                        title={'Wood'}
                        color={'#222222'}
                        width={1}
                        mobilePadding={true}
                        full={true}
                        fontSize={'20px'}
                        textAlignment={'center'} 
                        verticalAlignment={'flex-end'} 
                        verticalAlignmentMobile={'flex-start'}
                        linkClass={'ctaLink'}
                        linkText={'See More >'} />
                    <Banner
                        smallBanner
                        link={'/products/acrylic'}
                        desktop={'https://storage.googleapis.com/myphoto-img/banners/D_ACRYLIC.jpg'}
                        mobile={'https://storage.googleapis.com/myphoto-img/banners/M_ACRYLIC.jpg'}
                        alt={'Family wearing winter clothing on three dimensional acrylic photo block.'}
                        title={'Acrylic'}
                        color={'#222222'}
                        width={1}
                        mobilePadding={true}
                        full={true}
                        textAlignment={'center'}
                        fontSize={'20px'}
                        verticalAlignment={'flex-end'}
                        verticalAlignmentMobile={'flex-start'}
                        alignSelf={1} 
                        linkClass={'ctaLink'}
                        linkText={'See More >'} />
                    <Banner
                        smallBanner
                        link={'/products/frames-canvas'}
                        desktop={'https://storage.googleapis.com/myphoto-img/banners/D_FRAMES.jpg'}
                        mobile={'https://storage.googleapis.com/myphoto-img/banners/M_FRAMES.jpg'}
                        alt={'Father, mother, and daughter in an outdoor fall setting in a classic picture frame.'}
                        title={'Frames & Canvas'}
                        color={'#222222'}
                        width={1}
                        mobilePadding={true}
                        full={true}
                        fontSize={'20px'}
                        textAlignment={'center'}
                        verticalAlignment={'flex-end'} 
                        verticalAlignmentMobile={'flex-start'}
                        linkClass={'ctaLink'}
                        linkText={'See More >'} />
                </div>
                <div className="banners-columns is-full-width-mobile">
                    <Banner
                        link={'/products/minis'}
                        desktop={'https://storage.googleapis.com/myphoto-img/banners/HP_MinIsCard.jpg'}
                        mobile={'https://storage.googleapis.com/myphoto-img/banners/HP_MinIsCard.jpg'} 
                        alt={'Acrylic block featuring father and son sitting on wooden table in front of lit Christmas tree.'}
                        title={'Minis Collection'}
                        color={'white'}
                        width={1}
                        full={true}
                        textAlignment={'center'}
                        verticalAlignment={'flex-end'}
                        linkClass={'ctaButton'}
                        linkText={'Shop Now'}
                        hideLinkDesktop={true} />
                    <Banner
                        link={'/products/best-sellers'}
                        desktop={'https://storage.googleapis.com/myphoto-img/banners/HP_BestSellersCard.jpg'}
                        mobile={'https://storage.googleapis.com/myphoto-img/banners/HP_BestSellersCard.jpg'}
                        alt={'Top rated products to turn your favorite photos into awesome gifts or decor for your home.'}
                        title={'Best Sellers'}
                        color={'#222222'}
                        width={1}
                        full={true}
                        textAlignment={'center'}
                        verticalAlignment={'flex-end'}
                        linkClass={'ctaButton'}
                        linkText={'Shop Now'}
                        hideLinkDesktop={true} />
                </div>
                <ReviewsCarousel/>
                <div className='banners-columns'>
                    <Banner
                        link={'/products/gift-guide'}
                        desktop={'/images/banners/GiftGuide_HP_D.jpg'} 
                        mobile={'/images/banners/GiftGuide_HP_M.jpg'}
                        alt={'Family photo memories on holiday gifts under a Christmas tree.'}
                        title={`Gift Guide`}
                        additionalTitle={``}
                        fontSize={40}
                        mobileFontSize={26}
                        color={'#222222'}
                        full={true}
                        width={12}
                        textAlignment={'left'}
                        verticalAlignment={'center'}
                        textAlignmentMobile={'center'}
                        verticalAlignmentMobile={'flex-end'}
                        linkClass={'ctaButton'}
                        linkText={'Shop Now'} 
                        />
                    </div>
                    <div className='banners-columns'>
                    <Banner
                        link={'/articles'}
                        desktop={'https://storage.googleapis.com/myphoto-img/banners/BlogBanner_D.jpg'} 
                        mobile={'https://storage.googleapis.com/myphoto-img/banners/BlogBanner_M.jpg'}
                        alt={'Two girls playing'}
                        title={`Stories & Inspiration`}
                        // additionalTitle={``}
                        fontSize={32}
                        mobileFontSize={20}
                        color={'#222222'}
                        full={true}
                        width={12}
                        textAlignment={'right'}
                        verticalAlignment={'center'}
                        textAlignmentMobile={'center'}
                        verticalAlignmentMobile={'flex-end'}
                        linkClass={'ctaLink'}
                        linkText={'See More >'} />
                    </div>
                <TryItNow />
                <CategoryLinks/>
                <HomePageTabBar />
            </section>
        );
    }
}

HomePageContent.propTypes = propTypes;

function mapStateToProps(state) {
    return {
        products: state.products.list,
        categories: state.categories,
        items: state.products.items,
        photos: state.photos.photos,
        projectItems: state.projects.items,
        project: state.projects.project,
    }
}

export default withRouter(connect(mapStateToProps)(HomePageContent))
export const seoInfo = {
  homepage: {
    title: 'Photo Gifts and Decor Using Photos on Your Phone | MyPhoto',
    property: 'My Photo',
    metaDescription: 'Instantly turn photos on your phone into amazing photo gifts and decor, including frames, keepsake boxes, ornaments, coasters and more. HD printed on wood, glass, acrylic, metal and canvas, your favorite photos become precious memories. Free photo previews, free shipping and a Smile Guarantee.'
  },
  shopAll: {
    title: 'Shop Custom Photo Products - Handcrafted, High-Quality Photo Gifts and Decor | MyPhoto',
    property: 'Shop All',
    metaDescription: ' Shop our selection of unique, high-quality photo products HD printed on wood, metal, glass, acrylic, canvas, and bamboo. Enjoy ordering in minutes from the comfort of your home! Simply add your photo to instantly see it previewed on all our products. 100% quality guarantee or your money back.'
  },
  corporateGifting: {
    title: 'Discounted Holiday Gift Cards for Corporate Gifting | MyPhoto',
    property: 'Corporate Gifting',
    metaDescription: 'Volume discounts on MyPhoto gift cards help companies get the most out of their holiday budgets. And, bulk ordering is fast and easy! Your clients and employees will love these gift cards because they can order handcrafted photo gifts with their favorite photos. It\'s a gift they\'ll enjoy for years!'
  },
  aboutUs: {
    title: 'About Us – Our Story | MyPhoto',
    property: 'About Us',
    metaDescription: 'Whether it’s a photo of your family, friends, vacations, babies, pets, food, selfies…your photos deserve more than being stuck on your phone. Transform moments into quality, affordable photo art, gifts and decor instantly and affordably. Ordering takes a minute and is carefully delivered in 5 days!'
  },
  reviews: {
    title: 'Customer Reviews of Our Photo Gifts and Decor | MyPhoto',
    property: 'Reviews',
    metaDescription: 'Our customers love how easy and fast it is to order unique and meaningful photo gifts. Read their honest, unbiased reviews of all our handcrafted, high-quality photo gifts like frames, clocks, game sets, keepsake boxes, coasters, trays, canvas scrolls and more.'
  },
  faq: {
    title: 'Answers to FAQ About Our Photo Gifts and Decor | MyPhoto',
    property: 'FAQ',
    metaDescription: 'We make ordering amazing photo gifts and decor easy and fast. But sometimes customers have questions. We\'ve collected the answers to our most frequently asked questions here for easy reference. Find helpful information about shipping, social media photos, tutorials, our Smile Guarantee and more.'
  },
  howTo:{
    title: 'How To MyPhoto Your Photo – Fast and Easy Photo Gifts and Decor | MyPhoto',
    property: 'How To myPhoto',
    metaDescription: 'MyPhoto makes it fast and easy to create luxury, affordable photo art, gifts and decor from you’re most special moments. Prices start at just $9 and shipping is FREE on all orders $59 and up.'
  },
  services:{
    title: 'Personalized Photo Services + Custom Print Sizes | MyPhoto',
    property: 'Services',
    metaDescription: 'Looking for custom photo sizes? MyPhoto offers photo art and prints as large as 4x8 feet! Not only can we physicalize your digital photos…we can also digitize your physical photos. We’ll scan it and print it on the selected product with free shipping both ways!',
  },
  promotions:{
    title: 'MyPhoto Promotions – Special Offers, Discounts, and Deals | MyPhoto',
    property: 'Deals & Promotions',
    metaDescription: 'Searching for special offers for photo gifts and decor? Check out our promotions page to easily find our best discounts and deals!'
  }
}

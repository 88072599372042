import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ProductForm from '../components/homepage/ProductForm/'
import autoBind from 'react-autobind'
import '../assets/stylesheets/variables.scss'
import '../assets/stylesheets/page-shell.scss'
import '../assets/stylesheets/product-page.scss'

const propTypes = {
  products: PropTypes.array,
  items: PropTypes.array,
  location: PropTypes.object,
  dispatch: PropTypes.func,
  photo: PropTypes.object,
  params: PropTypes.object,
  edits: PropTypes.object,
  seo: PropTypes.object,
  user: PropTypes.object,
};

class ProductDetail extends React.Component {
  constructor(props) {
    super(props);
    const { project } = props;
    this.state = {
      itemId: (project && project.project && project.project.itemId) || null,
    };
    autoBind(this);
  }




  componentWillMount () {

    const { params: { productSlug } } = this.props.match
    if (productSlug) {
      this.setState({ productSlug })
    }
  }

  renderProductForm(productSlug) {
    if (this.props.products.length === 0 || this.props.loading) {
      return (
        <div>
          <div className="editing-bay-blackout"></div>
          <div className="editing-bay-blackout-container">Retrieving Images...</div>
        </div>
      );
    }

    return (
      <section className="shell-container main-container">
        <div className="container">
          <ProductForm
            key={productSlug}
            location={this.props.location}
            productSlug={productSlug}
            photo={this.props.photo}
            edits={this.props.edits}
            jsonResult={this.props.jsonResult}
            item={this.state.itemId}
          />
        </div>
      </section>
    );
  }
  render() {
    const { productSlug } = this.state;
    if(productSlug) {
      return this.renderProductForm(productSlug);
    } else {
      return <div>Loading...</div>
    }
  }
}

ProductDetail.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    products: state.products.list,
    items: state.products.items,
    photo: state.projects.current,
    edits: state.projects.current.edits,
    seo: state.config.seoData,
    loading: state.photos.loading,
    user: state.user.user,
    project: state.projects.project,
  };
}

export default connect(mapStateToProps)(ProductDetail);

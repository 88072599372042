import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as notifications } from 'react-notification-system-redux';

import loading from './loading'
import config from './config'
import product from './product'
import products from './products'
import user from './user'
import promotions from './promotions'
import giftcard from './giftcard'
import cart from './cart' // TODO: MAY NOT BE NEEDED AFTER OVERHAUL
import photos from './photos' // TODO: MAY NOT BE NEEDED AFTER OVERHAUL
import categories from './categories' // TODO: MAY NOT BE NEEDED AFTER OVERHAUL
import projects from './projects' // TODO: MAY NOT BE NEEDED AFTER OVERHAUL
import order from './order' // TODO: MAY NOT BE NEEDED AFTER OVERHAUL
import modal from './modal'
import corporateForm from './corporateForm'
import overlays from './overlays'

const rootReducer = combineReducers({
  cart,
  photos,
  product,
  products,
  categories,
  projects,
  config,
  overlays,
  routing,
  notifications,
  promotions,
  giftcard,
  order,
  user,
  loading,
  modal,
  corporateForm,
});

export default rootReducer
import React from "react";
import PropTypes from "prop-types";
import { HelmetProvider } from "react-helmet-async";
import { connect } from "react-redux";
import { getSearchParams } from "../helpers/domainParser";
import {
  fetch_user,
  appConfig,
  initialize,
  setProduct,
  is_fetching,
  update_project,
  updateZoom,
  updateMultiphotoStatus,
} from "../actions";
import queryString from "query-string-es5";
import BlockUi from "react-block-ui";
import { Spin, Icon } from "antd";

import "react-block-ui/style.css";

// integrations
import ReactGA from "react-ga";

// routes
import routes from "../routes";
import { withRouter } from "react-router-dom";

// common components
import Header from "../components/header";
import Footer from "../components/footer";
import PromoBar from "../components/header/PromoBar";
import CountDown from "../components/header/CountDown";
// import { ProductForm } from '../components/homepage/ProductForm';
import ImagesManager from "../components/common/ImagesManager";
import { closeModal } from "../actions";
import { isIOS, osVersion } from "react-device-detect";
// css
import "../assets/stylesheets/page-shell.scss";
import "../assets/stylesheets/cart-page.scss";

const badIOSArray = [
  "11.0",
  "11.0.1",
  "11.0.2",
  "11.0.3",
  "11.1",
  "11.1.1",
  "11.1.2",
  "11.2",
  "11.2.1",
  "11.2.2",
  "11.2.5",
  "11.2.6",
  "11.3",
];

const propTypes = {
  children: PropTypes.any,
  widgetMode: PropTypes.bool,
  dispatch: PropTypes.func,
  token: PropTypes.string,
  layout: PropTypes.object,
  products: PropTypes.array,
  items: PropTypes.array,
  user: PropTypes.object,
};

class App extends React.Component {
  // eslint-disable-line react/prefer-stateless-function

  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showFeatured: false,
      stripe: null,
    };
  }

  componentWillMount() {
    const userId = this.props.user.id;
    const { dispatch } = this.props;
    if (userId) {
      dispatch(fetch_user(userId));
    }
    // dispatch(fetch_photos(userId))
    if (isIOS && badIOSArray.includes(osVersion)) {
      console.log("BUGGY IOS VERSION: ", osVersion);
      let touchStartY, touchStopY, toScroll;
      let initialY = document.body.scrollTop;
      document.addEventListener(
        "touchstart",
        function(e) {
          touchStartY = e.layerY;
        },
        { passive: false }
      );
      document.addEventListener(
        "touchend",
        function(e) {
          touchStopY = e.layerY;
          toScroll = touchStartY + -1 * touchStopY;
          if (toScroll > 40 || toScroll < -40) {
            initialY += toScroll;
            document.body.scrollTop = initialY;
          }
        },
        { passive: false }
      );
      this.handleLocationChange(this.props.location);
    }
    this.props.history.listen((location, action) => {
      setTimeout(() => {
        document.getElementById("root").scrollIntoView({ behavior: "smooth" });
      }, 777);
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.history !== nextProps.history) {
      setTimeout(window.scrollTo(0, 0), 100);
    }
    if (this.props.user && this.props.user.id !== nextProps.user.id) {
      ReactGA.set({ dimension1: nextProps.user.id });
    }
    if (this.props.location !== nextProps.location) {
      ReactGA.pageview(window.location.pathname + window.location.search);
      this.handleLocationChange(nextProps.location);
    }
    if (nextProps.appConfig.id === 4) {
      // reprints channel
      const values = queryString.parse(this.props.location.search);
      if (values.isAdmin === "YES") {
        localStorage.setItem("isAdmin", "YES");
      } else {
        if (localStorage.getItem("isAdmin") !== "YES") {
          window.location.href = "https://www.myphoto.com";
        }
      }
    }
    const { dispatch, projects, user } = this.props;
    if (
      projects &&
      projects.project === undefined &&
      nextProps.user.projects &&
      nextProps.user.projects.project
    ) {
      // handle query parameters:
      const values = queryString.parse(this.props.location.search);
      if (values.photo) {
        const photoUrl = decodeURI(values.photo);
        const new_photo = nextProps.photos.find(
          (photo) => photo.image_url === photoUrl
        ); // repl image url instead of thumburl
        if (new_photo) {
          dispatch(update_project(nextProps.projects.project, new_photo));
          dispatch(is_fetching());
        }
      }
    }
    if (
      user.email !== nextProps.user.email ||
      user.name !== nextProps.user.name ||
      user.lastName !== nextProps.user.lastName ||
      user.phone !== nextProps.user.phone
    ) {
      window._learnq.push([
        "identify",
        {
          $email: nextProps.user.email,
          $first_name: nextProps.user.name,
          $last_name: nextProps.user.lastName,
          $phone_number: nextProps.user.phone,
        },
      ]);
    }
  }

  componentDidMount() {
    const { layout, dispatch } = this.props;
    const { search } = window.location;
    let { widgetToken } = window.sessionStorage;
    const { apiKey, productId } = getSearchParams(search);
    if (apiKey) {
      widgetToken = apiKey;
      window.sessionStorage.setItem("widgetToken", widgetToken);
    }
    this.setState({
      mobile: typeof window.orientation !== "undefined",
    });
    dispatch(initialize())
      .then(() => {
        dispatch(appConfig(widgetToken)).then(() => {
          document.querySelector("body").classList.add(`theme-${layout.theme}`);
          document
            .querySelector("body")
            .classList.add(`layout-${layout.layout}`);
          if (productId) {
            this.setProduct(productId);
          }
        });
      })
      .then(() => {
        // handle query parameters:
        const values = queryString.parse(this.props.location.search);
        const { dispatch } = this.props;
        if (values.uid) {
          dispatch(fetch_user(values.uid));
          dispatch(is_fetching());
          localStorage.setItem("userId", values.uid);
        } else {
          if (localStorage.getItem("userId")) {
            const userId = localStorage.getItem("userId");
            dispatch(fetch_user(userId));
            dispatch(is_fetching());
          }
        }
      });
    document.body.scrollTop = 0;
    // setTimeout(() => { window.scrollTo(0, 0) }, 197)
    setTimeout(() => {
      document.body.scrollTop = 0;
    }, 197);
    const isDev =
      window.location.href.indexOf("staging.") > -1 ||
      window.location.href.indexOf("develop.") > -1 ||
      window.location.href.indexOf(".local") > -1;
    const isHSN = window.location.href.indexOf("hsn.") > -1;
    const isMichaels = window.location.href.indexOf("michaels.") > -1;
    const uaCode = isDev
      ? "UA-68571182-4" // dev
      : isHSN
      ? "UA-68571182-5" // HSN
      : isMichaels
      ? "UA-68571182-6" // Michaels
      : "UA-68571182-1"; // default
    ReactGA.initialize(uaCode);
    ReactGA.pageview(window.location.pathname + window.location.search);
    // AdRoll pixel:
    window.adroll_adv_id = "Y4XJBRFNRBCGHLMIOPCRS2";
    window.adroll_pix_id = "FIBNSH3COZEVTP6N4FP5DH";
    var scr = document.createElement("script");
    scr.setAttribute("async", "true");
    scr.type = "text/javascript";
    scr.src =
      ("https:" === document.location.protocol
        ? "https://s.adroll.com"
        : "http://a.adroll.com") + "/j/roundtrip.js";
    (
      (document.getElementsByTagName("head") || [null])[0] ||
      document.getElementsByTagName("script")[0].parentNode
    ).appendChild(scr);
  }

  handleLocationChange = (location) => {
    // when navigate to a non-product page, we need to reset zoomed, zoomIndex, and isMultiphoto
    if (!location.pathname.startsWith("/products/")) {
      this.props.dispatch(updateZoom(0, false));
      this.props.dispatch(updateMultiphotoStatus(false));
    }
  };

  setProduct(productId) {
    const { products, items, dispatch } = this.props;
    const product = products.find(({ id }) => id === Number(productId));
    const option = product.optionItems.find(({ isDefault }) => isDefault);
    const groupName = (option && option.groupName) || "";
    // eslint-disable-next-line
    let item;
    // If there aren't any project items yet, load the default item.
    if (option) {
      item = {
        ...items.find(({ id: itemId }) => itemId === product.defaultItemId),
        groupName,
      };
    } else {
      item = {
        ...items.find(({ productId: id }) => id === product.id),
        groupName,
      };
    }
    dispatch(setProduct(productId));
    this.context.router.history.push("/upload-photo");
  }
  renderMenuIcon() {
    return (
      <div id="nav-icon3">
        <span></span>
        <span></span>
        <span></span>
        <span>
          <p className="more-text">more</p>
        </span>
      </div>
    );
  }
  menuToggle() {
    const menu = document.getElementById("nav-icon3");
    if (menu.classList.contains("open")) {
      menu.classList.remove("open");
    } else {
      menu.classList.add("open");
    }
  }
  render() {
    const { promo, loading, appConfig, timerCompleted, dispatch } = this.props;
    const timerEnded =
      appConfig && appConfig.hasOwnProperty("countdownEndDate")
        ? new Date() > new Date(appConfig.countdownEndDate)
        : true;
    const antIcon = (
      <Icon type="loading" style={{ fontSize: 100, color: "#ee6f6f" }} spin />
    );
    return (
      <BlockUi
        tag="div"
        blocking={loading}
        keepInView
        loader={<Spin indicator={antIcon} />}
      >
        <div className="App" id="page-wrap">
          <div className="ribbon-menu-container">
            {!timerEnded && !timerCompleted ? (
              <CountDown
                className="count-down-inapp"
                appConfig={appConfig}
                dispatch={dispatch}
              />
            ) : null}
            {promo ? (
              <PromoBar promoData={promo} className="promo-ribbon-inapp" />
            ) : (
              <PromoBar />
            )}
          </div>
          <Header />
          {this.props.products &&
            this.props.products[0] &&
            this.props.products[0].id && (
              <div className="wrap">
                <HelmetProvider>{routes}</HelmetProvider>
              </div>
            )}
          <ImagesManager
            isOpen={this.props.isOpen}
            onRequestClose={() => {
              this.props.dispatch(closeModal("UPLOAD_MODAL"));
            }}
          />
          <Footer />
        </div>
      </BlockUi>
    );
  }
}

function mapStateToProps(state) {
  return {
    showFeatured: state.showFeatured,
    seoData: state.config.seoData,
    token: state.config.token,
    layout: state.config.layout,
    promo: state.config.seoData.promo,
    seo: state.config.seoData,
    products: state.products.list,
    items: state.products.list.items,
    widgetMode: state.config.appConfig.widgetMode,
    user: state.user.user,
    projects: state.projects,
    isOpen: state.modal["UPLOAD_MODAL"],
    loading: state.loading.loading,
    appConfig: state.config.appConfig,
    timerCompleted: state.config.timerCompleted,
  };
}

App.propTypes = propTypes;

export default withRouter(connect(mapStateToProps)(App));

import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import _ from 'lodash';
import addDefaultSrc from '../../../helpers/addDefaultSrc'
import '../../../assets/stylesheets/product-page.scss'
const propTypes = {
  name: PropTypes.string,
  defaultOption: PropTypes.object,
  options: PropTypes.any,
  callback: PropTypes.func,
  value: PropTypes.number,
};
export class ProductOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value || 0,
      options: props.options,
      name: props.name,
      defaultOption: this.props.defaultOption,
    };
    autoBind(this);
  }

  componentWillMount() {
    if (this.props.value) this.props.callback({ value: this.props.value }, this.props.name);
  }

  componentWillReceiveProps({ value = 0, options, name, defaultOption }) {
    this.setState({
      value,
      options,
      name,
      defaultOption
    });
  }

  renderSelect(){
    return(
      <select
        className="select-option size"
        style={{ width: "170px" }}
        value={this.state.value}
        placeholder={`Select a ${this.state.name.toLowerCase()}`}
        onChange={id => {
          this.props.callback(id.target.value, this.state.name);
          this.setState({ value: id.target.value });
        }}
      >
        {this.state.options.map((option) => {
          return <option value={option.value}>{option.label.replace(/['"]+/g, '')}</option>
        })}
      </select>
    );
  }
  renderButtons(){
    const { productOptionItems } = this.props
    const selected = parseInt(this.state.value,10)
    const options = _.orderBy(this.state.options,'position','asc')
    return(
      options.map((option) => {
        const isSelected = (parseInt(option.value,10)===selected || (option.options && option.options.some(e => e.itemId === selected)))
              ? ' option-selected' : '';
        const optionLabel = (option.swatch)
              ? <img src={option.swatch} alt={option.label.replace(/['"]+/g, '')} style={{ visibility: 'hidden', margin: 0 }} />
              : option.label.replace(/['"]+/g, '')
        const thisStyle = (option.swatch)
              ? {
                  background: `url(${option.swatch}) no-repeat`,
                  backgroundPosition: 'center center',
                  color: 'white',
                  fontSize: 0,
                  padding: 0,
                  minWidth: '40px',
                  minHeight: '40px',
                }
              : { }
        const disabledStyle = (option.swatch)
              ? {
                  background: `url(${option.swatch}) no-repeat`,
                  backgroundPosition: 'center center',
                  color: 'white',
                  fontSize: 0,
                  padding: 0,
                  minWidth: '40px',
                  minHeight: '40px',
                  position: 'relative',
                }
              : { }
        const disabledIconStyle = {
          width: '40px',
          height: '40px',
          position: 'absolute',
          top: 'calc(50% - 20px)',
          left: 'calc(50% - 20px)',
        }
        var thisOption = null
        var useThisOption = false
        var disableThisOption = false
        // now let's see what item ID this button represents (or if it's disabled)
        if(option.options && productOptionItems) { // HANDLE MULTI-OPTION PRODUCTS:
          // first create array of current options names:
          const selectedProductOptions = productOptionItems.filter(opts => opts.options.some(o => o.itemId === selected))
          // const selectedNames = selectedProductOptions.map(o => o.name)
          // console.log('selectedNames ---> ', selectedNames)
          const selectedOptions = _.flatten(selectedProductOptions.map(o => o.options))
          // next check if this option is available:
          const checkIt = selectedOptions.some(o => o.name === option.label)
          // console.log('VALID OPTION ---> ', checkIt)
          disableThisOption = !checkIt
          // now determine which id to use if clicked
          const thisOptionSet = selectedOptions.find(o => o.name === option.label)
          if(thisOptionSet) {
            useThisOption = true
            thisOption = thisOptionSet.itemId
          } else {
            disableThisOption = true
          }
        } else {
        }
        if(disableThisOption) {
          return (
            <button
              key={option.id}
              style={disabledStyle}
              className={'option-button option-disabled '+(option.swatch? 'is-swatch' : '')}
              value={option.value}
              disabled
          >{(option.swatch) 
            && <img style={disabledIconStyle} onError={addDefaultSrc} src='https://storage.googleapis.com/myphoto-img/icons/disabled.svg' alt='option not available' /> }
            { optionLabel }</button>
          )
        } else {
          return (
            <button
              key={option.id}
              style={thisStyle}
              className={`option-button${isSelected} `+(option.swatch? 'is-swatch' : '')}
              value={option.value}
              onClick={id => {
                thisOption = useThisOption ? thisOption : id.target.value
                this.setState({ value: thisOption });
                this.props.callback(thisOption, this.state.name);
              }}
            >{ optionLabel }</button>
          )
        }
      })
    )
  }

  render() {
    const { overlayIsDisabled } = this.props
    const changeFlexWrap = overlayIsDisabled ? "flex-wrap" : ''
    return (
      <div className="columns with-padding-top-bottom">
        <div className="column is-3" style={{paddingRight: '20px'}}>
          <span className="option-title">{this.state.name.toUpperCase()}</span>
        </div>
        <div className={`column is-9 select-container ${changeFlexWrap}`} style={{paddingRight: 0, paddingLeft: 0}}>
              {(this.props.showAsSelector) 
              ? this.renderSelect()
              : this.renderButtons()}
        </div>
      </div>
    )
  }
}
ProductOptions.propTypes = propTypes;

export default ProductOptions;

import React from 'react'
import addDefaultSrc from '../../helpers/addDefaultSrc'
import '../../assets/stylesheets/page-shell.scss'
import '../../assets/stylesheets/warning.scss'

function ImageWarning() {
	return (
		<div className="main-container-warning">
			<div className="warning-modal-container">
				<div className="warning-modal-title-cont">
					<p className="warning-modal-title">{"DON'T WORRY, YOU HAVE OPTIONS:"}</p>
				</div>
				<div className="columns warning-modal-cont">
					<div className="column">
						<img className="warning-modal-icon"
							onError={addDefaultSrc}
							src="https://storage.googleapis.com/myphoto-img/icons/size_error_icon1.svg" alt="email" />
						<p className="warning-modal-text">1. Find the original photo and email it again at actual size.</p>
					</div>
					<div className="column">
						<img className="warning-modal-icon"
							onError={addDefaultSrc}
							src="https://storage.googleapis.com/myphoto-img/icons/size_error_icon2.svg" alt="larger size" />
						<p className="warning-modal-text">2. Select a smaller size or smaller product.</p>
						<p className="warning-modal-text-small">Several of our products are offered in a 4" x 4" size, which works well with
							most photos.</p>
					</div>
					<div className="column">
						<img className="warning-modal-icon"
							onError={addDefaultSrc}
							src="https://storage.googleapis.com/myphoto-img/icons/size_error_icon3.svg" alt="crop" />
						<p className="warning-modal-text">3. Adjust the cropping<br />(zoom out).</p>
						<p className="warning-modal-text-small">{"If you edited your photo, there's a chance you lost resolution. Try cropping less until your preview image shows:"}
							<br />{"Your photo's resolution will work at this size. Adjust the position or crop using the EDIT PHOTO button below"}</p>
					</div>
				</div>
			</div>
		</div>

	)
}
export default ImageWarning;

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Collapse } from 'antd';
import Menu from 'react-burger-menu/lib/menus/slide'
import addDefaultSrc from '../../helpers/addDefaultSrc'
import ChatBot from '../common/ChatBot'

const { Panel } = Collapse
const customPanelStyle = {
  backgroundColor: 'white',
  marginBottom: 24,
  border: '0',
};
class HamburgerMenu extends Component {
  constructor() {
    super();
    this.state = {
      isOpenVideo: false,
      menuOpen: false
    };
    //this.openModal = this.openModal.bind(this)
    
  }
  renderMenuIcon() {
    return (
      <div id="nav-icon3">
        <span></span>
        <span></span>
        <span ></span>
      </div>
    )
  }
  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen})  
  }
  closeMenu () {
    this.setState({menuOpen: false})
  }
  render() {
    return (
      <div className="hamburger-container">
        <Menu
          id='slide'
          pageWrapId={'page-wrap'}
          outerContainerId={'outer-container'}
          disableCloseOnEsc={false}
          right={(window.innerWidth > 768)}
          noOverlay={false}
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
          customBurgerIcon={this.renderMenuIcon()}
          customCrossIcon={<svg height="2em" width="2em" viewBox="64 64 896 896" fill="#3d3d3d" aria-hidden="true" focusable="false" data-icon="close">
              <path d="M 563.8 512 l 262.5 -312.9 c 4.4 -5.2 0.7 -13.1 -6.1 -13.1 h -79.8 c -4.7 0 -9.2 2.1 -12.3 5.7 L 511.6 449.8 L 295.1 191.7 c -3 -3.6 -7.5 -5.7 -12.3 -5.7 H 203 c -6.8 0 -10.5 7.9 -6.1 13.1 L 459.4 512 L 196.9 824.9 A 7.95 7.95 0 0 0 203 838 h 79.8 c 4.7 0 9.2 -2.1 12.3 -5.7 l 216.5 -258.1 l 216.5 258.1 c 3 3.6 7.5 5.7 12.3 5.7 h 79.8 c 6.8 0 10.5 -7.9 6.1 -13.1 L 563.8 512 Z"></path>
            </svg>}
          width={'240px'}
        > 
        <span className="menu-links-cont">
          <Link to="" className="menu-link home-link" onClick={()=>this.closeMenu()}>HOME</Link>
          <Link to="/products" className="menu-link shopall-link" onClick={()=>this.closeMenu()}>SHOP ALL</Link>
          <Collapse 
            accordion 
            style={customPanelStyle}
            expandIcon={({ isActive }) => <img className="colapse-icon" onError={addDefaultSrc} src="https://storage.googleapis.com/myphoto-img/icons/MENU_ARROW-01.svg" alt='' rotate={isActive ? 90 : 0} />}
          >
            <Panel header="SHOP BY CATEGORY" key="1" className="collapse-panel">
              <Collapse 
                accordion
                style={customPanelStyle}
                expandIcon={({ isActive }) => <img className="colapse-icon" onError={addDefaultSrc} src="https://storage.googleapis.com/myphoto-img/icons/MENU_ARROW-01.svg" alt='' rotate={isActive ? 90 : 0} />}
              >
                <Panel header="Shop by Material" key="2">
                  {/* <Link to="/products/ornaments" className='collapse-link' onClick={()=>this.closeMenu()}>Ornaments</Link> */}
                  <Link to="/products/acrylic" className='collapse-link' onClick={()=>this.closeMenu()}>Acrylic</Link>
                  <Link to="/products/glass" className='collapse-link' onClick={()=>this.closeMenu()}>Glass</Link>
                  <Link to="/products/metal" className='collapse-link' onClick={()=>this.closeMenu()}>Metal</Link>
                  <Link to="/products/wood" className='collapse-link' onClick={()=>this.closeMenu()}>Wood</Link>
                  <Link to="/products/frames-canvas" className='collapse-link' onClick={()=>this.closeMenu()}>Frames & Canvas</Link>
                </Panel>
                <Panel header="Shop by Placement" key="3">
                  <Link to="/products/table-top" className='collapse-link' onClick={()=>this.closeMenu()}>Shelf &amp; Desk</Link>
                  <Link to="/products/wall" className='collapse-link' onClick={()=>this.closeMenu()}>Wall</Link>
                  <Link to="/products/office" className='collapse-link' onClick={()=>this.closeMenu()}>Office</Link>
                </Panel>
                <Panel header="Shop by Style" key="4">
                  <Link to="/products/modern" className='collapse-link' onClick={()=>this.closeMenu()}>Modern</Link>
                  <Link to="/products/classic" className='collapse-link' onClick={()=>this.closeMenu()}>Classic</Link>
                </Panel>
              </Collapse>
            </Panel>
            <Panel header="FEATURED" key="5">
              <Link to="/products/best-sellers" className='collapse-link' onClick={()=>this.closeMenu()}>Best Sellers</Link>
              <Link to="/products/new" className='collapse-link' onClick={()=>this.closeMenu()}>New Arrivals</Link>
              <Link to="/products/gift-guide" className='collapse-link' onClick={()=>this.closeMenu()}>Gift Guide</Link> {/*<span role="img" aria-label="gift">&#127873;</span> */}
              <Link to="/products/minis" className='collapse-link' onClick={()=>this.closeMenu()}>Minis Collection</Link>
              <Link to="/products/ornaments" className='collapse-link' onClick={()=>this.closeMenu()}>Ornaments</Link>
              {/* <Link to="/products/acrylic-block" className='collapse-link' onClick={()=>this.closeMenu()}>Acrylic Blocks</Link> */}
            </Panel>
            <Panel header="ABOUT US" key="6">
              <Link to="/reviews" className='collapse-link' onClick={()=>this.closeMenu()}>Reviews</Link>
              <Link to="/how-to-myphoto" className='collapse-link' onClick={()=>this.closeMenu()}>How to</Link>
              <Link to="/our-story" className='collapse-link' onClick={()=>this.closeMenu()}>Our Story</Link>
              <Link to="/about-us" className='collapse-link' onClick={()=>this.closeMenu()}>About Us</Link>
              <span className="collapse-link" onClick={(e) => { 
                  e.preventDefault()
                  this.props.openModalVideo()
                  this.closeMenu()
                }}>TV Commercial</span>
            </Panel>
            <Link to="/faq" className='faq-link-hm menu-link' onClick={()=>this.closeMenu()}>FAQ</Link>
            <Link to="/order-status" className='order-status-link menu-link' onClick={()=>this.closeMenu()}>Order Status</Link>
            <div className="menu-link home-link" onClick={(e) => {
              this.closeMenu()
            }}>
              <ChatBot useTitle="SUPPORT" />

              <div className="extra_pad"></div>
            </div>
          </Collapse>
        </span>
        </Menu>
      </div>
    )
  }
}
export default HamburgerMenu
import React from "react"
import Countdown from "react-countdown-now"
import {countdownCompleted} from '../../actions'
import '../../assets/stylesheets/countdown.scss'

const pad = (number, length) => {
  var str = "" + number;
  while (str.length < length) {
    str = "0" + str;
  }
  return str;
};

export default ({ appConfig, dispatch }) => {
  const { countdownText1, countdownText2, countdownText3, countdownText1Mobile, countdownText2Mobile, 
          countdownText3Mobile, countdownBackgroundColor, countdownTextColor, countdownEndDate } = appConfig
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if(completed) { dispatch(countdownCompleted()) }
    return (
      <p className='countdown-container' style={{ backgroundColor: `${countdownBackgroundColor}`, color: `${countdownTextColor}` }}>
        <span className="first-column">
          <span className='countdown-text is-hidden-touch'>{countdownText1}</span>
          <span className='countdown-text is-hidden-desktop'>{countdownText1Mobile}</span>
          <span className='circular-number'>{pad(hours, 2)}
            <span className="time-name">hrs</span>
          </span>
          <span className='countdown-text'>:</span>
          <span className='circular-number'>{pad(minutes, 2)}
            <span className="time-name">mins</span>
          </span>
          <span className='countdown-text'>:</span>
          <span className='circular-number '>{pad(seconds, 2)}
            <span className="time-name">secs</span>
          </span>
        </span>
        <span className="second-column">
          <span className="one">
            <span className='countdown-text is-hidden-touch'>{countdownText2}&nbsp;</span>
            <span className='countdown-text is-hidden-desktop'>{countdownText2Mobile}&nbsp;</span>
            <span className='countdown-text bold is-hidden-touch'>{countdownText3}</span>
            <span className='countdown-text bold is-hidden-desktop'>{countdownText3Mobile}</span>
          </span>
          <span className="one">
          </span>
        </span>
      </p>
    )
  }
  return <Countdown
    daysInHours={true}
    date={countdownEndDate}
    renderer={renderer}
  />
}

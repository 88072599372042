import * as types from '../actions/actionTypes';

const initialState = [];

function categories(state = initialState, action) {
  switch (action.type) {
    case types.SET_APP_DATA:
      return [
        ...action.categories,
      ];
    case types.CATEGORIES_FETCH_SUCCESS:
      return [
        ...action.categories,
      ];
    case types.CATEGORIES_FETCH_ERROR:
      return [
        ...state,
      ];
    default:
      return [
        ...state,
      ];
  }
}

export default categories;

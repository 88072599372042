import React from 'react'
import addDefaultSrc from '../../helpers/addDefaultSrc'
import autoBind from 'react-autobind'
class ChatBot extends React.Component {
  constructor(props) {
    super(props)
    autoBind(this)
  }
  openWidget(){
      document.getElementById('fc_frame').style.visibility = 'visible'
      window.fcWidget.open()
  }
  render(){
    const {header} = this.props
    const useTitle = this.props.useTitle || false;
    return(
      useTitle?
      <div onClick={(e)=>this.openWidget()} style={{ cursor: 'pointer'}}>
        {useTitle}
      </div> 
      :
      <div id='custom_fc_button'>
        <span id="open_fc_widget" onClick={(e)=>this.openWidget()} style={{ cursor: 'pointer'}}>
        {header 
          ?<img id='imageicon' onError={addDefaultSrc} src="/images/icons/Chat_Button.svg" alt="CART"/>        
          :<p style={{margin: '10px 0', textTransform: 'uppercase', textDecoration: 'underline', fontSize: '12px'}}>Chat with us</p>}
          </span>
      </div>
    )
  }
}
export default ChatBot

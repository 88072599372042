import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { assign } from 'lodash';
import { withRouter } from 'react-router-dom';
import SliderProgress from './sliderProgress'
import { openModal, is_fetching, update_project_photo, delete_user_photo, switch_project_photo, update_has_filter } from '../../actions'
import addDefaultSrc from '../../helpers/addDefaultSrc'
import './slider.scss'
const API_KEY = 'AJPkbXMCpSHWIMU3KY6Ryz'

const propTypes = {
  user: PropTypes.object,
  photos: PropTypes.array,
  project: PropTypes.object,
  zoomed: PropTypes.bool,
  zoomIndex: PropTypes.number,
  isMultiphoto: PropTypes.bool,
}

const defaultItemSize = 200;
const defaultEase = 'linear';
const defaultSpeed = 500;

class Slider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      slider: null,
      style: {
        left: 0,
        width: null,
      },
      size: 0,
      current: 1,
      autoMove: null,
      count: null,
      selectedList: [],
      select: null,
      openModal: false,
      buttonsCount: 0,
      offScreen: false,
    }
    autoBind(this)
  }

  componentDidMount() {
    this.setSlider();
    window.addEventListener('resize', this.updateDimensions);
  }

  componentDidUpdate(prevProps) {
    const { photos, list, loop } = this.props;
    const { current } = this.state;
    if (this.state.slider) {
      this.updateDimensions();
    }
    if (prevProps.photos.length !== photos.length) {
      this.updateDimensions();
      if (list) {
        this.moveSliderToStart();
      } else {
        const action = (current === photos.length) ? 1 : (current + 1);
        this.moveSlider((loop ? action : (current + 1)), true);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  setSlider() {
    this.setState({
      slider: this.sliderEl,
    });
  }

  getItemSize() {
    const { list, listItemSize } = this.props;
    const { size } = this.state;
    if (list) {
      return listItemSize || defaultItemSize;
    }
    return size;
  }

  getSliderSize() {
    const { list, listItemSize, photos, remove, upload } = this.props;
    const { size } = this.state;
    this.setState(prevState => ({
      style: assign({}, prevState.style, {
        width: list ? ((listItemSize || defaultItemSize) * photos.length) :
          size * photos.length,
      }),
      count: list ? this.calculateCount(size) : null,
      current: list ? 1 : prevState.current,
      buttonsCount: ((!!upload ? 1 : 0) + ((!!remove && (photos.length > 0)) ? 1 : 0)),
    }));
  }

  moveSlider(current, click) {
    this.setState(prevState => ({
      current,
      style: assign({}, prevState.style, {
        left: -(prevState.size * (current - 1)),
      }),
    }));
  }

  moveSliderToStart() {
    this.setState(prevState => ({
      current: (1),
      style: assign({}, prevState.style, {
        left: 0,
      }),
    }));
  }

  moveSliderList(direction, click) {
    const { listItemSize, loop, photos } = this.props;
    const { size, current, count } = this.state;
    const listSize = (listItemSize || defaultItemSize) * photos.length;
    if (direction === 'fwd') {
      if (count >= (current + 1)) {
        this.setState(prevState => ({
          current: (current + 1),
          style: assign({}, prevState.style, {
            left: prevState.style.left - size,
          }),
        }));
      } else if (count > current && count < (current + 1)) {
        this.setState(prevState => ({
          current: (current + 1),
          style: assign({}, prevState.style, {
            left: -((listSize - size)),
          }),
        }));
      } else if (loop && ((current + 1) > count)) {
        this.setState(prevState => ({
          current: 1,
          style: assign({}, prevState.style, {
            left: -0,
          }),
        }));
      }
    } else {
      if (current > 2) {
        this.setState(prevState => ({
          current: (current - 1),
          style: assign({}, prevState.style, {
            left: prevState.style.left + size,
          }),
        }));
      } else if (current === 2) {
        this.setState(prevState => ({
          current: (current - 1),
          style: assign({}, prevState.style, {
            left: 0,
          }),
        }));
      } else if (loop && (current === 1)) {
        this.setState(prevState => ({
          current: Math.ceil(count, 1),
          style: assign({}, prevState.style, {
            left: -(listSize - size),
          }),
        }));
      }
    }
  }

  calculateCount(newSize) {
    const { listItemSize, photos } = this.props;
    const value = (listItemSize * photos.length) / newSize;
    return (value >= 1) ? value : 0;
  }

  updateDimensions() {
    const { list, listItemSize, photos, ease, speed } = this.props;
    const buttonsCount = (photos.length > 0) ? 1 : 0;
    const newSize = photos.length * listItemSize + buttonsCount * 4;
    if (this.state.slider && (this.state.size !== newSize)) {
      this.setState(prevState => ({
        size: newSize,
        style: {
          width: newSize,
          minWidth: newSize,
          left: list ? -0 : (-(newSize * (prevState.current - 1))),
          transition: `all ${speed || defaultSpeed}ms ${ease || defaultEase}`,
        },
        count: photos.length,
        current: prevState.current,
        buttonsCount,
        offScreen: ((window.innerWidth - 144) < newSize) ? true : false,
      }));
    }
  }

  handleSliderClick(photo) {
    const project = this.props.project
    const { dispatch, zoomIndex } = this.props
    const index = zoomIndex ? zoomIndex : 0 // localStorage.getItem("index")
    dispatch(switch_project_photo(project, photo, index))
    dispatch(update_has_filter(false))
    dispatch(is_fetching());
  }

  handleSliderDelete(photo) {
    const { dispatch, photos, user, zoomIndex } = this.props
    dispatch(is_fetching())
    const project = this.props.project
    const old_photopersonalization = project && project.project && project.project.photopersonalization && project.project.photopersonalization
    const new_photopersonalization =  old_photopersonalization.filter(p => (p.imageUrl !== photo.imageUrl && p.imageUrl !== photo.image_url))
    const index = zoomIndex ? zoomIndex : 0
    if(new_photopersonalization.length > 0) {
      dispatch(update_project_photo(project.id, project, new_photopersonalization))
      dispatch(delete_user_photo(photo.id, user.id))
    } else {
      //replace the photo
      const photo_index = photos.findIndex(s => s.id === photo.id)
      const nextPhotoIndex = photos.length - 1 - photo_index
      const nextPhoto = photos[nextPhotoIndex]
      if(nextPhoto && (nextPhoto !== photo)){
        dispatch(switch_project_photo(project, nextPhoto, index))
        dispatch(delete_user_photo(photo.id, user.id))
      } else {
        const null_photopersonalization = []
        dispatch(update_project_photo(project.id, project, null_photopersonalization))
        dispatch(delete_user_photo(photo.id, user.id))
      }
    }
  }
  render() {
    const { loop, list, showNav, navAlign, showControls, title, display, onlyShow,
      divClass, titleClass, zoomed, zoomIndex, isMultiphoto, numPhotos, photos, project } = this.props
    const { current, style, offScreen } = this.state;
    photos.sort(function compare(a, b) { return new Date(b.createdAt) - new Date(a.createdAt) })
    const useProject = (project && project.project && project.project.photopersonalization && project.project.photopersonalization.length)
    const projectIndex = (zoomed && useProject && zoomIndex < project.project.photopersonalization.length) ? zoomIndex : 0
    const projectUrl = (useProject)
        ? project.project.photopersonalization[projectIndex].imageUrl || project.project.photopersonalization[projectIndex].url
        : null
    const sliderPadding = '64px'
    const this_personalization = (useProject) ? project.project.photopersonalization.slice(0, numPhotos || 1) : null
    return (
      <div className={divClass}>
        {(!zoomed && title) &&
          <h3 className={titleClass}
            style={{ paddingLeft: sliderPadding }}>{`${title} ${(offScreen) ? " Swipe to see more." : ''}`}</h3>
        }
        <div className="slider__holder"
          ref={(el) => { this.sliderEl = el; }}
          style={{ paddingLeft: sliderPadding, position: 'relative' }}>
          <p className='add-btn-text'>Add Photo</p>
          <ul className="slider__buttons__list">
            <li className={`slider__item upload__slide ${list ? 'list' : ''}`}>
              <div
                className="slider-upload-btn"
                onClick={(e) => {
                  this.setState({ showModal: true })
                  this.props.dispatch(openModal('UPLOAD_MODAL'))
                }}>
                <img className="upload-icon-slider"
                  onError={addDefaultSrc}
                  src="https://storage.googleapis.com/myphoto-img/icons/add_photo_icon_badge.svg" alt="plus" style={{ marginBottom: 0 }} />
              </div>
            </li>
          </ul>
          <div className='slider'>
            <ul className="slider__list" style={style} onLoad={this.getSliderSize}>
              {photos.map((photo, i) => {
                const resizeImage = photo.width > (photo.height * 1.77) ? 'resize=height:90' : 'resize=width:160'
                const imageUrl = (photo.handle !== null) 
                                    ? `https://cdn.filestackcontent.com/${API_KEY}/rotate=deg:exif/${resizeImage}/${photo.handle}` 
                                    : photo.thumbUrl || photo.thumb_url || photo.url
                if (!imageUrl) { return null }
                // check if slider is selected:
                const selectedSlider = (photo.image_url === projectUrl || photo.imageUrl === projectUrl || photo.url === projectUrl)
                const isBeingUsed = (useProject && isMultiphoto) ? this_personalization.some(p => { return p.url === imageUrl }) : false
                const blobUrl = imageUrl.startsWith('blob')
                const displayImage = (blobUrl) ? imageUrl : imageUrl.replace('http:', 'https:')
                return (
                  <li key={i}
                    className={`slider__item ${list && 'list'} ${onlyShow && 'onlyShow'} ${display && 'display'} ${(photo.id) && 'selected'}`}
                    style={{ width: this.getItemSize() }}
                    onClick={() => { this.handleSliderClick(photo) }}>
                    <div className={`slider__background ${selectedSlider ? 'slider_selected' : ''}`}
                      style={(blobUrl) ? { background: 'rgba(255, 255, 255, 0.9)', width: '80px', height: '45px' } : {}}>
                      {(isBeingUsed) &&
                        <span className="in_use">
                          <img className="in_use_icon"
                            onError={addDefaultSrc}
                            src="https://storage.googleapis.com/myphoto-img/icons/check-mark-icon2.svg" alt="In Use" />
                        </span>
                      }
                      {(blobUrl)
                        ? <SliderProgress displayImage={displayImage} />
                        : <img className="slider__hidden__image" src={displayImage} alt="Your Upload" />
                      }
                    </div>
                    <span className="delete_button"
                      onClick={(e) => {
                        e.stopPropagation(); // don't trigger slider click
                        this.handleSliderDelete(photo)
                      }}>
                      <img className="delete-x-image"
                        onError={addDefaultSrc}
                        src="https://storage.googleapis.com/myphoto-img/icons/close-btn.png" alt="Delete botton" />
                    </span>
                  </li>
                );
              })
              }
            </ul>
            {(offScreen && showControls !== false) ?
              <button className="slider__prev icon-keyboard_arrow_left"
                onClick={() => {
                  if (list) {
                    this.moveSliderList('bwd', true);
                  } else {
                    const action = (current === 1) ? photos.length : (current - 1);
                    this.moveSlider((loop ? action : current - 1), true);
                  }
                }}>&lt;</button>
              : null}
            {(offScreen && showControls !== false) ?
              <button className="slider__next icon-keyboard_arrow_right"
                onClick={() => {
                  if (list) {
                    this.moveSliderList('fwd', true);
                  } else {
                    const action = (current === photos.length) ? 1 : (current + 1);
                    this.moveSlider((loop ? action : (current + 1)), true);
                  }
                }}>&gt;</button>
              : null}
            {((showNav !== false) && (photos.length > 1)) &&
              <ul className="slider__nav__list" style={{ textAlign: navAlign }}>
                {photos.map((item, i) => {
                  return (
                    <li className="slider__nav__item" key={`nav-${i}`}>
                      <button className={`slider__nav__button ${current === (i + 1) ? 'active' : ''}`}
                        onClick={() => this.moveSlider(i + 1)}>{i + 1}</button>
                    </li>
                  );
                })}
              </ul>
            }
          </div>
        </div>
      </div>
    );
  }
}

Slider.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    user: state.user.user,
    photos: state.photos.photos,
    project: state.projects.project,
    zoomed: state.projects.zoomed,
    zoomIndex: state.projects.zoomIndex,
    isMultiphoto: state.projects.isMultiphoto,
  };
}

export default withRouter(connect(mapStateToProps)(Slider))
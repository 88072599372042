import { is_fetching, update_photo } from '../actions'

const addPhotoFromProject = (project, userId, dispatch) => {
    const newPhoto = [{
        actualSize: project.project.photopersonalization[0].actualSize,
        thumbSize:  project.project.photopersonalization[0].thumbSize,
        url: project.project.photopersonalization[0].url,
        originalUrl: project.project.photopersonalization[0].url,
        fileName: "null",
        fileSize: 0
    }]
    dispatch(update_photo(userId, newPhoto))
    dispatch(is_fetching())
}


export default addPhotoFromProject

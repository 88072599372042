import React, { Fragment } from 'react'
import Cropper from 'react-easy-crop'
import { Slider } from 'antd'
import addDefaultSrc from '../../helpers/addDefaultSrc'
import { imageFilterParams, updateZoom } from '../../actions/'
import '../../assets/stylesheets/editor.scss'

const filters = [{
  name: '',
  tag: 'Original'
}, {
  name: 'enhance',
  tag: 'Enhance'
}, {
  name: 'monochrome',
  tag: 'B&W'
}, {
  name: 'sepia',
  tag: 'Sepia'
}]
const Editor = ({
  crop, zoom, onCropChange, onCropComplete, onZoomChange, containerStyle, maxZoom, dispatch, personalization,
  imageStyle, cropAreaStyle, aspect, showThumbUrl, onInteractionStart, onInteractionEnd, changeUrlFilter,
  inlineEditor, saveEditedValues, zoomIndex, zoomed, inlineEditorToggle, resetValues }) => {
  const splitImage = showThumbUrl.split("/")
  const handle = splitImage[splitImage.length - 1]
  const usedFilter = splitImage[splitImage.length - 2]
  let transformation
  if (personalization.thumbSize.width > personalization.thumbSize.height * 2) {
    transformation = 'rotate=deg:exif/resize=height:600'
  } else if (personalization.thumbSize.height > personalization.thumbSize.width * 2) {
    transformation = 'rotate=deg:exif/resize=width:600'
  } else {
    transformation = 'rotate=deg:exif/resize=width:600,height:600'
  }
  return (
    <Fragment>
      <Cropper
        image={showThumbUrl}
        crop={crop}
        zoom={zoom}
        maxZoom={maxZoom}
        aspect={aspect}
        onCropChange={onCropChange}
        onCropComplete={onCropComplete}
        onZoomChange={onZoomChange}
        style={{ containerStyle, imageStyle, cropAreaStyle }}
        onInteractionStart={onInteractionStart}
        onInteractionEnd={onInteractionEnd}
      />
      <div className="zoom-controls is-hidden-mobile"
        style={{ position: 'absolute', bottom: '-60px', width: '100%' }}>
        <span className='zoom-action-container'>
          <img onError={addDefaultSrc} alt='zoom out'
            src='https://storage.googleapis.com/myphoto-img/icons/minus-zoom-magnifier.svg' />
        </span>
        <Slider
          className='zoom-slider'
          value={zoom}
          min={1}
          max={maxZoom}
          step={0.001}
          tipFormatter={null}
          aria-labelledby="Zoom"
          onChange={zoom => onZoomChange(zoom)}
        />
        <span className='zoom-action-container'>
          <img onError={addDefaultSrc} alt='zoom in'
            src='https://storage.googleapis.com/myphoto-img/icons/plus-zoom-magnifier.svg' />
        </span>
      </div>
      {(showThumbUrl.startsWith('https://cdn.filestackcontent.com/'))
        ? <div className='editor-thumbnails-parent '>
          <div className='editor-thumbnails-container'>
            <span className='cancel-btn-container is-hidden-mobile' >
              <button
                style={{ background: 'transparent' }}
                onClick={() => {
                  inlineEditorToggle()
                  resetValues(zoomIndex, zoomed)
                  dispatch(updateZoom(zoomIndex, false))
                }}
                type="button"
                className="cancel-edits-btn"
                id="customize-btn"
              >Cancel
        </button>
            </span>
            {filters.map(filter => {
              const selectedFilterStyle = filter.name === usedFilter ? '2px solid #ee6f6f' : 'none'
              return <div
                className='editor-thumbnail'
                style={{ outline: selectedFilterStyle }}
                onClick={(e) => {
                  e.preventDefault()
                  changeUrlFilter(filter.name, handle, transformation)
                  dispatch(imageFilterParams(filter.name, handle, transformation))
                }}
              >
                <img src={`https://cdn.filestackcontent.com/${transformation}/${filter.name}/${handle}`} alt='fiter' />
                <p className='editor-filter-tag'>{filter.tag}</p>
              </div>
            })}
            <span className="save-btn-container is-hidden-mobile">
              <button onClick={(e) => {
                inlineEditor && saveEditedValues(zoomIndex, zoomed)
                inlineEditorToggle()
              }}
                type="button"
                className="save-btn"
                id="customize-btn">
                Save Edits
                  </button>
            </span>
          </div>
        </div>
        : null}
    </Fragment>
  )
}

export default Editor;
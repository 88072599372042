import React from 'react'
import { Form, Col } from 'antd'
import stateList from './stateList'
import { Field, ErrorMessage } from 'formik'
import '../../assets/stylesheets/address-form.scss'

const FormItem = Form.Item

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 }
  }
}

const config = (formikName) => ([{
  label: 'First Name',
  name: `${formikName}['name']`
}, {
  label: 'Last Name',
  name: `${formikName}['last_name']`
}, {
  label: 'Address',
  name: `${formikName}['address1']`,
}, {
  label: 'Apt/Ste, etc.',
  name: `${formikName}['address2']`
}, {
  label: 'City',
  name: `${formikName}['city']`
}])

export default ({ addressType, formikName }) => (
  <div className={`address__list ${addressType}`}>
    {config(formikName).map(({ label, name, index }) => (
      <FormItem
        label={label}
        key={`form_elem_${index}`}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 15 }}
      >
        <Field name={name} />
        <span className='alert__message'><ErrorMessage name={name} /></span>
      </FormItem>
    ))}
    <Col span={13}>
      <FormItem
        {...formItemLayout}
        label='State'
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 15 }}
        className='state-form-select'
      >
        <Field
          component='select'
          name={`${formikName}['state']`}
          className='select-state'
        >
          <option value='' disabled>Select State</option>
          {stateList.map((option) => {
            return <option key={option.value}
              placeholder='State' value={option.value}>{option.label}</option>
          })}
        </Field>
        <span className='alert__message'><ErrorMessage name={`${formikName}['state']`} /></span>
      </FormItem>
    </Col>

    <Col span={11}>
      <FormItem
        {...formItemLayout}
        style={{ display: 'inline-block' }}
        label='Zip'
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 6 }}
        className='address_part'
      >
        <Field
          name={`${formikName}['zip']`}
          style={{ width: '100px' }}
          className='zip-input'
          placeholder='12345'
        />
        <span className='alert__message_zipCode'><ErrorMessage name={`${formikName}['zip']`} /></span>
      </FormItem>
    </Col>
  </div>
)

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import PhotoPicker from './PhotoPicker';
import { Drawer } from 'antd'
import { connect } from 'react-redux'
import { isMobileOnly } from "react-device-detect"
import { closeModal } from '../../actions/'
import './images-manager.scss'

const propTypes = {
  dispatch: PropTypes.func,
  compareSelection: PropTypes.array,
  user: PropTypes.object,
}

class ImagesManager extends React.Component {
  constructor(props) {

    super(props);

    this.state = {
      selectionList: [],
      message: false,
    };

    autoBind(this);
  }

  removeAll() {
    this.setState({
      selectionList: this.state.user.photos,
      message: true,
    });
  }

  removeSelected() {
    this.setState({
      message: true,
    });
  }

  testSelection(image) {
    const { compareSelection } = this.props;
    const { selectionList } = this.state;

    if (compareSelection) {
      return !!compareSelection.find((item) => {
        return (item.url || item.photoUrl) === (image.url || image.photoUrl);
      });
    }

    return !!selectionList.filter((item) => {
      return item.url === image.url;
    }).length;
  }

  uploadPhotos() {
    this.context.router.history.push('/upload-photo');
  }

  selectImage(image) {
    const { selectionList } = this.state;
    const selection = !!selectionList.filter((item) => {
      return item.url === image.url;
    }).length;
    let newList;

    if (selection) {
      newList = selectionList.filter((item) => {
        return item.url !== image.url;
      });
    } else {
      newList = selectionList.concat([image]);
    }

    this.setState({
      selectionList: newList,
    });
  }

  switchAllSelection() {
    const { selectionList, user } = this.state;

    if (user.photos.length === selectionList.length) {
      this.setState({
        selectionList: [],
      });
    } else if (user.photos.length > selectionList.length) {
      this.setState({
        selectionList: user.photos,
      });
    }
  }
  onClose = () => {
    this.props.dispatch(closeModal('UPLOAD_MODAL')) 
  }

  render() {
    const sliderWidth = isMobileOnly ? '100vw' : 460
    return (
      <Fragment>
        <Drawer
          width={sliderWidth}
          onClose={this.onClose}
          visible={this.props.isOpen}
        >
          <PhotoPicker onClose={this.onClose}/>
        </Drawer>
      </Fragment>
    );
  }
}

ImagesManager.propTypes = propTypes;

export default connect(null)(ImagesManager);

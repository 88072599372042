import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { message } from "antd";
import { path } from "ramda";
import { send_text as sendText } from "../../../actions/";
import InputForm from "./InputForm";
import ContinueButton from "./ContinueButton";
import addDefaultSrc from "../../../helpers/addDefaultSrc";
import "../../../assets/stylesheets/page-shell.scss";
import "../../common/photo-picker/device-upload.scss";

const Content = {
  step1: {
    title: "From Your Computer:",
    text: "Enter your mobile phone number below and click SEND TEXT.",
    subText: "(we promise, we'll only send you this one text)",
  },
  step2: {
    title: "From Your Phone:",
    text1: "Open the text message we just sent. Click the link.",
    text2: 'Select "My Phone" option and choose the photo(s) you wish to use.',
    text3: 'Click "Done"',
    text4:
      "You'll see your photo(s) loading on your phone. WAIT until all selected photos have completed when the",
    underlinedText: "spinning wheel(s) stop.",
  },
  step3: {
    title: "From Your Computer:",
    text: "Click CONTINUE below to see those photos on your computer.",
  },
  icon: "https://storage.googleapis.com/myphoto-img/icons/stop.svg",
};
const WaitingForUrl = () => (
  <div className="phone__instructions">
    <h3 className="phone__connection__title">
      Getting the url to connect, please wait...
    </h3>
  </div>
);

class PhoneImport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayContinue: false,
      newPhone: null,
    };
    this.setState = this.setState.bind(this);
    this.updatePhoneNumber = this.updatePhoneNumber.bind(this);
    this.handleNewPhoneNumber = this.handleNewPhoneNumber.bind(this);
  }

  updatePhoneNumber(e) {
    this.setState({ newPhone: e.target.value });
  }

  handleNewPhoneNumber(value) {
    // call twilio api point here
    const { dispatch, user } = this.props;
    const userId = path(["id"], user);
    dispatch(sendText(userId, value));
    setTimeout(() => {
      this.setState({ displayContinue: true });
    }, 30000); // waits 30 seconds to activate the 'Continue' button
    message.info("Text Message Sent", 10);
  }
  renderIcon() {
    return (
      <div className="phone-import-image-container">
        <div className="phone-import-line"></div>
        <img
          className="phone-import-image"
          alt="STOP"
          src={Content.icon}
          onError={addDefaultSrc}
        />
        <div className="phone-import-line"></div>
      </div>
    );
  }
  render() {
    const { user, phone, dispatch } = this.props;
    const { displayContinue, newPhone } = this.state;
    const userId = path(["id"], user);
    const existingPhone = path(["phone"], user);
    return path(["connectionUrl", phone]) ? (
      <div className="phone-steps">
        <h3 className="phone-import-title">{Content.step1.title}</h3>
        <p className="phone-import-text">{Content.step1.text}</p>
        <InputForm
          dispatch={dispatch}
          existingPhone={existingPhone}
          newPhone={newPhone}
          updatePhoneNumber={this.updatePhoneNumber}
          handleNewPhoneNumber={this.handleNewPhoneNumber}
        />
        <p className="phone-import-subtext">{Content.step1.subText}</p>
        {this.renderIcon()}
        <h3 className="phone-import-title">{Content.step2.title}</h3>
        <p className="phone-import-text paragraph">{Content.step2.text1}</p>
        <p className="phone-import-text paragraph">{Content.step2.text2}</p>
        <p className="phone-import-text end-paragraph">{Content.step2.text3}</p>
        <p className="phone-import-text">
          {Content.step2.text4}&nbsp;
          <span style={{ textDecoration: "underline" }}>
            {Content.step2.underlinedText}
          </span>
        </p>
        {this.renderIcon()}
        <h3 className="phone-import-title">{Content.step3.title}</h3>
        <p className="phone-import-text">{Content.step3.text}</p>
        <ContinueButton
          dispatch={dispatch}
          userId={userId}
          displayContinue={displayContinue}
          backButton={this.props.goBack}
        />
      </div>
    ) : (
      <WaitingForUrl />
    );
  }
}

const mapStateToProps = (state) => ({
  phone: state.photos.phone,
  user: state.user.user,
});

export default withRouter(connect(mapStateToProps)(PhoneImport));

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { openModal } from '../../../actions'
import addDefaultSrc from '../../../helpers/addDefaultSrc'
import TabBar from './TabBar';

class GenericTabBar extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  }
  render() {
    const hasUploads =
      (this.props.photos && this.props.photos.length > 0 &&
        this.props.project && this.props.photos[0] && this.props.photos[0].id);
    return (
      <TabBar>
        <div className="tab-bar-level">
          {hasUploads 
            ? <div className="tab-bar-level-item">
              <button
                onClick={() => {
                  this.context.router.history.push('/products');
                }}
                type="button"
                className="cta-btn easy-btn"
              >
                See all Products
          </button>
            </div>
            : <div className="tab-bar-level-item">
              <button
                onClick={() => {
                  this.props.dispatch(openModal('UPLOAD_MODAL'))
                }}
                type="button"
                className="cta-btn easy-btn"
              >
                Upload your photo. It's Easy.
                <img className='tab-bar-arrow-icon' onError={addDefaultSrc}
                  src={'https://storage.googleapis.com/myphoto-img/icons/arrow-right-icon.png'} alt='' />
              </button>
            </div>}
        </div>
      </TabBar>
    )
  }
}
function mapStateToProps(state) {
  return {
    user: state.user.user,
    project: state.projects.project,
    photos: state.photos.photos,
  }
}
export default connect(mapStateToProps)(GenericTabBar)
import React, { Fragment, useState } from 'react'
import ModalVideo from 'react-modal-video'

export default function ViewVideoLink( { videoId, title }) {
    const [isOpen, setOpen] = useState(false);

    return (
        (videoId !== null ) && 
        <Fragment>
            <ModalVideo
            channel='youtube'
            isOpen={isOpen}
            videoId={videoId}
            onClose={() => setOpen(false)} />

            <div className="playVideoLink"
            onClick={(e) => {
                e.preventDefault();
                setOpen(true);
              }
            }><img src="/images/icons/play-video-btn.svg" alt="" /> {title}</div>
        </Fragment>      
    )
}
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
// import { updateZoom } from '../../actions/index'

const BackToProducts = ({product, prodCatName, prodStyle, item, prodStyleName, dispatch, zoomIndex, zoomed }) => {
 
 return <div className="back-to-products-container">
    <Link to="/" className="back-to-products-link">
      <span className="back-to-products-text">Home</span>
    </Link> &gt;&nbsp;
                <Link to="/products" className="back-to-products-link">
      <span className="back-to-products-text">All Products</span>
    </Link> &gt;&nbsp;
                <Link to={`/products/${product.category}`} className="back-to-products-link">
      <span className="back-to-products-text">{prodCatName}</span>
    </Link> &gt;&nbsp;
                <Link to={`/products/${product.category}/${prodStyle}`} className="back-to-products-link">
      <span className="back-to-products-text">{prodStyleName}</span>
    </Link> {/* &gt;&nbsp;
                {zoomed
      ? <span>
        <span onClick={e => { dispatch(updateZoom(0, false)) }} className="back-to-products-link">
          <span className="back-to-products-text">{item.name}</span>
        </span> &gt;&nbsp;<span className="back-to-products-text">Photo {zoomIndex + 1}</span>
      </span>
                : <span className="back-to-products-text">{item.name}</span>
    } */}
  </div>
}

export default withRouter(BackToProducts)

import * as types from '../actions/actionTypes';
import { assign } from 'lodash';

const initialState = {
  id: null,
  salesChannelId: 1,
  status: 'cart',
  sourceId: null,
  cartId: null,
  orderDate: null,
  dropship: false,
  debugMode: false,
  discount: {},
  notes: { gift: '' },
  customer_email: '',	     
  customer_name: '',	 
  customer_last_name: '',	 
  customer_phone: '',	 
  billing_address1: '',
  billing_address2: '',
  billing_city: '',
  billing_state: '',
  billing_zip: '',
  billing_country: '',
  billing_name: '',
  billing_last_name: '',
  shipping: [],
  amounts:
   { merchdise: 0,
     tax: 0,
     shipping: 0,
     handling: 0,
     discount: 0,
     total: 0,
     currency: 'USD' },
  items: [],
  processing: {},
  transactions: [],
  confirm_id: null,
  orderStatus: [],
 };

function order(state = initialState, action) {
  switch (action.type) {
    case types.ORDER_PLACE_SUCCESS:
      return assign({}, state, {
          ...action.order,
        });
    case types.ORDER_PLACE_ERROR:
      return assign({}, state, {
        ...state,
        error: action.error,
      });
    case types.FETCH_ORDER_STATUS:
    return assign({}, state, {
      orderStatus: action.payload,
    });
    case types.RESET_ORDER:
      return initialState;
    case types.SAVE_BRAINTREE_INSTANCE:
      return {brainTreeInstance: action.payload};
    default:
      return state;
  }
}

export default order;

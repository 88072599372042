import React from 'react'
import Slider from 'react-slick'
import { Link, withRouter } from 'react-router-dom'
import addDefaultSrc from '../../helpers/addDefaultSrc'
import '../../assets/stylesheets/reviewscarousel-container.scss'

export const prevArrow = <img className="slick-prev" alt="previous review" onError={addDefaultSrc} src='https://storage.googleapis.com/myphoto-img/reviews-carousel/Arrow2.svg' />
export const nextArrow = <img className="slick-next" alt="more reviews" onError={addDefaultSrc} src='https://storage.googleapis.com/myphoto-img/reviews-carousel/Arrow1.svg' />
export const sliderSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // comment out this to stop auto scroll
    // speed: 7000,    // ^^^
    autoplaySpeed: 7000, // ^^^
    // cssEase: "linear",
    prevArrow,
    nextArrow,
}
const reviews = [
    {
        comment:  'With it being our baby girls first holiday, I knew I had to find something unique, and MyPhoto came through! Not only did I find the perfect ornament for Ellie, but for the whole family too!',
        name: '- @casaderami',
        image: 'https://storage.googleapis.com/myphoto-img/reviews-carousel/reviews-carousel-7.jpg',
        link: '/products/ornaments/glass/miniglassornament',
        position: 'right'
    }, {
        comment:  'Can’t wait to set these beauties on our playroom shelf! MyPhoto has SO many options but these acrylic cubes have our heart…',
        name: '- @pienthesky',
        image: 'https://storage.googleapis.com/myphoto-img/reviews-carousel/reviews-carousel-1.jpg',
        link: '/products/acrylic/table-top/acrylic-miniblox',
        position: 'right'
    }, {
        comment: 'I love the acrylic blocks! The quality is fantastic and I know my Mom will love them!',
        name: '- Katie Z.',
        image: 'https://storage.googleapis.com/myphoto-img/reviews-carousel/reviews-carousel-2.jpg',
        link: '/products/acrylic/table-top/acrylic-block',
        position: 'left'
    }, {
        comment: 'How cute is this! The quality is amazing. It’s a crystal clear print and look how thin it is! All you have to do is peel the back and stick it on the wall -- it’s as easy as that!',
        name: '- @emilywondree',
        image: 'https://storage.googleapis.com/myphoto-img/reviews-carousel/reviews-carousel-8.jpg',
        link: '/products/glass/wall/airglass', // change to galleri frame when back in site
        position: 'right'
    }, {
        comment: 'Love these 2×2 photo cubes! I have purchased the acrylic & bamboo and love them both!',
        name: '- Rhonda L.',
        image: 'https://storage.googleapis.com/myphoto-img/reviews-carousel/reviews-carousel-4.jpg',
        link: '/products/acrylic/table-top/acrylic-miniblox',
        position: 'left'
    }, {
        comment: 'I wanted a few sentimental and beautiful ornaments for my Christmas tree and  these wooden ornaments from MyPhoto are perfect! They are very beautiful and well made.',
        name: '- @our.simplehectic.home',
        image: 'https://storage.googleapis.com/myphoto-img/reviews-carousel/reviews-carousel-9.jpg',
        link: '/products/ornaments/wall/wood-ornament',
        position: 'right'
    }, {
        comment: 'This is my second photo printed on metal and they are beautifully done. Quality is great and the price is affordable.',
        name: '- Deborah N.',
        image: 'https://storage.googleapis.com/myphoto-img/reviews-carousel/reviews-carousel-6.jpg',
        link: '/products/metal/table-top/moderna-metal',
        position: 'left'
    }]

class CTAsection extends React.Component {
    render() {
        return (
            <div className='reviewscomponent-container'>
                <Slider ref={slider => (this.slider = slider)} {...sliderSettings}>
                    {reviews.map((review, index) => (
                        <div key={index} className='reviewscomponent-card'>
                            <div className='reviewscomponent-content'>
                                <img className='reviewscomponent-stars' alt="previous review" onError={addDefaultSrc} src='https://storage.googleapis.com/myphoto-img/icons/stars.png'/>
                                <p className='reviewscomponent-text'>"{review.comment}"</p>
                                <p className='reviewscomponent-customer'>{review.name}</p>
                                <Link to='/reviews' style={{marginTop: '20px'}}><button className='more-reviews-btn'>See More Reviews</button></Link>
                            </div>
                            <div className={`reviewscomponent-image ${review.position}`}>
                                <Link to={review.link}>
                                    <img alt="" onError={addDefaultSrc}
                                            src={review.image} />
                                </Link>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        )
    }
}

export default withRouter(CTAsection)
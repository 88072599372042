import React from 'react'
import { withRouter } from 'react-router-dom'
import Slider from 'react-slick'
import ProductMiniCard from './ProductMiniCard'
import addDefaultSrc from '../../helpers/addDefaultSrc'
import '../../assets/stylesheets/product-carousel.scss'

export const prevArrow = <img className="prev-arrow" alt="previous slide" onError={addDefaultSrc} src='https://storage.googleapis.com/myphoto-img/reviews-carousel/Arrow2.svg' />
export const nextArrow = <img alt="next slide" onError={addDefaultSrc} src='https://storage.googleapis.com/myphoto-img/reviews-carousel/Arrow1.svg' />
export const sliderSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // comment out this to stop auto scroll
    // speed: 7000,    // ^^^
    autoplaySpeed: 7000, // ^^^
    // cssEase: "linear",
    prevArrow,
    nextArrow,
    responsive: [
        {
            breakpoint: 3000,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4
            }
        },
        {
            breakpoint: 1366,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}

class ProductCarousel extends React.Component {
    render() {
        const { title, productIds, products, project } = this.props
        const displayProducts = productIds.map(i => products.find(p => p.id === i))
        const hasProject = project && project.project && project.project.photopersonalization && project.project.photopersonalization[0] && project.project.photopersonalization[0].actualSize && project.project.photopersonalization[0].actualSize.width
        return (
            <div className ='product-carousel-container'>
                <div className='carousel-title'><h2>{title}</h2></div>
                <Slider ref={slider => (this.slider = slider)} {...sliderSettings}>
                    {displayProducts.map && displayProducts.map((product, index) => {
                        if(product){
                            return(
                                <div key={index}>
                                    <ProductMiniCard
                                        product={product}
                                        hasProject={hasProject}
                                    />
                                </div>
                            )
                        } else {
                            return null
                        }
                    })}
                </Slider>
            </div>
        )
    }
}

export default withRouter(ProductCarousel)
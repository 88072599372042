import React from 'react'
import { Form } from 'antd'
import { Field, ErrorMessage } from 'formik'

const FormItem = Form.Item

const options = [{
  label: 'Email:',
  placeholder: 'you@email.com',
  name: 'email',
  type: 'email'
}, {
  label: 'First Name:',
  placeholder: 'Enter your first name',
  name: 'name',
  type: 'text'
}, {
  label: 'Last Name:',
  placeholder: 'Enter your last name',
  name: 'lastName',
  type: 'text'
}, {
  label: 'Phone:',
  placeholder: 'Enter your phone number',
  name: 'phone',
  type: 'tel'
}]

export default () =>
  options.map(({ label, placeholder, name, type }) => (
    <FormItem key={name} label={label} labelCol={{ span: 5 }} wrapperCol={{ span: 15 }}>
      <Field
        type={type}
        placeholder={placeholder}
        name={name}
      />
      <span className='alert__message'><ErrorMessage name={name} /></span>
    </FormItem>
  ))

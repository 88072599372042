import React from 'react'
import autoBind from 'react-autobind'
import { Collapse } from 'antd';
import FaqCollapseConatiner from '../../components/faq/FaqCollapseContainer'
import FaqCategoryWrapper from '../../components/faq/FaqCategoryWrapper'

const { Panel } = Collapse

class FaqCorporateGifting extends React.Component {
  constructor(props) {
      super(props)
      autoBind(this)
  }
  render() {
      return (
        <FaqCategoryWrapper title='Corporate Gifting'>
          <div className='faq-questions-container'>
            <FaqCollapseConatiner>
              <Panel header="Do you offer corporate gifting programs or large quantity discounts?" key="1">
                <p>While we always have amazing prices, if you want to order over $2,000 for family, friends, or organizations, please contact us at <a className="faq-link" href="mailto:bigorders@myphoto.com">bigorders@myphoto.com</a> to learn more. </p>
              </Panel>
            </FaqCollapseConatiner>
          </div>
        </FaqCategoryWrapper>
      )
  }
}

export default FaqCorporateGifting;
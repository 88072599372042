import React from 'react'

export default ({hasProject, project, qtyValue, qty, qtySuffix, 
      allowsPersonalization, isMultiPhoto, addDefaultSrc, handlePromoModal, handleQtyChange, setQuantity}) => {
  return (
    <div className="columns" style={{ justifyContent: 'space-between', paddingBottom: "15px" }}>
      <div className="column">
        <h3 className="product-qty">QTY:</h3>
      </div>
      <div className="column">
        <select
          className="select-option qty"
          style={{ width: '50px', wordSpacing: '30px' }}
          value={(hasProject) ? project.project.qty : qtyValue}
          onChange={e => {
            setQuantity(e.target.value)
            handleQtyChange(e.target.value)
          }}
          name="form-field-name1">
          {qty.map((option, id) => {
            return <option key={id} value={option.value}>{option.label}{(option.value > 1) ? qtySuffix : null}</option>
          })}
        </select>
      </div>
      <div className="column">
        <div
          onClick={(e) => {
            e.preventDefault()
            handlePromoModal()
          }}>
          {allowsPersonalization
            ? (isMultiPhoto)
              ? <p className="add-second-text">Buy Another Set at <span className='bold'>50% Off</span><img className="info-icon" onError={addDefaultSrc} src="https://storage.googleapis.com/myphoto-img/icons/info.svg" alt="info" /></p>
              : <p className="add-second-text">Buy Another at <span className='bold'>50% Off</span><img className="info-icon" onError={addDefaultSrc} src="https://storage.googleapis.com/myphoto-img/icons/info.svg" alt="info" /></p>
            : null}
        </div>
      </div>
    </div>
  )
}
import React from 'react';
import ChatBot from '../common/ChatBot';
import './footer.scss';

function ContactText() {
  return (
    <span style={{letterSpacing: '1px', fontSize: '12px'}}>
      <ChatBot />
      EMAIL:&nbsp;<a href="mailto:hello@myphoto.com">hello@myphoto.com</a>
      <br />
      {/* <p style={{margin: '15px 0 0', letterSpacing: 'normal'}}>2915 South Congress Ave</p> */}
      {/* <p style={{margin: '5px 0 0', letterSpacing: 'normal'}}>Delray Beach, FL 33445</p> */}
    </span>
  );
}

export default ContactText

import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import autoBind from 'react-autobind'
import ImagesManager from '../../src/components/common/ImagesManager'
import MetaDataTags from '../helpers/ProcessMetaData'
import { Link } from 'react-router-dom'
import ReactPlayer from 'react-player'
import { seoInfo } from '../helpers/seoInfo'
import addDefaultSrc from '../helpers/addDefaultSrc'
import AboutLinks from '../components/common/AboutLinks'
import CTAsection from '../components/homepage/CTAsection'
import '../assets/stylesheets/page-shell.scss'
import '../assets/stylesheets/reviews.scss'
import '../assets/stylesheets/about-us.scss'

const Content ={
    title1: 'See what our customers are saying',
    title2: 'Be Inspired',
    stars: 'https://storage.googleapis.com/myphoto-img/reviews/reviews-stars-01.svg',
    reviewsCards: [{
        image: 'https://storage.googleapis.com/myphoto-img/reviews/reviews-card-1.jpg',
        link: '/products/acrylic/table-top/acrylic-block',
        review: 'The picture came out very clear. It’s a memorable gift for my Mom and I know she is going to love it! It was shipped very quickly as well!',
        reviewer: '- Nicole R.',
        position: 'left'
    },{
        image: 'https://storage.googleapis.com/myphoto-img/reviews/reviews-card-2.jpg',
        link: '/products/acrylic/table-top/acrylic-miniblox',
        review: 'Thanks MyPhoto for making my memories eternal in such a fun way! Loooove my acrylic miniblox!',
        reviewer: '- @urbangirlgonebeachmom',
        position: 'right'
    },{
        image: 'https://storage.googleapis.com/myphoto-img/reviews/reviews-card-4.jpg',
        link: '/products/ornaments/glass/glassornament',
        review: 'It’s our first Christmas with Henry and we wanted something special on the tree this year. These ornaments came out adorable - I love them!',
        reviewer: '- @classicallydunn',
        position: 'left'
    }],
    inspiration:[{
        image: '/images/reviews/reviews-inspiration1.png',
        link: '/products/wood/table-top/bamboo-miniblox',
        className: 'inspiration-1'
    },{
        image: '/images/reviews/reviews-inspiration2.png',
        link: '/products/acrylic/table-top/miniblox-collection',
        className: 'inspiration-2'
    },{
        image: '/images/reviews/reviews-inspiration3.png',
        link: '/products/acrylic/wall/mini-magnets',
        className: 'inspiration-3'
    },{
        image: '/images/reviews/reviews-inspiration4.png',
        link: '/products/acrylic/table-top/acrylic-miniblox',
        className: 'inspiration-4'
    },{
        image: '/images/reviews/reviews-inspiration5.png',
        link: '/products/acrylic/table-top/acrylic-block',
        className: 'inspiration-5'      
    }]
}
const propTypes = {
    dispatch: PropTypes.func,
}
class Reviews extends Component {
    static contextTypes = {
        router: PropTypes.object
    }

    constructor() {
        super();
        this.state = {
            showModal: false,
        };
        autoBind(this);
    }
    componentDidMount() {
        setTimeout(() => { if (window.yotpo) window.yotpo.refreshWidgets() }, 537);
        window.gtag('event', 'page_view', {
            'send_to': 'AW-668262331',
            'ecomm_pagetype':'other'
        });
    }
    
    render() {
        return (
            <div className="shell-container main-container ">
                <MetaDataTags baseUrl='reviews' seoInfo={seoInfo.reviews} />
                <div className="reviews-container">
                    <AboutLinks title={'Reviews'}/>
                    <h1 className='reviews-title title1'>{Content.title1}</h1>
                    { this.state.showModal && <ImagesManager showModal={this.state.showModal}/>}
                    {Content.reviewsCards.map(card =>{
                        return(
                            <div className='reviews-card'>
                                <div className="reviews-card-image">
                                    <Link to={card.link}>
                                        <img alt="" onError={addDefaultSrc}
                                        src={card.image} />
                                    </Link>
                                </div>
                                <div className={`reviews-card-content ${card.position}`} >
                                    <img className='reviews-card-stars' alt="" onError={addDefaultSrc}
                                        src={Content.stars} />
                                    <p className='reviews-card-review'>"{card.review}"</p>
                                    <p className='reviews-card-reviewer'>{card.reviewer}</p>
                                </div>
                            </div>
                        )
                    })}
                    <h1 className='reviews-title title2'>{Content.title2}</h1>
                    <div className='reviews-images-grid'>
                        {Content.inspiration.map( i =>{
                            return(
                                    <Link to={i.link} className={i.className}>
                                        <div className=""
                                            style={{
                                            backgroundPosition:'center',
                                            backgroundSize: 'cover',
                                            backgroundImage: `url(${i.image})`,
                                            backgroundRepeat: 'no-repeat',
                                            height: '100%'
                                        }}></div>
                                    </Link>
                                    )
                            })}
                    </div>
                    <div className='reviews-relive-container'>
                        <h1 className='reviews-title title3'>Relive It</h1>
                        <ReactPlayer 
                            className='react-player-component'
                            url='https://youtu.be/O-cGQW0Rzgo'/>
                    </div>
                    <CTAsection />
                    <div className="container reviews-yotpo" style={{marginTop: '40px'}}>
                        <div 
                            id="yotpo-testimonials-custom-tab"
                            className="yotpo yotpo-main-widget"
                            data-name="MyPhoto Site Reviews"
                            data-product-id="yotpo_site_reviews"
                            data-url="www.myphoto.com"></div>
                    </div>
                </div>
            </div>
        )
    }
}
Reviews.propTypes = propTypes;


export default withRouter(connect((state) => {
    return {
    photos: state.photos.photos,
    };
})(Reviews))

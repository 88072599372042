import React from 'react'
import autoBind from 'react-autobind'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { openModal } from '../../actions'
import '../../assets/stylesheets/try-it-now.scss'

class TryItNow extends React.Component {
    constructor(props) {
        super(props)
        autoBind(this)
    }
    render() {
        const { dispatch } = this.props
        return (
            <div className='try-it-now-container'>
                <div className='try-it-now-text-container'>
                  <h2>Try it now</h2>
                  <p>It's Crazy Easy. Crazy Fast.&trade;</p>
                </div>
                <div className='try-it-now-button-container'>
                  <div className='try-it-now-section'>
                    <div className='try-it-now-number'><p>1</p></div>
                    <button style={{ cursor: "pointer" }} className="try-it-btn"
                        onClick={(e) => {
                            e.preventDefault()
                            this.setState({ showModal: true })
                            dispatch(openModal('UPLOAD_MODAL'))
                        }}>
                        <span style={{ verticalAlign: 'middle', display: 'inline-block', lineHeight: '100%',
                                    paddingLeft: '4px' }}>Upload Photo</span>
                    </button>
                  </div>
                  <div className='try-it-now-section'>
                    <div className='try-it-now-number'><p>2</p></div>
                    <p className='try-it-now-text'>See your photos </p>
                    <p className='try-it-now-text'>on our products.</p>
                  </div>
                  <div className='try-it-now-section'>
                    <div className='try-it-now-number'><p>3</p></div>
                    <p className='try-it-now-text'>Add to cart and <span className='try-it-now-break'>checkout!</span></p>
                  </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect()(TryItNow))
import React, { useState } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { history } from '../store'
import PropTypes from 'prop-types'
import { pathOr} from 'ramda'
import CartList from '../components/cart'
import GuaranteeRibbonWhite from '../components/common/guaranteeUpdates/GuaranteeRibbonWhite'
import {monetateEmptyCartTracker,setIntervalHelper} from '../helpers/monetate'
import ShippingProgressBar from '../components/common/ShippingProgressBar'
import MetaDataTags from '../helpers/ProcessMetaData'
import addDefaultSrc from '../helpers/addDefaultSrc'
import '../assets/stylesheets/cart-page.scss'
import '../assets/stylesheets/page-shell.scss'



const propTypes = {
  user: PropTypes.object,
  shippingOptions: PropTypes.array,
  
}

const Cart = ({user, cart, seo, products, shippingOptions}) => {
  const [subtotal, setSubtotal] = useState(0)
  const cartItems = pathOr(false, ['items', 0, 'id'], cart)
  console.log("cartItems", cart.items)
  function handler(subtotal) {
    setSubtotal(subtotal)
   

    
  }



  function renderLoading() {
  
    return (
      <div className="shell-container main-container">
        <section className="empty-cart-page main__container">
          <div className="empty-cart-page__content">
            <h2 className="empty-cart-header">Loading...</h2>
          </div>
        </section>
      </div>
    );
  }
  function renderEmptyCart() {
    setIntervalHelper(() =>{monetateEmptyCartTracker()});
    return (
      <div className="cart-modal-main-container">
        <section className="empty-cart-page main__container">
          {seo && <MetaDataTags pagesMetaData={seo} optionalTitle="Cart" />}
          <div className="modal__header main__header">

            {/* {this.doubleUpBanner()}
            {this.state.showModal ? this.doubleUpBannerModal() : null} */}
            <div className="empty-cart-page__content container">
              <h2 className="main__title empty-cart-title">Your Cart</h2>
              <h2 className="empty-cart-header">Always worth a try.</h2>
              <p className="empty-cart-text">Unfortunately, we do not allow you to purchase empty carts.</p>
              <div className="continue-shopping-cont" onClick={() => {
                history.push('/products')
              }}> <h5 className="continue-shopping-link">Continue Shopping</h5>
                  <img className='continue-shopping-arrow' alt='right chevron' src='https://storage.googleapis.com/myphoto-img/icons/continue-shopping-arrow.svg' onError={addDefaultSrc}/>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }

  

  if (!user || !cartItems || !products) {
    if ((seo && !user) || (user && !user.id) || (user.id && (!cartItems))) {
      // if no items, render empty cart
      return renderEmptyCart();
    } else {
      
      // otherwise, render Loading...
      return renderLoading();

      
    }
  } else {
  


  return (

    
    <div className="shell-container cart-page-container">
        <MetaDataTags baseUrl='cart' />
        <div className="cart-modal-main-container">
        
          <div className="modal__header main__header">
            <h2 className="main__title your-cart-title">
              {/* <img className="cart-icon-cart" src="https://storage.googleapis.com/myphoto-img/icons/cart-icon.svg" alt="CART" style={{ float: 'none' }} onError={addDefaultSrc}/> */}
              Your Cart
                </h2>
          </div>
          <div className="cart-list-container">
            <span >
              <GuaranteeRibbonWhite/>
            </span>
            <div className="shipping-progress-container">
              <ShippingProgressBar subtotal={subtotal} shippingOptions={shippingOptions} />
                <div 
                className="added-continue-order"
                onClick={() => {
                history.push('/products')
              }}>
                <h5 className="continue-shopping-link">Continue Shopping</h5>
                <img className='continue-shopping-arrow' alt='right chevron' src='https://storage.googleapis.com/myphoto-img/icons/continue-shopping-arrow.svg' onError={addDefaultSrc}/>
              </div>
            </div>
            <CartList handler={handler} /> 
          </div>
        </div>
    </div>
  )
}
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
    products: state.products.list,
    cart: state.cart.cart,
    shippingOptions: state.config.appConfig.shippingOptions,
  }
}

Cart.propTypes = propTypes;
Cart.contextTypes = {
router: PropTypes.object.isRequired
}

export default withRouter(connect(mapStateToProps)(Cart))

import React from 'react'
import autoBind from 'react-autobind'
import { Link } from 'react-router-dom'
import { Collapse } from 'antd';
import FaqCollapseConatiner from '../../components/faq/FaqCollapseContainer'
import FaqCategoryWrapper from '../../components/faq/FaqCategoryWrapper'

const { Panel } = Collapse

class FaqSecurePersonalInfo extends React.Component {
  constructor(props) {
      super(props)
      autoBind(this)
  }
  render() {
      return (
        <FaqCategoryWrapper title='Secure Personal Information'>
          <div className='faq-questions-container'>
            <FaqCollapseConatiner>
              <Panel header="Are payments secure?" key="1">
                <p>Yes. Our checkout process is secured by Thawte, an industry-leader in encryption technologies for transferring information securely over the Internet.</p>
              </Panel>
              <Panel header="What do you do with the pictures I upload?" key="2">
                <p>We do not do anything with any of the files you upload other than produce the products your order. 
                We understand that your pictures are property of the user uploading them. 
                Please see our <Link className="faq-link" to="/terms">&nbsp;Terms of Use</Link> for more details.</p>
              </Panel>
              <Panel header="How will my personal information be used?" key="3">
                <p>Your privacy is of utmost importance to us. 
                When placing an order online, we do ask for your personal and credit card information. 
                Your personal information is stored in a secure environment separate from our website.&nbsp;
                <span className='underlined'>Your credit card numbers are never stored</span>.&nbsp;
                For more details, please see our 
                <Link className="faq-link" to="/privacy">&nbsp;Privacy Policy. </Link>
                We will send an e-mail message to you to confirm online orders you place. 
                From time to time we may contact you via e-mail with special MyPhoto promotions and product releases. 
                You will always have the option to opt out of these e-mails should you wish.</p>
              </Panel>
            </FaqCollapseConatiner>
          </div>
        </FaqCategoryWrapper>
      )
  }
}

export default FaqSecurePersonalInfo;
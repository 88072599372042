import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ImagesManager from '../common/ImagesManager';
import { openModal } from '../../actions'
// import ModalVideo from 'react-modal-video'
import ModalVideo from './ModalVideoFix';
import addDefaultSrc from '../../helpers/addDefaultSrc'
import './hero.scss'

const propTypes = {
  dispatch: PropTypes.func,
  heroImg: PropTypes.object,
  user: PropTypes.object,
  config: PropTypes.object,
};

export class Hero extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  }

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }
  openModal( e ) {
    // console.log( e );
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isOpen: true })
  }

  render() {
    const { videoId, subdomain } = this.props.config
    return (
      <header className="hero__section" style={{ textAlign: 'center', position: 'relative' }}>
        <div
          className="hero__link"
          style={{ padding: 0, border: 0 }}
          onClick={(e) => {
            e.preventDefault();
            this.setState({ showModal: true })
            this.props.dispatch(openModal('UPLOAD_MODAL'))
          }}
        >
            <img
              style={{ cursor: "pointer" }}
              className={`is-hidden-mobile ${(this.props.heroImg && this.props.heroImg.mobile) ? 'hero__desktop' : ''}`}
              id="hero__img"
              alt="Hero Section"
              src={this.props.heroImg.desktop}
            />
          <img
            className="is-hidden-tablet hero__mobile"
            id="hero__img"
            alt="Hero Section"
            src={this.props.heroImg.mobile.replace('_withCTA', '')}
          />
        {this.state.showModal && <ImagesManager showModal={this.state.showModal} />}
        { subdomain.toLowerCase() !== 'hsn' && <div>
          <ModalVideo
            channel='youtube'
            isOpen={this.state.isOpen}
            videoId= {videoId}
            autoplay = {0}
            onClose={() => { this.setState({ isOpen: false }) } } />
          <span
            style={{ display: 'block' }}
            className="is-hidden-mobile tv-logo-container"
            onClick={(e) =>this.openModal(e)}
          >
            <img src="https://storage.googleapis.com/myphoto-img/ASOTV-01.png"
              onError={addDefaultSrc}
              alt="As Seen on TV"
              // style={{
              //   cursor: 'pointer',
              //   display: 'inline-block',
              //   maxWidth: '100%',
              //   height: 'auto',
              //   maxHeight: '8%',
              //   position: 'absolute',
              //   right: '1.3%',
              //   bottom: '5%'
              // }}
               />
          </span>
          </div>}
        </div> 
      </header>
    );
  }
}

Hero.propTypes = propTypes;

export default withRouter(connect((state) => {
  return {
    user: state.user.user,
    config: state.config.appConfig,
  };
})(Hero))
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Modal } from 'antd'
import autoBind from 'react-autobind'
import addDefaultSrc from '../../helpers/addDefaultSrc'
import PromoBarInfoModal from './PromoBarInfoModal'
import '../../assets/stylesheets/promo-ribbon.scss'

const propTypes = {
  config: PropTypes.object,
};

export class PromoBar extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  }

  constructor(props) {
    super(props);
    this.state = {
      visiblePromo: false,
      showPromoModal: false,
    }
    autoBind(this);
  }

  handleCancel = (e) => {
    this.setState({
      visiblePromo: false,
    });
  }
  closeDoubleUpBannerModal(){
    this.setState({
      showPromoModal: false
    })
  }
  doubleUpBannerModal() {
    return (
      <div className="promo-modal">
        <Modal
          visible={this.state.visiblePromo}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        >
          <PromoBarInfoModal closeDoubleUpBannerModal={this.closeDoubleUpBannerModal} />
        </Modal>
      </div>
    );
  }
  render() {
    const { showPromo, promoText, promoText2, promoText3, promoSubtext,
      promoTextMobile, promoText2Mobile, promoText3Mobile, promoSubtextMobile,
      promoColor, promoHighlightColor, promoBackgroundColor, promoLink } = this.props.config;
    if(showPromo!==false && (promoText || promoText2 || promoText3 || promoSubtext || promoTextMobile || promoText2Mobile || promoText3Mobile || promoSubtextMobile)) {
        const linked = (promoLink!==null && promoLink!=='');
        const promoClassName = (linked) ? 'promo-ribbon-container linked' : 'promo-ribbon-container'
        return (
          <span style={{display:'flex'}}>
            <div className={promoClassName}
                style={{ backgroundColor: `${promoBackgroundColor}`, flex: 6 }}>
                  <div className="" style={{ color: `${promoColor}` }}>
                    <div
                    onClick={(e) => {e.preventDefault()
                      if (linked) {
                        if (this.props.config.promoLink === 'doubleupmodal') {
                          this.setState({
                            visiblePromo: true,
                            showPromoModal: true
                          })
                        } else {
                          window.location.replace(this.props.config.promoLink)
                        }
                      }
                    }}>
                    {promoText && <span className="is-hidden-touch promo-text1" style={{ color: `${promoHighlightColor}` }}>{promoText}</span>}
                    {promoTextMobile && <span className="is-hidden-desktop promo-text1" style={{ color: `${promoHighlightColor}` }}>{promoTextMobile}</span>}
                    <span className="promo-text2" style={{ color: `${promoColor}` }}>
                      <span className="is-hidden-touch">{promoText2}</span>
                      <span className="is-hidden-desktop">{promoText2Mobile}</span>
                    </span>
                    <span className="is-hidden-touch promo-text3" style={{ color: `${promoColor}` }}>{promoText3}</span>
                    <span className="is-hidden-desktop promo-text3" style={{ color: `${promoColor}` }}>{promoText3Mobile}</span>
                    <span className="promo-subtext" style={{ color: `${promoColor}` }}>
                      <span className="is-hidden-touch">{promoSubtext}</span>
                      <span className="is-hidden-desktop">{promoSubtextMobile}</span>
                    </span>
                    <img className="help-icon"  onError={addDefaultSrc}
                        src="https://storage.googleapis.com/myphoto-img/icons/info_wht.svg" alt="info" />
                  </div>
                </div>
                {this.state.showPromoModal ? this.doubleUpBannerModal() : null}
        </div>
      </span>
      )
    } else {
      return null;
    }
  }
}

PromoBar.propTypes = propTypes;


export default withRouter(connect((state) => {
  return {
    config: state.config.appConfig,
  };
})(PromoBar))

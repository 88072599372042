
export function getDomainInfo(hostname) {
  const MAIN_SALES_CHANNEL = 'main';
  const envSubdomains = ['local', 'dev2'];
  const mainSalesChannelsSubdomains = ['frontend'];

  if (hostname === 'localhost' || /\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/.test(hostname)) {
    return MAIN_SALES_CHANNEL;
  }

  const [, ,
    subdomain = MAIN_SALES_CHANNEL,
    secondSubdomain = MAIN_SALES_CHANNEL,
    thirdSubdomain,
  ] = hostname.split('.').reverse();

  if (mainSalesChannelsSubdomains.find(channelSubdomain => channelSubdomain === secondSubdomain) && !thirdSubdomain) {
    return MAIN_SALES_CHANNEL;
  }

  /**
   * If the subdomain is one of the subdomains, then secondSubdomain
   * is the one to be used for the request.
   * That is, if the hostname is something like: secondSubdomain.local.domain.tld
   * 'local' being the env subdomain.
   */
  return envSubdomains.find(env => env === subdomain) ? thirdSubdomain || secondSubdomain : subdomain;
}

export function getSearchParams(search) {
  return search
    .replace('?', '')
    .split('&')
    .map(param => param.split('='))
    .reduce((result, [key, value]) => ({ ...result, [key]: value }), {});
}

export function getApiKey(search) {
  const API_KEY = 'apiKey';
  const params = search
    .replace('?', '')
    .split('&')
    .map(param => param.split('='))
    .map(([key, value]) => ({ key, value }));
  const apiKeyParam = params.find(({ key }) => key === API_KEY);

  return apiKeyParam && apiKeyParam.value;
}

import * as types from '../actions/actionTypes'
import { assign } from 'lodash'

const INITIAL_STATE = {
  list: [],
  items: [],
  products: [],
}

function products(state = INITIAL_STATE, action) {
  switch (action.type) {
    // TODO: The following may all be deprecated...
    case types.SET_APP_DATA:
      return {
        ...state,
        list: [...action.products],
        items: [...action.items],
      };
    case types.PRODUCTS_FETCH_SUCCESS:
      return assign({}, state, {
        list: [...action.products],
        items: [...action.items],
      });
    default:
        return state
  }
}

export default products
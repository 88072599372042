import React from 'react';
// import ModalVideo from 'react-modal-video'

// Note: Do not convert this component into class component since we are using react-hooks
const YoutubeCommercial = ({ yotpoId, product }) => { 
  // const [isOpen, setOpen] = useState(false);
  console.log('yotpoId', yotpoId)
  return <div className="product-form-description">
    {/* {yotpoId === 'acrylic' // video only for Atrium Acrylic Blocks
      ? <div style={{ fontWeight: '700', fontSize: '16px', marginBottom: '20px' }}>
          <ModalVideo
            channel='youtube'
            isOpen={isOpen}
            videoId='F-A6neS_HIA'
            onClose={() => setOpen(false)} />
          <span
            style={{ display: 'block' }}
            onClick={(e) => {
              e.preventDefault();
              setOpen(true)
            }
            }
          >To see it in action, click <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>here.</span></span>
        </div>
      : null}
    {yotpoId === 'airglass' // video only for Air Glass
      ? <div style={{ fontWeight: '700', fontSize: '16px', marginBottom: '20px' }}>
          <ModalVideo
            channel='youtube'
            isOpen={isOpen}
            videoId='KhMJ4GwgMgA'
            onClose={() => setOpen(false)} />
          <span
            style={{ display: 'block' }}
            onClick={(e) => {
              e.preventDefault();
              setOpen(true)
            }
            }
          >To see it in action, click <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>here.</span></span>
        </div>
      : null} */}
    {/*yotpoId === 'minimags' || yotpoId === 'minimagnetset' || yotpoId === 'mini-magnets' || yotpoId === 'magnet-bar-set' || yotpoId === 'magnet-chalkboard' // video only for magnet products
      ? <span style={{ fontWeight: '700', fontSize: '16px' }}>
        <ModalVideo
          channel='youtube'
          isOpen={isOpen}
          videoId='syoxgTB43eA'
          onClose={() => setOpen(false)} />
        <span
          style={{ display: 'block' }}
          onClick={(e) => {
            e.preventDefault();
            setOpen(true)
          }
          }
        >To see it in action, click <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>here.</span></span>
      </span>
        : null*/}
    <p className="product__form__description" dangerouslySetInnerHTML={{ __html: product.description }}></p>
  </div>
}

export default YoutubeCommercial
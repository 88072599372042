import React from 'react'
import { BrowserView } from "react-device-detect"

export const OldDropzoneUpload = ({ addDefaultSrc }) => {
  return (
    <BrowserView>
      <div className="dropzone-area" style={{marginTop: '-21px'}}>
        <span className="dropzone-content">
          <img className="drop-icon" alt=""
            onError={addDefaultSrc}
            src={'https://storage.googleapis.com/myphoto-img/icons/photopicker/Icon_PLUS-02.svg'}
          />
          <button className="button-upload button-computer" >
            <span className="icon-container"><img className="folder-icon icon" alt=""
              onError={addDefaultSrc}
              src={'https://storage.googleapis.com/myphoto-img/icons/photopicker/Icon_COMPUTER-02.svg'} /></span>
            <span className="upload-btn-text">My Computer</span>
          </button>
        </span>
      </div>
    </BrowserView>
  )
}

export const OldInstagramUpload = ({ onClickHandler, addDefaultSrc, imageIcon, name }) => {
  return (
    <button
      className="button-upload button-facebook"
      onClick={() => {
        onClickHandler('facebook')
      }}>
      <span className="icon-container"><img className="facebook-icon icon" alt=""
        onError={addDefaultSrc}
        src={imageIcon} /></span>
      <span className="upload-btn-text">{name}</span>
    </button>
  )
}
import React, { Fragment } from 'react'
import { Modal } from 'antd'
import AddedToCart from '../../cart/AddedToCart'
import PrintPreviewModal from '../../common/PrintPreviewModal'
import PromoBarInfoModal from '../../header/PromoBarInfoModal'
import ImageWarning from "../ImageWarning"

export const CartModal = ({ dispatch, products, items, cart, visible, onCancel, footer, product, handler }) => {
  const cancelCartModal = (e) => onCancel(e, "visibleCartModal")
  return <Fragment>
    <Modal
      visible={visible}
      onCancel={(e) => onCancel(e, "visibleCartModal")}
      footer={footer}
    >
      {/* *********Component to render******** */}
      <div className="added-to-cart-container">
        <AddedToCart
          cart={cart}
          dispatch={dispatch}
          products={products}
          items={items}
          handler={handler}
          handleCancelCartModal={cancelCartModal}
          productPage={product.yotpoId}
          hideLink={true}
        />
      </div>
    </Modal>
  </Fragment>
}

export const PreviewModal = ({ dispatch, item, project, handleAddToCartClick,
  isMultiphoto, zoomIndex, openInlineEditor, visible, onCancel, overlayIsDisabled }) => {
  return (
    <div className="preview-modal">
      <Modal
        visible={visible}
        onCancel={(e) => { onCancel(e, "visiblePreviewModal") }}
        footer={null}
      >
        <div>
          <PrintPreviewModal
            item={item}
            project={project}
            handleClosePreviewModal={(e) => { onCancel(e, "visiblePreviewModal") }}
            handleAddToCartClick={handleAddToCartClick}
            isMultiphoto={isMultiphoto}
            zoomIndex={zoomIndex}
            openInlineEditor={openInlineEditor}
            dispatch={dispatch}
            overlayIsDisabled={overlayIsDisabled}
          />
        </div>
      </Modal>
    </div>
  )
}

export const DoubleUpModal = ({ visible, onCancel }) => {
  return (
    <div className="promo-modal">
        <Modal
          visible={visible}
          onCancel={(e) => { onCancel(e, "visiblePromoModal") }}
          footer={null}>
          <PromoBarInfoModal 
          closeDoubleUpBannerModal={onCancel} />
        </Modal>
      </div>
  )
}

export const WarningModal =({ visible, onCancel }) => {
  return(
    <div className="warning-modal">
        <Modal
          visible={visible}
          onCancel={(e) => { onCancel(e, "visibleWarningModal") }}
          footer={null}
        >
          <div>
            <ImageWarning />
          </div>
        </Modal>
      </div>
  )
}
import React from 'react';

import './footer.scss';

function Copyright() {
  return (
    <div className="container">
      <div className="column content copyright-footer">
        <div className="copy_info">
          <p className="style19">&copy; 2020 MyPhoto. All Rights Reserved. Email upload to order is patent pending. </p> 
          <span>
            <a href="/privacy" className="pp">Privacy Policy</a>
            <a href="/terms" className="pp">Terms of Use</a>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Copyright;

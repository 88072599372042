import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '../assets/stylesheets/page-shell.scss'
import '../assets/stylesheets/order-success-page.scss'
// import {montetateCheckStatus} from '../actions'

const propTypes = {
  user: PropTypes.object,
}

class OrderConfirmationItems extends Component {
  static contextTypes = {
    router: PropTypes.object,
  }


  render() {
    const { item } = this.props
    const isMultiphoto = item.photopersonalization[0].personalized && item.photopersonalization[0].previewObj.numPhotos > 1
    return (
      <div className="order-confirmation-main-container">
        <div className="order-confirm-image-container">
          <img className="order-confirm-overlay-image" 
          style={{width: '100%'}}
          src={ 
            !isMultiphoto && item.photopersonalization[0].personalized
              ? item.photopersonalization[0].previewUrl 
              : item.photopersonalization[0].previewObj.productImg
            }
          alt="Our product overlay" 
          />
        </div>
        <div className="order-confirm-card-item-content-container">
            <p className="order-confirm-card-item-name">
                {item.name}
            </p>
            { isMultiphoto && <span><p className='order-confirm-card-is-multiphoto'>*image is a product preview (YOUR photos will print)</p></span>}
        <div/>
        </div>
      </div>
    )
  }
}

OrderConfirmationItems.propTypes = propTypes;
OrderConfirmationItems.contextTypes = {
  router: PropTypes.object.isRequired
}

export default (OrderConfirmationItems);

import React from 'react'

export default ({ yotpoId, product }) => {
  return (
    <div className="column">
      <div className="yotpo bottomLine"
        data-appkey="QJL7i7XyPLnGHtG8sLX56GdlEOsWYnUcWMaxuoYe"
        data-domain="http%3A%2F%2Fwww.myphoto.com"
        data-product-id={yotpoId}
        data-name={product.name}
        data-description={product.description} >
      </div>
    </div>
  )
} 
/************ LOADING ACTIONS ************/
export const IS_FETCHING = 'is_fetching'
export const IS_NOT_FETCHING = 'is_not_fetching'
export const TURN_OFF_INITIAL_LOAD = 'turn_off_initial_load'

/************ CONFIG ************/
export const SET_INITIALIZE = 'SET_INITIALIZE'
export const LOCATION_DATA = 'LOCATION_DATA'
export const SET_APP_DATA = 'SET_APP_DATA'

/************ PRODUCTS ************/
export const PRODUCTS_FETCH_SUCCESS = 'PRODUCTS_FETCH_SUCCESS'
export const PRODUCTS_FETCH_ERROR = 'PRODUCTS_FETCH_ERROR'

/************ PRODUCT ************/
export const PRODUCT_FETCH_SUCCESS = 'PRODUCT_FETCH_SUCCESS'

/************ Categories ************/
export const CATEGORIES_FETCH_SUCCESS = 'CATEGORIES_FETCH_SUCCESS'
export const CATEGORIES_FETCH_ERROR = 'CATEGORIES_FETCH_ERROR'

/************ USER ************/
export const FETCH_USER = 'fetch_user'
export const UPDATE_USER = 'update_user'

/************ PHOTOS ************/
export const FETCH_PHOTOS = 'FETCH_PHOTOS'
export const INSTAGRAM_FETCH_IMAGES = 'INSTAGRAM_FETCH_IMAGES'
export const USERPHOTO_UPLOAD_ERROR = 'USERPHOTO_UPLOAD_ERROR'
export const SELECTED_PHOTO = 'SELECTED_PHOTO'
export const CLEAR_PHOTO_SERVICES_SELECTIONS = 'CLEAR_PHOTO_SERVICES_SELECTIONS'
export const PROGRESS_BAR = 'PROGRESS_BAR'
export const ADD_PHOTOS_TO_LIST = 'ADD_PHOTOS_TO_LIST'
export const REMOVE_PHOTO_FROM_LIST = 'REMOVE_PHOTO_FROM_LIST'

/************ PROJECT ************/
export const FETCH_PROJECT = 'FETCH_PROJECT'
export const UPDATE_PROJECT = 'update_project'
export const UPDATE_ZOOM = 'UPDATE_ZOOM'
export const UPDATE_MULTIPHOTO = 'UPDATE_MULTIPHOTO'
export const UPDATE_CROPPED_VALUES = 'UPDATE_CROPPED_VALUES'
export const UPDATE_IMAGE_FILTER = 'UPDATE_IMAGE_FILTER'
export const UPDATE_HAS_FILTER = 'UPDATE_HAS_FILTER'
export const SAVE_OVERLAY = 'SAVE_OVERLAY'

/************ CART ************/
export const FETCH_CART = 'FETCH_CART'
export const ITEM_REMOVED = 'ITEM_REMOVED'
export const NEW_CART_ITEM = 'new_cart_item'

/************ ORDERS ************/
export const ORDER_PLACE_SUCCESS = 'ORDER_PLACE_SUCCESS'
export const ORDER_PLACE_ERROR = 'ORDER_PLACE_ERROR'
export const FETCH_ORDER_STATUS = 'FETCH_ORDER_STATUS'
export const RESET_ORDER = 'reset_order'
export const ORDER_SUCCESS = 'order_success'
export const ORDER_FAILURE = 'order_failure'
export const CONFIRM_ORDER = 'update_order_pending'
export const UPDATE_ORDER = 'update_order'
export const NEW_USER_ORDER = 'new_user_order'
export const SAVE_BRAINTREE_INSTANCE = 'SAVE_BRAINTREE_INSTANCE'

/************ ADDRESS ************/
export const SMARTYSTREETS_VALIDATION_SUCCESS = 'SMARTYSTREETS_VALIDATION_SUCCESS'
export const SMARTYSTREETS_VALIDATION_FAILURE = 'SMARTYSTREETS_VALIDATION_FAILURE'

/************ PROMOTIONS ************/
export const FETCH_VALID_PROMO = 'fetch_valid_promo'
export const REMOVE_PROMOTION = 'REMOVE_PROMOTION'
export const INVALID_PROMOTION = 'INVALID_PROMOTION'
export const PROMO_SUCCESS = 'PROMO_SUCCESS'
export const SAVE_PROMO_CODE = 'save_promo_code'
export const FETCH_ARTICLES = 'fetch_articles'
export const FETCH_ARTICLE = 'fetch_article'
export const SHOW_UPSELL_MODAL = 'SHOW_UPSELL_MODAL'
export const FETCH_UPSELL_ITEM = 'FETCH_UPSELL_ITEM'

/************ GIFT CARD ************/
export const GET_GIFTCARD_BALANCE_ERROR = 'GET_GIFTCARD_BALANCE_ERROR'
export const GIFTCARD_ZERO_BALANCE = 'GIFTCARD_ZERO_BALANCE'
export const CLEAR_GIFTCARD_ERROR = 'CLEAR_GIFTCARD_ERROR'
export const CLEAR_GIFTCARD_BALANCE = 'CLEAR_GIFTCARD_BALANCE'

/************ CORPORATE GIFTING DATA ************/
export const CORPORATE_DATA_SUCCESS = 'CORPORATE_DATA_SUCCESS'
export const CORPORATE_DATA_ERROR = 'CORPORATE_DATA_ERROR'

export const SEND_TEXT = 'send_text'
export const USER_CONFIRMATION = 'user_confirmation'
export const COUNTDOWN_COMPLETED = 'COUNTDOWN_COMPLETED'

import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import ThumbnailGenerator from '../common/Thumbnail'
import addDefaultSrc from '../../helpers/addDefaultSrc'
import '../../assets/stylesheets/product-page/product-card.scss'

class ProductCard extends React.Component {

  static contextTypes = {
    router: PropTypes.object,
  }
  getItem(product, single) {
    const { projectItems, items } = this.props
    let item
    const option = product.optionItems.find(({ isDefault }) => isDefault)
    const groupName = (option && option.groupName) || '';
    // If there aren't any project items yet, load the default item.
    if (projectItems.length === 0 || single) {
      if (option) {
        item = {
          ...items.find(({ id: itemId }) => itemId === product.defaultItemId),
          groupName,
        };
      } else {
        item = {
          ...items.find(({ productId: id }) => id === product.id),
          groupName,
        };
      }
    } else {
      // const projectItem = projectItems.find(({ productId }) => productId === product.id)
      const projectItem = items.find(({ productId }) => productId === product.id)
      item = {
        ...items.find(({ id: itemId }) => itemId === projectItem.id),
        groupName,
      };
    }
    return item
  }
  render(){
      const { photo, hasProject } = this.props
      const getProduct = (product) => {
        const items = this.props.items.filter((itm) => itm.productId === product.id);
        const item = items.reduce((prev, current) => ((prev.price < current.price) ? prev : current));
        const samePrice = (items.length > 1) ? !!!items.find(itm => itm.price !== item.price) : true;
        if (_.isEmpty(item) || product.disabled) {
          return null;
        }
        const itemInfo = this.getItem(product, true)
        const prodStyle = (product.attributes && product.attributes[0]) ? product.attributes[0] : product.slug
        const prodLink = `/products/${product.category}/${prodStyle}/${product.slug}`
        if (product.allowsPersonalization && hasProject && photo && (photo.thumb_url || photo.thumbUrl || photo.url)) {
          return (
            <div className='product-card-main-container-a' key={product.id}>
              <Link to={prodLink}>
                <div className="product-card-image-cont">
                  {product.newproduct ?
                    <img className="new-product-img thumb-img" onError={addDefaultSrc} src="https://storage.googleapis.com/myphoto-img/new-product.svg" alt="NEW" />
                    : null}
                  <ThumbnailGenerator
                    photoUrl={photo.thumb_url || photo.thumbUrl || photo.url}
                    actualSize={photo !== null ? { width: photo.width, height: photo.height } : null}
                    edits={photo.edits}
                    item={itemInfo}
                    alt={item.name || product.name}
                    ref="thumbnailCreator"
                    />
                </div>
                <div className="product-card-desc-container">
                  <h3 itemProp="name" className="product-card-title" dangerouslySetInnerHTML={{ __html: product.name }} />
                  <div className="product-card-footer-container"> 
                    <div className="product-card-footer">
                      {product.tagline && <p itemProp="description" className="product-card-description" dangerouslySetInnerHTML={{ __html: product.tagline }}></p>}
                      <div className="product-price-container">
                        <p className="price-holder">{samePrice ? 'Price' : 'From'}</p>
                        <p itemProp="price" className="price">${item.price ? item.price : '1'}</p>
                      </div>
                    </div>
                    <div className="product-card-btn-container">
                      <button className="product-card-btn shop-now-cta">Shop Now</button>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          );
        } else {
          return (
            <div className='product-card-main-container-a' key={product.id}>
              <Link to={prodLink}>
                <div className="product-card-image-cont">
                  {product.newproduct ?
                    <img className="new-product-img product-img" onError={addDefaultSrc} src="https://storage.googleapis.com/myphoto-img/new-product.svg" alt="NEW" />
                    : null}
                    <img className="home-product-image"
                      style={{width: '100%'}}
                      src={(hasProject && photo && (photo.thumb_url || photo.thumbUrl || photo.url)) ? product.preview.portrait[0].img : product.img}
                      alt="Product"
                      onError={(e) => { e.target.onerror = null; e.target.src = "https://s3.amazonaws.com/focus-misc-assets/image_not_available_829x455.jpg" }}
                    />
                </div>
                <div className="product-card-desc-container">
                  <h3 itemProp="name" className="product-card-title" dangerouslySetInnerHTML={{ __html: product.name }} />
                  <div className="product-card-footer-container"> 
                    <div className="product-card-footer">
                      {product.tagline && <p itemProp="description" className="product-card-description" dangerouslySetInnerHTML={{ __html: product.tagline }}></p>}
                      <div className="product-price-container">
                        <p className="price-holder">{samePrice ? 'Price' : 'From'}</p>
                        <p itemProp="price" className="price">${item.price ? item.price : '1'}</p>
                      </div>
                    </div>
                    <div className="product-card-btn-container">
                      <button className="product-card-btn shop-now-cta">Shop Now</button>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          )
        }
    }
    const doNotShow = [100]
    const showProducts = this.props.products.filter(p => { return !doNotShow.includes(p.id) })
    return showProducts.map((product) => { return getProduct(product) })
  }
}
export default withRouter(ProductCard)
import React from 'react'
import { Form, Input } from 'antd'
import '../../../assets/stylesheets/page-shell.scss'
import '../../common/photo-picker/device-upload.scss'

export default ({ handleNewPhoneNumber, newPhone, existingPhone, updatePhoneNumber }) => (
  <Form className='phone-import-input'>
    <Form.Item
      label='Phone:'
      labelCol={{ span: 3 }}
      wrapperCol={{ span: 15 }}
    >
      <Input.Search
        size="large"
        id='phone'
        type='tel'
        value={newPhone || existingPhone}
        placeholder='555-867-5309'
        onChange={e => updatePhoneNumber(e)}
        onSearch={value => handleNewPhoneNumber(value)}
        className='column send-text-btn'
        enterButton='SEND TEXT'
      />
    </Form.Item>
  </Form>
)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import MetaDataTags from '../helpers/ProcessMetaData'
import { seoInfo } from '../helpers/seoInfo'
import TabBar from '../components/common/tabBar/TabBar';
import '../assets/stylesheets/page-shell.scss'
import '../assets/stylesheets/promotions.scss'

const Content = {
  title:"Deals & Promotions",
  subTitle: "The latest offers from MyPhoto, all in one place.",
  cards: [
    {
      title: "Buy More, Save More",
      text1: "Buy any product and get all duplicates 50% Off. Must be same product, same photo, same address.",
      text2: "No promo code required.",
      promoCode: null
    },
    {
      title: "20% off your 1st order",
      text1: "Get 20% off your entire order. Just enter the code at checkout.",
      text2: "Use promo code:",
      promoCode: "Photo2020"
    },
    {
      title: "Free Shipping $59+",
      text1: "Get fast 5-day shipping on all orders of $59 and up.",
      text2: "No promo code required.",
      promoCode: null
    }
  ]

}
const propTypes = {
  user: PropTypes.object,
}

class Promotions extends Component {

  static contextTypes = {
      router: PropTypes.object,
  }

  componentWillMount() {
      window.gtag('event', 'page_view', {
          'send_to': 'AW-668262331',
          'ecomm_pagetype':'other'
        });
  }
  render() {
      return (
          <div className="shell-container main-container">
              <MetaDataTags baseUrl='promotions' seoInfo={seoInfo.promotions} />
              <div className="promos-main-container">
                <div className="promos-title-container">
                  <h1 className="promos-title">{Content.title}</h1>
                  <h1 className="promos-subtitle">{Content.subTitle}</h1>
                </div>
                <div className="promos-cards-container">
                  {Content.cards.map((card)=>{
                    return(
                      <div className='promos-card'>
                        <h1 className='promos-card-title'>{card.title}</h1>
                        <p className='promos-card-text1'>{card.text1}</p>
                        <p className='promos-card-text1'>{card.text3}</p>
                        <p className='promos-card-text2'>{card.text2}&nbsp;<span className='bold'>{card.promoCode}</span></p>
                        <button
                          className="promotions-cta-btn"
                          onClick={() => {
                            this.context.router.history.push('/cart')
                          }}>
                          Complete Your Order
                        </button>
                      </div>
                    )
                  })}
                  <div className='promos-card promos-card-links'>
                        <h1 className='promos-card-title'>30% off your 1st mobile app order</h1>
                        <p className='promos-card-text1'>Get 30% off your entire order. Download our app and use code at checkout.</p>
                        <p className='promos-card-text2'>Use promo code:&nbsp;<span className='bold'>APP30</span></p>
                        <div className="promotions-link-cont">
                          <a className="promotions-link" href="https://apps.apple.com/us/app/myphoto/id1484696085" target="_blank" rel="noopener noreferrer"><img src="/images/apple-store-badge.svg" alt="apple store logo"/></a>
                          <a className="promotions-link" href="https://play.google.com/store/apps/details?id=com.cuemby.myphoto&hl=en_US" target="_blank" rel="noopener noreferrer"><img src="/images/google-play-badge-01.svg" alt="apple store logo"/></a>
                        </div>
                      </div>
                </div>
              </div>
              <TabBar>
                <div className="promos-cta-btn-container">
                  <button 
                    className="promos-cta-btn promos-cta-btn-tabbar"
                    onClick={() => {
                        this.context.router.history.push('/cart')
                      }}>
                      Complete Your Order
                  </button>
                </div>
              </TabBar>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
      user: state.user.user,
    };
  }

  Promotions.propTypes = propTypes;
  Promotions.contextTypes = {
  router: PropTypes.object.isRequired
}
export default withRouter(connect(mapStateToProps)(Promotions));
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import addDefaultSrc from '../../helpers/addDefaultSrc'
import '../../assets/stylesheets/best-sellers.scss'
import '../../assets/stylesheets/banner.scss'


class Banner extends React.Component {
    render() {
        const { link, desktop, mobile, alt, title, color, width, hideLinkDesktop, fontSize, mobileTitle, mobilePadding, mobileFontSize,
            textAlignment, verticalAlignment, textAlignmentMobile, verticalAlignmentMobile, linkClass, linkText, full, smallBanner, additionalTitle} = this.props
        const rounded = (full!==true) ? { borderRadius: '2px' } : {}
        const withPadding = (full!==true) ? { flex: width, padding: ' 0 10px 15px' } : {paddingBottom: '15px'}
        const linkDesktop = hideLinkDesktop ? 'hide-link-desktop' : ''
        const textAlignMobile = textAlignmentMobile ? { textAlign: textAlignmentMobile } : { textAlign: textAlignment}
        const verticalAlignMobile = verticalAlignmentMobile ? { alignItems: verticalAlignmentMobile } : { alignItems: verticalAlignment }
        const mobTitle = mobileTitle ? mobileTitle : title
        const paddingMobile = mobilePadding ? 'padding-mobile' : ''
        const fontWeight = fontSize > 50 ? 600 : 400
        const mobileFS = mobileFontSize ? mobileFontSize : fontSize
        const fontFamily = smallBanner ? 'font-open-sans' : 'font-play-fair'
        const btnWidth = smallBanner ? 'wide-banner-cta-btn' : 'banner-cta-btn'
        const bannerSize = smallBanner ? 'small-banner' : 'big-banner'
        const buttonAlignment = (textAlignment === 'right') 
                                ? 'button-alignment-right' 
                                    :(textAlignment === 'left') 
                                        ? 'button-alignment-left'
                                        : ''
        const mDayBtnStyle =  title===`Mother's Month Gift Guide` ? {backgroundColor: '#fff', color: "black"} : {}
        const bannerTextContent = () =>{
            return (
                <div className={buttonAlignment}> 
                    <div>{additionalTitle && <h1 className={`home-banner-additional-title ${fontFamily}`}>{additionalTitle}&nbsp;</h1>}
                    {title && <h1 style={{ color, fontSize, fontWeight: fontWeight }} className='home-banner-title is-hidden-mobile font-play-fair'>{title}</h1>}
                    { (title || mobileTitle) && <h1 style={{ color, fontSize: mobileFS, fontWeight: fontWeight } } className='home-banner-title is-hidden-desktop is-hidden-tablet font-play-fair'>{mobTitle}</h1>}</div>
                    {(linkClass==='ctaButton')
                        ? <button style={ mDayBtnStyle} className={`try-it-btn ${btnWidth}`}>
                            {linkText}
                        </button>
                        : <p style={{ color }} className={`${'home-banner-text-1'} ${linkDesktop}`}>{linkText}</p>}
                </div>
                ) 
        }
        return (
            <div className={`home-banner-flex ${paddingMobile}`} style={ withPadding }>
                <Link to={link}>
                    <div className="home-banner-container is-hidden-desktop is-hidden-tablet">
                        <div className={`home-text-container ${bannerSize}`} style={verticalAlignMobile}>
                            <div className="home-text" style={textAlignMobile}>
                            {bannerTextContent()}
                            </div>
                        </div>
                        <img style={rounded} onError={addDefaultSrc} alt={alt} src={mobile} />
                    </div>
                    <div className="home-banner-container is-hidden-mobile">
                    <div className={`home-text-container ${bannerSize} is-hidden-mobile`} style={{alignItems: verticalAlignment }}>
                            <div className="home-text" style={{textAlign: textAlignment }}>
                            {bannerTextContent()}
                            </div>
                        </div>
                        <img style={rounded} onError={addDefaultSrc} alt={alt} src={desktop} />
                    </div>
                </Link>
            </div>
        )
    }
}

export default withRouter(Banner)
import * as types from '../actions/actionTypes'

const INITIAL_STATE = {
  giftcard: {},
  balance: 0,
  drawdown: 0,
  error: null,
  giftcardZeroBalance: ''
};

function giftcard(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.GIFTCARD_ZERO_BALANCE:
      return {  ...state, giftcardZeroBalance: action.payload }
    // TODO: The following may all be deprecated...
      case types.GET_GIFTCARD_BALANCE_ERROR:
        return {
          ...state,
          error: action.error,
        };
      case types.CLEAR_GIFTCARD_BALANCE:
      return {
        ...state,
        balance: 0,
        drawdown: 0,
        error: null,
      };
      case types.CLEAR_GIFTCARD_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state
  }
}

export default giftcard
import React from 'react'
import addDefaultSrc from '../../helpers/addDefaultSrc'
import './footer.scss'


function ConnectSection() {
  return (
      <div className='footer-connect column is-2'>
            <h3 className="conn-title">Connect</h3>
            <div className="conn-links-container">
              <a href="https://www.instagram.com/myphoto/" rel="noopener noreferrer" target="_blank">
                <img
                  onError={addDefaultSrc}
                  src="https://storage.googleapis.com/myphoto-img/icons/instagram_footer.svg"
                  alt="Instagram" className="social_icon" />
              </a>
              <a href="https://www.facebook.com/123MyPhoto" rel="noopener noreferrer" target="_blank">
                <img
                  onError={addDefaultSrc}
                  src="https://storage.googleapis.com/myphoto-img/icons/facebook_footer.svg"
                  alt="Facebook" className="social_icon" />
              </a>
              <a href="https://twitter.com/123_MyPhoto" rel="noopener noreferrer" target="_blank">
                <img
                  onError={addDefaultSrc}
                  src="https://storage.googleapis.com/myphoto-img/icons/twitter_footer.svg"
                  alt="Twitter" className="social_icon" />
              </a>
              <a href="https://www.youtube.com/channel/UC2jdPBBgcw5nfqj5DVf8-IA" rel="noopener noreferrer" target="_blank">
                <img
                  onError={addDefaultSrc}
                  src="https://storage.googleapis.com/myphoto-img/icons/youtube_footer.svg"
                  alt="YouTube" className="social_icon-youtube" />
              </a>
            </div>
      </div>
  );
}

export default ConnectSection
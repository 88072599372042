import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios'
const staging = (window.location.href.indexOf("staging.") > -1 || window.location.href.indexOf("photoandgo.local") > -1) ? true : false;
const ROOT_URL = (staging) ? '//api.staging.myphoto.com/v2' : '//api.myphoto.com/v2';
const host = (staging) ? 'staging.myphoto.com' : 'myphoto.com';

const propTypes = {
  location: PropTypes.object,
};

class Redirects extends React.Component {
  componentWillMount() {
    const { params: { id, categorySlug, session, sessionUpload, sessionAbout, sessionReviews, sessionFAQ, emaBS, 
      activaCampaignId, activaProductId } } = this.props.match;
    if(id) { // FOCUS 1.0 product page urls
      const pid = parseInt(id, 10)
      const { products } = this.props
      const product = products.find(p => p.id === pid)
      if(product) {
        const prodStyle = (product.attributes && product.attributes[0]) ? product.attributes[0] : product.slug
        window.location.replace(`/products/${product.category}/${prodStyle}/${product.slug}`)
      } else {
        // otherwise, just take to products page
        console.log('cannot find product ', pid)
        window.location.replace("/products")
      }
    }
    if(categorySlug) {
      window.location.replace(`/products/${categorySlug}`)
    }
    if(session){ 
        const subId = session.split('-');
        if(subId[0]==='sub'){
            const { token } = this.props.config;
            // get submission event from Activa
            axios.get(`${ROOT_URL}/events/activa/${session}`,{
              headers: {'Authorization': token}
            })
            .then((response) => {
                window.location.replace(response.data);
            })
            .catch((err) => {
                console.log("~~ GOT ERROR: ",err);
                // window.location.replace("/");
            });
        } else {
            // otherwise, just take to products page
            window.location.replace("/products");
        }
    }
    if(sessionUpload){
      const campId = sessionUpload.split('-');
      if(campId[0]==='cid'){
        const { token } = this.props.config;
        axios.get(`${ROOT_URL}/saleschannels/activa/${campId[1]}`,{
          headers: {'Authorization': token}
        })
        .then((response) => {
          // var url = (response && response.data && response.data.results && response.data.results.subdomain) 
          //     ? `//${response.data.results.subdomain}.myphoto.com` : '';
          var url = (response && response.data && response.data.results && response.data.results.subdomain) 
          ? `//${response.data.results.subdomain}.${host}` : '';
          window.location.replace(url);
        })
        .catch(() => {
          window.location.replace("/upload-photo");
        })
      } else {
        window.location.replace("/upload-photo");
      }
    }
    if(sessionAbout){ window.location.replace("/our-story"); }
    if(sessionReviews){ window.location.replace("/reviews"); }
    if(sessionFAQ){ window.location.replace("/faq"); }
    if(emaBS){ window.location.replace("/products/best-sellers"); }
    // handle if activaCampaignId (and optionally if activaProductId, too)
    if(activaCampaignId) {
      const { token } = this.props.config;
      const campId = activaCampaignId.split('-');
      axios.get(`${ROOT_URL}/saleschannels/activa/${campId[1]}`,{
        headers: {'Authorization': token}
      })
      .then((response) => {
        // var url = (response && response.data && response.data.results && response.data.results.subdomain) 
        //     ? `//${response.data.results.subdomain}.myphoto.com` : '';
        var url = (response && response.data && response.data.results && response.data.results.subdomain) 
            ? `//${response.data.results.subdomain}.${host}` : '';
        if(activaProductId) {
          switch(activaProductId){
            case 'metal':
            case 'moderna-metal': url += "/product/3/moderna-metal"; break;
            case 'acrylic':
            case 'atrium-acrylic':  url += "/product/5/atrium-acrylic-block"; break;
            case 'diamond':
            case 'diamond-glass':  url += "/product/105/diamond-glass"; break;
            case 'canvas':
            case 'canvas-photo-scroll':  url += "/product/125/canvas-photo-scroll-new"; break;
            case 'mounted':
            case 'tudor-wood':  url += "/product/8/tudor-wood"; break;
            case 'glasspad':  url += "/product/103/glasspad-8482-new"; break;
            case 'metalux':  url += "/product/96/metalux"; break;
            case 'metaluxpets':
            case 'metalux-pets':  url += "/product/137/metalux-pets-new"; break;
            case 'soholux':
            case 'metalux-soho-designs':  url += "/product/102/metalux-soho"; break;
            case 'framedprint':
            case 'galleri-frame':  url += "/product/6/galleri-frame"; break;
            case 'framedprintme':
            case 'galleri-frame-metallic-edition':  url += "/product/98/galleri-frame-metallic-edition"; break;
            case 'trayacrylic':
            case 'atrium-acrylic-tray':  url += "/product/99/atrium-acrylic-tray"; break;
            case 'wallacrylic':
            case 'atrium-wall-acrylic':  url += "/product/7/atrium-acrylic-frame"; break;
            case 'float':
            case 'wood-float-frame':  url += "/product/100/wood-float-frame"; break;
            // case 'masterfloat':
            // case 'masterpiece-float-frame':  url += "/product/2/aurora-glass"; break;
            case 'glass':
            case 'aurora-glass':  url += "/product/2/aurora-glass"; break;
            case 'phototees':  url += "/product/104/supersoft-photo-t-shirts-new"; break;
            case 'glasscoaster':
            case 'glass-coasters':  url += "/product/126/glass-coasters-new"; break;
            case 'woodcoaster':
            case 'wood-coasters':  url += "/product/92/wood-coasters"; break;
            case 'wood':
            case 'cape-cod-wood':  url += "/product/4/cape-cod-wood"; break;
            case 'metaluxheart':
            case 'occlux':
            case 'metalux-occasions':  url += "/product/97/metalux-occasions"; break;
            case 'flipframe':
            case 'magneto-flipframe':  url += "/product/91/magneto-flipframe"; break;
            case 'acrylicornament':
            case 'acrylic-ornament':  url += "/product/93/acrylic-ornament"; break;
            case 'woodornament':
            case 'wood-ornament':  url += "/product/94/wood-ornament"; break;
            case 'wallcling':
            case 'decora-wall-cling':  url += "/product/9/decora-wall-cling"; break;
            // case 'bagtag': case 'boulevard-bag-tag':  url += ""; break;
            // case 'goprint': url += ""; break; // "Ultra Thick GoPrint"
            // case 'stonecoaster': url += ""; break; // "Stone Coasters"
            // case 'cards': url += ""; break; // "Holiday Cards (Pack of 10)"
            // case 'curvedacrylic': url += ""; break; // "Atrium Curved Acrylic"
            // case 'mdfplaq': url += ""; break; // "MDF Plaque"
            // case 'metalhsn5x7': url += ""; break; // "Moderna Metal 5x7 - Buy One Get One"
            // case 'glasshsn5x7': url += ""; break; // "Aurora Glass 5x7 - Buy One Get One"
            // case 'mamalux': url += ""; break; // "Limited Edition - Mother's Day Metalux"
            case 'gift-cards':  url += "/product/95/gift-card"; break;
            default: url += "/products"; break;
          }
        }
        window.location.replace(url);
      });
    }
  }
  render() {
    return (
      <section className="shell-container main-container">
        <div className="container">
            <div>
            <div className="editing-bay-blackout"></div>
            <div className="editing-bay-blackout-container">Loading...</div>
            </div>
        </div>
      </section>
    );
  }
}

Redirects.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    products: state.products.list,
    config: state.config,
  };
}

export default connect(mapStateToProps)(Redirects);
import React from 'react'
import autoBind from 'react-autobind'
import { Collapse } from 'antd';
import addDefaultSrc from '../../helpers/addDefaultSrc'
import FaqCollapseConatiner from '../../components/faq/FaqCollapseContainer'
import FaqCategoryWrapper from '../../components/faq/FaqCategoryWrapper'

const { Panel } = Collapse

class FaqPhotoProductQuality extends React.Component {
  constructor(props) {
      super(props)
      autoBind(this)
  }
  render() {
      return (
        <FaqCategoryWrapper title={'Photo & Product Quality'}>
          <div className='faq-questions-container'>
            <FaqCollapseConatiner>
              <Panel header="How do I know it will look great?" key="1">
              <p>We use the highest quality digital imaging and printing technology on the planet! 
              Your photos are HD printed on the finest materials sourced from all over the world, handcrafted for you using the utmost TLC and attention to detail. </p> 
              <p>Our website will <span className='semi-bold'>automatically inform you</span> if the photo you've chosen will not print at a good quality. 
              If you choose to email your photo to <a className="faq-link" href="mailto:123@myphoto.com">123@myphoto.com</a>, 
              be sure to choose <span className='semi-bold'>"ACTUAL SIZE"</span> (if asked) to ensure your photo will print at the highest quality. </p> 
              <p>But remember...if for any reason you're not satisfied, we have a <span className='semi-bold'>100% Money Back Guarantee</span> to ensure you're always happy with your experience. </p>
              </Panel>
              <Panel header="Are the photos really inside the acrylic blocks?" key="2">
              <p>Using our incredibly advanced printing technology, we actually HD print your photo <span className="underlined">directly</span>&nbsp;
              onto the back of the acrylic or glass, giving a unique prismatic quality when looking at them from the front, back or different angles.</p> 
              <img style={{height: '200px', width: '200px', margin: '20px 30px'}} src={'https://storage.googleapis.com/myphoto-img/faq/faq-mini.png'} onError={addDefaultSrc} alt='gift note'/>
              <p>Your photos are not "inside" the acrylic and we <span className="semi-bold underlined">do not</span> cheaply paste an image on the material (like some others may try &#128521;).</p>
              <img style={{height: '200px', width: '200px', margin: '20px 30px'}} src={'https://storage.googleapis.com/myphoto-img/faq/faq-bamboo.png'} onError={addDefaultSrc} alt='gift note'/>
              </Panel>
            </FaqCollapseConatiner>
          </div>
        </FaqCategoryWrapper>
      )
  }
}

export default FaqPhotoProductQuality;
import { assign } from 'lodash';

const initialState = {
    'UPLOAD_MODAL': false,
    'CART_MODAL': false,
    'CORPORATE_GIFTING_MODAL': false,
    'SIZE_ERROR_MODAL': false,
    'PROMO_BAR_MODAL': false,
    'ADDRESS_CONFIRMATION' : false,
  };

function modal(state = initialState, action) {
  switch (action.type) {
    case 'OPEN_MODAL':
      return assign({}, state, {
        [action.modalName]: true,
        })
    case 'CLOSE_MODAL':
    return assign({}, state, {
        [action.modalName]: false,
        })
    default:
      return state
  }
}

export default modal;

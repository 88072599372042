import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import autoBind from 'react-autobind'
import { clearOrder } from '../actions'
import MetaDataTags from '../helpers/ProcessMetaData'
import {monetatePurchase,setIntervalHelper} from '../helpers/monetate'
import OrderConfirmationItems from './OrderConfirmationItems'
import TabBar from '../components/common/tabBar/TabBar'
import addDefaultSrc from '../helpers/addDefaultSrc'
import '../assets/stylesheets/page-shell.scss'
import '../assets/stylesheets/order-success-page.scss'



const propTypes = {
  user: PropTypes.object,
}
class OrderSuccessPage extends Component {
  static contextTypes = {
    router: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.state = {
      specificItem: ''
    }
    autoBind(this);
    const script = document.createElement('script');
    script.environment = 'production'
    script.src = 'https://widget.coopcommerce.co/static/coopcommerce.js?publisher_id=a81a6154-9850-4e5c-aac0-4370cd686806';
    document.body.appendChild(script);
  }

  componentWillMount() {

    const { dispatch } = this.props
    dispatch(clearOrder())
   
    window.gtag('event', 'page_view', {
      'send_to': 'AW-668262331',
      'ecomm_pagetype': 'purchase' // for order-confirmation page
    });
  }
  componentDidMount() {
  
    if (this.props.order[0] && this.props.orderItems) {
      const {shipping_amount, discount_amount, giftcard_redeemed, total_amount, tax_amount} = this.props.order[0]
      let length = 0
      this.props.orderItems.forEach(item => {
        length += item.qty
      })
      let shippingNumber = Number((shipping_amount / length).toFixed(2))
      let discountNumber = Number((discount_amount/length).toFixed(2))
      let giftCardNumber = (Number((giftcard_redeemed/length)).toFixed(2))
      let totalItemNumber = Number(((total_amount) - (tax_amount)).toFixed(2))
    setIntervalHelper(()=>{monetatePurchase(giftcard_redeemed,totalItemNumber,shippingNumber,discountNumber,giftCardNumber, this.props.orderItems, this.props.confirm_id)});
  }}
  render() {
    document.getElementById('coopcommerce') && document.getElementById('coopcommerce').setAttribute("scrolling", "yes");
    return(
      <div className="order-success-main">
      <div className="shell-container main-container order-success-main-container order-success-left">
        <MetaDataTags baseUrl='order-success' />
        {this.props && this.props.confirm_id && this.props.orderItems ? (
          <div className="order-success-container">
            <div className="order-success-check-mark">
              <img src='https://storage.googleapis.com/myphoto-img/check.svg' alt='check mark' onError={addDefaultSrc} />
            </div>
            <div className="">
              <h2 className="order-success-page__header">Thanks for ordering!</h2>
            </div>
            <div className="">
              {(this.props.confirm_id) &&
                <h2 className="order-success-confirmation">
                  Your confirmation number is: &nbsp;
                  <strong>{this.props.confirm_id}</strong>
                </h2>
              }
            </div>
            <div className="order-success-cards">
              {this.props.orderItems.map((item, index) => {
                return (
                  <OrderConfirmationItems
                    dispatch={this.props.dispatch}
                    item={item}
                    key={`order_item_${index}`}
                    isOpen={this.props}
                    user={this.props.user}
                    orderItems={this.props.orderItems} /> )
                }
              )}
            </div>
          </div> ) : (
            <div className="columns" style={{justifyContent: 'center', paddingBottom: '60px'}}>
              <img className="thankyou-image" src="https://storage.googleapis.com/myphoto-img/order-success-1.jpg" alt="Beautiful Custom Products" onError={addDefaultSrc}/>
              <img className="thankyou-image" src="https://storage.googleapis.com/myphoto-img/order-success-2.jpg" alt="Beautiful Custom Products" onError={addDefaultSrc}/>
              <img className="thankyou-image" src="https://storage.googleapis.com/myphoto-img/order-success-3.jpg" alt="Beautiful Custom Products" onError={addDefaultSrc}/>
            </div>
        )}
        <div className="columns is-hidden-mobile" >
          <div 
            className="column continue-order" 
            style={{textAlign: 'center'}}>
            <button 
              className="order-success-continue-shopping-button" 
              onClick={() => {
                this.context.router.history.push('/products') }}>
              Continue Shopping {">"} </button>
          </div>
        </div>
        <TabBar>
          <div className="tab-bar-level">
            <div className="tab-bar-level-item">
              <button
                onClick={() => {
                  this.context.router.history.push('/products') }}
                type="button"
                className="cta-btn easy-btn" >
                Continue Shopping {">"}
              </button>
            </div>
          </div>
        </TabBar>
      </div> 
      <div className='section'></div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
    confirm_id: state.user.confirm_id || null,
    order: state.user.user.orders, //.find(order => { return order.status==='pending'; }),
    orderItems: state.user.orderItems || null,
  }
}

OrderSuccessPage.propTypes = propTypes;
OrderSuccessPage.contextTypes = {
  router: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(OrderSuccessPage);

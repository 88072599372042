import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import MetaDataTags from '../helpers/ProcessMetaData'
import { seoInfo } from '../helpers/seoInfo'
import addDefaultSrc from '../helpers/addDefaultSrc'
import '../assets/stylesheets/page-shell.scss'
import '../assets/stylesheets/services.scss'

const BannerContent = {
  title1: "But wait,",
  title2: "there's more...",
  text: "MyPhoto offers a ton of exclusive White Glove Services that are sure to make you smile.",
  icon1: "https://storage.googleapis.com/myphoto-img/icons/size_error_icon2.svg",
  heading1: "CustomSizes",
  subheading1: "As big as 4x8 feet!",
  icon2: "https://storage.googleapis.com/myphoto-img/icons/photo-stack-icon.svg",
  heading2: "Mail Us A Print & Select A Product",
  subheading2: "We'll scan it & print on the selected product(free shipping both ways).",
  icon3: "https://storage.googleapis.com/myphoto-img/icons/scanner-icon.svg",
  heading3: "Photo Scanning & Digitalization",
  subheading3: "Perfect for old shoebox photos you want to keep forever.",
  imageDesktop: "https://storage.googleapis.com/myphoto-img/services-banner.jpg",
  imageMobile: "https://storage.googleapis.com/myphoto-img/services-banner-mobile.jpg"
}
const propTypes = {
  user: PropTypes.object,
}

class Services extends Component {
  static contextTypes = {
    router: PropTypes.object,
  }

  componentWillMount() {
    window.gtag('event', 'page_view', {
      'send_to': 'AW-668262331',
      'ecomm_pagetype': 'other'
    });
  }
  
  render() {
    return (
      <div className="shell-container services-main-container">
        <MetaDataTags baseUrl='services' seoInfo={seoInfo.services}/>
        <div className="columns container">
          <div className="column is-10" style={{ marginBottom: '20px' }}>
            <div className="services-banner-container">
              <span className="services-title-container-mobile is-hidden-desktop">
                <span className="services-title1">
                  <p className="services-banner-title">{BannerContent.title1}<br />{BannerContent.title2}</p>
                  <p className="services-banner-text">{BannerContent.text}</p>
                </span>
                <img alt="services banner" className="is-hidden-tablet" style={{width: '100%'}} onError={addDefaultSrc} src="https://storage.googleapis.com/myphoto-img/services-banner-mobile.jpg" />
                <img alt="services banner" className="is-hidden-mobile" style={{width: '100%'}} onError={addDefaultSrc} src="https://storage.googleapis.com/myphoto-img/services-banner-tablet.jpg" />
              </span>
              <span className="services-title-container-desktop">
                <div className="columns columns-services-mobile">
                  <div className="column" style={{paddingRight: '40px'}}>
                    <span className="services-title2  is-hidden-touch">
                      <p className="services-banner-title">{BannerContent.title1}<br />{BannerContent.title2}</p>
                      <p className="services-banner-text">{BannerContent.text}</p>
                    </span>
                    <div className="columns">
                      <div className="column">
                        <img className="services-icon" onError={addDefaultSrc} alt="quiestion mark" src={BannerContent.icon1} />
                        <p className="services-heading">{BannerContent.heading1}</p>
                        <p className="services-subheading">{BannerContent.subheading1}</p>
                      </div>
                      <div className="column">
                        <img className="services-icon" onError={addDefaultSrc} alt="quiestion mark" src={BannerContent.icon2} />
                        <p className="services-heading">{BannerContent.heading2}</p>
                        <p className="services-subheading">{BannerContent.subheading2}</p>
                      </div>
                      <div className="column">
                        <img className="services-icon" onError={addDefaultSrc} alt="quiestion mark" src={BannerContent.icon3} />
                        <p className="services-heading">{BannerContent.heading3}</p>
                        <p className="services-subheading">{BannerContent.subheading3}</p>
                      </div>
                    </div>
                    <p className="services-line"></p>
                    <p className="services-footer">Call our customer hotline at
                    <a href="tel:8004851760">
                        <span className="faq-link">&nbsp;800.485.1760</span>
                      </a><br />(M-F 9am-5pm EDT) for more information</p>
                  </div>
                  <div className="column is-4 is-hidden-touch" style={{padding: 0}}>
                    <img alt="services banner" style={{width: '100%'}} onError={addDefaultSrc} src="https://storage.googleapis.com/myphoto-img/services-banner.jpg" className="is-hidden-touch" />
                  </div>
                </div>
              </span>
            </div>

          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    photos: state.photos.photos,
  }
}

Services.propTypes = propTypes;
Services.contextTypes = {
  router: PropTypes.object.isRequired
}
export default withRouter(connect(mapStateToProps)(Services));

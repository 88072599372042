import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Modal } from 'antd'
import autoBind from 'react-autobind'
import GuaranteeQualityModal from './GuaranteeQualityModal'
import GuaranteeShippingModal from './GuaranteeShippingModal'
import addDefaultSrc from '../../../helpers/addDefaultSrc'
import '../../../assets/stylesheets/guarantee-ribbon.scss'

class GuaranteeRibbonWhite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleQuality: false,
      showQualityModal: false,
      visibleShipping: false,
      showShippingModal: false
    }
    autoBind(this);
  }
  handleCancel = (e) => {
    this.setState({
      visibleQuality: false,
      visibleShipping: false,
    });
  }
  GuaranteeQualityModal() {
    return (
      <div>
        <Modal
          visible={this.state.visibleQuality}
          onCancel={this.handleCancel}
          footer={false}
        >
          <GuaranteeQualityModal />
        </Modal>
      </div>
    );
  }
  GuaranteeShippingModal(shippingOptions ) {
    return (
      <div>
        <Modal
          visible={this.state.visibleShipping}
          onCancel={this.handleCancel}
          footer={false}
        >
          <GuaranteeShippingModal shippingOptions={shippingOptions}/>
        </Modal>
      </div>
    );
  }
  render(){
    const { shippingOptions } = this.props
    const thisMethod = shippingOptions.find(o => o.id === 1)
    return(
      <div className='guarantee-white-container'>
        <div
          style={{cursor: 'pointer'}}
          className='guarantee-cont'
          onClick={(e) => {e.preventDefault()
            this.setState({
              visibleQuality: true,
              showQualityModal: true
            })
          }}>
          <span className="guarantee-icon-cont">
              <img className="guarantee-icon" onError={addDefaultSrc} style={{height: '23px'}}
                src={'https://storage.googleapis.com/myphoto-img/icons/Guarantee_Icon.svg'} alt='seal'/></span>
          <p className='guarantee-text'>100% money <br/>back <span className="underline">guarantee</span></p>
        </div>
        <div 
          className='shipping-cont'
          style={{cursor: 'pointer'}}
          onClick={(e) => {e.preventDefault()
            this.setState({
              visibleShipping: true,
              showShippingModal: true
            })
          }}
          >
          <span className="guarantee-icon-cont">
              <img className="guarantee-icon" onError={addDefaultSrc} style={{width: '60px'}}
                src={'https://storage.googleapis.com/myphoto-img/icons/FedEx_Icon.svg'} alt='seal'/></span>
                <p className='guarantee-text'><span className="underlined">Arrives in 5 Days</span><br/> (Free ${thisMethod.free_cutoff}+)</p>
        </div>
        {this.state.showShippingModal? this.GuaranteeShippingModal(shippingOptions): null}
        {this.state.showQualityModal? this.GuaranteeQualityModal(): null}
      </div>
    )
  }
}
export default withRouter(connect(state => {
  return {
    shippingOptions: state.config.appConfig.shippingOptions,
  };
})(GuaranteeRibbonWhite)) 
import React, { Component } from 'react'
import MetaDataTags from '../helpers/ProcessMetaData'
import '../assets/stylesheets/page-shell.scss'
import '../assets/stylesheets/reviews.scss'

class TermsAndConditions extends Component {

    componentDidMount() {
        window.gtag('event', 'page_view', {
            'send_to': 'AW-668262331',
            'ecomm_pagetype': 'other'
        });
    }

    render() {
        return (
            <div className="shell-container main-container">
                <MetaDataTags baseUrl='terms' />
                <div className="columns container" style={{justifyContent: 'center'}}>
                    <div className="column is-8">
                        <div className="terms-text-container">
                            <h3 className="p1">
                                <span className="s1">
                                    <strong>TERMS OF USE</strong>
                                </span>
                            </h3>
                            <p className="p2">
                                <span className="s1">
                                    <strong>of the MyPhoto brand of companies (collectively, MyPhoto)</strong>
                                </span>
                            </p>
                            <p className="p3">
                                <span className="s2">
                                    <strong>Acceptance of the MyPhoto Terms of Use</strong>
                                </span>
                            </p>
                            <p className="p4">
                                <span className="s1">MyPhoto's mission is to create the easiest and fastest way possible for photos on your phone to become amazing products and gifts. </span>
                            </p>
                            <p className="p4">
                                <span className="s1">Please read these Terms of Use carefully before enjoying MyPhoto because it applies to and describes the terms, conditions and requirements that govern your use and purchases from each MyPhoto website, mobile site, mobile application and e-mail that displays the Terms of Use, including www.myphoto.com and e-mails to 123@myphoto.com (such sites, applications and e-mail addresses are referred to collectively and singularly as the MyPhoto Site). Your use of the MyPhoto Site, including to order or purchase a product or gift, constitutes acceptance by you and agreement by you to all of the terms, conditions and requirements of the Terms of Use and creates a binding contract between you and MyPhoto, including Photo &amp; Go Web, LLC, which operates or owns the MyPhoto Site.&nbsp;</span>
                            </p>
                            <p className="p4">
                                <span className="s1">If you do NOT agree with any of the terms, conditions or requirements of the Terms of Use, do NOT access or otherwise use the MyPhoto Site or order or purchase any products or services from or through the MyPhoto Site. </span>
                            </p>
                            <p className="p4">
                                <span className="s1">MyPhoto can and may change any of the Terms of Use or the Terms of Use as a whole by posting an updated or changed Terms of Use on the MyPhoto Site. If any change, addition or modification is unacceptable to you, then you should cease using the MyPhoto Site. Your continued use of the MyPhoto Site constitutes acceptance by you and agreement by you to all of the changed, modified or added terms, conditions and requirements of the Terms of Use and creates a binding contract between you and MyPhoto with respect to the entire updated or changed Terms of Use.</span>
                            </p>
                            <p className="p4">
                                <span className="s1">Please contact MyPhoto at hello@myphoto.com, if you have any questions about the Terms of Use or its terms, conditions and requirements.</span>
                            </p>
                            <p className="p3">
                                <span className="s2">
                                    <strong>Terms, Conditions and Requirements for Use of the MyPhoto Site</strong>
                                </span>
                            </p>
                            <p className="p4">
                                <span className="s1">You may access and use the MyPhoto Site solely for your personal and noncommercial use. If you wish to use the MyPhoto Site for any commercial or business purpose, please contact us first at
                                        <a href="mailto:hello@myphoto.com">
                                        <span className="s3"> hello@myphoto.com</span>
                                    </a> to explain and request our permission, which we may grant or deny solely at our discretion.&nbsp;
                                </span>
                            </p>
                            <p className="p4">
                                <span className="s1">To use the MyPhoto Site, you agree not to do any of the following, which are prohibited: </span>
                            </p>
                            <p className="p4">
                                <span className="s1">Copy, duplicate, reproduce, create derivative works from, transmit, publicly display, modify, hack, deface, scrape, decompile or reverse-engineer the MyPhoto Site, or any part of it.</span>
                            </p>
                            <p className="p4">
                                <span className="s1">Transmit any software or other materials or data that contain any viruses, worms, trojan horses, defects, or other destructive items;</span>
                            </p>
                            <p className="p4">
                                <span className="s1">Misrepresent or falsely state your identity, use any fraudulent, misleading or knowingly inaccurate email address or other contact information, impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity.</span>
                            </p>
                            <p className="p5">
                                <span className="s1">Upload or transmit any photograph, image or other content (Content) to the MyPhoto Site that:</span>
                            </p>
                            <p className="p5">
                                <span className="s1">- you do not own or do not have the lawful and legal right, license or permission to use, reproduce, create, distribute and display;</span>
                            </p>
                            <p className="p5">
                                <span className="s1">- is false, intentionally misleading or defamatory;</span>
                            </p>
                            <p className="p5">
                                <span className="s1">- promotes a fraudulent scheme or is for the purpose of deceptive advertising or unfair competition</span>
                            </p>
                            <p className="p5">
                                <span className="s1">- violates the privacy or publicity rights of any person, living or deceased;</span>
                            </p>
                            <p className="p5">
                                <span className="s1">- violates the intellectual property or proprietary rights of any third-party right, including any copyright, trademark, patent, trade secret or moral right;</span>
                            </p>
                            <p className="p5">
                                <span className="s1">- contains material that is unlawful, including illegal hate speech or incitement to violence, threatening or pornographic;</span>
                            </p>
                            <p className="p5">
                                <span className="s1">- displays or depicts graphic violence or sexual acts;</span>
                            </p>
                            <p className="p5">
                                <span className="s1">- displays or depicts inappropriate child nudity or exploits or otherwise harms minors; </span>
                            </p>
                            <p className="p5">
                                <span className="s1">- displays or depicts animal cruelty or violence towards animals; or </span>
                            </p>
                            <p className="p5">
                                <span className="s1">- violates or advocates the violation of any applicable federal, state and local laws, regulations and ordinances.</span>
                            </p>
                            <p className="p6">&nbsp;</p>
                            <p className="p5">
                                <span className="s1">Please note that by using the MyPhoto Site, you also agree that MyPhoto may reject any Content and refuse to fulfill any orders that include Content that MyPhoto determines in its sole discretion to be objectionable or offensive or believes may violate the rights of others. </span>
                            </p>
                            <p className="p6">&nbsp;</p>
                            <p className="p5">
                                <span className="s1">YOU MUST BE AT LEAST THIRTEEN (13) YEARS OLD TO USE THE MYPHOTO SITE. IF YOU ARE UNDER AGE 18 BUT ABOVE 13 YOU ARE PERMITTED TO USE THE MYPHOTO SITE, PROVIDED YOU DO SO WITH THE CONSENT OF A PARENT OR LEGAL GUARDIAN WHO ACCEPTS THE TERMS OF USE ON YOUR BEHALF.</span>
                            </p>
                            <p className="p6">&nbsp;</p>
                            <p className="p3">
                                <span className="s2">
                                    <strong>Violations of These Terms, Conditions and Requirements; Indemnification </strong>
                                </span>
                            </p>
                            <p className="p4">
                                <span className="s1">You agree and understand that MyPhoto may immediately terminate your access to and use of the MyPhoto Site if you disregard or violate any of the terms, conditions, requirements and prohibitions in these Terms of Use. You also agree that even without a violation and unless prohibited by applicable law, MyPhoto may, in its sole discretion and without any notice or liability, deny use of the MyPhoto Site Website to any person for any reason or no reason at all.</span>
                            </p>
                            <p className="p4">
                                <span className="s1">You agree to indemnify, defend and hold harmless MyPhoto and its employees, officers, directors, contractors, agents, parents, subsidiaries or affiliates from all claims, lawsuits, losses, damages, judgments and expenses, including reasonable attorneys' fees and other litigation or defense costs and expenses, which relate to, arise from or are based on (1) your right, authority or license (or lack thereof) to use, reproduce, distribute and/or display Content that you uploaded or transmitted to MyPhoto or that MyPhoto included in a product that you ordered or purchased through the MyPhoto Site or from MyPhoto; (2) your violation or alleged violation of the Terms of Use, (3) your use of the MyPhoto Site, and/or (4) your violation or alleged violation of any rights of a third-party.</span>
                            </p>
                            <p className="p3">
                                <span className="s2">
                                    <strong>Your Representations, Warranties and License to MyPhoto</strong>
                                </span>
                            </p>
                            <p className="p4">
                                <span className="s1">By using the MyPhoto Site to upload Content or order or purchase any product that includes Content, you represent and warrant that (1) you own or have the lawful and legal right or license to use, reproduce, create, distribute and display such Content and anything depicted in such Content, including images, words, slogans, phrases, logos, emblems, photographs, drawings, pictures, endorsements, quotations, articles, or any other thing; (2) the Content complies with all applicable federal, state and local laws, regulations and ordinances; (3) the Content does not violate or infringe and intellectual property or proprietary rights of any third-party, including any copyright, trademark, patent, trade secret or moral right; (4) the Content does not infringe or violate the privacy or publicity rights of any person, living or deceased; and (5) you are at least thirteen (13) years old.</span>
                            </p>
                            <p className="p4">
                                <span className="s1">By uploading or transmitting Content to MyPhoto, you grant MyPhoto a limited, worldwide, non-exclusive, royalty-free license and right to copy, transmit, distribute, publicly perform and display (through all media now known or hereafter created), and make derivative works from such Content for the purposes of (1) creating, producing and fulfilling any products or services that you order or purchase from MyPhoto, (2) marketing or otherwise displaying MyPhoto products and services to you (at the e-mail address you provide to us or from which you upload or transmit the Content to us), including creating, displaying and transmitting to you digital representations of MyPhoto products that display your Content, (3) MyPhoto internal record keeping, administration, maintenance, research and development, and (4) archiving or preserving the Content for disputes, legal proceedings, or investigations.</span>
                            </p>
                            <p className="p3">
                                <span className="s2">
                                    <strong>Disclaimer of Warranties</strong>
                                </span>
                            </p>
                            <p className="p4">
                                <span className="s1">MyPhoto currently extends a Smile Guarantee to its customers. The current terms of the Smile Guarantee are set forth in full in the FAQ Section of the MyPhoto website (www.myphoto,com). The complete terms of the Smile Guarantee are as follows, but please refer to the FAQ Section for the current terms, in the event that they have been changed or updated:</span>
                            </p>
                            <p className="p7">
                                <span className="s1">We stand behind our products. If your item(s) arrives damaged or with a material manufacturing defect, please email hello@myphoto.com within 30 days of receipt and a member of our Customer Experience team will get back to you shortly.</span>
                            </p>
                            <p className="p4">
                                <span className="s1">The Smile Guarantee is the ONLY guarantee or warranty of any kind offered by MyPhoto. To the fullest extent permitted by applicable law, MyPhoto and its affiliates expressly disclaim any and all other warranties or guarantees of any kind whether express or implied, including but not limited to any warranties of title and license, or implied warranties of merchantability, fitness for a particular purpose and non-infringement.</span>
                            </p>
                            <p className="p4">
                                <span className="s1">MyPhoto does not make any warranty that (a) the MyPhoto Site or its content will meet your requirements, or (b) that the MyPhoto Site service will be uninterrupted, timely, secure, or error free, or (c) the quality of any products, services, information, or other material purchased or obtained by you through the MyPhoto Site will meet your expectations, or (d) that defects, if any, will be corrected (except to the extent that the then current version of the Smile Guarantee expressly sets forth that a particular defect will be corrected).</span>
                            </p>
                            <p className="p4">
                                <span className="s1">You agree that MyPhoto shall have no responsibility for any damages suffered by you in connection with the MyPhoto Site or any content contained therein.</span>
                            </p>
                            <p className="p4">
                                <span className="s1">You expressly agree that use of the MyPhoto Site, including all content, data or software distributed by, downloaded or accessed from or through the MyPhoto Site, is at your sole risk. You understand and agree that you will be solely responsible for any damage to your business, your computer system or loss of data that results from your connection to the MyPhoto Site or the download of content, data and/or software from the MyPhoto Site.</span>
                            </p>
                            <p className="p4">
                                <span className="s1">You acknowledge that MyPhoto makes no warranty or representation that confidentiality of information transmitted through the MyPhoto Site will be maintained, although MyPhoto does follow a Privacy Policy with respect to customer information.</span>
                            </p>
                            <p className="p4">
                                <span className="s1">No advice or information, whether oral or written, obtained by you from MyPhoto or through or from the MyPhoto Site shall create any warranty not expressly stated in these Terms of Use. </span>
                            </p>
                            <p className="p4">
                                <span className="s1">In the event that the MyPhoto Site is available through any third-party platform or website, or if the MyPhoto Site provides links to any third-party platform or website, or permits any third party to link to the MyPhoto Site, you understand and agree that MyPhoto makes no warranty of any kind, express or implied, and accepts no responsibility for any content or practices of such third parties, their platforms or websites, which are not under the control of MyPhoto. </span>
                            </p>
                            <p className="p3">
                                <span className="s2">
                                    <strong>Limitation of Liability</strong>
                                </span>
                            </p>
                            <p className="p4">
                                <span className="s1">TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NEITHER MYPHOTO NOR ITS AFFILIATES, PARENTS, SUBSIDIARIES, OFFICERS, EMPLOYEES, DIRECTORS, AGENTS, SUPPLIERS, OR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, DISTRIBUTING OR MARKETING A MYPHOTO PRODUCT OR SERVICE (THE "MYPHOTO PARTIES") SHALL BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF THE MYPHOTO PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE AND STRICT LIABILITY) OR ANY OTHER LEGAL THEORY. </span>
                            </p>
                            <p className="p4">
                                <span className="s1">THIS LIMITATION INCLUDES AND APPLIES TO ANY MATTER RELATING TO OR ARISING FROM THE MYPHOTO SITE OR THE OFFER, PURCHASE, SALE, FULFILLMENT OR DELIVERY OF A MYPHOTO PRODUCT OR SERVICE, INCLUDING THOSE RESULTING FROM OR RELATING TO YOUR USE OR INABILITY TO USE THE MYPHOTO SITE, UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR CONTENT, TRANSMISSIONS OR DATA, STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE MYPHOTO SITE (INCLUDING DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES), THE OFFER, PURCHASE, SALE, FULFILLMENT OR DELIVERY OF A MYPHOTO PRODUCT OR SERVICE, OR THE PROVISION OF (OR FAILURE TO PROVIDE) ANY PRODUCTS OR SERVICES. </span>
                            </p>
                            <p className="p4">
                                <span className="s1">YOUR SOLE AND EXCLUSIVE REMEDIES FOR ANY DISSATISFACTION WITH A MYPHOTO PRODUCT OR SERVICE (INCLUDING ITS OFFER, PURCHASE, SALE, FULFILLMENT OR DELIVERY) IS TO OBTAIN A REFUND AND TO STOP USING THE PRODUCT, SERVICE OR MYPHOTO SITE. </span>
                            </p>
                            <p className="p4">
                                <span className="s1">TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THE MAXIMUM LIABILITY OF THE MYPHOTO PARTIES ARISING OUT OF OR RELATING TO OUR PROVISION OF (OR FAILURE TO PROVIDE) PRODUCTS OR SERVICES SHALL BE THE ACTUAL PRICE FOR THE PRODUCT OR SERVICE THAT YOU PAID TO THE MYPHOTO PARTIES. </span>
                            </p>
                            <p className="p4">
                                <span className="s1">SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO SOME OF THE ABOVE MAY NOT APPLY TO YOU. IN SUCH JURISDICTIONS, LIABILITY OF THE MYPHOTO PARTIES IS LIMITED TO THE FULLEST EXTENT PERMITTED BY LAW.</span>
                            </p>
                            <p className="p3">
                                <span className="s2">
                                    <strong>Dispute Resolution</strong>
                                </span>
                            </p>
                            <p className="p4">
                                <span className="s1">These Terms of Use shall be governed under and construed in accordance with the laws of the State of Florida, without regard to its conflict or choice of laws provision. Any suits, actions, legal proceedings, arbitrations or mediations between you and MyPhoto (including for this Dispute Resolution section, MyPhoto’s employees, officers, directors, agents, parents, subsidiaries or affiliates) arising out of or relating in any way to (1) these Terms of Use, (2) your use of the MyPhoto Site, or (3) products or services that you order or purchase through the MyPhoto Site or through MyPhoto, shall be initiated and held in the State of Florida, County of Palm Beach, and you and MyPhoto consent to personal jurisdiction and venue in Palm Beach County, Florida and waive any affirmative defenses to the contrary.
                                    <span className="Apple-converted-space">&nbsp; &nbsp;</span>
                                </span>
                            </p>
                            <p className="p4">
                                <span className="s1">It is further agreed that before commencing any suits, actions, legal proceedings or arbitrations, the parties will first attempt to resolve any disputes by good faith discussions and then by non-binding mediation, with the costs of the mediation to be split evenly between or among the parties to the dispute. Both you and MyPhoto are prohibited from commencing any suits, actions, legal proceedings or arbitrations (other than a legal proceeding for equitable relief) against the other until one party has agreed in writing to the other party (or to the other party’s designated attorney) to engage in a non-binding mediation in Palm Beach County, Florida and to share evenly in the costs of the mediation and thereafter, the other party has either refused in writing to engage in the mediation or 45 days have elapsed without the other party agreeing to engage in the mediation, or the parties have engaged in the mediation and the dispute has not been resolved.&nbsp;</span>
                            </p>
                            <p className="p4">
                                <span className="s1">YOU AGREE THAT YOU MAY BRING CLAIMS AGAINST MYPHOTO AND PARTICIPATE IN SUITS, ACTIONS, LEGAL PROCEEDINGS, OR ARBITRATIONS AGAINST MYPHOTO, ONLY IN YOUR INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.</span>
                            </p>
                            <p className="p3">
                                <span className="s2">
                                    <strong>Copyright Issues and DMCA Copyright Infringement Notice</strong>
                                </span>
                            </p>
                            <p className="p4">
                                <span className="s1">As set forth in these Terms of Use, MyPhoto prohibits Content that violates or infringes the copyright of any person or third-party. </span>
                            </p>
                            <p className="p4">
                                <span className="s1">If you believe that your copyrighted work has been copied in a way that constitutes copyright infringement and it is accessible on the MyPhoto Site, please provide us with the following information (DMCA Notice"). Please be aware that to be effective under the Digital Millennium Copyright Act of 1998 ("DMCA"), the DMCA Notice must include ALL of the following:</span>
                            </p>
                            <p className="p4">
                                <span className="s1">1. A physical or electronic signature of the person authorized to act on behalf of the owner of an exclusive copyright that is allegedly infringed;
                                    <br />2. Identification of the copyrighted work that you claim has been infringed (and if you claim multiple copyrighted works at the MyPhoto Site are covered, a representative list of such works);
                                    <br />3. Identification of the material that you claim is infringing and information reasonably sufficient to permit MyPhoto to locate the material on the MyPhoto Site;
                                    <br />4. Information reasonably sufficient to permit MyPhoto to contact you, such as an address, telephone number, and, if available, an e mail address at which the complaining party may be contacted;
                                    <br />5. A statement by you that you have a good faith belief that the use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law;
                                    <br />6. A statement by you that the above information in your Notice is accurate and, under penalty of perjury, that you are the copyright owner or authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
                                </span>
                            </p>
                            <p className="p4">
                                <span className="s1">
                                    <strong>Notices of claimed copyright infringement should be directed to:</strong>
                                </span>
                            </p>
                            <p className="p4">
                                <span className="s2">By mail</span>
                                <span className="s1">:</span>
                            </p>
                            <p className="p5">
                                <span className="s1">MyPhoto</span>
                            </p>
                            <p className="p5">
                                <span className="s1">2915 S Congress Avenue
                             <br />Delray Beach, Fl 33445
                            </span>
                            </p>
                            <p className="p5">
                                <span className="s1">Attn: Legal</span>
                            </p>
                            <p className="p6">&nbsp;</p>
                            <p className="p4">
                                <span className="s2">By email</span>
                                <span className="s1">: hello@myphoto.com</span>
                            </p>
                            <p className="p3">
                                <span className="s2">
                                    <strong>General Provisions</strong>
                                </span>
                            </p>
                            <p className="p4">
                                <span className="s2">Severability</span>
                                <span className="s1">: If any provision of these Terms of Use is invalid, illegal or unenforceable under any applicable statute or rule of law, such provision shall be deemed amended to achieve as nearly as possible the same economic effect as the original provision and the remaining provisions of these Terms of Use shall in no way be affected or impaired.</span>
                            </p>
                            <p className="p4">
                                <span className="s2">Waiver</span>
                                <span className="s1">: A waiver by either you or MyPhoto of (i) any term or condition of these Terms of Use, or (ii) a breach of any term or condition of these Terms of Use, in any one instance, will not waive such term or condition or any subsequent breach thereof.
                                    <br />
                                    <br />
                                </span>
                                <span className="s2">Assignment</span>
                                <span className="s1">: You may not assign or transfer these Terms of Use or any of your rights and obligations under these Terms of Use, without the express prior written consent of MyPhoto. MyPhoto may assign these Terms of Use or any of its rights and obligations under these Terms of Use, without your consent and at the sole and exclusive discretion of MyPhoto.
                                    <br />
                                    <br />
                                </span>
                                <span className="s2">Relationship</span>
                                <span className="s1">: Without limiting any other provision of these Terms of Use, these Terms of Use do not create an agency, partnership, joint venture or employee-employer relationship between you and MyPhoto.
                                    <br />
                                </span>
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default (TermsAndConditions);

import * as types from '../actions/actionTypes'

const INITIAL_STATE = {
  overlays: [],
  selectedOverlay: {}
}

function overlays(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_APP_DATA:
      return {
        ...state,
        overlays: [...action.overlays],
      };
    case types.SAVE_OVERLAY:
      return {
        ...state,
        selectedOverlay: action.payload,
      }
    default:
      return state
  }
}

export default overlays
import * as types from '../actions/actionTypes';
import { assign } from 'lodash';

const initialState = {
  current: {
    photoUrl: '',
    originalUrl: '',
    base64: '',
    edits: {
      ratio: 0,
      text: {
        ribbonHeight: 0.1,
        fontSize: 0.8,
        fontFamily: 'Marydale',
        value: '',
        color: 'white',
        band: false,
        x: 0,
        y: 0,
      },
      rotation: 0,
      points: [],
    },
    canvasData: {},
    jsonResult: {},
  },
  cartItemId: undefined,
  items: [],
  list: [],
  quantity: 1,
  selectedPhotoId: null,
  zoomed: false,
  zoomIndex: 0,
  isMultiphoto: false,
  croppedEdits: { },
  crop: {
    x: 0,
    y: 0,
  },
  zoom: 1,
  initialCroppedAreaPixels: {},
  filter: {},
  hasFilter: false,
};

function projects(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_PROJECT:
      // console.log('Setting projects.project in state: ', action.payload)
      return assign({}, state, {
        project: JSON.parse(JSON.stringify(action.payload)),
      })
    case types.ADD_PHOTOS_TO_LIST:
      return assign({}, state, {
        list: [
          ...state.list,
          {
            photoUrl: action.item.photoUrl || action.item.url,
            originalUrl: action.item.originalUrl || null,
            edits: action.item.edits || initialState.current.edits,
            canvasData: action.item.canvasData || {},
            jsonResult: action.item.jsonResult || {},
            base64: action.item.base64 || {},
            actualSize: action.item.actualSize || {},
            thumbSize: action.item.thumbSize || {},
          },
        ],
      });
    case types.REMOVE_PHOTO_FROM_LIST:
      return assign({}, state, {
        list: state.list.filter(item =>
          item.id !== action.itemId
        ),
      });
    case types.UPDATE_ZOOM:
    case types.UPDATE_MULTIPHOTO:
      return assign({}, state, action.payload )
    case types.UPDATE_CROPPED_VALUES:
      return assign({}, state, action.payload )  
    case types.UPDATE_IMAGE_FILTER:
      return assign({}, state, action.payload )  
    case types.UPDATE_HAS_FILTER:
      return assign({}, state, action.payload )  
    default:
      return state
  }
}

export default projects;

import React from 'react'
import { Link } from 'react-router-dom'

function FaqCategoryWrapper(props) {
  return (
    <div className='faq-category-main-container container'>
      <h1 className='faq-category-title'>{props.title}</h1>
      <Link className='faq-see-all-link' to='/faq'>&nbsp;&lt;&nbsp;See all FAQs</Link>
      {props.children}
    </div>
  )
}

export default FaqCategoryWrapper;
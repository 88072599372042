import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import autoBind from 'react-autobind'
import { openModal } from '../../actions'
import HamburgerMenu from './HamburgerMenu'
import ModalVideo from 'react-modal-video'
import { Dropdown } from 'antd';
import addDefaultSrc from '../../helpers/addDefaultSrc'
import './header.scss'
import ChatBot from '../common/ChatBot'

const propTypes = {
  dispatch: PropTypes.func,
  seo: PropTypes.object,
  clientLogo: PropTypes.string,
  user: PropTypes.object,
}


class Header extends Component {
  constructor() {
    super();
    this.state = {
      badge: 'invisible',
      showModal: false,
      quantity: 0,
      isOpenVideo: false
    };
    autoBind(this);
    this.openModalVideo = this.openModalVideo.bind(this)
  }
  closeModal = () => {
    this.setState({ showModal: false })
  }
  openModalVideo() {
    this.setState({ isOpenVideo: true })
  }
  getBadgeState() {
    const { badge } = this.state;
    const { cart } = this.props;
    var quantity = 0;
    if (cart && cart.items && cart.items.length && cart.items[0] && cart.items[0].item_id) {
      quantity = cart.items.reduce((accumulator, item) => {
        return (item.qty && item.item_id) ? accumulator + parseInt(item.qty,10) : accumulator;
      }, 0);
    }
    if (quantity !== this.state.quantity) {
      this.setState({
        quantity
      })
    }
    if (badge !== 'in' && quantity) {
      this.setState({
        badge: 'in',
      });
    }
    if (badge === 'in' && !quantity) {
      this.setState({
        badge: 'out',
      });
    }
    if (badge === 'out' && !quantity) {
      this.setState({
        badge: 'invisible',
      });
    }
  }

  render() {
    const shopAllMenu =(
      <div className='dropdown-content'>
        <div className='first-column'>
          <Link to="/products" className='dropdown-menu-link shop-all-link'>Shop All</Link>
          <p className="dropdown-title">Shop by Material</p>
          {/* <Link to="/products/ornaments" className='dropdown-menu-link'>Ornaments</Link> */}
          <Link to="/products/acrylic" className='dropdown-menu-link'>Acrylic</Link>
          <Link to="/products/glass" className='dropdown-menu-link'>Glass</Link>
          <Link to="/products/metal" className='dropdown-menu-link'>Metal</Link>
          <Link to="/products/wood" className='dropdown-menu-link'>Wood</Link>
          <Link to="/products/frames-canvas" className='dropdown-menu-link'>Frames & Canvas</Link>
        </div>
        <div className='second-column second-column-shopall'>
          <p className="dropdown-title">Shop by Placement</p>
          <Link to="/products/table-top" className='dropdown-menu-link'>Shelf &amp; Desk</Link>
          <Link to="/products/wall" className='dropdown-menu-link'>Wall</Link>
          <Link to="/products/office" className='dropdown-menu-link table-top-link'>Office</Link>
          <p className="dropdown-title">Shop by Style</p>
          <Link to="/products/modern" className='dropdown-menu-link'>Modern</Link>
          <Link to="/products/classic" className='dropdown-menu-link'>Classic</Link>
        </div>
      </div>
    )
    const featuredMenu =(
      <div className='dropdown-content'>
          <Link to="/products/best-sellers" className='dropdown-menu-link '>Best Sellers</Link>
          <Link to="/products/new" className='dropdown-menu-link'>New Arrivals</Link>
          <Link to="/products/gift-guide" className='dropdown-menu-link'>Gift Guide</Link>
          <Link to="/products/minis" className='dropdown-menu-link'>Minis Collection</Link>
          <Link to="/products/ornaments" className='dropdown-menu-link'>Ornaments</Link> 
          {/* <Link to="/products/acrylic-block" className='dropdown-menu-link'>Acrylic Blocks</Link>*/}
      </div>
    )
    const aboutUsMenu = (
      <div className='dropdown-content'>
          <Link to="/reviews" className='dropdown-menu-link '>Reviews</Link>
          <Link to="/how-to-myphoto" className='dropdown-menu-link'>How To</Link>
          <Link to="/our-story" className='dropdown-menu-link'>Our Story</Link>
          <Link to="/about-us" className='dropdown-menu-link'>About Us</Link>
          <span className="dropdown-menu-link"
            onClick={this.openModalVideo}
          >TV Commercial</span>
      </div>
    )
    if(this.props.user) this.getBadgeState()
    const { quantity } = this.state;
    const { pathname } = this.context.router.history.location
    const clientLogo = this.props.clientLogo
    const { videoId } = this.props.config
    const cartCount = (
      <span className={`cart-badge ${this.state.badge}`}>
        <span className='cart-badge-text'>{quantity}</span>
      </span>);
    return (
      <section className="navbar__container" id="outer-container">
      <div className="main__container">
        <div className="header-container">
          <HamburgerMenu openModalVideo={this.openModalVideo}/>
            <div className="navbar_brand">
              <Link
              style={clientLogo === null || clientLogo === '' ? { backgroundImage: `url(https://storage.googleapis.com/myphoto-img/myphoto-logo.svg)` } : { backgroundImage: `url(${clientLogo})` }} to="/" className="navbar-logo">MyPhoto</Link>
            </div>
            <div className="navbar__list">
              <div className="header-item is-hidden-touch">
                {/* <Link to="/products" className={`link links best-seller-link ${(pathname==='/products') ? 'active' : ''}`}>SHOP ALL</Link> */}
                <Dropdown 
                  overlay={shopAllMenu}
                  placement="bottomCenter"
                  >
                  <Link to="/products" className={`link links ${(pathname==='/products') ? 'active' : ''}`}>Shop All</Link>
                </Dropdown>
              </div>
              <div className="header-item is-hidden-desktop">
                <Link to="/products" className={`link links ${(pathname==='/products') ? 'active' : ''}`}>Shop All</Link>
              </div>
              <div className="header-item is-hidden-touch">
                <Dropdown 
                  overlay={featuredMenu}
                  placement="bottomCenter"
                  >
                  <Link to="/products/best-sellers" className={`link links`}>Featured</Link>
                </Dropdown>
              </div>
              <div className="header-item">
                <Link to="/products/gift-guide" className={`link links ${(pathname==='/products/gift-guide') ? 'active' : ''}`}>Gift Guide</Link>
              </div>
              <div className="header-item is-hidden-touch">
                <Dropdown 
                  overlay={aboutUsMenu}
                  placement="bottomCenter"
                  >
                  <Link to="/about-us" className={`link links ${(pathname==='/about-us') ? 'active' : ''}`}>About Us</Link>
                </Dropdown>
              </div>
              {/* <div className="header-item is-hidden-touch">
                <Link to="/faq" className={`link links ${(pathname==='/faq') ? 'active' : ''}`}>FAQ</Link>
              </div>*/}
              <div className="header-item">
                <Link to="/order-status" className={`link links ${(pathname==='/order-status') ? 'active' : ''}`}>Order Status</Link>
              </div>
              <div className="header-item is-hidden-touch" style={{marginRight: 0}}>
                <div className={`link links`}><ChatBot useTitle="Support" /></div>
              </div>
              </div>
              <span className="cart-container">
                <div className="is-hidden-desktop"><ChatBot header /></div>
                {/* <ChatBot header /> */}
                {/* <Link to='/faq' style={{marginRight: '15px'}}><img id='imageicon' onError={addDefaultSrc}
                  src="https://storage.googleapis.com/myphoto-img/icons/HelpIcon.svg" alt="CART"/>
                </Link>*/}
                <div
                  className="icon-cart"
                  onClick={() => {
                          this.context.router.history.push('/cart')}}>
                      <img className="cart-icon" onError={addDefaultSrc}
                        src="https://storage.googleapis.com/myphoto-img/icons/CartIcon.svg" alt="CART" />
                      <span className="cart-badge">{cartCount} </span>
                </div>
                {/* UPLOAD: + ADD PHOTO */}
                {(window.location.href.indexOf("/checkout") > -1) ? null :
                  <button
                    className="navbar__link add-btn is-hidden-mobile"
                    onClick={(e) => {
                      this.setState({ showModal: true })
                      this.props.dispatch(openModal('UPLOAD_MODAL'))
                      e.preventDefault();
                    }}>
                    <p className="upload-link-text">Upload Photo</p>
                  </button>}
              </span>
            </div>
        </div>
        <ModalVideo
        channel='youtube'
        isOpen={this.state.isOpenVideo}
        videoId= {videoId}
        onClose={() => this.setState({ isOpenVideo: false })} />
      </section>
    );
  }
}

Header.propTypes = propTypes;
Header.contextTypes = {
  router: PropTypes.object.isRequired
}

export default withRouter(connect((state) => {
  return {
    clientLogo: state.config.layout.clientLogo,
    user: state.user.user,
    cart: state.cart.cart,
    config: state.config.appConfig,
  };
})(Header))

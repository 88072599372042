import * as types from '../actions/actionTypes';
import { assign } from 'lodash';

const initialState = {
    corporateData: {
    },
};

function corporateForm(state = initialState, action) {
  switch (action.type) {
    case types.CORPORATE_DATA_SUCCESS:
      return assign({}, state, {
        corporateData: action.corporateData,
      });

    case types.CORPORATE_DATA_ERROR:
      return assign({}, state, {
        error: action.data,
      });

    default:
      return state;
  }
}

export default corporateForm;

import React from 'react'
import { message, Button } from 'antd'
import { createBraintreeToken, saveBrainTreeInstance, is_fetching } from '../../actions/index'

var client = require('braintree-web/client');
var hostedFields = require('braintree-web/hosted-fields');

var inputStyles = {
  'input': {
    'font-size': '14px',
    'font-family': 'Open Sans, sans-serif',
    //'font-weight': 'lighter',
    'color': '#ccc'
  },
  ':focus': {
    'color': 'black'
  },
  '.valid': {
    'color': '#0ac600'
  }
}

var fields = {
  number: {
    selector: '#card-number',
    placeholder: '•••• •••• •••• ••••'
  },
  cvv: {
    selector: '#cvv',
    placeholder: '•••'
  },
  expirationDate: {
    selector: '#expiration-date',
    placeholder: 'MM/YYYY'
  },
  postalCode: {
    selector: '#postal-code',
    placeholder: '•••••'
  }
}
class BrainTreePayments extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      clientInstance: '',
      hostedFieldsInstance: '',
    }
  }

  componentDidMount() {
    const { dispatch } = this.props
    const that = this
    dispatch(is_fetching())
    dispatch(createBraintreeToken())
      .then((res) => {
        console.log('RESULT RES', res);
        client.create({
          authorization: res.clientToken,
        }, function (err, clientInstance) {
          if (err) {
            console.error(err);
            return;
          }
          that.setState({
            clientInstance
          })
          that.createHostedFields(clientInstance);
        })
      })
      .catch((e) => {
        console.log('Error retriving client token', e)
      })
  }

  componentWillReceiveProps(nextProps){
    const {placeOrder} = this.props
    console.log('PLACE ORDER ******* BRAINTREE', placeOrder, nextProps.placeOrder)
    if(placeOrder !== nextProps.placeOrder && nextProps.placeOrder === true ){
      console.log('HANDLE MOBILE PLACE ORDER')
      this.handleMobilePlaceOrder();
    }
  }

  handleBrainTreeClick =  (event) => {
    const {clientInstance, hostedFieldsInstance} = this.props.brainTreeInstance
    const {handleBraintreeCheckout} = this.props
    const that=this
    hostedFieldsInstance && hostedFieldsInstance.tokenize(function (err, payload) {
      if (err) {
        console.error('ERROR TOKENIZING HOSTFIELDS**', err)
        const errorType = err && err.details && err.details.invalidFieldKeys && err.details.invalidFieldKeys[0]
        switch (errorType) {
          case 'number':
            message.error("Incorrect Card Numner details.", 10)
            break
          case 'cvv':
            message.error("Incorrect CVV.", 10)
            break
          case 'expirationDate':
            message.error("Incorrect Expiration Date.", 10)
            break
          case 'postalCode':
            message.error("Incorrect Zip Code", 10)
            break
          default: // no additional action needed
            message.error("CreditCard Payment Failed.", 10)
        }
        return
      }
      console.log('**** payload', payload)
      that.createHostedFields(clientInstance)
      handleBraintreeCheckout(payload.nonce)
    })
  }

  createHostedFields = (clientInstance) => {
    console.log('CreateHostedFields', clientInstance)
    const { dispatch } = this.props
    const that = this
    hostedFields.create({
      client: clientInstance,
      styles: inputStyles,
      fields: fields,
    }, function (err, hostedFieldsInstance) {
      if(err) {
        console.log('ERROR CREATING HOSTFOELDS INSTANCE ', err)
      }
      that.setState({
        hostedFieldsInstance
      })
      if(hostedFieldsInstance) {
        dispatch(saveBrainTreeInstance(clientInstance, hostedFieldsInstance))
      }
    })
  }

  handleMobilePlaceOrder=(e)=>{
    const { hostedFieldsInstance } = this.props.brainTreeInstance
    const that = this
    hostedFieldsInstance && hostedFieldsInstance.tokenize(function (err, payload) {
      if (err) {
        console.error('ERROR FROM CARDS ****', err)
        const errorType = err && err.details && err.details.invalidFieldKeys && err.details.invalidFieldKeys[0]
        that.props.toggleMobilePlaceOrder()
        switch (errorType) {
          case 'number':
            message.error("Incorrect Card Numner details.", 10)
            break
          case 'cvv':
            message.error("Incorrect CVV.", 10)
            break
          case 'expirationDate':
            message.error("Incorrect Expiration Date.", 10)
            break
          case 'postalCode':
            message.error("Incorrect Zip Code", 10)
            break
          default: // no additional action needed
            message.error("CreditCard Payment Failed.", 10)
        }
        return
      }
      that.props.handleBraintreeCheckout(payload.nonce)
    })
  }
  render() {
    const { paymentProcessing, enablePlaceOrderButton } = this.props
    return (
      <div className="demo-frame">
        <form action="/" method="post" id="cardForm" >
          <label className="hosted-fields--label" for="card-number">Card Number</label>
          <div id="card-number" className="hosted-field"></div>

          <label className="hosted-fields--label" for="expiration-date">Expiration Date</label>
          <div id="expiration-date" className="hosted-field"></div>

          <label className="hosted-fields--label" for="cvv">CVV</label>
          <div id="cvv" className="hosted-field"></div>

          <label className="hosted-fields--label" for="postal-code">Postal Code</label>
          <div id="postal-code" className="hosted-field"></div>

          <div className="button-container">
          { enablePlaceOrderButton
            ? <Button 
                htmlType="submit"
                className="button is-medium is-fullwidth place-order-btn is-hidden-mobile" 
                loading={paymentProcessing}
                value="Place Order" 
                id="submit"
                onClick={this.handleBrainTreeClick}
                >Place Order
              </Button>
            : <Button 
                htmlType="submit"
                className="button is-medium is-fullwidth place-order-btn is-hidden-mobile" 
                value="Place Order" 
                id="submit"
                disabled
                >Place Order
              </Button> }
          </div>
        </form>
      </div>
    )
  }

}

export default BrainTreePayments
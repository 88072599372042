import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import autoBind from 'react-autobind'
import { Link } from 'react-router-dom'
import { Collapse } from 'antd';
import FaqCollapseConatiner from '../../components/faq/FaqCollapseContainer'
import FaqCategoryWrapper from '../../components/faq/FaqCategoryWrapper'
import '../../assets/stylesheets/faq.scss'

const { Panel } = Collapse

class FaqShippingTracking extends React.Component {
  constructor(props) {
      super(props)
      autoBind(this)
  }
  render() {
      const { shippingOptions } = this.props
        const fastShippingMethod = shippingOptions.find(o => o.id === 1)
        // const crazyFastShippingMethod = shippingOptions.find(o => o.id === 2)
        // <p className="faq-text">We offer FREE 5-Day shipping on orders ${fastShippingMethod.free_cutoff}+. {!fastShippingMethod && !fastShippingMethod.disabled ? `Upgrade to 3-Day shipping for just $${crazyFastShippingMethod && crazyFastShippingMethod.price}.` : ''}</p>
        // <p className="faq-text">Absolutely! Shipping is free for all orders ${fastShippingMethod.free_cutoff}+ anywhere in the U.S., and your order will be in their hands within 5 business days. {!fastShippingMethod && !fastShippingMethod.disabled ? `We also offer 3-Day shipping for just $${crazyFastShippingMethod && crazyFastShippingMethod.price}. Perfect for high-quality, personalized, last minute gifts.` : ''}</p>
      return (
        <FaqCategoryWrapper title='Shipping/Tracking'>
          <div className='faq-questions-container'>
            <FaqCollapseConatiner>
              <Panel header="How do I check the status of my order?" key="1">
              <p>You'll IMMEDIATELY get an order confirmation email after you place your order. 
              You will only hear from us again once your order ships. 
              You can click on the following <Link className="faq-link" to="/order-status">&nbsp;link</Link> and it will redirect you to our new order status page, where you can check the current status of your order. 
              It will also provide information on previous orders! 
              If you can't find your order confirmation or shipping email, check your spam or promotions folder as emails can sometimes be unpredictable.</p> 
              </Panel>
              <Panel header="Are there any shipping delays as a result of COVID-19?" key="2">
              <p>Fortunately, we have been able to maintain business as usual while adhering to the necessary standards of safety, social distancing, and sanitation.
              </p> 
              </Panel>
              <Panel header="How do I get FREE shipping?" key="3">
              <p>You're on the right site! 
              Every order of ${fastShippingMethod && fastShippingMethod.free_cutoff} or more is <span className='semi-bold underlined'>FREE</span> shipping and we get it to you or your loved ones will get it within 5 business days.</p> 
              </Panel>
              {/**<Panel header="What does 7-Day Delivery mean?" key="4">
              <p>It means it is in your or your recipient's <span className="underlined">hands</span> within 7 business days. It takes us 4 days to handcraft your photo product. 
              You'll receive a confirmation email once your order has shipped and just 3 days later, you or someone you love will be smiling from getting that precious MyPhoto memory. 
              To clarify, if you placed an order on <span className="underlined">Monday</span>, 7 business days later would have your product(s) delivered on or before the next Wednesday. 
              If you ordered on <span className="underlined">Saturday</span>, you would receive your products(s) on or before the next Tuesday since the weekends DO NOT count as business days.
              NOTE: Processing and shipping time DOES NOT include holidays.</p>
              </Panel> **/}
              <Panel header="What does 5-Day Delivery mean?" key="4">
              <p>It means it is in your or your recipient's <span className="underlined">hands</span> within 5 business days. It takes us 3 days to handcraft your photo product. 
              You'll receive a confirmation email once your order has shipped and just 2 days later, you or someone you love will be smiling from getting that precious MyPhoto memory. 
              To clarify, if you placed an order on <span className="underlined">Monday</span>, 5 business days later would have your product(s) delivered on or before that Saturday. 
              If you ordered on <span className="underlined">Saturday</span>, you would receive your products(s) on or before that Friday since the weekends DO NOT count as business days.</p> 
              <p>NOTE: Processing and shipping time DOES NOT include holidays.</p>
              </Panel>
            {/**
              <Panel header="What does 3-Day Delivery mean?" key="4">
              <p>Need your product faster? 
              For only ${crazyFastShippingMethod && crazyFastShippingMethod.price ? crazyFastShippingMethod.price : '12.52'}, no matter how large your order is, we will get your shipment delivered within 3 business days. 
              Orders on Monday will arrive on or before Thursday and orders on Saturday will arrive on or before Wednesday.</p>
              </Panel> **/}
              <Panel header="I just placed my order and the shipping address is wrong! How can I update my shipping address?" key="5">
              <p>We are more than happy to update the shipping address for you! 
              Please email us with your confirmation number and new shipping address <span className="underlined">within an hour</span> after your order has been placed to <a className="faq-link" href="mailto:hello@myphoto.com">hello@myphoto.com</a>. 
              Please keep in mind that this is a time-sensitive matter and we are unable to change the address once your package has been shipped.</p>
              </Panel>
              <Panel header="Can I ship to someone else's address or a PO box?" key="6">
              <p>Of course! We ship anywhere in the U.S. with FAST, FREE Shipping for all orders over ${fastShippingMethod && fastShippingMethod.free_cutoff}.</p>
              </Panel>
              <Panel header="Can I ship to multiple addresses in the same order?" key="7">
              <p>At this time, you can only ship to one address per order. We will be adding multiple shipping addresses in the near future. Stay tuned!</p>
              </Panel>
              <Panel header="Do you ship international?" key="8">
              <p>MyPhoto currently ships to the U.S. only. We will start expanding internationally over the next few months!</p>
              </Panel>
            </FaqCollapseConatiner>
          </div>
        </FaqCategoryWrapper>
      )
  }
}
function mapStateToProps(state) {
  return {
      photos: state.photos.photos,
      shippingOptions: state.config.appConfig.shippingOptions,
  };
}

export default withRouter(connect(mapStateToProps)(FaqShippingTracking));
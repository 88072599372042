import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { pathOr } from 'ramda'
import { update_cart_item, is_fetching } from '../../actions/'
import ThumbnailGenerator from '../common/Thumbnail'
import addDefaultSrc from '../../helpers/addDefaultSrc'
import { handleQuanityChangeMonetate}  from '../../helpers/monetate.js'
import '../../assets/stylesheets/cart-item.scss'

class CartItem extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  }

  handleQuantityChange(cartItemId, amt, allowsPersonalization) {
    const { dispatch, cart } = this.props;

    
    if (amt > 0) {
      dispatch(update_cart_item(cartItemId, cart, 'qty', amt, allowsPersonalization));
      dispatch(is_fetching())
      handleQuanityChangeMonetate(this.props.cart.items) 
   }}
  

  handleRemoveItem(cartItemId) {
    const { dispatch, cart } = this.props;
    dispatch(update_cart_item(cartItemId, cart, 'delete', 0));
    dispatch(is_fetching())
    handleQuanityChangeMonetate(this.props.cart.items)
  }
 



  render() {
    const { cartItem, product, item, addedToCart, lastAddedToCart, qty, hideLink, upsellItemSku, upsellItem, upsellDiscount } = this.props
    const overlayIsDisabledCart = product && product.overlayDisabled
    if (item === 'itemNotFound') { // item not available
      const itemImg = pathOr('https://s3.amazonaws.com/focus-misc-assets/image_not_available_829x455.jpg', ['photopersonalization', 0, 'previewUrl'], cartItem)
      return (
        <div className='cart-item-wrapper'>
          <div key={cartItem.id} className="card-item-main-container">
            <div className="card-item-image-container">
              <img
                style={{ width: '100%' }}
                className="img"
                src={itemImg}
                alt={cartItem.name}
              />
            </div>
            <div className="card-item-content-container">
              <p className="card-item-name" >
                {cartItem.name}
                <br />
              </p>
              <p className="alert__message icon-error" style={{
                marginBottom: '0px',
                fontSize: '14px'
              }}>Item currently unavailable.<br />Please remove it to continue.</p>
              <div className="cart-item-ctas">
                <button
                  className="cart-item-remove"
                  style={{ marginLeft: 0 }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleRemoveItem(cartItem.id)
                  }
                  }>
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      const itemImage = item.img ? item.img : product.img
      const itemName = (item.sku === upsellItemSku) ? item.name : product.name
      const allowsPersonalization = (item.sku === upsellItemSku) ? false : product.allowsPersonalization
      const isMultiPhoto = (item.preview.numPhotos > 1)
      const isCollageOrMask = item.maskHorizontal || item.maskVertical
      const isSet = isMultiPhoto && !isCollageOrMask
      const isUpsellItem = upsellItem && (cartItem.sku === upsellItemSku)
      const nonDiscountPrice = (isUpsellItem) ? upsellItem.msrp * cartItem.qty : item.msrp * cartItem.qty
      const cartItemTotal = (isUpsellItem) ? upsellItem.msrp * cartItem.qty * upsellDiscount : cartItem.total
      const priceDisplay =
        ((cartItem.qty > 1 && allowsPersonalization) || isUpsellItem)
          ? <Fragment><strike>${nonDiscountPrice.toFixed(2)}</strike> ${cartItemTotal.toFixed(2)}</Fragment>
          : (cartItemTotal > 0) ? '$' + cartItemTotal.toFixed(2) : '$0'
      return (
        <div className='cart-item-wrapper'>
          <div key={cartItem.id} className="card-item-main-container">
            <div className="card-item-image-container">
              <span className={`cart__item__image__holder image clickable ${cartItem.disabled ? 'disabled-product' : ''}`}>
                {cartItem.disabled &&
                  <span className="cart__item__disable">
                    <p className="alert__message icon-error">
                      Item not available. Please remove it to continue.
                    </p>
                  </span>
                }
                {(product && product.allowsPersonalization && cartItem && cartItem.photopersonalization && cartItem.photopersonalization.length) ?
                  <ThumbnailGenerator
                    useProject={false}
                    photopersonalization={cartItem.photopersonalization}
                    item={item}
                    alt={itemName}
                    ref="thumbnailCreator"
                    overlayIsDisabledCart={overlayIsDisabledCart}
                  /> :
                  <img
                    style={{ width: '100%' }}
                    className="img"
                    src={itemImage || 'https://s3.amazonaws.com/focus-misc-assets/image_not_available_829x455.jpg'}
                    alt={itemName || item.name}
                  />
                }
              </span>
            </div>
            <div className="card-item-content-container">
              {!hideLink ? <p className="card-item-name" style={{ cursor: 'pointer' }} onClick={() => {
                const prodStyle = (product.attributes && product.attributes[0]) ? product.attributes[0] : product.slug
                this.context.router.history.push(`/products/${product.category}/${prodStyle}/${product.slug}`)
              }}>
                {cartItem.name}
                <br />
              </p>
                : <p className="card-item-name" >
                  {cartItem.name}
                  <br />
                </p>}
              <div className='price-added-container'>
                <p className="cart-item-price">{priceDisplay}</p>
                {addedToCart && lastAddedToCart
                  ? <div className="added-container">
                    <img className="added-icon" alt="" onError={addDefaultSrc}
                      src={'https://storage.googleapis.com/myphoto-img/icons/check-mark-icon2.svg'} />
                    <p className="added-text">Added to cart</p>
                  </div>
                  : null}
              </div>
              <div className="cart-item-ctas">
                {!isSet &&
                  <select
                    className="cart-item-select select-option"
                    style={{ width: "55px" }}
                    value={cartItem.qty}
                    onChange={id => {
                      this.handleQuantityChange(cartItem.id, id.target.value, allowsPersonalization)
                    }}
                    name="form-field-name1">
                    {qty.map((option, id) => {
                      return <option key={id} value={option.value}>{option.label}</option>
                    })}
                    }
                    </select>
                }
                <button
                  className="cart-item-remove"
                  style={{ paddingLeft: 0 }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleRemoveItem(cartItem.id)
                  }
                  }>
                  Delete
                </button>
              </div>
            </div>
          </div>
          {(product && product.allowsPersonalization && !isSet)
            ? <button
              className='cart-item-double-up-btn'
              onClick={() => this.handleQuantityChange(cartItem.id, parseInt(cartItem.qty, 10) + 1, allowsPersonalization)}
            >+ Buy Another at <span className='bold'>50% off</span></button>
            : null}
        </div>
      )
    }
  }
}

export default CartItem


import React from 'react'
import Slider from 'react-slick'
import addDefaultSrc from '../../helpers/addDefaultSrc'

export const prevArrow = <img className="prev-arrow" alt="previous slide" onError={addDefaultSrc} src='https://storage.googleapis.com/myphoto-img/icons/carousel-arrow-prev.png' />

export const nextArrow = <img alt="next slide" onError={addDefaultSrc} src='https://storage.googleapis.com/myphoto-img/icons/carousel-arrow-next.png' />


export class SlickSlider extends React.Component {
  componentWillReceiveProps(nextProps) {
    if(nextProps.item !== this.props.item) {
      if(this.slider) this.slider.slickGoTo(0)
    }
  }
  
  render() {
    const { item, product, productMoreViews, uploadPhotos, isMobile } = this.props
    const urls = productMoreViews && productMoreViews.map(({ url }) => { return url })
    urls && urls.length && urls.unshift(item.img || product.img)
    const sliderSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoPlay: true,
      arrows: true,
      prevArrow,
      nextArrow,
      dotsClass: 'slick-dots slick-dots-pre-preview slick-thumb '+(product&&product.productVideoUrl != null? 'video-offset' : ''),
      customPaging: i => (
        isMobile ? <div className='carousel-preview-line' key={i}></div> : <img className='carousel-preview-img' key={i} src={urls[i]} alt='preview'/>
      )
    }
    return (
        <Slider ref={slider => (this.slider = slider)} {...sliderSettings}>
          <div onClick={uploadPhotos}>
            <img
              src={item.img || product.img}
              onError={(e) => { e.target.onerror = null; e.target.src = 'https://s3.amazonaws.com/focus-misc-assets/image_not_available_829x455.jpg' }}
              alt='product-preview'
              className='imagePreview'
            />
          </div>
          {productMoreViews.map && productMoreViews.map(({ url, displayOrder }) => (
            <div key={displayOrder} onClick={uploadPhotos}>
              <img src={url} alt='More views' />
            </div>
          ))}
        </Slider>
    )
  }
}
export default SlickSlider
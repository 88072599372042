import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import addDefaultSrc from '../helpers/addDefaultSrc'
import '../assets/stylesheets/page-shell.scss';
import '../assets/stylesheets/page-not-found.scss';

const propTypes = {
  dispatch: PropTypes.func,
}

class PageNotFound extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  }

  componentWillMount() {
    window.gtag('event', 'page_view', {
        'send_to': 'AW-668262331',
        'ecomm_pagetype':'other'
      });
  }
  
  render() {
    
    return (
      <div className="shell-container main-container main-container-404">
        <div className="page-not-found-container columns">
          <div className="column is-4 not-found-container">
              <h1 className="not-found-title">404</h1>
              <p className="not-found-text">Oops! We can't seem to find the page you're looking for.</p>
              <Link to="/" className="go-home-btn">GO TO HOME PAGE</Link>

          </div>
          <div className="column is-8">
            <img className="image-page-not" src="https://storage.googleapis.com/myphoto-img/not-found.png" alt="man holding empty frames" onError={addDefaultSrc}/>
          </div>
        </div>
      </div>
    );
  }

}

PageNotFound.propTypes = propTypes;


export default withRouter(connect((state) => {
  return {
    photos: state.photos.photos,
  }
})(PageNotFound))

import React, { Component } from 'react'
import MetaDataTags from '../helpers/ProcessMetaData'
import { seoInfo } from '../helpers/seoInfo'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import AboutLinks from '../components/common/AboutLinks'
import CTAsection from '../components/homepage/CTAsection'
import '../assets/stylesheets/about-us.scss'


const Content = {
  titleParagraph: "MyPhoto is a photo memory company built on core values that drive every decision we make. We’ve got passion, purpose and principles. Here’s what we know you deserve:",
  guaranteeShipping: [{
      title: "100% Money-Back Guarantee",
      icon: "./images/icons/Guarantee_Badge-01.svg",
      iconWidth: 100,
      text: "Be prepared for a big smile when you open your order. We stand behind both the photos printed as well as the products themselves. We're certain you'll love it, and that's why we can provide you a 60-Day Money Back Guarantee. If you're not happy and smiling, tell us and we'll take care of it. We promise.",
    },
    {
      title: "Fast, Free Shipping",
      icon: "./images/icons/Shipping_Plane-01.svg",
      iconWidth: 120,
      text: "A personalized, handcrafted product…sounds crazy right? Well, MyPhoto prides itself on being Crazy Easy. Crazy Fast! That's why we promise FREE SHIPPING on all orders over $59! The perfect last-minute gift solution!",
    }
  ],
  giftReadyCard:{ 
    imageDesktop: "./images/Desktop_Packaging2.png",
    title: "Gift-Ready Packaging",
    text: "Photos make the best gifts! They are personal, emotional, and meaningful. They can’t be re-gifted and are meant to be displayed, never stored away. So, we decided that every package should arrive carefully, expertly packed, worthy of a “WOW” as soon as the box is opened. Make your gifts even more personalized by adding a FREE, CUSTOM GIFT NOTE. Include a special message with every gift at no extra charge. ",
  },
  buyMoreCard: { 
    imageDesktop: "./images/BuyMore_SaveMore.png",
    title: "BUY MORE, SAVE MORE",
    text: "The perfect photo deserves to be shared which is why we’ll always give you 50% OFF all duplicates. Whether it’s giving a wedding photo to your bridesmaids, giving a reunion photo to the whole family, or giving a new baby photo to all the grandparents. Get 2, 5, 20 or more! Just make it happen at checkout. No codes required. Discounts are automatically applied.",
  },
}


class AboutUs extends Component {
  render(){
    const { photos } = this.props
    const hasPictures = photos.length > 0 && photos[0] && photos[0].width
    return(
      <div className="shell-container main-container">
        <MetaDataTags baseUrl='about-us' seoInfo={seoInfo.aboutUs} />
        <div className='about-us-container'>
          <AboutLinks title={'About Us'}/>
          <div className='about-us-content'>
            <p className='about-us-paragraph'>{Content.titleParagraph}</p>
            {Content.guaranteeShipping.map(card =>
              <div className='about-us-card'>
                <img src={card.icon} alt='' style={{ width: card.iconWidth }}/>
                <h3>{card.title}</h3>
                <p>{card.text}</p>
              </div>
            )}
          </div>
          <div className='gift-ready-card'>
            <div 
              className='image-container'
              style={{background: `url(${Content.giftReadyCard.imageDesktop}) no-repeat left center` }}
            ></div>
            <div className='about-text-container'>
              <h3>{Content.giftReadyCard.title}</h3>
              <p>{Content.giftReadyCard.text}</p>
            </div>
          </div>
          <div className='buy-more-card'>
            <div className='about-text-container'>
              <h3>{Content.buyMoreCard.title}</h3>
              <p>{Content.buyMoreCard.text}</p>
            </div>
            <div
              className='image-container'
              style={{background: `url(${Content.buyMoreCard.imageDesktop}) no-repeat right bottom` }}
            ></div>
          </div>
          <CTAsection hasPictures={hasPictures} />
        </div>
      </div>
    )
  }

}

AboutUs.contextTypes = {
  router: PropTypes.object.isRequired
}
function mapStateToProps(state) {
  return {
      photos: state.photos.photos,
  }
  
}
export default withRouter(connect(mapStateToProps)(AboutUs))
import React, { Fragment } from 'react'
import autoBind from 'react-autobind'
import { connect } from 'react-redux'
import { updateZoom, resetCroppedEdits, openModal, croppedParams, update_has_filter, setCroppedZoom, setCroppedEdits } from '../../actions'
import { path } from 'ramda'
import addDefaultSrc from '../../helpers/addDefaultSrc'
import Editor from './Editor'

const editButtonStyle1 = {
    width: '22px',
    height: '22px',
    position: 'absolute',
    left: 'calc(50% - 11px)',
    top: 'calc(50% - 11px)',
    zIndex: 1,
    border: 0,
}
const editButtonStyle2 = {
    width: '24px',
    height: '24px',
    position: 'absolute',
    left: '2px',
    bottom: '2px',
    zIndex: 1,
    border: 0,
}
const editButtonStyle3 = {
    width: '18px',
    height: '18px',
    position: 'absolute',
    left: 'calc(50% - 9px)',
    top: 'calc(50% - 9px)',
    zIndex: 1,
    border: 0,
}
const EditButton = ({ i, hasImage, productMiniCard }) => (
    <img style={(hasImage) ? editButtonStyle2 : productMiniCard ? editButtonStyle3 : editButtonStyle1}
        src={(hasImage) ? 'https://storage.googleapis.com/myphoto-img/icons/EDIT8.png' : 'https://storage.googleapis.com/myphoto-img/icons/add_photo_icon_home.svg'}
        onError={addDefaultSrc} alt="Customize" />
)
const calcAspect = (width, height) => width / height
const calcMaxZoom = (personalization, itemPrintData, isLandscape, i) => {
    // itemPrintData.printMinPpi = 150
    const maxWidth = personalization.actualSize.width / itemPrintData.printMinPpi
    const maxHeight = personalization.actualSize.height / itemPrintData.printMinPpi
    const productWidth = (isLandscape) ? itemPrintData.landscape[i].width : itemPrintData.portrait[i].width
    const productHeight = (isLandscape) ? itemPrintData.landscape[i].height : itemPrintData.portrait[i].height
    return parseInt(Math.min(maxWidth / productWidth, maxHeight / productHeight, 10), 10)
}
export class ThumbnailChild extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            edits: {},
            hasCropped: false,
            thumbUrlFilter: '',
            filter: '',
        }
        autoBind(this)
    }

    updateIndex(i) {
        const { dispatch, inlineEditorToggle } = this.props
        dispatch(updateZoom(i, true))
        dispatch(update_has_filter(false))
        dispatch(resetCroppedEdits())
        inlineEditorToggle()
    }
    onCropChange = crop => {
        this.props.dispatch(setCroppedEdits(crop))
    }
    onCropComplete = (croppedArea) => {
        this.setState({
            edits: {
                imageX: Number(croppedArea.x).toFixed(6),
                imageY: Number(croppedArea.y).toFixed(6), //(converting exponential values)
                imageWidth: Number(croppedArea.width).toFixed(6),
                imageHeight: Number(croppedArea.height).toFixed(6),
            },
            crop: this.props.crop,
            zoom: this.props.zoom,
        })
    }
    onZoomChange = zoom => {
        this.props.dispatch(setCroppedZoom(zoom))
    }
    onInteractionStart = () => {
        if (!this.state.hasCropped) {
            window.dispatchEvent(new Event('resize'))
            this.setState({ hasCropped: true })
        }
    }
    onInteractionEnd = () => {
        this.props.dispatch(croppedParams(this.state.edits, this.props.crop, this.props.zoom, this.state.filter))
    }
    changeUrlFilter(filter, handle, transformation) {
        this.setState({
            thumbUrlFilter: `https://cdn.filestackcontent.com/${transformation}/${filter}/${handle}`,
            filter,
        })
        this.props.dispatch(update_has_filter(true))
    }
    render() {
        const { item, project, useProject, i, photoUrl, edits, zoomed, isMultiphoto, dispatch, resetValues, saveEditedValues, zoomIndex,
            photopersonalization, lowResIndexArr, productMiniCard, inlineEditor, printPreview, handler, hasFilter, inlineEditorToggle, 
            overlayIsDisabled, selectedOverlay, overlayIsDisabledCart } = this.props
            console.log('isMultiphoto', isMultiphoto)
            console.log('overlayIsDisabled', overlayIsDisabled)
        const { thumbUrlFilter } = this.state
        const isLowRes = lowResIndexArr ? lowResIndexArr.includes(i) : null
        const overlayWidth = 870; // UPDATE or get dynamically <-- TODO:
        const overlayHeight = 607; // UPDATE or get dynamically <-- TODO:
        const personalization = (useProject !== false)
            ? project.photopersonalization[i]
                ? project.photopersonalization[i]
                : project.photopersonalization[0]
            : photopersonalization
        var thumbUrl = (useProject !== false)
            ? (personalization && personalization.thumbUrl
                ? personalization.thumbUrl
                : personalization.url)
            : photoUrl
        const hasImage = (useProject === false) ? thumbUrl : thumbUrl && project.photopersonalization[i]
        var thumbWidth = (useProject !== false) ? path(['thumbSize', 'width'], personalization) : path([i, 'images', 0, 'thumbSize', 'width'], photopersonalization)
        var thumbHeight = (useProject !== false) ? path(['thumbSize', 'height'], personalization) : path([i, 'images', 0, 'thumbSize', 'height'], photopersonalization)
        const isLandscape = (thumbWidth > thumbHeight)
        if (printPreview) { handler(isLandscape) } //passing props to ProntPreviewModal
        const preview = isLandscape ? item.preview.landscape[i] : item.preview.portrait[i]
        const useZoomOverlay = (zoomed || inlineEditor) && preview.zoomedHeight
        const printWidth = isLandscape ? item.printData.landscape[i].width : item.printData.portrait[i].width;
        const printHeight = isLandscape ? item.printData.landscape[i].height : item.printData.portrait[i].height;
        const rotate = (useProject !== false) ? personalization.rotation || 0
            : (edits && edits.rotation) ? edits.rotation : 0;
        const brightness = (useProject !== false) ? personalization.brightness || 1
            : (edits && edits.brightness) ? edits.brightness : 1;
        // Handle cropping (cart vs project)
        const imageIsWider = ((thumbWidth / thumbHeight) > (printWidth / printHeight))
        var imageWidth = 100;
        var imageHeight = 100;
        var imageX = 0;
        var imageY = 0;
        if (imageIsWider) {
            imageHeight = (useProject !== false)
                ? personalization && personalization.imageHeight
                    ? personalization.imageHeight
                    : 100
                : (edits && edits.imageHeight)
                    ? edits.imageHeight
                    : 100;
            imageY = (useProject !== false)
                ? personalization && personalization.imageY
                    ? personalization.imageY
                    : 0
                : (edits && edits.imageY)
                    ? edits.imageY
                    : 0;
            imageWidth = (useProject !== false)
                ? personalization && personalization.imageWidth
                    ? personalization.imageWidth
                    : printWidth * thumbHeight * 100 / (printHeight * thumbWidth)
                : (edits && edits.imageWidth)
                    ? edits.imageWidth
                    : printWidth * thumbHeight * 100 / (printHeight * thumbWidth);
            imageX = (useProject !== false)
                ? personalization.hasOwnProperty('imageX')
                    ? personalization.imageX
                    : (100 - imageWidth) / 2
                : (edits && edits.hasOwnProperty('imageX'))
                    ? edits.imageX
                    : (100 - imageWidth) / 2;
        } else {
            imageHeight = (useProject !== false)
                ? personalization && personalization.imageHeight
                    ? personalization.imageHeight
                    : printHeight * thumbWidth * 100 / (printWidth * thumbHeight)
                : (edits && edits.imageHeight)
                    ? edits.imageHeight
                    : printHeight * thumbWidth * 100 / (printWidth * thumbHeight);
            imageY = (useProject !== false)
                ? personalization.hasOwnProperty('imageY')
                    ? personalization.imageY
                    : (100 - imageHeight) / 3
                : (edits && edits.hasOwnProperty('imageY'))
                    ? edits.imageY
                    : (100 - imageHeight) / 3;
            imageWidth = (useProject !== false)
                ? personalization && personalization.imageWidth
                    ? personalization.imageWidth
                    : 100
                : (edits && edits.imageWidth)
                    ? edits.imageWidth
                    : 100;
            imageX = (useProject !== false)
                ? personalization && personalization.imageX
                    ? personalization.imageX
                    : 0
                : (edits && edits.imageX)
                    ? edits.imageX
                    : 0;
        }
        // calculate print aspect ratio
        var projectAspect = calcAspect(thumbWidth, thumbHeight)
        const aspectWidth = (useZoomOverlay) ? preview.zoomedWidth : preview.width
        const aspectHeight = (useZoomOverlay) ? preview.zoomedHeight : preview.height
        const aspect = calcAspect(aspectWidth, aspectHeight)
        const thumbAspect = calcAspect(thumbWidth, thumbHeight)
        const maxZoom = (useProject !== false && hasImage) ? Math.max(1.1, calcMaxZoom(personalization, item.printData, isLandscape, i)) : 3
        // check if cropped aspect ratio is same as print aspect ratio (only if cropped already)
        var new_x, new_y;
        if (useProject !== false && personalization && personalization.imageHeight) {
            //console.log('USER HAS ALREADY CROPPED IMAGE!!!!', personalization)
            // user has already cropped image:
            // calculate cropped aspect ratio
            projectAspect = calcAspect(personalization.imageWidth * thumbWidth, personalization.imageHeight * thumbHeight);
            // compare print to cropped aspect ratios to X decimals
            if (projectAspect.toFixed(1) !== aspect.toFixed(1)) {
                // if different, update w or h (choose based on imageIsWider)
                if (imageIsWider) {
                    // recalc imageWidth
                    imageWidth = personalization.imageHeight * aspect / thumbAspect
                    // recalc imageX
                    new_x = personalization.imageX - (imageWidth - personalization.imageWidth) / 2
                    if (new_x < 0) {
                        imageX = 0
                    } else {
                        if (new_x + imageWidth > 100) {
                            imageX = 100 - imageWidth
                        } else {
                            imageX = new_x
                        }
                    }
                } else {
                    // recalc imageHeight
                    imageHeight = personalization.imageWidth * thumbAspect / aspect
                    // recalc imageY
                    new_y = personalization.imageY - (imageHeight - personalization.imageHeight) / 2
                    if (new_y < 0) {
                        imageY = 0
                    } else {
                        if (new_y + imageHeight > 100) {
                            imageY = 100 - imageHeight
                        } else {
                            imageY = new_y
                        }
                    }
                }
            }
        } else {
            if (edits && edits.imageHeight) {
                //console.log('EDITS ON THUMBNAIL COMPONENT')
                // user has already cropped image:
                // calculate cropped aspect ratio
                projectAspect = calcAspect(edits.imageWidth * thumbWidth, edits.imageHeight * thumbHeight);
                // compare print to cropped aspect ratios to X decimals
                if (projectAspect.toFixed(1) !== aspect.toFixed(1)) {
                    // if different, update w or h (choose based on imageIsWider)
                    if (imageIsWider) {
                        // recalc imageWidth
                        imageWidth = edits.imageHeight * aspect / thumbAspect
                        // recalc imageX
                        new_x = edits.imageX - (imageWidth - edits.imageWidth) / 2
                        if (new_x < 0) {
                            imageX = 0
                        } else {
                            if (new_x + imageWidth > 100) {
                                imageX = 100 - imageWidth
                            } else {
                                imageX = new_x
                            }
                        }
                    } else {
                        // recalc imageHeight
                        imageHeight = edits.imageWidth * thumbAspect / aspect
                        // recalc imageY
                        new_y = edits.imageY - (imageHeight - edits.imageHeight) / 2
                        if (new_y < 0) {
                            imageY = 0
                        } else {
                            if (new_y + imageHeight > 100) {
                                imageY = 100 - imageHeight
                            } else {
                                imageY = new_y
                            }
                        }
                    }
                }
            }
        }
        const imgWdPct = 100.0 / (imageWidth / 100.0)
        const imgHtPct = 100.0 / (imageHeight / 100.0)
        const xPct = - imageX * imgWdPct / 100.0
        const yPct = - imageY * imgHtPct / 100.0
        const divXPct = (useZoomOverlay) ? 100 * preview.zoomedX / overlayWidth : 100 * preview.x / overlayWidth
        const divYPct = (useZoomOverlay) ? 100 * preview.zoomedY / overlayHeight : 100 * preview.y / overlayHeight
        const divWdPct = (useZoomOverlay) ? 100 * preview.zoomedWidth / overlayWidth : 100 * preview.width / overlayWidth
        const divHtPct = (useZoomOverlay) ? 100 * preview.zoomedHeight / overlayHeight : 100 * preview.height / overlayHeight
        const divPreviewWidth = item.previewShape ? (printWidth / item.printWidth) * 220 : 220
        const divPreviewHeight = item.previewShape
            ? item.printHeight === printHeight
                ? ((printHeight * 220) / printWidth)
                : (printHeight * divPreviewWidth) / printWidth
            : ((printHeight * 220) / printWidth)
        const overlay = (useProject !== false)
            ? path(['photopersonalization', 0, 'overlay', 'selectedOverlay'], project)
            : path([0, 'overlay', 'selectedOverlay'], photopersonalization)
        const divStyle = {
            width: !printPreview ? (divWdPct + '%') : (divPreviewWidth + 'px'),
            height: !printPreview ? (divHtPct + '%') : (divPreviewHeight + 'px'),
            position: 'absolute',
            verticalAlign: 'middle',
            border: 0,
            zIndex: !inlineEditor ? preview.z || 0 : 100,
            top: !printPreview ? divYPct + '%' : 0,
            left: !printPreview ? divXPct + '%' : 0,
            overflow: 'hidden',
        }
        const containerStyle = {
            width: divWdPct + '%',
            height: divHtPct + '%',
            position: 'absolute',
            border: 0,
            zIndex: !inlineEditor ? preview.z || 0 : 100,
            top: divYPct + '%',
            left: divXPct + '%',
            right: 'auto',
            bottom: 'auto',
            overflow: 'hidden',
            outline: '3px #6fa8ee dashed',
        }
        const showThumbUrl = hasFilter && thumbUrlFilter
            ? thumbUrlFilter
            : (!hasImage || (thumbUrl && thumbUrl.startsWith('blob')))
                ? thumbUrl
                : thumbUrl && thumbUrl.replace('http:', 'https:')
        const splitImage = showThumbUrl && showThumbUrl.split("/")
        const handle = splitImage && splitImage[splitImage.length - 1]
        const usedFilter = splitImage && splitImage[splitImage.length - 2]
        let transformation = 'rotate=deg:exif/resize=width:600,height:600'
        if (useProject === false) {
            if (personalization[0].images[0].thumbSize.width > personalization[0].images[0].thumbSize.height * 2) {
                transformation = 'rotate=deg:exif/resize=height:600'
            } else if (personalization[0].images[0].thumbSize.height > personalization[0].images[0].thumbSize.width * 2) {
                transformation = 'rotate=deg:exif/resize=width:600'
            }
        }
        var new_thumbUrl = (useProject === false) && (usedFilter === 'monochrome' || usedFilter === 'sepia' || usedFilter === 'enhance')
            ? `https://cdn.filestackcontent.com/${transformation}/${usedFilter}/${handle}`
            : showThumbUrl
        // console.log('---- SHOWTHUMBURL ---->', showThumbUrl)
        // console.log('---- thumbUrlFilter ---->', thumbUrlFilter )
        // console.log('---- new_thumbUrl ---->', new_thumbUrl)
        const imageIsWiderOverlay = thumbAspect > aspect
        const xFix = (imageIsWiderOverlay) ? (thumbAspect / aspect) : 1.
        const yFix = (imageIsWiderOverlay) ? 1. : (aspect / thumbAspect)
        const imageStyle = {
            width: 100. * xFix + '%',
            height: 100. * yFix + '%',
            maxWidth: 100. * xFix + '%',
            maxHeight: 100. * yFix + '%',
            left: (100. - (100. * xFix)) / 2. + '%',
            // top: (100. - (100. * yFix)) / 2. + '%',
        }
        const cropAreaStyle = {
            maxWidth: '100%',
            maxHeight: '100%',
            minWidth: '100%',
            minHeight: '100%',
        }
        const divClickStyle = {
            width: divWdPct + '%',
            height: divHtPct + '%',
            position: 'absolute',
            verticalAlign: 'middle',
            border: (!zoomed) ? (isLowRes) ? '3px solid #b00202' : 0 : 0,
            zIndex: inlineEditor ? 99 : 110,
            top: divYPct + '%',
            left: divXPct + '%',
            overflow: 'hidden',
            cursor: 'pointer',
        }
        const userImgStyle = {
            width: imgWdPct + '%',
            height: imgHtPct + '%',
            minWidth: imgWdPct + '%',
            minHeight: imgHtPct + '%',
            position: 'absolute',
            verticalAlign: 'middle',
            border: 0,
            zIndex: 0,
            top: yPct + '%',
            left: xPct + '%',
            filter: 'brightness(' + brightness + ')',
            transform: 'rotate(' + rotate + 'deg)',
        }
        const overlayStyle = {
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            zIndex: 10,
            position: 'absolute',
        }
        return (
            <Fragment>
                {(isMultiphoto) && <div style={divClickStyle} onClick={(e) => {
                    (hasImage)
                        ? this.updateIndex(i)
                        : dispatch(openModal('UPLOAD_MODAL'))
                }}></div>}
                {inlineEditor
                    ?
                    <Editor
                        showThumbUrl={new_thumbUrl}
                        personalization={personalization}
                        crop={this.props.crop}
                        zoom={this.props.zoom}
                        maxZoom={maxZoom}
                        zoomed={zoomed}
                        zoomIndex={zoomIndex}
                        onCropChange={this.onCropChange}
                        onCropComplete={this.onCropComplete}
                        onZoomChange={this.onZoomChange}
                        containerStyle={containerStyle}
                        imageStyle={imageStyle}
                        cropAreaStyle={cropAreaStyle}
                        dispatch={this.props.dispatch}
                        onInteractionEnd={this.onInteractionEnd}
                        onInteractionStart={this.onInteractionStart}
                        aspect={aspect}
                        inlineEditor={inlineEditor}
                        inlineEditorToggle={inlineEditorToggle}
                        saveEditedValues={saveEditedValues}
                        resetValues={resetValues}
                        selectedOverlay={selectedOverlay}
                        changeUrlFilter={this.changeUrlFilter} />
                    : <div style={divStyle}>
                        {(hasImage) &&
                            <Fragment>
                                {selectedOverlay && overlay && overlayIsDisabled === false && <img style={overlayStyle} // ProductForm
                                    src={overlay} alt="overlay" />}
                                {overlay && overlayIsDisabled === false && printPreview && <img style={overlayStyle} // PrintPreview
                                    src={overlay} alt="overlay" />}
                                {overlay && overlayIsDisabledCart === false && useProject === false && <img style={overlayStyle} // cart
                                    src={overlay} alt="overlay" />}
                                <img style={userImgStyle}
                                    className="frame-1 frame"
                                    src={new_thumbUrl} alt="Your beautiful upload" />
                            </Fragment>}
                        {(useProject !== false && ((!printPreview && isMultiphoto && (!hasImage || !zoomed)) || (!isMultiphoto && !hasImage))) &&
                            <EditButton {...{ i, hasImage, productMiniCard }} />
                        }
                    </div>}
            </Fragment>
        )
    }
}

export default connect()(ThumbnailChild)
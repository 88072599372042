import React from 'react'
import { Link } from 'react-router-dom'
import autoBind from 'react-autobind'
import addDefaultSrc from '../../helpers/addDefaultSrc'
import '../../assets/stylesheets/faq.scss'

class FaqBtn extends React.Component {
  constructor(props) {
      super(props)
      autoBind(this)
  }
  render() {
    const {categoryTitle, image, altText, imageHeight, link } = this.props
      return (
          <Link className='faq-btn' to={link}>
            <div className='faq-btn-img'><img  
            style={{height: imageHeight}}
            src={image} alt={altText} onError={addDefaultSrc}/>
            </div>
            <p className='faq-btn-text'>{categoryTitle}</p>
          </Link>
      )
  }
}

export default FaqBtn;
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { routerMiddleware } from 'react-router-redux'
import { createBrowserHistory } from 'history'
import { createLogger } from 'redux-logger'

// import root reducer
import rootReducer from './reducers'

// Create a browsing history
export const history = createBrowserHistory()

// set the initial state
const initialState = {}
const enhancers = []

// Build the middleware for intercepting and dispatching navigation actions
const middleware = [
  thunk,
  routerMiddleware(history),
]
// Configure the logger middleware
const logger = createLogger({
  level: 'info',
  collapsed: true,
})

// history.listen((location, action) => { setTimeout(window.scrollTo(0,0),100) })

// in development, open dev tools and run logger
if (process.env.NODE_ENV === 'development') {
  middleware.push(logger)
  const devToolsExtension = window.devToolsExtension
  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

// Apply our middleware for navigating
const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

// Add the reducer to our store on the `router` key
export default createStore(
    rootReducer,
    initialState,
    composedEnhancers
)
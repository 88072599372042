import React from 'react';
import PropTypes from 'prop-types';
import addDefaultSrc from '../../helpers/addDefaultSrc'

const toggleSelectPhoto = (index, updateSelectedPhotos, images) => {
  if (images[index] && typeof images[index] === 'object') {
    const imagesView = images;
    imagesView[index].isSelected = !imagesView[index].isSelected;
    const newPhotos = imagesView.map((data) => {
      if (!data.source && data.images) {
        return {
          isSelected: data.isSelected,
          photoUrl: data.images.standard_resolution.url,
        };
      }
      if (!data.source && data.thumbnailLink) {
        return {
          isSelected: data.isSelected,
          photoUrl: data.thumbnailLink.replace('=s220', ''),
          fileName: data.originalFilename,
        };
      }

      return {
        isSelected: data.isSelected,
        photoUrl: data.source,
      };
    });
   
    updateSelectedPhotos(newPhotos[index]);
  }
};

const addPhotoToList = (photos, updateSelectedPhotos, socialMedia) => {
  const newPhotos = photos
    .filter(photo => photo.isSelected)
    .map(photo => {
      const { isSelected, source, thumbnailLink, images, originalFilename } = photo;
      // Instagram doesn't have a source attribute but stores different urls
      // in the images attribute
      if (!photos && !(photos.source) && images) {
        return {
          isSelected,
          photoUrl: getBg(photo, socialMedia),
        };
      }

      if (!!photos && !(photos.source) && images) {
        return {
          isSelected,
          photoUrl: getBg(photo, socialMedia),
        };
      }
   
      if (!source && thumbnailLink) {
        return {
          isSelected,
          photoUrl: thumbnailLink.replace('=s220', ''),
          fileName: originalFilename,
        };
      }
      // TODO: See if this return makes sense. Seems like first `if` is kind of useless.
      return {
        
        isSelected,
        photoUrl: source,
      };
    });
  updateSelectedPhotos(newPhotos);
};

const propTypes = {
  images: PropTypes.array,
  updateSelectedPhotos: PropTypes.func,
  source: PropTypes.string,
  multiPhoto: PropTypes.bool,
}

const getBg = (photo, source) => {
  switch(source){
    case 'instagram':
      return photo.images.standard_resolution.url;
    case 'facebook':
      return photo.source;
    case 'google':
      return photo.thumbnailLink.replace('=s220', '');
    default:
      return null;
  }
}

const ImageViewer = ({ images, updateSelectedPhotos, source }) => {
  if (source === 'facebook' || source === 'instagram' || source === 'google') {
    return (
      <div className="photo__upload__list__holder ">
      <div className="photo-upload-container has-text-centered">
        {images && images.filter(photo => photo.isSelected).length ?
        
          <span className="photos__action__limit">
            <button
              onClick={(e) => {
                e.preventDefault();
                addPhotoToList(images, updateSelectedPhotos, source);
              }}
              className="button button-upload-images"
            >
              Upload Selected Photos
            </button>
          </span> : 
          <span className="photos__action__limit">
            <button
              disabled
              className="button button-disabled"
            >
              Select Photos
            </button>
          </span>
        }
        </div>
        <div className="columns photo__upload__list" style={{flexWrap: 'wrap', justifyContent: 'space-around'}}>        
          {images && images.map((photo, index) => {
            return (
              <div
                key={index}
                className={`column is-4  ${photo.isSelected ? 'selected' : ''}`}
                style={{margin:'5px'}}
                onClick={() => {
                  toggleSelectPhoto(index, updateSelectedPhotos, images);
                }}
              >
              <span className="photo__upload__item">
                <div className="photo__upload__image"
                      style={{
                      backgroundPosition:'center',
                      backgroundClip: 'padding-box',
                      backgroundSize: 'contain',
                      backgroundImage: `url(${getBg(photo, source)})`,
                      backgroundRepeat: 'no-repeat',
                      height: '140px'
                    }}
                  > <div
                  
                  style={{ visibility: (photo.isSelected) ? 'visible' : 'hidden' }}>
                  <img className="check-mark-icon" onError={addDefaultSrc} src="https://storage.googleapis.com/myphoto-img/icons/check-mark-icon.svg" alt="check mark" />
                </div>       
                </div>
                </span>
              </div>
            );
          })}
        </div>      
    </div>);
  }
  return null;
};


ImageViewer.propTypes = propTypes;

export default ImageViewer;


let ScrollerArea = function( el ){
  if( el.dataset.type === 'scrollarea'){
    return;
  }
  // console.log('create');

  this.el = el;
  this.scroller = this.el.querySelector('.scroll_container');
  // this.scroller.style.cssText = 'position:absolute;display:flex;left:20px;top:0;z-index:1;overflow-x:auto;width:calc(100% - 40px);scroll-behavior: smooth;';
//   this.scroller.scrollLeft = 0;

  this.el.style.cssText = 'width:100%;position:relative;overflow:hidden';
  this.el.dataset.type = 'scrollarea';
  let t = (this.el.classList.contains('overlay-carousel')? '14px' : '0');

  this.larr = document.createElement('div');
  this.larr.setAttribute('class', 'l-arr');
  this.larr.setAttribute('style', `position:absolute;z-index:10;width:20px;height:42px;left: 0; top:${t};background: #fff url("https://storage.googleapis.com/myphoto-img/reviews-carousel/Arrow2.svg") left center no-repeat;background-size: auto 22px;cursor:pointer;`);
  this.el.appendChild( this.larr );

  this.rarr = document.createElement('div');
  this.rarr.setAttribute('class', 'r-arr');
  this.rarr.setAttribute('style', `position:absolute;z-index:10;width:20px;height:42px;right: 0; top:${t};background: #fff url("https://storage.googleapis.com/myphoto-img/reviews-carousel/Arrow1.svg") right center no-repeat;background-size: auto 22px;cursor:pointer;`);
  this.el.appendChild( this.rarr );

  this.elb = this.el.getBoundingClientRect();
  this.aw = this.elb.width;
  let b = this.scroller.getBoundingClientRect();
  this.lx = b.left;
  this.cw = parseInt(b.width);
  this.dragging = false;
  this.startX = 0;

  this.mleft = this.mleft.bind(this);
  this.mright = this.mright.bind(this);
  this.larr.addEventListener('click', this.mleft, false );
  this.rarr.addEventListener('click', this.mright, false );
  this.anim = this.anim.bind(this);
  this.destroy = this.destroy.bind(this);
  this.mdown = this.mdown.bind(this);
  this.mmove = this.mmove.bind(this);
  this.mup = this.mup.bind(this);

  this.el.addEventListener('mousedown', this.mdown, true );
//   this.update_arrows = this.update_arrows.bind(this);
//   this.update_arrows();

  return this;
}
ScrollerArea.prototype.update_arrows = function(){
    this.larr.classList.remove('disabled');
    this.rarr.classList.remove('disabled');
    if( this.scroller.scrollLeft === 0 ){
        this.larr.classList.add('disabled');
    }
    if( this.scroller.scrollLeft === (this.scroller.scrollWidth-this.cw) ){
        this.rarr.classList.add('disabled');
    }
}
ScrollerArea.prototype.mleft = function(e ){
  this.scrollTarg = Math.max( 0, this.scroller.scrollLeft - 210 );
  this.diff = (this.scrollTarg - this.scroller.scrollLeft)/14;
  if( this.raf ){
    cancelAnimationFrame( this.raf );
    }
  this.raf = requestAnimationFrame( this.anim );
//   this.update_arrows();
}
ScrollerArea.prototype.mright = function(e ){
  this.scrollTarg = Math.min( this.scroller.scrollLeft + 210, this.scroller.scrollWidth-this.cw );

  this.diff = (this.scrollTarg - this.scroller.scrollLeft)/14;
  if( this.raf ){
    cancelAnimationFrame( this.raf );
    }
  this.raf = requestAnimationFrame( this.anim );
//   this.update_arrows();
}
ScrollerArea.prototype.mdown = function( e ){
  window.addEventListener('mouseup', this.mup, true );
  this.el.addEventListener('mousemove', this.mmove, false );
  this.moved = false;
  this.dragging = true;
  this.startX = e.pageX - parseInt(this.elb.left) + this.scroller.scrollLeft;

  e.preventDefault();
  e.stopPropagation();
  e.stopImmediatePropagation();
}
ScrollerArea.prototype.mup = function( e ){
  this.el.removeEventListener('mousemove', this.mmove, false );
  window.removeEventListener('mouseup', this.mup, true );
  this.dragging = false;
  e.preventDefault();
  if( this.moved ){
    e.stopPropagation();
  }
  this.update_arrows();
}
ScrollerArea.prototype.mmove = function( e ){
  if( this.dragging ){
    this.moved = true;
    let diff = (e.pageX-parseInt(this.elb.left)) - this.startX;
    this.scroller.scrollLeft = -diff;
  }
}
ScrollerArea.prototype.anim = function( tm ){
    if( Math.abs( this.scrollTarg - this.scroller.scrollLeft) > 5 ){
        this.scroller.scrollLeft += this.diff;
        this.raf = requestAnimationFrame( this.anim );
    } else {
        this.scroller.scrollLeft = this.scrollTarg;
        cancelAnimationFrame( this.raf );
        this.update_arrows();
    }
}
ScrollerArea.prototype.destroy = function(){
  // console.log('destroy');
  this.el.removeEventListener('mousedown', this.mdown, true );
  this.el.removeEventListener('mousemove', this.mmove, false );
  window.removeEventListener('mouseup', this.mup, true );

  this.larr.removeEventListener('click', this.mleft, false );
  this.rarr.removeEventListener('click', this.mright, false );
  this.el.removeChild( this.larr );
  this.el.removeChild( this.rarr );
  this.el.removeAttribute('data-type');
}

export default ScrollerArea;
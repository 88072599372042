import React from 'react'
import { Field } from 'formik'

function CalculateRows(txtArea) {
  var maxChars = txtArea.value.length > 7 * 40
  var textAreaSplitted = txtArea.value.split("\n")
  var numLines = textAreaSplitted.reduce((accum, line) =>{
    var lineLength = line === '' ? 1 : line.length
    return accum + Math.ceil(lineLength/40)
  },0)
  if((numLines > 7) || maxChars ) {
    txtArea.value = txtArea.value.substr(0,txtArea.value.length-1)
  }
}

const GiftNote = ({ linkStyle, handleGiftNote, showGiftbox, errors }) => {
  var txtArea = document.getElementById('ta')
  return (
    <div className="checkout-note-container">
    <p className="gift-note-text">
      <img src="/images/icons/gift.svg" alt="gift icon" className="gift-icon" />
      Sending a gift?
      <button
        className="promo-gift-btns"
        onClick={(e) => { handleGiftNote(e) }}
        style={linkStyle}>
        Add a Gift Note
      </button>
      for free.</p>
    {(showGiftbox) ?
      <article className="checkout__gift__note__holder">
        <span className='alert__message'>{errors.giftNote}</span>
        <Field
          className='gift__note__text__area'
          name='giftNote'>
            {({ field }) => (
              <textarea
                style={{lineHeight: '20px'}}
                id='ta'
                className='textarea-giftnote'
                {...field} 
                error={errors.giftNote}
                rows='7'
                placeholder=" Dear Mom, This is my favorite... (7 line max)"
                onKeyUp={e=> {e.preventDefault(); CalculateRows(txtArea)}}
                />
          )}
          </Field>
      </article>
      : null}
  </div>
  )
}

export default GiftNote;
import { isEmpty, isMobilePhone, isEmail, isPostalCode } from 'validator'
import {
  map,
  curry,
  fromPairs,
  adjust,
  toPairs,
  contains,
  not,
  filter,
  isEmpty as RIsEmpty
} from 'ramda'

// from ramda cookbook https://github.com/ramda/ramda/wiki/Cookbook#map-keys-of-an-object-rename-keys-by-a-function
export const mapKeys = curry((fn, obj) => fromPairs(map(adjust(fn, 0), toPairs(obj))))

const pattern = new RegExp(/^[a-zA-Z0-9- ,_!&()\r\n'^{}|<>.*%$#@?\u2018\u2019\u201C\u201D\u2013\u2014]*$/)
const emailPattern = new RegExp(/^[a-zA-Z0-9- ,@.!#$%&'*+-/=?^_`{|}~\r\n\u2018\u2019\u201C\u201D\u2013\u2014]*$/)

export const validateIsNotEmpty = value => isEmpty(value) ? 'Please enter a value.' : !pattern.test(value) ? 'Please remove emoji and other special characters.' : ''

export const validatePhone = value => !isMobilePhone(value, ['en-US']) ? 'Please enter a valid phone number.' : ''

export const validateEmail = value => !isEmail(value) 
      ? 'Please enter a valid email address.' 
      : !emailPattern.test(value) 
        ? 'Please remove emoji and other special characters.'
        : ''

export const validateGiftNote = value => {
  var maxChars = value.length > 7 * 40
  var textAreaSplitted = value.split("\n")
  var numLines = textAreaSplitted.reduce((accum, line) =>{
    var lineLength = line === '' ? 1 : line.length
    return accum + Math.ceil(lineLength/40)
  },0)
  if((numLines > 7) || maxChars ) {
    value = value.substr(0,value.length-1)
    if(numLines > 7){
      return "Please enter 7 lines or less (280 characters max)"
    }
    if(maxChars){
      return "Please enter 280 characters or less"
    }
  } else if(!pattern.test(value)){
    return 'Please remove emoji and other special characters.'
  } else {
    return ''
  }
}

const isShippingValid = value => {
  let errors = {}
  mapKeys(key => {
    if (key === 'zip') {
      errors[key] = !isPostalCode(value[key], ['US']) ? 'Please enter a valid US zipcode' : ''
    } else if (key !== 'address2') {
      errors[key] = isEmpty(value[key]) ? 'Please enter a value' : !pattern.test(value[key]) ? 'Please remove emoji and other special characters.' : ''
    } else if (key === 'address2') {
      errors[key] =  !pattern.test(value[key]) ? 'Please remove emoji and other special characters.' : ''
    }
  }, value)
  const res = filter(item => not(RIsEmpty(item)), errors)
  return res
}

export const panelValidationMap = {
  name: value => validateIsNotEmpty(value),
  email: value => validateEmail(value),
  lastName: value => validateIsNotEmpty(value),
  phone: value => validatePhone(value),
  shipping: value => isShippingValid(value),
  giftNote: value => validateGiftNote(value)
}

const fieldsToValidate = ['name', 'email', 'lastName', 'phone', 'shipping', 'giftNote']

export const validatePanel = (values) => {
  let errors = {}
  mapKeys((key) => {
    if (contains(key, fieldsToValidate)) {
      errors[key] = panelValidationMap[key](values[key])
    }
  }, values)
  const res = filter(item => not(RIsEmpty(item)), errors)
  return res
}

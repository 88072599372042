import React from 'react'

function TabBar(props) {
  return (
     <div className="bottom-nav-bar-container is-hidden-desktop is-hidden-tablet">
      <nav className="tab-bar-container">
        {props.children}
      </nav>
    </div>
  )
}

export default TabBar
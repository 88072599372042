import React from 'react'
import addDefaultSrc from '../../helpers/addDefaultSrc'
import './footer.scss'

function FeaturedOn() {
  const h3style = {
    margin: 'auto'
  }
  return (
    <div className="container has-text-centered">
      <div className="row content container has-text-centered">
        <h3 className="footer-title is-fullwidth" style={h3style}>AS FEATURED ON</h3>
      </div>
      <div className="as-featuredon-container">
        <div className="buzz-logo">
            <img
                onError={addDefaultSrc}
                src="https://storage.googleapis.com/myphoto-img/logos/logosArtboard-5.2.png" alt="Megyn Kelly TODAY logo" />
        </div>
        <div className="buzz-logo">
            <img
                onError={addDefaultSrc}
                src="https://storage.googleapis.com/myphoto-img/logos/logosArtboard-6.png" alt="as seen on NBC News" />
        </div>
        <div className="buzz-logo">
            <img
                onError={addDefaultSrc}
                src="https://storage.googleapis.com/myphoto-img/logos/logosArtboard-7.png" alt="as seen on NBC News" />
        </div>
        <div className="buzz-logo">
            <img
                onError={addDefaultSrc}
                src="https://storage.googleapis.com/myphoto-img/logos/logosArtboard-3.png" alt="Inc. logo" />
        </div>
        <div className="buzz-logo">
            <img
                onError={addDefaultSrc}
                src="https://storage.googleapis.com/myphoto-img/logos/logosArtboard-2.png" alt="lifetime logo" />
        </div>
        <div className="buzz-logo">
            <img
                onError={addDefaultSrc}
                src="https://storage.googleapis.com/myphoto-img/logos/logosArtboard-1.png" alt="the Blanacing Act logo" />
        </div>
      </div>
    </div>
  )
}

export default FeaturedOn
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import addDefaultSrc from '../../helpers/addDefaultSrc'
import '../../assets/stylesheets/promo-bar-modal.scss'

const Content = {
	title: 'Get',
	titleBold: '50% off',
	text1: 'All duplicates. As many as you want.',
	subText: 'same photo - same product - same address',
	text2: 'Increase QTY to 2 or more and discount will be automatically applied.',
	image1: 'https://storage.googleapis.com/myphoto-img/QTY-doubleup.png',
	image2: 'https://storage.googleapis.com/myphoto-img/BambooMiiniBlox_2x.png',
}
class PromoBarInfoModal extends React.Component {
	static contextTypes = {
		router: PropTypes.object
	}
	constructor(props) {
		super(props);
		this.state = {
		}
	}
	render() {
		return (
			<div className='shell-container main-container-double-up'>
				<div className='double-up-container'>
					<div className='double-up-text-container'>
						<h1 className='double-up-title'>{Content.title}<span className="semi-bold">&nbsp;{Content.titleBold}</span></h1>
						<h3 className='double-up-text1'>{Content.text1}</h3>
						<p className='double-up-subtext'>{Content.subText}</p>
						<h3 className='double-up-text2'>{Content.text2}</h3>
						<img className="double-up-qty-image" onError={addDefaultSrc} src={Content.image1} alt="quantity select" />
					</div>
					<div className='double-up-example-container'>
						<img className="double-up-qty-image" onError={addDefaultSrc} src={Content.image2} alt="two of the same ornament" />
						<div className='double-up-example'>
							<h3 className='double-up-example-title'>Example:</h3>
							<div className='double-up-example-text-container'>
								<div className='double-up-example-column1'>
									<p className='double-up-example-text'>If one is&nbsp;</p>
									<p className='double-up-example-price'>$16</p>
								</div>
								<div className='double-up-example-column2'>
									<p className='double-up-example-text'>Get two of the same for</p>
									<p className='double-up-example-price'>
										<span className='double-up-example-crossed'>$32</span>&nbsp;
										<span className='double-up-example-red'>$24</span>
									</p>
								</div>
							</div>
							<p className='double-up-example-text-2'>Applies to&nbsp;<span className='bold'>any</span> product!</p>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
function mapStateToProps(state) {
	return {
	};
}
export default withRouter(connect(mapStateToProps)(PromoBarInfoModal))

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Dropzone from "react-dropzone";
import autoBind from "react-autobind";
import _ from "lodash";
import {
  MobileOnlyView,
  TabletView,
  isMobile,
  isTablet,
  isBrowser,
} from "react-device-detect";
import {
  setSelectedPhoto,
  clearPhotoServicesSelections,
  social_media,
  closeModal,
  new_user_initial_upload,
  filestack_final_uploads,
  filestack_initial_uploads,
  filestack_social_uploads,
  update_project_initial,
} from "../../actions/";
import PhoneImport from "../upload/PhoneImport";
import InstagramLogin from "../upload/InstagramImport";
import { FilestackMyComputer, FilestackUploader } from "./FileStackUpload";
import { OldInstagramUpload, OldDropzoneUpload } from "./CustomUpload";
import getImageActualSize from "../../helpers/getImageActualSize";
import addDefaultSrc from "../../helpers/addDefaultSrc";
import "./photo-picker/photo-upload.scss";
import "../../assets/stylesheets/page-shell.scss";

const propTypes = {
  dispatch: PropTypes.func,
  uploaded: PropTypes.bool,
  changeProductPhoto: PropTypes.bool, // Selected product to be changed.
  wsConnection: PropTypes.bool, // Device upload connection key
  user: PropTypes.object,
  project: PropTypes.object,
  zoomed: PropTypes.bool,
  zoomIndex: PropTypes.number,
  isMultiphoto: PropTypes.bool,
  photos: PropTypes.array,
};

const clientOptions = {
  sessionCache: true,
};
const client = window.filestack.init("AJPkbXMCpSHWIMU3KY6Ryz", clientOptions);

class PhotoPicker extends React.Component {
  static contextTypes = {
    router: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.state = {
      selector: "",
      selectedPhoto: {},
      loading: false,
      filesDone: false,
      photos: props.photos,
      textIndex: 0,
      intervalFunc: null,
      blobFiles: "",
      selectedPhotoIds: [],
    };
    autoBind(this);
  }

  componentDidMount() {
    window.obApi("track", "Upload");
    var filestackDiv = document.querySelector("#filestack-div");
    if (filestackDiv) {
      var image = filestackDiv.getElementsByTagName("img")[0];
      const that = this;
      if (image) {
        image.addEventListener("load", function() {
          that.handleDropzone();
        });
      } else {
        this.selectorType("myComputer");
      }
    } else {
      this.selectorType("myComputer");
    }
  }

  componentWillUpdate(nextProps) {
    const { uploaded, failedUpload } = nextProps;
    if (uploaded && !failedUpload) {
      this.setState({
        filesDone: true,
      });
    }
  }
  componentWillUnmount() {
    this.props.dispatch(clearPhotoServicesSelections());
    clearInterval(this.state.intervalFunc);
  }

  handleDropzone() {
    const that = this;
    const selectorContainer =
      isMobile || isTablet ? "#mobile-filestack" : "#filestack-div";
    client
      .picker({
        uploadInBackground: true,
        exposeOriginalFile: true,
        disableTransformer: true,
        fromSources: ["local_file_system"],
        container: selectorContainer, // can be CSS selector string or DOM node
        displayMode: "dropPane",
        maxFiles: 20,
        // customAuthText: {
        //   // use it for every cloud authentication screen
        //   default: {
        //     bottom: [
        //       'MyPhoto needs your permission to access your data and',
        //       'process it with our machine learning system.'
        //     ]
        //   }
        // },
        accept: [".jpeg", ".jpg", ".png", ".gif"],
        // onFileUploadProgress: ((metadata, event) => {
        //   if (event.totalPercent > 0 && event.totalPercent < 100) {
        //     const this_blob = this.state.blobFiles.find(f => f.uploadId === metadata.uploadId)
        //     //this.props.dispatch(setProgressBar(this_blob.url, event.totalPercent))
        //   }
        // }),
        onFileUploadFinished: (res) => {
          this.filestackUpload(res); // to update photos
        },
        onUploadStarted: (files) => {
          const new_files = files.map((f) => {
            const blobUrl = URL.createObjectURL(f.originalFile);
            return {
              url: blobUrl,
              name: f.filename,
              size: f.size,
              source: f.source,
              uploadId: f.uploadId,
            };
          });
          this.handleBlobUploads(new_files);
        },
        dropPane: {
          overlay: false, // if this is true then overlays will conflict between instances
          onSuccess: function(res) {
            if (!res.length) {
              that.selectorType("myComputer");
            }
          },
        },
      })
      .open()
      .catch((e) => {
        console.log("Filestack Failed to load!", e);
        this.selectorType("myComputer");
      });
  }
  filestackUpload(uploadedFile) {
    const { user, dispatch } = this.props;
    const user_id = user ? user.id : null;
    const { blobFiles } = this.state;
    const this_blob = blobFiles.find(
      (b) => b.uploadId === uploadedFile.uploadId
    );
    dispatch(
      filestack_final_uploads(
        uploadedFile,
        uploadedFile.url,
        this_blob.url,
        user_id
      )
    );
  }
  handleBlobUploads(files) {
    const { user, dispatch } = this.props;
    const user_id = user && user.id ? user.id : null;
    this.setState({
      blobFiles: files,
    });
    dispatch(closeModal("UPLOAD_MODAL"));
    dispatch(filestack_initial_uploads(files, user_id));
    if (!window.location.pathname.startsWith("/products")) {
      this.context.router.history.push("/products");
    } else {
      document.body.scrollTop = 0;
    }
  }

  filestackSocialUpload(files) {
    const { user, dispatch } = this.props;
    const uploadFiles = files.filesUploaded;
    const user_id = user ? user.id : null;
    dispatch(closeModal("UPLOAD_MODAL"));
    dispatch(filestack_social_uploads(uploadFiles, user_id));
    if (!window.location.pathname.startsWith("/products")) {
      this.context.router.history.push("/products");
    } else {
      document.body.scrollTop = 0;
    }
  }
  onDrop(files) {
    const {
      dispatch,
      user,
      zoomed,
      zoomIndex,
      project,
      isMultiphoto,
    } = this.props;
    const user_id = user ? user.id : null;
    // TODO: Make the source below dynamic
    this.setState({
      files: files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    });
    const zIndex =
      project &&
      project.project &&
      project.project.photopersonalization &&
      project.project.photopersonalization.length &&
      zoomIndex < project.project.photopersonalization.length
        ? zoomIndex
        : 0;
    dispatch(
      new_user_initial_upload(
        files,
        "desktop",
        user_id,
        zoomed,
        zIndex,
        isMultiphoto
      )
    );
    localStorage.setItem("imagePreview", null);
    this.setState({ loading: true, filesDone: false });
    dispatch(closeModal("UPLOAD_MODAL"));
    // if not on a category or product page, when uploading, go to all products
    if (!window.location.pathname.startsWith("/products")) {
      this.context.router.history.push("/products");
    } else {
      document.body.scrollTop = 0;
    }
  }
  selectorType(value) {
    this.setState({
      selector: value,
    });
  }
  addPhotosTo(selectedPhoto, source = "facebook") {
    const { user, dispatch } = this.props;
    if (_.isArray(selectedPhoto)) {
      const user_id = user ? user.id : null;
      dispatch(social_media(selectedPhoto, source, user_id));
      if (!window.location.pathname.startsWith("/products")) {
        this.context.router.history.push("/products");
      } else {
        document.body.scrollTop = 0;
      }
    } else if (this.props.changeProductPhoto) {
      getImageActualSize(selectedPhoto.photoUrl).then(() => {
        localStorage.setItem("imagePreview", null);
        if (!window.location.pathname.startsWith("/products")) {
          this.context.router.history.push("/products");
        } else {
          document.body.scrollTop = 0;
        }
      });
    } else {
      // This dispatch is only for the UI to update when a photo has been picked.
      this.props.dispatch(setSelectedPhoto(selectedPhoto));
    }
  }
  backBtn() {
    return (
      <button
        style={{
          fontfamily: "Open Sans",
          fontSize: "16px",
          color: "#686868",
          textTransform: "uppercase",
          border: "none",
          background: "none",
          textAlign: "left",
          paddingLeft: "0",
        }}
        onClick={(e) => {
          e.preventDefault();
          this.setState({
            selector: "",
          });
        }}
      >
        &lt; back
      </button>
    );
  }
  uploadFromPhone() {
    return (
      <button
        className="button-upload button-phone button-phone-browser"
        onClick={() => {
          this.setState({
            selector: "external",
          });
        }}
      >
        <span className="icon-container">
          <img
            className="phone-icon icon"
            alt=""
            onError={addDefaultSrc}
            src={
              "https://storage.googleapis.com/myphoto-img/icons/photopicker/Icon_PHONE-02.svg"
            }
          />
        </span>
        <span className="upload-btn-text">My Phone/ Camera Roll</span>
      </button>
    );
  }
  renderMobileTabletBtn() {
    return (
      <div
        className="upload-buttons-container"
        style={{ marginBottom: "10px" }}
      >
        {this.state.selector === "myComputer" ? (
          <Dropzone
            ref="dropzone"
            accept="image/jpeg, image/png, image/gif"
            multiple
            onDrop={this.onDrop}
            className="photouploader"
            style={{ width: "100%" }}
          >
            <MobileOnlyView>
              <button
                className="button-upload button-phone button-phone-mobile-only"
                style={{ width: "100%" }}
              >
                <span className="icon-container">
                  <img
                    className="phone-icon icon"
                    alt=""
                    onError={addDefaultSrc}
                    src={
                      "https://storage.googleapis.com/myphoto-img/icons/photopicker/Icon_PHONE-02.svg"
                    }
                  />
                </span>
                <span className="upload-btn-text"> My Phone/Camera Roll </span>
              </button>
            </MobileOnlyView>
            <TabletView>
              <button
                className="button-upload button-computer"
                style={{ width: "100%" }}
              >
                <span className="icon-container">
                  <img
                    className="phone-icon icon"
                    alt=""
                    onError={addDefaultSrc}
                    src={
                      "https://storage.googleapis.com/myphoto-img/icons/photopicker/Icon_TABLET-2.svg"
                    }
                  />
                </span>
                <span className="upload-btn-text">My Tablet</span>
              </button>
            </TabletView>
          </Dropzone>
        ) : (
          <div id="mobile-filestack" style={{ width: "100%" }}>
            <FilestackMyComputer
              client={client}
              filestackUpload={this.filestackUpload}
              addDefaultSrc={addDefaultSrc}
              handleBlobUploads={this.handleBlobUploads}
              selectorType={this.selectorType}
            />
          </div>
        )}
      </div>
    );
  }
  renderBrowserBtn() {
    return (
      <div className="uploader__item" style={{ marginBottom: "10px" }}>
        {this.state.selector === "myComputer" ? ( // Computer Uploads
          <Dropzone
            ref="dropzone"
            accept="image/jpeg, image/png, image/gif"
            multiple
            onDrop={this.onDrop}
            className="photouploader"
          >
            <OldDropzoneUpload
              selectorType={this.selectorType}
              addDefaultSrc={addDefaultSrc}
            />
          </Dropzone>
        ) : (
          <FilestackMyComputer
            client={client}
            filestackUpload={this.filestackUpload}
            addDefaultSrc={addDefaultSrc}
            handleBlobUploads={this.handleBlobUploads}
            selectorType={this.selectorType}
          />
        )}
        {(!isMobile || !isTablet) && this.uploadFromPhone()}
      </div>
    );
  }
  toggleSelectedImage(photo) {
    const { selectedPhotoIds } = this.state;
    if (selectedPhotoIds.includes(photo.id)) {
      // remove selected image
      const new_selectedPhotoIds = selectedPhotoIds.filter(
        (p) => p !== photo.id
      );
      this.setState({ selectedPhotoIds: new_selectedPhotoIds });
    } else {
      // add selected image
      this.setState({ selectedPhotoIds: selectedPhotoIds.concat(photo.id) });
    }
  }
  addSelectedImages() {
    const { photos, dispatch } = this.props;
    const { selectedPhotoIds } = this.state;
    const selectedPhotos = photos.filter((p) =>
      selectedPhotoIds.includes(p.id)
    );
    const new_selectedPhotos = selectedPhotos.map((p) => {
      const this_imageUrl = p.image_url || p.imageUrl;
      var newFileName = this_imageUrl.replace(/[^0-9a-zA-Z.]/g, "");
      return {
        actualSize: {
          width: p.width,
          height: p.height,
        },
        thumbSize: {
          width: p.thumb_width || p.thumbWidth,
          height: p.thumb_height || p.thumbHeight,
        },
        url: this_imageUrl,
        originalUrl: this_imageUrl,
        fileName: newFileName,
        fileSize: p.file_size || p.fileSize,
        thumbUrl: p.thumb_url || p.thumbUrl,
        handle: p.handle,
      };
    });
    dispatch(update_project_initial(new_selectedPhotos));
    this.setState({
      selectedPhotoIds: [],
    });
    if (
      window.location.pathname === "/" ||
      window.location.pathname.startsWith("/faq") === "/" ||
      window.location.pathname.startsWith("/faq") ||
      window.location.pathname.startsWith("/about-us") ||
      window.location.pathname.startsWith("/our-story") ||
      window.location.pathname.startsWith("/how-to-myphoto") ||
      window.location.pathname.startsWith("/order-status") ||
      window.location.pathname.startsWith("/reviews")
    ) {
      this.context.router.history.push("/products");
    }
    this.props.onClose();
  }
  render() {
    const { selectedPhotoIds } = this.state;
    if (this.state.selector === "instagram" && typeof window !== "undefined") {
      const redirectUri = window.location.origin;
      return (
        <section
          className="photo-upload-container upload-container"
          style={{ height: "80vh" }}
        >
          <header className="main__header photo-upload-header">
            {this.backBtn()}
            <h2 className="main__title photo-upload-title icon-instagram">
              Instagram Photos
            </h2>
            <p className="center">Select one or more photos to upload.</p>
          </header>
          <article className="images__selection__container">
            <InstagramLogin
              appId="1d1af87188ba4723a8892a06b5f3eecb" // new ID
              callback={(data) => {
                if (data) {
                  this.setState({
                    selector: "",
                  });
                }
              }}
              redirectUri={redirectUri}
              updateSelectedPhotos={(selectedInstagram) => {
                this.addPhotosTo(selectedInstagram, "instagram");
              }}
            />
          </article>
        </section>
      );
    }
    if (this.state.selector === "external" || this.props.wsConnection) {
      return (
        <section className="main__container upload-container">
          <header
            className={`main__header photo-upload-header ${
              this.props.wsConnection ? "padd-off" : ""
            }`}
          >
            {!this.props.wsConnection ? this.backBtn() : null}
            <p className="main__title photo-upload-title icon-phone_iphone">
              How to order from your computer <br />
              with photos on your phone:{" "}
              <span className="bold">It’s Crazy Easy!</span>
            </p>
          </header>
          <article className="phone-upload-container">
            <PhoneImport
              goBack={() => {
                this.setState({
                  selector: "",
                });
              }}
            />
          </article>
        </section>
      );
    }
    return (
      <Fragment>
        <div className="upload-header"></div>
        <div className="upload-container">
          <div className="upload-buttons-columns">
            <div className="upload-buttons-title">
              <h3 className="upload-title">See Your Photos on all Products!</h3>
              <p className="upload-subtitle">
                Click below and choose your photo(s).
              </p>
              <p className="upload-subtitle">It's Crazy Eazy. Crazy Fast.</p>
            </div>
            <div className="add-photos-container">
              <h3 className="add-photos-title">Upload your photo(s)</h3>
              {isBrowser && this.renderBrowserBtn()}
              {(isMobile || isTablet) && this.renderMobileTabletBtn()}
              <div className="upload-buttons-container">
                <FilestackUploader // FACEBOOK
                  client={client}
                  filestackSocialUpload={this.filestackSocialUpload}
                  source={"facebook"}
                  icon={
                    "https://storage.googleapis.com/myphoto-img/icons/photopicker/facebook-01.svg"
                  }
                  btnText={"Facebook"}
                  addDefaultSrc={addDefaultSrc}
                  iconClassName={"facebook-icon"}
                />
                {this.state.selector === "instagram" ? ( // INSTAGRAM
                  <OldInstagramUpload
                    onClickHandler={this.selectorType}
                    addDefaultSrc={addDefaultSrc}
                    imageIcon={
                      "https://storage.googleapis.com/myphoto-img/icons/photopicker/instagram-logo.png"
                    }
                    name={"Instagram"}
                  />
                ) : (
                  <FilestackUploader
                    client={client}
                    filestackSocialUpload={this.filestackSocialUpload}
                    source={"instagram"}
                    icon={
                      "https://storage.googleapis.com/myphoto-img/icons/photopicker/instagram-logo.png"
                    }
                    btnText={"Instagram"}
                    addDefaultSrc={addDefaultSrc}
                    iconClassName={"instagram-icon"}
                    selectorType={this.selectorType}
                  />
                )}
              </div>
            </div>
            {/* {(photos && photos.length > 0 && photos[0] !== null ) &&
              <div>
                <h3 className='add-photos-title-2'>Uploaded photo(s)</h3>
                <PhotoPickerImages 
                toggleSelectedImage={this.toggleSelectedImage}
                selectedPhotoIds={selectedPhotoIds}
                photos={photos}/>
              </div>
          } */}
          </div>
          <div className="upload-footer">
            {/* <span onClick={()=>{this.props.dispatch(closeModal('UPLOAD_MODAL'))}}><Link className="need-help-link" to="/faq">Need help?</Link></span> */}
            <span className="secure-text">
              &nbsp;All photos added are 100% secure and never shared.
            </span>
          </div>
        </div>
        {selectedPhotoIds && selectedPhotoIds.length ? (
          <div className="upload-add-button-container">
            <button
              className="upload-add-button cta-btn"
              onClick={(e) => {
                e.preventDefault();
                this.addSelectedImages();
              }}
            >
              ADD {selectedPhotoIds.length} PHOTO(S)
            </button>
          </div>
        ) : null}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    photo: state.photos.photo,
    uploaded: state.photos.uploaded,
    failedUpload: state.photos.failed,
    user: state.user.user,
    project: state.projects.project,
    photos: state.photos.photos,
    zoomed: state.projects.zoomed,
    zoomIndex: state.projects.zoomIndex,
    isMultiphoto: state.projects.isMultiphoto,
  };
}

PhotoPicker.propTypes = propTypes;

export default withRouter(connect(mapStateToProps)(PhotoPicker));

import React, { Component } from 'react'
import autoBind from 'react-autobind'
import PropTypes from 'prop-types'
import MetaDataTags from '../helpers/ProcessMetaData'
import { seoInfo } from '../helpers/seoInfo'
import FaqBtn from '../components/faq/FaqBtn'
//import CustomerExperience from '../components/faq/CustomerExperience';
import MoneyBackGuarantee from '../components/faq/MoneyBackGuarantee';
import CTAsection from '../components/homepage/CTAsection'
import '../assets/stylesheets/page-shell.scss'
import '../assets/stylesheets/faq.scss'



const propTypes = {
    user: PropTypes.object,
}
const Content = {
    cartImage: 'https://storage.googleapis.com/myphoto-img/faq/icons/faq-cart.svg',
    shippingImage: 'https://storage.googleapis.com/myphoto-img/faq/icons/faq-shipping.svg',
    qualityImage: 'https://storage.googleapis.com/myphoto-img/faq/icons/faq-quality.svg',
    giftImage: 'https://storage.googleapis.com/myphoto-img/faq/icons/faq-gifting.svg',
    secureImage: 'https://storage.googleapis.com/myphoto-img/faq/icons/faq-secure.svg',
    corporateImage: 'https://storage.googleapis.com/myphoto-img/faq/icons/faq-corporate.svg',
    largeImage: 'https://storage.googleapis.com/myphoto-img/faq/icons/faq-large.svg',
    otherImage: 'https://storage.googleapis.com/myphoto-img/faq/icons/faq-dots.svg',
}
class FAQ extends Component {
    static contextTypes = {
        router: PropTypes.object,
    }
    constructor (props) {
        super(props)
        this.state = {
            activeKey:''
        }
        autoBind(this)
    }
    componentDidMount() {
        window.gtag('event', 'page_view', {
            'send_to': 'AW-668262331',
            'ecomm_pagetype': 'other'
        });
    }
    handleOpenOption(key){
        this.setState({activeKey: key})
    }
    render() {
        return (
            <div className="shell-container faq-main-container">
                <MetaDataTags baseUrl='faq' seoInfo={seoInfo.faq} />
                <div className="container faq-container">
                    <h1 className='faq-title'>How can we help you?</h1>
                    <p className='faq-subtitle'>Choose a category to find the help you need</p>
                    <div className='faq-category-row'>
                        <FaqBtn
                            categoryTitle ={'Placing an Order'}
                            image={Content.cartImage} 
                            altText ='cart'
                            imageHeight ={'50px'}
                            link={'/faq-placing-order'}
                        />
                        <FaqBtn
                            categoryTitle ={'Shipping/Tracking'}
                            image={Content.shippingImage} 
                            altText ='shipping'
                            imageHeight ={'40px'}
                            link={'/faq-shipping'}
                        />
                        <FaqBtn
                            categoryTitle ={`Photo & Product Quality`}
                            image={Content.qualityImage} 
                            altText ='quality'
                            imageHeight ={'40px'}
                            link={'/faq-quality'}
                        />
                        <FaqBtn
                            categoryTitle ={'Gifting'}
                            image={Content.giftImage} 
                            altText ='gift box'
                            imageHeight ={'45px'}
                            link={'/faq-gifting'}
                        />
                        <FaqBtn
                            categoryTitle ={'Secure Personal Information'}
                            image={Content.secureImage} 
                            altText ='lock'
                            imageHeight ={'50px'}
                            link={'/faq-secure'}
                        />
                        <FaqBtn
                            categoryTitle ={`Corporate Gifting`}
                            image={Content.corporateImage} 
                            altText ='corporate bag'
                            imageHeight ={'40px'}
                            link={'/faq-corporate'}
                        />
                        <FaqBtn
                            categoryTitle ={'Large Volume Orders'}
                            image={Content.largeImage} 
                            altText ='box'
                            imageHeight ={'45px'}
                            link={'/faq-large'}
                        />
                        <FaqBtn
                            categoryTitle ={'Other Questions'}
                            image={Content.otherImage} 
                            altText ='dots'
                            imageHeight ={'13px'}
                            link={'/faq-questions'}
                        />
                    </div> 
                </div>
                {/* <CustomerExperience /> */}
                <MoneyBackGuarantee />
                <CTAsection />
            </div>
        )
    }
}


FAQ.propTypes = propTypes;
FAQ.contextTypes = {
    router: PropTypes.object.isRequired
}
export default FAQ;
